/*
	this.OrdersClass = new OrdersClass();

	THIS CLASS SHOULD HANDLE ALL API CALLS FOR BASIC ORDERS
	GETTING ORDERS
	CREATING ODERS
	DELETE ORDERS, 
	UPDATING ORDERS, 
	CLONE ORDERS, 
	ETC.
*/
import React from 'react';
import {IssuesClass} from 'global-api-helper';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class OrdersClass {
  constructor(props) {
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }

	Get(input, callbackSuccess=()=>{}, callbackFail=()=>{}){
		var {businessID, admin, limit, trueStatus} = input;
    var Pointer = this.Firestore.collection("orders").where("business", "==", businessID).where("trueStatus", "==", trueStatus);
    if(admin){ //IF ADMIN THEN SHOW ALL
      Pointer = this.Firestore.collection("orders").where("trueStatus", "==", trueStatus);
    }
    var LastOrder = Pointer.orderBy("date", "desc").limit(1);
    var ASCOrder = Pointer.orderBy("date").limit(limit);
    var DESOrder = Pointer.orderBy("date", 'desc').limit(limit);

		LastOrder.get()
			.then((querySnapshot) => {
				var lastOrderObj = undefined;
	      querySnapshot.forEach(function(doc) {
	        lastOrderObj = doc.data();
	      });
	      //ONCE GOT LAST THEN GET FIRST AND SET UP PAGINATION
	      ASCOrder.get()
			    .then((querySnapshot) => {
						var fullList = [];
			      querySnapshot.forEach(function(doc) {
			        fullList.push(doc.data());
			      });
			      callbackSuccess(fullList, lastOrderObj, GlobalAPINew.PaginationFunction(ASCOrder, DESOrder, querySnapshot));
			      return querySnapshot;
			    })
			    .catch(info=>{
			      callbackFail(false);
			      console.log(info);
			      return undefined;
			    }); 

	    })
	    .catch(info=>{
	      callbackFail(false);
	      console.log(info);
	      return undefined;
	    }); 	
	}



	GetSingle(orderID, callbackSuccess=()=>{}, callbackFail=()=>{}){
		if(!orderID) return;
		var Pointer = this.Firestore.collection("orders").doc(orderID);
			Pointer.get()
			.then(obj=>{
				callbackSuccess(obj);
			})
			.catch(obj=>{
				callbackFail(obj);
			});	
	}


	Set(order){
		var isValidUser = this.ValidateAllowed();
		if(!isValidUser || !order.business) return;
		var newPointer = this.Firestore.collection("orders").doc();
    order.id = newPointer.id;
    delete order.product;
    return newPointer.set(order);
	}



	
	Delete(id, callbackSuccess=()=>{}, callbackFail=()=>{}){
		if(!id) return;
		var Pointer = this.Firestore.collection("orders").doc(id);
		
		//CHECK IF ANY FILES ARE LINKED TO THIS ORDER. IF SO DELETE THEM FIRST
		Pointer.get()
		.then((snapshot)=>{
			var data = snapshot.data();
			var scans = [];
			data.products.forEach((product, index)=>{
				if(product.scans) product.scans.forEach((scan, index)=>scans.push(scan.path));
			});
			if(scans.length > 0){ //IF LIST OF SCANS EXIST THEN REMOVE EACH FROM DB THEN REMOVE FULL ORDER
				var listOfPromises = scans.map(scan=>{
					return new Promise((resolve, reject)=>{
						GlobalAPINew.RemoveFile(scan, suc=>resolve(suc), fail=>reject(fail));
					});
				});
				Promise.all(listOfPromises)
		    .then((values)=>{
		      //DELETE ORDER
					Pointer.delete()
			    .then((querySnapshot) => {
						callbackSuccess(querySnapshot);
					})
					.catch(info=>{
						callbackFail(info);
					  console.log(info);
					});
					//END OF DELETE ORDER 
		    })
		    .catch((values)=>{
		    	console.log("catch in DeleteOrder()");
		    	console.log(values,"\n\n");
		    });
			} else {
				//DELETE ORDER
				Pointer.delete()
		    .then((querySnapshot) => {
					callbackSuccess(querySnapshot);
				})
				.catch(info=>{
					callbackFail(info);
				  console.log(info);
				});//END OF DELETE ORDER 
			}
		});//END OF GET ORDER
	}



	Update(order, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){	
		return this.ValidateCleanAndSubmitOrder(order, callbackStart, callbackSuccess, callbackFail);
	}

	Save(order, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){
		return this.ValidateCleanAndSubmitOrder(order, callbackStart, callbackSuccess, callbackFail);
	}

	SaveOrder(order, callbackSuccess=()=>{}, callbackFail=()=>{}){  //THIS UPDATES EXISTING ORDER OR CREATES A NEW ONE
		if(!order.monthCreated) order.monthCreated = window.GlobalUtil.getFirstOfMonth();
		if(!order.weekCreated) order.weekCreated = window.GlobalUtil.getWeekNumber();

		var isValidUser = this.ValidateAllowed();
		if(!isValidUser || !order.business) return;
		    	
		if(order.id){ //update
			var Pointer = this.Firestore.collection("orders").doc(order.id);
			Pointer.update(order)
			.then(obj=>{
				callbackSuccess(obj);
				//window.toastr.success('Update Order', 'Success');
			})
			.catch(obj=>{
				callbackFail(obj);
			});
		} else { //Add new
			var Pointer = this.Firestore.collection("orders");
			Pointer.add(order)
			.then(obj=>{
				Pointer.doc(obj.id).update({id: obj.id});
				callbackSuccess(obj);
			})
			.catch(obj=>{
				callbackFail(obj);
			});
		}
	}

	/*
		THIS DOES A LOT
		1) IT CLEANS UP THE ORDER
		2) RUNS ANY START CALLBACK
		3) UPLOADS ANY IMAGES THAT NEED TO BE ADDED
		4) THEN SAVES THE ORDER IF IT EXIST
		5) ADDS THE ORDER AS NEW IF IT DID NOT EXIST
		6) CALLS ANY SUCCESS OR FAIL CALLBACKS
	*/
	ValidateCleanAndSubmitOrder(order, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){
		//SEE IF ORDER HAS ORDER ID, IF NOT ADD IT HERE SO ORDER AND IMAGES HAVE SAME ORDER_ID
    if(!order.id){ 
	    var newOrderPointer = this.Firestore.collection("orders").doc();
	    var orderID = newOrderPointer.id;
	    newOrderPointer.set({id: orderID});
      order.id = orderID;
    }


		//CLEAN UP THE ORDER OBJECT
		var cleanOrder = {
			...order,
			business: order.business ? order.business : window.GlobalUtil.getCurrentBusinessID(), //JUST INCASE ADMIN IS MODIFING IT LET IT STILL GO TO THE SAME ORIGINAL COMPANY NOT ADMIN
			lastModified: Date.now()
		};
		if(!cleanOrder.date) cleanOrder.date = Date.now();

		cleanOrder.total = order.products.reduce((accumulator, product)=>{
			return Number(accumulator)+Number(product.total);
  	}, 0);

  	if(cleanOrder.priceOverride) cleanOrder.total = cleanOrder.priceOverride;

		//ONLY UPDATE FOR NEW ORDERS NOT FOR ORDER THAT HAVE ALLREADY DONE THIS
		if(cleanOrder.doctor && cleanOrder.doctor.name) cleanOrder.doctor = order.doctor.name;
		if(!cleanOrder.status) cleanOrder.status = "pending";
		if(!cleanOrder.trueStatus) cleanOrder.trueStatus = "active";
    
		delete cleanOrder.product;
    //CHECK TO SEE IF IMAGES INCLUDED IF SO THEN UPLOAD THEM ELSE JUST UPLOAD ORDER
    var hasScansToUpload = cleanOrder.products.filter((product, index)=>product.scans);       	       
    if(hasScansToUpload.length > 0){
      this.UPLOAD_FILES(cleanOrder, callbackStart, callbackSuccess, callbackFail);
    } else {
      this.SaveOrder(cleanOrder, callbackSuccess, callbackFail);
    }
	}//---------------------------------------------------------------------------------------------------------------END OF ValidateCleanAndSubmitOrder()


	Clone(order, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){
		callbackStart();
		delete order.date;
    delete order.status;
    delete order.trueStatus;
    delete order.id;
		
		var productsWithExitingImages = order.products.filter((product, index)=>product.scans);
		if(productsWithExitingImages && productsWithExitingImages.length > 0){ //First check to see if has files
			//If so then upload any new files and copy any existing files
			var allPomises = [];
			productsWithExitingImages.forEach((product, index)=>{
	      product.scans.forEach((scan, index)=>{
	        if(scan.ref) allPomises.push(fetch(scan.file, {mode: 'no-cors'}).then(res => res.blob()).then(res => {return {name: scan.name, file: res}} )); //DONT DO FOR NEW IMAGES THAT HAVE NOT YET BEEN UPLOADED
	      });
	    });

			Promise.all(allPomises)
			.then((values)=>{
				order.products = order.products.map((product, index)=>{
					if(product.scans){
						product.scans = product.scans.map((scan, index)=>{
							values.map((newScan, index)=>{
								if(newScan.name === scan.name) scan = newScan;
							})
							return scan;
						});
					}
					return product;
				});
				    	
				this.ValidateCleanAndSubmitOrder(order, ()=>{}, callbackSuccess, callbackFail);
			})
	    .catch((error)=>{
	      callbackFail(error);
	    });
		} else {
			this.ValidateCleanAndSubmitOrder(order, ()=>{}, callbackSuccess, callbackFail);
		} //END OF ELSE IF NO IMAGES JUST RUN
	}






	ReportOrderError(newOrder, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){
		var isValidUser = this.ValidateAllowed();
		if(!isValidUser) return;
		const IssuesClassNew = new IssuesClass()
		const GetTime = window.GlobalUtil.getTimeNow();
		var Issues = {
			id: newOrder.id,
			issue: newOrder.issue,
			business: newOrder.business,
			date: GetTime,
			resolved: false
		};
		
		IssuesClassNew.Save(JSON.parse(JSON.stringify(Issues)))
		.then(obj=>{
			return this.Update(newOrder, callbackStart, callbackSuccess, callbackFail);
		})
		.catch(obj=>{
			callbackFail(obj);
		});
	}



	
	ResolveOrderError(issue, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){
		var isValidUser = this.ValidateAllowed();
		if(!isValidUser) return;
		var IssuesClassNew = new IssuesClass()

		var UpdateOrderToResloved = (issue) => {
			var Pointer = this.Firestore.collection("orders").doc(issue.id);
			Pointer.update({issue: issue})
			.catch(obj=>{
				callbackFail(obj);
			});
		}


		var UpdateOrderToReslovedForAdmin = (issue) => {  	
			issue = {...issue, resolved: true, resolvedDate: window.GlobalUtil.getTimeNow()}
			IssuesClassNew.Update(issue)
			.then(()=> UpdateOrderToResloved(issue))
			.then(()=>{
				callbackSuccess();
			})
			.catch(obj=>{
				callbackFail(obj);
			});
		}
		callbackStart();
		return UpdateOrderToReslovedForAdmin(issue);

	}





	//HELPER FUNCTIONS ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//IVE MADE THEM UPPERCASE TO REMEMBER THEY'RE HELPERS------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

	UPLOAD_FILES(order, callbackStart=()=>{}, callbackSuccess=()=>{}, callbackFail=()=>{}){
    callbackStart();
    
    //GET ALL SCANS IN EACH ORDER
    var AllImagesToUpload = [];
    order.products.filter((product, index)=>product.scans).forEach((product, index)=>{
      product.scans.forEach((scan, index)=>{
        AllImagesToUpload.push(scan);
      });
    });
		
		//ADD THE ORDER ID TO THE PATH OF THE SCANS (SO WE CONNECT THE ORDER TO THE SCAN)
		AllImagesToUpload = AllImagesToUpload.map((scan, index)=>{
			scan.path = `orders/${order.id}/`;
			return scan;
		}) 
    
    //UPLOAD ALL THE IMAGE SCANS
    GlobalAPINew.AsyncUploadImage(AllImagesToUpload)
    .then((values)=>{
      //GET PROPER REFS TO EACH FILE AND SAVE THE ORDER WITH PROPER REFS
      order.products = order.products.map((product, index)=>{
        if(product.scans){
          product.scans = product.scans.map((scan, index)=>{
            return values.filter(value=>value.name === scan.name)[0];
          })
        }
        return(product);
      });
      this.SaveOrder(order, callbackSuccess, callbackFail); //FINALY SET THE ORDER WITH PATH TO SCANS
    })
    .catch((error)=>{
      callbackFail(error);
    });
  }//END OF UPLOAD_FILES

}
