import React from 'react';
import EditPricing from './edit_pricing';
class ProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount(){}
  componentWillUnmount(){}


  render(){
    //var {} = this.state;
    var {products} = this.props;
    const AccordionSingle = window.GlobalComp.AccordionSingle;
    return (
      <div id="productsListAccordion">
        {
          products.map((product, index)=>{
            return(
              <AccordionSingle 
                key={index}
                header={(
                  <div className="title">
                    <h5 style={{margin: "auto", textTransform:'uppercase'}}>{product.name} - <strong>Base Price:</strong> {window.GlobalUtil.convertToMoney(product.add)}</h5>
                  </div>
                )}
              >
                <EditPricing
                  product={product}
                  updateProducts={(newProduct)=>{
                    products[index] = newProduct;
                    this.props.updateProducts(products)
                  }}
                />
              </AccordionSingle>
            )
          })
        }
      </div>
    );
  }
}






export default ProductsList;