import React from 'react';
import EditDoctor from '../../editOrder/doctor';
import EditPatient from '../../editOrder/patient';
import EditProducts from '../../editOrder/products';
import PreviewTotal from '../../previewOrder/total';


class TabNewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.curOrderObject
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave(newOrder){
    this.setState({
      order: newOrder
    });
    this.props.onUpdateObject(newOrder);
  }

  render(){
    const {showLogin, activeTeeth, showPoductModal, showNewUserModal, editProductIndex, order={}} = this.state;
    var {products=[]} = order;
    var productsTotal = products.reduce(function(accumulator, obj) {
      return Number(accumulator)+Number(obj.total);
    }, 0);
    return (
      <React.Fragment>
        <div id="PageContent">
          <div id="Orders">
            <h2 className="tabHeader">New Order</h2>

            <EditDoctor
              order={order}
              onChange={this.onSave}
            />
            <hr/>
            
            <EditPatient
              order={order}
              onChange={this.onSave}
            />
            <hr/>

            
            <EditProducts
              order={order}
              onChange={this.onSave}
            />
            <hr/>

            <PreviewTotal
              order={order}
            />
          </div>
        </div>
        <div id="NavButtons">
          <div className="leftButtons">
            <button className="button cancleButton" onClick={this.props.onCancel}>Cancel</button>
          </div>
          <div className="rightButtons">
            <button className={`button prevButton ${!this.props.currentPage ? 'disabled' : ''}`} onClick={this.props.onPrevPage}>Previous</button>
            <button className={`button nextButton ${this.props.disabled ? 'disabled' : ''}`} onClick={this.props.onNextPage}>Next</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TabNewOrder;
