import React from 'react';
const TableList = (props) => {
  var {headers=[], rows=[], orderBy=[]} = props;
  return(
    <div className="panelItem table-responsive">
      <table className="table">
      <thead>
        <tr>
          {
            headers.map((header, index)=>{
              var {width, name, style, disableOrderBy, key} = header;
              var isActive = ((orderBy && orderBy.length > 0 && orderBy[0] === key) ? true : false);
              return(
                <th 
                  key={index} 
                  scope="col" 
                  width={width} 
                  className={(isActive ? 'active' : '')}
                  style={style}
                  onClick={()=>{
                    if(!disableOrderBy && props.onChangeOrderBy) props.onChangeOrderBy(key);
                  }}>
                    <div style={{"cursor": (disableOrderBy ? 'inherit' : "pointer")}}>
                      <span className="">{name}</span> 
                      {
                        isActive && 
                        <span style={{"paddingLeft":"5px","fontSize":"14px"}}>
                          <i className={`fas ${orderBy[1]  === 'asc'  ? 'fa-chevron-up' : 'fa-chevron-down'}`} /> 
                        </span>
                      }
                    </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, index)=>{
            return(
              <tr key={index}>
                {
                  row.items.map((item, index1)=>{
                    return(
                      <td key={`${index}${index1}`} style={(row.customStyles && row.customStyles[index1] ? row.customStyles[index1] : {})}>{item}</td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
    </div>
  )
}
export default TableList;
