import React from 'react';
import Wrapper from '../components/wrapper';
import Form from '../components/form';
import HowItWorks from '../components/howItWorks';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {NavLink, Link} from 'react-router-dom';
import millingImage from "../../img/milling_tray.jpg";
import workingCrowns from "../../img/working_crowns.jpg";
import TriosReadyLogo from "../../img/3shapeTriosReadyLogo.png";
import ThreeShapeLogo from "../../img/3shapeLogo.png";


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
  }

  render(){
    var {activeStep=0} = this.state;
    var currentScreenWidth = window.screen.width;
    var numberOfIconsInSlider = 6;
    if(currentScreenWidth < 900) numberOfIconsInSlider = 5;
    if(currentScreenWidth < 800) numberOfIconsInSlider = 4;
    if(currentScreenWidth < 700) numberOfIconsInSlider = 3;
    if(currentScreenWidth < 600) numberOfIconsInSlider = 2;
    if(currentScreenWidth < 500) numberOfIconsInSlider = 1;
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: numberOfIconsInSlider,
      slidesToScroll: (numberOfIconsInSlider < 5 ? 1 : 2),
      //autoplay: true,
      arrows: true,
      //autoplaySpeed: 1000,
    };
          
    return (
      <Wrapper>
        <div id="homePage">
          <div id="body">
            <div id="body_item_123" className="section">
              <div className="body_item_123_background">
                <div className="body_item_123_background_overlay">
                </div>
              </div>
              <div className="containerWrapper">
                <div className="container">
                    <div className="row">
                      <div className="col-md">
                        <div className="centerText">
                          <div className="textSection">
                            <h1>A Dental Revolution</h1>
                            <ul className="largeTextBullets">
                              <li>
                                <div><strong>Esthetic Crowns & Bridges</strong></div>
                                <div>Unparallel quality & precision</div>
                              </li>
                              <li>
                                <div><strong>Crowns delivered in 3 days or less!</strong></div>
                                <div>Two weeks is too long!!!</div>
                              </li>
                              <li>
                                <div><strong>One-Year Guarantee on all our Work!</strong></div>
                                <div>Satisfaction guaranteed</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md formContainer">
                        <div className="contactSection">
                          <Form customHeader={<h3 className="headerFormHeader"><strong className="">ORDER YOUR FREE CROWN!</strong></h3>} />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div  id="body_item_124" className="section">
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <h2 className="">Why Choose Ready Crowns?</h2>
                    <p className="subMenu">Wow, there are so many reasons! Here are just a few:</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Slider {...settings}>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-tooth"/>
                          </div>
                          <p className="">Machine Precision Crowns and More</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-medal"/>
                          </div>
                          <p className="">Highest Quality Materials</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-flag-usa"/>
                          </div>
                          <p className="">Proud American Company</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-shipping-fast"/>
                          </div>
                          <p className="">Standard <strong>FREE</strong> 3 day shipping</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="far fa-calendar-alt"/>
                          </div>
                          <p className="">End of Month Billing Option</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-comment-dollar"/>
                          </div>
                          <p className="">Referral Discount Program</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-tachometer-alt"/>
                          </div>
                          <p className="">Easy to Use Ordering System</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="far fa-money-bill-alt"/>
                          </div>
                          <p className="">High Volume Discounts</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-star"/>
                          </div>
                          <p className="">Quality Satisfaction Guaranteed</p>
                        </div>
                      </div>
                      <div className="iconSlide">
                        <div className="iconText">
                          <div className="iconWrapper">
                            <i className="fas fa-map-pin"/>
                          </div>
                          <p className="">Fast Nationwide Shipping</p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div  id="body_item_125" className="section">
              <div className="container">
                <div className="imageTextBox">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-5">
                      <div className="iconText">
                        <div className="iconWrapper">
                          <img src={millingImage} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-7">
                      <div className="textSection">
                        <h2 className="largest">Machine Precision</h2>
                        <p className="">Using the latest CAD/CAM technology, Ready Crowns delivers a durable well-fitting, aesthetically pleasing prosthesis for the patient. This technology allows Ready Crowns to improve the quality of prostheses in dentistry and standardize the production process. We have improved the design and creation of dental restorations, especially dental prostheses, including crowns, bridges and dental implant-supported restorations. Ready Crowns has eliminated the “2 weeks” wait time for a dental prothesis forever. Order your free, no-obligation crown today and see the difference!</p>
                        <a href="#Footer" className="button yellowButton">Sign Up Now!</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  id="body_item_127" className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="image"><img src={workingCrowns} alt="" className=""/></div>
                  </div>
                  <div className="col-12">
                    <h2>What We Do</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="text">
                      <p>In the past 20 years, the dental industry has seen the development of many new all-ceramic materials and restorative techniques fueled by the desire and goal to capture esthetic perfection. This has resulted in the fusion of the latest in material science and the evolution in computer-assisted design/computer-assisted manufacturing (CAD/CAM) technology.</p>
                      <p>Ready Crowns is a leader in this revolution. With highly trained professionals, using state-of-the-art equipment and software, we offer quality, precision and delivery times previously unheard of in dentistry.</p>
                      {/*<div className="image" style={{minWidth: "0", display: "flex"}}>
                        <div style={{minWidth: "300px", flex: "1"}}>
                          <img src={TriosReadyLogo} alt="" style={{width: "100%"}}/>
                        </div>
                        <div style={{minWidth: "300px", flex: "1"}}>
                          <img src={ThreeShapeLogo} alt="" style={{width: "100%"}}/>
                        </div>
                        <div style={{minWidth: "300px", flex: "1"}}>
                          <img src={TriosReadyLogo} alt="" style={{width: "100%"}}/>
                        </div>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  id="body_item_126" className="section blueBackground">
              <div className="container">
                  <div className="row">
                    <div className="col-sm">
                      <h2 className="">Reasons We Rock</h2>
                      <p className="subMenu">Ready Crowns was created by dentists to satisfy the needs and frustrations of dentists. We understand the issues that come from ordering crowns. Order your free, no-obligation crown today and see the difference!</p>
                      <a href="#Footer" className="button yellowButton" style={{"marginBottom": "30px"}}>Learn More</a>
                    </div>
                    <div className="col-sm">
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-4">
                          <div className="iconText">
                            <div className="iconWrapper">
                              <i className="far fa-calendar-alt"/>
                            </div>
                            <p className="">Get your crowns faster</p>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4">
                          <div className="iconText">
                            <div className="iconWrapper">
                              <i className="fas fa-comment-dollar"/>
                            </div>
                            <p className="">Volume discount program</p>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4">
                          <div className="iconText">
                            <div className="iconWrapper">
                              <i className="fas fa-tachometer-alt"/>
                            </div>
                            <p className="">Easy to use ordering system</p>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4">
                          <div className="iconText">
                            <div className="iconWrapper">
                              <i className="fas fa-heart"/>
                            </div>
                            <p className="">Increase patient satisfaction</p>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4">
                          <div className="iconText">
                            <div className="iconWrapper">
                              <i className="fas fa-star"/>
                            </div>
                            <p className="">Highest quality tested materials</p>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4">
                          <div className="iconText">
                            <div className="iconWrapper">
                              <i className="fas fa-map-pin"/>
                            </div>
                            <p className="">Fast 3 Day Shipping on orders</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div  id="body_item_128" className="section purpleBackground">
              <div className="backgroundAngeled purpleBackground">
                <HowItWorks />
              </div>
            </div>
            <div id="body_item_131" className="section">
              <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-lg-3">
                      <div className="iconText">
                        <div className="iconWrapper">
                          <i className="fas fa-comment-dollar"/>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-lg-9">
                      <div className="text">
                        <h2 className="">Ready to Cut Costs, Get Crowns Faster, and Increase Patient Satisfaction? </h2>
                        <p className="">Contact us now to ORDER YOUR FREE CROWN! One of our representatives will follow up with you to answer all your questions and explain how Ready Crowns can exceed your expectations.</p>
                        <a href="#Footer" className="button yellowButton">Contact Us</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Home;
