import React from 'react';
import '../css/invoices.css';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';
import TabInvoicesUnpaid from './tabsInvoices/tab_invoices_unpaid';
import TabInvoicesPaid from './tabsInvoices/tab_invoices_paid';
class Archives extends React.Component {
  render(){
    return (
      <React.Fragment>
        <Tabs
          tabs={[
            {"name": "Unpaid Invoices", "url":"/panel/admin/invoices/"},
            {"name": "Paid Invoices", "url":"/panel/admin/invoices/paid"}
          ]}>
          <Route exact path={["/panel/admin/invoices", "/panel/admin/invoices/unpaid"]} component={TabInvoicesUnpaid} />
          <Route exact path={["/panel/admin/invoices/paid"]} component={TabInvoicesPaid} />
        </Tabs>
      </React.Fragment>
    );
  }
}


export default Archives;













