import React from 'react';
export default class GlobalUtil {
	constructor() {
		this.events = {};
		this.globalVariables = {};
		this.State = new State();
		this.LocalStorage = new LocalStorage();
	}
	
	IsSuperAdminUser(){
		var isSuperAdmin = true;
		var user = window.firebase.auth().currentUser;
		if(!user) isSuperAdmin=false;
		if(!window.Session.AdminBusiness) isSuperAdmin=false;
		return(isSuperAdmin)
	}
	
	IsSuperAdminUserAndTab(){
		var isCorrect = true;
		if(!window.Session.isAdminTab) isCorrect = false;
		if(!this.IsSuperAdminUser()) isCorrect = false;
		return isCorrect;
	}

	getCurrentBusinessID(){
		var BusinessID = undefined;
		if(window.Session && window.Session.Business && window.Session.Business.id) BusinessID = window.Session.Business.id;
		return BusinessID;
	}

	compairTimes(time1, time2=new Date()){
		function millisToMinutesAndSeconds(millis) {
			if(millis < 0) millis = millis * -1;
			    	
		  var minutes = Math.floor(millis / 60000);
		  var seconds = ((millis % 60000) / 1000).toFixed(0);
		  var results = `${minutes}.${seconds}`;
		  return(Number(results));
		}

		var time1InSec = new Date(time1).getTime();
		var time2InSec = new Date(time2).getTime();
		return(millisToMinutesAndSeconds(time1InSec-time2InSec))
	}

	getFirstOfMonth(customDate){
		var today = new Date();
		if(customDate) today = new Date(customDate);
		return today.getStartOfMonth().getTime();
	}

	getFirstOfLastMonth(){
		var today = new Date();
		var endDate = new Date(`${today.getMonth()+1}/1/${1900+today.getYear() }`);
		var startDate = new Date(endDate);
		startDate = new Date(startDate.setMonth(startDate.getMonth()-1));
		return startDate.getTime();
	}
	
	getTimeNow(){
		return new Date().getTime();
	}
	
	convertToDate(dateString, formatString){
		return new Date(Number(dateString)).formatDate(formatString);
	}


	getWeekNumber(customDate){
		var today = new Date();
		if(customDate) today = new Date(customDate);
		var weekNumber = today.getWeek();
		return Number(`${today.formatDate("Y")}${(weekNumber.toString().length < 2 ? 0 : '')}${weekNumber}`)
	}

	camelCaseToCapitalSpace(start){
		var result = start.replace( /([A-Z])/g, " $1" );
    result = result.split(' ');
    result = result.map(word=>{
      return (word.charAt(0).toUpperCase() + word.slice(1))
    })
    return result.join(" ");
	}


	apiFail(id, error){
		console.log("API FAILED "+id);
		console.log(error,"\n\n");
	}

	weekNumberToDate(weekNumber) { //works with this.getWeekNumber();
		if(!weekNumber) return;
		var w = Number(weekNumber.toString().slice(4,6));
		var y = Number(weekNumber.toString().slice(0,4));
    var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    return new Date(y, 0, d);
	}

	capitalizeFirst = (string) => {
		return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
	}

	capitalizeFirstOfEachWord = (string) => {
		if (typeof(string) === typeof('string'))
		{
			var array = string.split(' ');
			var capitalizedArray = array.map(element => this.capitalizeFirst(element));
			return capitalizedArray.join(' ');
		}  
		else return '';
	}


	inputToBool = (input) =>
	{
		if (input === 'true') return true;
		else if (input === true) return true;
		else if (input === '1') return true;
		else if (input === 1) return true;
		else return false;
	}


	htmlTextStripper = (html) => {
		let tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}

	getImageUrl = (sitefile, desiredSize) => {
		let currentURL = '/global/assets/images/unavailable.png';
		if(!sitefile || !sitefile.url) return [currentURL];
		if(!sitefile.image_urls || Number(sitefile.images_resized) !== 1 || !desiredSize || desiredSize.length == 0) return [sitefile.url];
		let arrayOfImages =  desiredSize.map((size, index)=>{
			let imageUrl = sitefile.image_urls[size] ? sitefile.image_urls[size] : sitefile.url;
			return imageUrl;
		})
		return arrayOfImages
	}

	convertToMoney = (newNumb, freeValue) => {
		if (freeValue && Number(newNumb) == 0) return freeValue;
		if(!Number.prototype.format) Number.prototype.format = function(n, x, s, c) {
		let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
				num = this.toFixed(Math.max(0, ~~n));

			return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
		};
		return `$${(Number(newNumb)).format(2)}`;
	}

	numberWithCommas = (x, decNumb) => {
		var numberOfDec = ((decNumb) ? decNumb : 2);
		var parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		if(parts[1] || decNumb) parts[1] = Number(`.${((parts[1]) ? parts[1] : 0)}`).toFixed(numberOfDec).split('.')[1];
		return parts.join(".");
	}

	//Check to see if on mobile ios device using safari
	iOSMobileSafariChecker = () => {
		var ua = window.navigator.userAgent;
		var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
		var webkit = !!ua.match(/WebKit/i);
		var iOSSafari = iOS && webkit && !ua.match(/CriOS/i); //IF THIS IS TRUE THEN YES IT IS A STUPID APPLE DEVICE
		return(iOSSafari) //Return bool
	}

	delay = (duration) => new Promise(resolve => setTimeout(resolve, duration));


	
	decycle(obj, stack = []) { //ALLOWS STRINGIFY OF CLASS
    if (!obj || typeof obj !== 'object')
        return obj;
    
    if (stack.includes(obj))
        return null;

    let s = stack.concat([obj]);

    return Array.isArray(obj)
        ? obj.map(x => this.decycle(x, s))
        : Object.fromEntries(
            Object.entries(obj)
                .map(([k, v]) => [k, this.decycle(v, s)]));
	}

	// purpose
	//   copy to Object
	// args
	//   key (unique identifier)
	//   value (object)
	// returns
	//   (none)
	copyObject = (obj) => {
		return(JSON.parse(JSON.stringify(obj)))
	}

	// purpose
	//   paste an object
	// args
	//   key (unique identifier)
	//   value (object)
	// returns
	//   (none)
	pasteObject = (key) => {
		return this.clipboard[key];
	}

	// purpose
	//   copy text to clipboard
	//   (there may be some problems with IE 10 and below)
	// args
	//   stringToCopy
	// returns
	//   (none)
	copyToClipboard = (string) => {
		var textArea = document.createElement("textarea");
		textArea.value = string;
		document.body.appendChild(textArea);
		textArea.select();
		try {
			// for IOS (You may think this code is not necessary but seems to be the only way ios would work)
			if (this.iOSMobileSafariChecker())
			{
				var el = textArea;
				var editable = el.contentEditable;
				var readOnly = el.readOnly;
				el.contentEditable = true;
				el.readOnly = false;
				var range = document.createRange();
				range.selectNodeContents(el);
				var sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange(range);
				el.setSelectionRange(0, 999999);
				el.contentEditable = editable;
				el.readOnly = readOnly;
			}
			// End for IOS

			var successful = document.execCommand('copy');
			if(window.toastr) window.toastr.success("Copied to Clipboard")
		} catch (err) {
			console.log('Oops, unable to copy');
		}
		document.body.removeChild(textArea);
	}


	ulify = (string='', character_limit) =>
	{
		if (string == undefined) return '';
		return (string.length <= character_limit) ? string : string.substring(0, character_limit) + "...";
	}

	getArrayFromDictionary = (dictionary) => {
		var array = [];
		Object.keys(dictionary).map(function(element)
		{
			array.push(dictionary[element]);
		});
		return array;
	}

	getRandomId = () => {
		var FirstRandomLetter = Math.random().toString(36).replace(/[^A-Za-z]+/g, '').substr(0, 1); //without this they could have an random id that starts with a number.
		return FirstRandomLetter+Math.random().toString(36).slice(2);
	}


	getRandomIdList = (howMany=1) => {
		return new Array(howMany).fill(1).map(cur=>this.getRandomId());
	}

	getRandomNumber = (min, max) =>
	{
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	deepGetFromString = (obj, key, defaultReturn, delimiter = ',') => {
		return this.deepGetFromArray(obj, key.split(','), defaultReturn);
	}

	deepGetFromArray = (obj, array, defaultReturn) => {
		var compareObject = obj;
		for (var i = 0; i < array.length; i++)
		{
			if (compareObject[array[i]] === undefined || compareObject[array[i]] === null) return defaultReturn;
			compareObject = compareObject[array[i]];
		}
		return compareObject;
	}

	deepSetFromString = (obj, key, newValue) => {
		return this.deepSetFromArray(obj, key.split(","), newValue);
	}

	deepSetFromArray = (obj, array, newValue) => {
		var foundSoFar;
		var remainingProps = array.slice(1);

		if (obj[array[0]] === undefined || obj[array[0]] === null) {
			obj[array[0]] = {};
		}
		foundSoFar = obj[array[0]];

		if (remainingProps.length === 0) {
			return obj[array[0]] = newValue;
		}

		return this.deepSetFromArray(foundSoFar, remainingProps, newValue);
	}

	isEquivalent = (a, b) => {
		let aProps = Object.getOwnPropertyNames(a);
		let bProps = Object.getOwnPropertyNames(b);

		if (aProps.length != bProps.length) {
			return false;
		}
		for (let i = 0; i < aProps.length; i++) {
			let propName = aProps[i];
			if (a[propName] !== b[propName]) {
				return false;
			}
		}
		if (JSON.stringify(a) !== JSON.stringify(b)) {
			return false;
		}

		return true;
	}


	// whether or not it successfully set config value
    processEvent(eventKey, args)
    {
    	if (this.events[eventKey] !== undefined) {
    		Object.keys(this.events[eventKey]).forEach(key => {
    			if(typeof this.events[eventKey][key] === 'function') this.events[eventKey][key](args);
    		})
    	}
    }

    subscribeEvent(eventKey, method)
    {
    	if (this.events[eventKey] === undefined) this.events[eventKey] = {};
    	let randomKey = this.getRandomId();
    	this.events[eventKey][randomKey] = method;
    	return randomKey;
    }

    unsubscribeEvent(eventKey, methodKey)
    {
    	if (this.events[eventKey] !== undefined && this.events[eventKey][methodKey] !== undefined) delete this.events[eventKey][methodKey];
    }


	padZeros = (string, number_of_zeros) =>
	{
		string = String(string);
		while(string.length < number_of_zeros) string = '0' + string;
		return string;
	}

	isChild(parent, child, checkIgnores = true) {

		// fix for unmounting elements
		if (this.isEmpty(child.parentElement) && child.tagName != 'HTML') return true;

		// first check ignores
		if (checkIgnores) {
			let foundParent = false;
			this.ignoreComponents.forEach(component => 
			{
				if (!foundParent && this.isChild(component, child, false)) foundParent = true;
			})

			Object.keys(this.ignoreComponentQueries).map(key => {
				let elements = document.querySelectorAll(this.ignoreComponentQueries[key]);
				elements.forEach(element => 
				{
					if (!foundParent && this.isChild(element, child, false)) foundParent = true;
				})
			})


			if (foundParent) return foundParent;
		}

		// parent = javascript element
		// child = javascript element
		if (!parent) return false;
		if (parent == child) return true;
		if (parent && child && parent.classList == child.classList) return true;
		else if (child.parentElement) return this.isChild(parent, child.parentElement, false);
		
		else return false;
	}



	setGlobalVariable(key, value) {
		this.globalVariables[key] = value;
	}

	getGlobalVariable(key, defaultValue) {
		if (this.globalVariables[key] === undefined) this.globalVariables[key] = defaultValue;
		return this.globalVariables[key];
	} 

	addNumberSuffix(i) {
		let j = i % 10, k = i % 100;
	    if (j == 1 && k != 11) return i + "st";
	    if (j == 2 && k != 12) return i + "nd";
	    if (j == 3 && k != 13) return i + "rd";
	    return i + "th";
	}

	compareTwoObjects(a, b)
	{

		let foundInconsistancy = false;

		Object.keys(a).forEach(x => {
			if (a[x] != b[x]) foundInconsistancy = true;
		})

		Object.keys(b).forEach(x => {
			if (a[x] != b[x]) foundInconsistancy = true;
		})

		return !foundInconsistancy
	}


	scrollTo(hash){
		if(!hash) return;
		var element = document.getElementById(`${hash}`);
		if(element) window.scrollTo(0, element.offsetTop);    	
	}


	formatPhoneClean(input){
		input = input.replace(/\D/g,'');
		input = input.slice(0,10);
		return(input)
	}

	formatPhone(input){
		var cleaned = ('' + input).replace(/\D/g, '');
		var match = [cleaned.slice(0,3), cleaned.slice(3,6), cleaned.slice(6,10)];
		return match
	}

	dateSeconds(dt=Date.now()){ //THESE WILL BE THE STANDARDS I USE IN MY DATABASE
		var date = new Date(dt);
		return(date.getTime())
	}

	dateString(dt=Date.now()){ //THESE WILL BE THE STANDARDS I USE IN MY DATABASE
		var date = new Date(dt);
		return(date.toString())
	}
		
	checkOrderIsValid(order){
		var valid = true;
		if(!order.patient) valid = false;
		if(!order.doctor || (typeof order.doctor === "object" && !order.doctor.name)) valid = false;
		if(!order.products || order.products.length < 1) valid = false;
		if(!order.payment) valid = false;
		if(!order.shipping) valid = false;
		return valid;
	}
	// dateStringSimple(dt=Date.now()){
	// 	var date = new Date(dt);
	// 	return(newDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
	// }

	states(){
		return({
			"AL": "Alabama",
			"AK": "Alaska",
			"AZ": "Arizona",
			"AR": "Arkansas",
			"CA": "California",
			"CO": "Colorado",
			"CT": "Connecticut",
			"DE": "Delaware",
			"DC": "District Of Columbia",
			"FL": "Florida",
			"GA": "Georgia",
			"HI": "Hawaii",
			"ID": "Idaho",
			"IL": "Illinois",
			"IN": "Indiana",
			"IA": "Iowa",
			"KS": "Kansas",
			"KY": "Kentucky",
			"LA": "Louisiana",
			"ME": "Maine",
			"MD": "Maryland",
			"MA": "Massachusetts",
			"MI": "Michigan",
			"MN": "Minnesota",
			"MS": "Mississippi",
			"MO": "Missouri",
			"MT": "Montana",
			"NE": "Nebraska",
			"NV": "Nevada",
			"NH": "New Hampshire",
			"NJ": "New Jersey",
			"NM": "New Mexico",
			"NY": "New York",
			"NC": "North Carolina",
			"ND": "North Dakota",
			"OH": "Ohio",
			"OK": "Oklahoma",
			"OR": "Oregon",
			"PA": "Pennsylvania",
			"PR": "Puerto Rico",
			"RI": "Rhode Island",
			"SC": "South Carolina",
			"SD": "South Dakota",
			"TN": "Tennessee",
			"TX": "Texas",
			"UT": "Utah",
			"VT": "Vermont",
			"VI": "Virgin Islands",
			"VA": "Virginia",
			"WA": "Washington",
			"WV": "West Virginia",
			"WI": "Wisconsin",
			"WY": "Wyoming"
		})
	}

}

class State {
	constructor() {
		this.states = {};
		this.statesChangeCallbacks = {}
		this.subscribeKeyIndex = 0;
	}

	set(key, state) {
		if (!this.states[key]) this.states[key] = {};
		this.states[key] = {...this.states[key], ...state};
		if (this.statesChangeCallbacks[key])
		{
			Object.keys(this.statesChangeCallbacks[key]).forEach(x => this.statesChangeCallbacks[key][x](this.states[key]));
		}
	}

	get(key) {
		return this.states[key] ? this.states[key] : undefined;
	}

	clear(key) {
		delete this.states[key];
		delete this.statesChangeCallbacks[key];
	}

	subscribe(key, callback) {
		let subscribeKey = this.subscribeKeyIndex++;
		if (!this.statesChangeCallbacks[key]) this.statesChangeCallbacks[key] = {};
		this.statesChangeCallbacks[key][subscribeKey] = callback;
		return subscribeKey;
	}

	unsubscribe(key, subscribeKey) {
		if (this.statesChangeCallbacks[key]) delete this.statesChangeCallbacks[key][subscribeKey];
	}
}


class LocalStorage {
	constructor(){
		this.states = {};
		this.get = this.get.bind(this);
		this.checkAllExpired = this.checkAllExpired.bind(this);
		this.checkAllExpired();
	}

	checkAllExpired(){
		Object.keys(window.localStorage).map((key, index)=>{
			if(key.search('_experation') == -1) this.checkExpired(key);
		})
	}

	checkExpired(key){
		//Check each item in local storage and check experation date
		//if it's expired then remove it
		var today = new Date();
   	var lastupdate = new Date(window.localStorage.getItem(`${key}_experation`));
   	if(lastupdate.getTime() < today.getTime()){
      	window.localStorage.removeItem(key);
      	window.localStorage.removeItem(`${key}_experation`);
    	}
	}

	set(key, object, experationDate) {
		window.localStorage.setItem(key, JSON.stringify(object));
      window.localStorage.setItem(`${key}_experation`, JSON.stringify(experationDate));
	}

	get(key) {
		this.checkExpired(key);
		var item = window.localStorage.getItem(key);
		if(!item) item = "";
		return JSON.parse(item);
	}

	remove(key) {
		window.localStorage.removeItem(key);
      window.localStorage.removeItem(`${key}_experation`);
	}
}


window.GlobalUtil = new GlobalUtil();
