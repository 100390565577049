import React from 'react';
import DiscountTiersList from './tabDiscounts/discount_tiers_list';
import {ProductsClass, UsersClass, BusinessClass, PendingClass} from 'global-api-helper';
import {DiscountsClass} from 'global-api-helper';
class TabDiscouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: [],
      loading: true
    };
    this.getAllDiscounts = this.getAllDiscounts.bind(this);
    this.updateDiscount = this.updateDiscount.bind(this);
    this.DiscountsClass = new DiscountsClass();
          
  }
  componentDidMount(){
    this.getAllDiscounts();

  }
  componentWillUnmount(){

  }

  getAllDiscounts(){
    //BECAUSE THIS IS JUST ADMIN, ILL ALLOW AN API FOR EACH SCREEN LOAD. NOT TOO MUCH TRAFFIC
    this.DiscountsClass.GetAll()
    .then(success=>{
      this.setState({
        loading: false,
        discounts: success
      });
    })
    .catch(fail=>{
      this.setState({
        loading: false,
        discounts: []
      });
    });
  }


  updateDiscount(newDiscount){
    var {discounts, loading} = this.state;
    this.setState({loading: true});
    this.DiscountsClass.Update(newDiscount)
    .then(success=>{
      discounts = discounts.map((discount, index)=>{
        if(discount.id == newDiscount.id) return newDiscount;
        return discount;
      })
      this.setState({
        discounts: discounts,
        loading: false
      });
    })
    .catch();
    
  }
  render(){
    var {discounts, loading} = this.state;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    return (
      <div className="scrolableMaxHeight">
        <h5 className="panelTitle panelTitleWithButtons"><span className="text">List of Discount Options</span></h5>
        {
          loading
          ? <div id="InvoicePreviewDialog">
              <LoadingComponent 
              />
            </div>
          : <div className="panelItem">
              <DiscountTiersList
                discounts={discounts}
                updateDiscount={this.updateDiscount}
              />
            </div>
        }
      </div>
    );
  }
}

export default TabDiscouts;