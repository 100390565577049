import React from 'react';
const Products = (props) => {
  var {order, showEdit, onEdit} = props;
  var {products=[]} = order; 
  return (
    <div className="sectionIconLeft">
      <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-tooth" /></div>
      <div className="sectionIconLeftContent">
        <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-tooth" /></div>Products {products.length < 1 && window.GlobalComp.Required} {showEdit && <i className="fas fa-pen editIcon" onClick={()=>onEdit("products")}></i>}</div>
        <div className="inputPatient">
          {
            products.length > 0
            && products.map((object, index)=>{
                var {type={}, teeth={}, enclosed={}, material={}, occlusalstains={}, incisalshade={}, bodyshade={}, translucency={}, notes={}, total=0} = object;
                return(
                  <div key={index} className="productSumFull">
       
                    <div className="quickInfo">
                      <div className="productType">
                        <div className="sectionProductHeader">Type</div>
                        <div className={"productQuickInfoText" + (!type.value && " none")}>{(type.value ? type.value : "None")}</div>
                      </div>
                      <div className="productTeeth">
                        <div className="sectionProductHeader">Teeth</div>
                        <div className={"productQuickInfoText" + (!teeth && !teeth.length > 0 && " none")}>{(teeth && teeth.length > 0 ? teeth.map(tth=>tth.value).join(',') : "None")}</div>
                      </div>
                      <div className="productEnclosed">
                        <div className="sectionProductHeader">Enclosed</div>
                        <div className={"productQuickInfoText" + (!enclosed.value && " none")}>{(enclosed.value ? enclosed.value : "None")}</div>
                      </div>
                      <div className="productMaterial">
                        <div className="sectionProductHeader">Material</div>
                        <div className={"productQuickInfoText" + (!material.value && " none")}>{(material.value ? material.value : "None")}</div>
                      </div>
                      <div className="productOcclusalstains">
                        <div className="sectionProductHeader">Occlusal Stains</div>
                        <div className={"productQuickInfoText" + (!occlusalstains.value && " none")}>{(occlusalstains.value ? occlusalstains.value : "None")}</div>
                      </div>
                      <div className="productTranslucency">
                        <div className="sectionProductHeader">Translucency</div>
                        <div className={"productQuickInfoText" + (!translucency.value && " none")}>{(translucency.value ? translucency.value : "None")}</div>
                      </div>
                      <div className="productIncisalshade">
                        <div className="sectionProductHeader">Incisal Shade</div>
                        <div className={"productQuickInfoText" + (!incisalshade.value && " none")}>{(incisalshade.value ? incisalshade.value : "None")}</div>
                      </div>
                      <div className="productBodyshade">
                        <div className="sectionProductHeader">Body Shade</div>
                        <div className={"productQuickInfoText" + (!bodyshade.value && " none")}>{(bodyshade.value ? bodyshade.value : "None")}</div>
                      </div>
                    </div>
                    <hr/>
                    <div className="productNotes">
                      <div className="sectionHeader">Notes</div>
                      {notes.value!=="None" && <div className="productNotesText">{notes.value}</div>}
                      <hr/>
                      <div className="sectionHeader">Total <span className="productNotesText">{window.GlobalUtil.convertToMoney(Number(total))}</span></div>
                    </div>
                    <br/>
                  </div>
                )
              })
          }
        </div>
      </div>
    </div>
  );
}

export default Products;
