import React from 'react';
//import CryptoJS from 'crypto-js';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';
// import { createBrowserHistory } from "history";
//render= ONLY LOADS ONCE WHILE component= LOADS EACH TIME THIS REFRESHES
//import axios from 'axios';
import './css/app.css';
import Home from './frontend/pages/home';
import Terms from './frontend/pages/terms_of_use';
import TermsOfTrade from './frontend/pages/terms_of_trade';
import FAQ from './frontend/pages/faq';
import Privacy from './frontend/pages/privacy';
import LoginPage from './frontend/pages/login';


import LeadPage1 from './frontend/lead_page1/index';
import LeadPage2 from './frontend/lead_page2/index';


import EmailConfirm from './emailPages/email_confirm';


import BackEnd from './backend/pages/validate-backend-layer';


// window.EmailTest = ()=>{
//   var ciphertext = CryptoJS.AES.encrypt(JSON.stringify({
//     Destination: {
//       "ToAddresses": ["danielezaldivar@gmail.com"]
//     },
//     Template: "emailtemplatewelcome1",
//     TemplateData: JSON.stringify({
//       name: "David Smithson",
//       companyName: "Monkey Butt",
//       confirmKey: "COMPANY_NAME"
//     })
//   }), process.env.REACT_APP_SECRET).toString(); 
//   console.log(`ciphertext`,"\n\n",ciphertext,"\n\n");
//   axios.defaults.baseURL = ((process.env.NODE_ENV === 'development') ? `http://localhost:3001` : `https://clubsonriasa.com`);
//   axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//   axios.post('/api/email', 
//       JSON.stringify({
//         data: ciphertext
//       })
//     )
//     .then(function(response) {
//         console.log(response.data);
//         return response.data;
//     })
//     .catch(function(error) {
//         console.log("error", error);
//         return error;
//     });
// }
//const HISTORY = createBrowserHistory();
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      //history: createBrowserHistory(),
      //business: window.Session.Business
    };
  }

  componentDidMount(){
    if(this.history) window.Router = this.history;
  }

  componentWillUnmount(){
  }

  render(){
    var {showLogin} = this.state;
          
    return (
      <Router ref={ref=>this.history=ref}>
        <div id="ReadyCrown">
          <Switch>
            <Route exact path={["/", "/home"]} render={props=><Home {...props}/>} />
            <Route exact path={["/terms", "/Terms"]} render={props=><Terms {...props}/>} />
            <Route exact path={["/terms_of_trade", "/Terms_of_trade"]} render={props=><TermsOfTrade {...props}/>} />
            <Route exact path={["/faq", "/Faq", "/FAQ"]} render={props=><FAQ {...props}/>} />
            <Route exact path={["/privacy", "/Privacy"]} render={props=><Privacy {...props}/>} />
            <Route path={["/login", "/Login"]} render={props=><LoginPage {...props}/>} />



            {/*
              SEO PAGES
            */}
            <Route exact path={["/scanner", "/Scanner"]} render={props=><LeadPage1 {...props}/>} />
            <Route exact path={["/fast", "/Fast"]} render={props=><LeadPage2 {...props}/>} />




            {/*
              EMAIL PAGES
            */}
            <Route exact path={["/emailconfirmation"]} render={props=><EmailConfirm {...props}/>} />
            


            {/*<Route
              exact 
              path={["/panel"]}
              render={props => {
                return(
                  business 
                  ? <ComingSoon business={business} /> 
                  : <Redirect
                      to={{
                        pathname: "/"
                      }}
                    />
                )
              }}
            />*/}
            <Route path={["/panel"]} render={props=><BackEnd {...props} />} />
            <Route>
               <Redirect to="/" /> 
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}


export default App;


/*
var element = document.getElementById(`${location.hash.slice(1,)}`);
console.log("element.offsetTop", element.offsetTop)
window.scrollTo(0, element.offsetTop)

NEXT TIME

MAKE MOBILE MENU APPEAR AND WORK
MAKE DUMMY BACKEND AREA AND MENUS
  USER
  HISTORY
  ORDER CROWN

MAKE LINKS NAV TO BACK END

AT END OF DAY------------------------
npm run build
copy build to server and rename ReadyCrown

*/
