import React from 'react';
import AccordionSingle from "./components/accordion_single";
import AccordionList from "./components/accordion_list";
import DialogWrapper from "./components/dialog_wrapper";
import DropdownSelect from "./components/dropdown_select";
import MultipleSelect from "./components/multiple_select";
import InputBoolRadio from "./components/input_bool_radio";
import InputNumberMoney from "./components/input_number_money";
import InputText from "./components/input_text";
import FormBuilder from "./components/form_builder";
import ProductFormBuilder from "./components/product_form_builder";
import Loading from "./components/loading";
import ConfirmationDialog from "./components/confirmation_dialog";

//THESE GLOBAL COMPONENTS CAN BE USED ANYWHERE WITHOUT NEEDING TO FIND PATH TO IMPORT THEM
//EXAMPLE: var AccordionSingle = window.GlobalComp.AccordionSingle;
window.GlobalComp = {
	AccordionSingle: AccordionSingle,
	AccordionList: AccordionList,
	DialogWrapper: DialogWrapper,
	DropdownSelect: DropdownSelect,
	MultipleSelect: MultipleSelect,
	InputBoolRadio: InputBoolRadio,
	InputNumberMoney: InputNumberMoney,
	InputText: InputText,
	FormBuilder: FormBuilder,
	ProductFormBuilder: ProductFormBuilder,
	Loading: Loading,
	Required: (<span className="form-group" style={{"margin": "-10px 0 0 0"}}><small className="form-text red-text text-muted" style={{"display": "inline"}}>Required</small></span>),
	Confirm: ConfirmationDialog
}; 
