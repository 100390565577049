import React from 'react';
import '../css/side_menu.css';
import {NavLink, Link} from 'react-router-dom';


class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Business: window.Session.Business
    };
    this.onChangeBusinessListener = window.GlobalUtil.subscribeEvent("businessChanged", ()=>{
      this.setState({Business: window.Session.Business})
    });
  }


  componentWillUnmount(){
    if(this.onChangeBusinessListener) window.GlobalUtil.unsubscribeEvent("businessChanged", this.onChangeBusiness);
  }

  render(){
    var {showSideMenu, admin, isVR} = this.props;
    var {Business={}} = this.state;
    var {companyName, hasInvoiceLocations, hasShippingLocations} = Business;          
    var hasProducts = (window.Session.Products && window.Session.Products[0] && window.Session.Products[0].add !== undefined);
    return (
      <div id="SideMenu" className={`${(admin ? 'admin' : '')} ${(showSideMenu ? 'showSideMenu' : '')}`}>
        <div id="SideMenuTriggerButton" className="d-flex d-sm-flex d-md-none d-lg-none d-xl-none" onClick={this.props.toggleMobileMenu}>
          <i className="fas fa-bars" />
        </div>
        <div id="SideMenuLogo">
          <div className="logoContainer">
            {
              isVR
              ? <div className="">{window.Session.Business.companyName}</div>
              : <NavLink to="/panel" className="mainLogo"><img src={(admin ? window.CONST.SITE.logo.light : window.CONST.SITE.logo.dark)} alt="" className="" /></NavLink>
            }
            <NavLink to="/panel" className="iconLogo"><img src={window.CONST.SITE.logo.darkSVG} alt="" className="" /></NavLink>
          </div>
        </div>
        <div id="SideMenuNavigation">
          {
            admin 
            ? <ul>
                <li>
                  <NavLink exact to="/panel" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-tachometer-alt" /><span className="">Dashboard</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/admin/businesses" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-user" /><span className="">Businesses</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/admin/orders" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-tasks" /><span className="">Orders</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/admin/invoices" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-archive" /><span className="">Invoices</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/admin/pricing" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-coins" /><span className="">Pricing</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/admin/contacts" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="far fa-envelope" /><span className="">Contacts</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            : <ul>
                <li>
                  <NavLink exact to="/panel" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-tachometer-alt" /><span className="">Dashboard</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/orders" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-tasks" /><span className="">Orders</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/invoices" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-archive" /><span className="">Invoices</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/users" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-user" /><span className="">Users</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/panel/settings" onClick={()=>{
                    if(showSideMenu) this.props.toggleMobileMenu()
                  }}>
                    <div className="navButton">
                      <i className="fas fa-cog" /><span style={{flex: 1}}>Settings</span>{!hasInvoiceLocations || !hasShippingLocations ? <i className="fas fa-exclamation-circle" /> : null}
                    </div>
                  </NavLink>
                </li>
              </ul>
          }
          
        </div>
        <div id="AddNew">
          <a className={"button purpleButton " + (hasProducts ? '' : 'disabled')} onClick={()=>{
            if(showSideMenu) this.props.toggleMobileMenu();
            window.GlobalUtil.processEvent("triggerNewOrderEvent", true);
          }}>
            <i className="fas fa-tooth" /> New Order
          </a>
        </div>
        {
          !isVR 
          && <div id="Logout">
                <a onClick={this.props.onLogout}><span><i className="fas fa-power-off" /> <span className="buttonText">Logout</span></span></a>
              </div>
        }

        {
          isVR
          && <div id="CloseVRBusiness" onClick={()=>{
                window.GlobalUtil.processEvent("closeVREvent", true);
              }}>
              <span>CLOSE VR</span> 
              <i className="fas fa-window-close" />
            </div>
        }      
      </div>
    );
  }
}


export default SideMenu;
