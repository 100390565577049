import React from 'react';
import Wrapper from '../components/wrapper';
import {NavLink, Link} from 'react-router-dom';


const TermsAndConditionsOfUse = (props) => {
  return (
    <Wrapper>
      <div id="termsPage">
        <div id="body">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                  <div className="contactSection">
                    <h5>Terms Quick Nav</h5>
                    <ul className="sectionQuickNav">
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms", "hash":"Section1"}}>Welcome to ReadyCrowns</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section2"}}>Copyrights/Trademarks</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section3"}}>Typographical Errors & Modifications</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section4"}}>Limitation of Liability/No Warranties</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section5"}}>Confidentiality of User Communications</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section6"}}>Access to Password Protected/Secure Areas</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section7"}}>Term/Termination/Violation of Terms and Conditions</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/terms", "hash":"Section8"}}>Miscellaneous</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                  <div className="centerText">
                    <div className="textSection">
                      <h1>Terms and Conditions of Use</h1>
                      <h4 id="Section1">Welcome to ReadyCrowns’ online website.</h4>
                      <p>These terms and conditions govern the use of the website <a href="www.readycrowns.com">www.readycrowns.com</a>. Please read the following terms and conditions of use carefully, before using this website. These terms and conditions are subject to change without prior notice. Therefore, if you are a regular user of this website, please review these terms and conditions on a regular basis.</p>

                      <p>Please note that this website is intended solely for adult use and we do not market any products or services to children through this website. Please do not use this website if you are under eighteen (18) years of age.</p>

                      <p>By accessing and using this website, you acknowledge that you have read and agree to the following terms, conditions and other applicable federal, state and local laws and regulations.</p>

                      <h4 id="Section2">Copyrights / Trademarks</h4>

                      <p>The entire content of this website, including but not limited to images, text, code, materials and other components or elements herein are copyrighted materials owned by ReadyCrowns or other third parties, and are protected by United States, worldwide copyright and trademark laws and treaty provisions. Therefore, you may not use, copy, reproduce, publish, modify, transmit, upload or distribute the contents or elements of this website in any way without ReadyCrowns’ express prior written permission. This website may be used for informational purposes, online ordering of products, creating and accessing ReadyCrowns user accounts; but, may not be used to commercialize or market any product or service without our express written permission. Permission is granted to electronically copy and print hard copy portions of this website for the sole purpose of placing an order with the ReadyCrowns website shopping cart and/or user account; thus, solely for your own non-commercial use. Except as expressly provided herein, ReadyCrowns does not grant to users or individuals who access this website, any express or implied rights under any patents, copyright, trademarks, or trade secrets. Copy of any portion of this website thereof must be maintained in an unchanged fashion, include the copyright notices as it appears on this website.</p>

                      <p><u>All rights are hereby expressly reserved.</u></p>

                      <h4 id="Section3">Typographical Errors & Modifications</h4>

                      <p>Though we at ReadyCrowns try to make sure that all prices posted on this website are accurate at all times, we cannot be responsible for typographical and other errors that may appear on this website. At any time, the content published on this website could be outdated or contain technical inaccuracies. If the posted price for a product or service is incorrect due to mistake, typographical or other errors, or wrong number of Free Goods in a promotion were entered by mistake, we are only responsible for the correct price and the correct promotion. ReadyCrowns reserves the right to refuse or cancel any orders placed for product listed at the incorrect price. Furthermore, ReadyCrowns reserves the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit card charged. For more information regarding a charged matter, please contact the accounting department in ReadyCrowns during regular business hours. We will make every effort to provide you the most accurate, up-to-the-minute information; however, it is ultimately your responsibility, as a user, to verify that all details listed are accurate. Some images and descriptions posted on our website pages are the representations provided by manufacturers and/or our suppliers. Please note that the actual products may differ slightly from specifications and/or the pictures contained in our online catalog, viewing areas or order forms. Thus, ReadyCrowns is not responsible for typographical, pricing, product information, advertising or shipping errors.</p>

                      <p><u>Advertised prices, discounts and available quantities are subject to change without prior notice.</u></p>

                      <h4 id="Section4">Limitation of Liability / No Warranties</h4>

                      <p><strong>READYCROWNS MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THIS WEBSITE'S CONTENT OR THE CONTENT OF ANY WEBSITE WHICH MAY BE LINKED TO IT FROM THIS WEBSITE. TO THE EXTENT PERMISSIBLE BY LAW, READYCROWNS DISCLAIMS ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. AS SUCH, YOU AGREE TO HOLD HARMLESS AND DEFEND ANY OF READYCROWNS’OFFICERS, DIRECTORS OR EMPLOYEES, AGAINST LIABILITY FOR ALL USE OF THIS WEBSITE AND IN ANY ACTION INCLUDING BUT NOT LIMITED TO CONTRACT, TORT, AND STRICT LIABILITY (PRODUCT OR TORT), THAT MAY RESULT IN PUNITIVE, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS WEBSITE OR A LINKED WEBSITE, OR WITH THE FAILURE TO USE THIS WEBSITE, EVEN IF READYCROWNS WAS MADE AWARE OF THE POSSIBILITY OF SUCH DAMAGES. FURTHERMORE, READYCROWNS DOES NOT WARRANT THAT THIS WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF COMPUTER VIRUSES. READYCROWNS CANNOT AND DOES NOT GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE WEBSITE.</strong></p>

                      <p>In an attempt to enhance your knowledge and to add value to your experience while using this website, ReadyCrowns may provide links for your convenience to other internet websites from this one. ReadyCrowns specifically disclaims any responsibility and further, does not control the contents of any other internet websites linked to this website. Access to any other website linked to this website is entirely at your own risk. Furthermore, such internet websites which are linked to this one and which are not owned by ReadyCrowns may have their own terms and conditions of use, privacy policies, rules, regulations and confidentiality provisions that may differ from ours.</p>

                      <h4 id="Section5">Confidentiality of User Communications</h4>

                      <p>In accordance with the ReadyCrowns website's Privacy Policy and as required by law or court order, ReadyCrowns will maintain the confidentiality of all user communications which contain personal user information, which are transmitted directly to ReadyCrowns.</p>

                      <h4 id="Section6">Access to Password Protected / Secure Areas</h4>

                      <p>ReadyCrowns wishes to please and serve its customers. Therefore, through its website, ReadyCrowns will provide only authorized users access to and use of password protected or secure areas of this website. Every registered user of password protected and/or secure areas of the ReadyCrowns website is required to maintain the confidentiality of his or her username and password. Every registered user agrees and understands that he or she will be responsible for activities under his or her username and password, not ReadyCrowns. Individuals misusing user access/passwords or unauthorized individuals attempting to access restricted areas of this website will have their access withdrawn and such conduct may amount to civil liabilities and penalties or criminal prosecution.</p>

                      <h4 id="Section7">Term / Termination / Violation of Terms and Conditions</h4>

                      <p>As it deems necessary, ReadyCrowns will exercise any rights or enforce any provisions aforementioned, and furthermore reserves the right to seek all remedies available at law and/or in equity for violations of these terms and conditions. Access, registration and the completion of the shopping process online, will not terminate the survivability of such terms and conditions. Furthermore, use which constitutes abusive, harassing, or fraudulent conduct, including impersonation of others, and the transmission of defamatory, threatening, obscene, illicit and illegal or otherwise objectionable material, in any manner, with regard to this website, including but not limited to electronic mail transmission (e-mail) or submission of any form or upload of data, is strictly prohibited. Furthermore, acts which may constitute a criminal offense or give rise to liability or violate any law, is also strictly prohibited. ReadyCrowns reserves the right to block access and use from a particular user or internet address for any reason or no reason at all, at any time.</p>

                      <h4 id="Section8">Miscellaneous</h4>

                      <p>ReadyCrowns operates this website from its main office, within the United States of America, in the State of Utah, in the County of Utah. As such any claim relating to this website shall be governed by the applicable laws of the United States and the State of Utah. Therefore, you hereby consent to the personal jurisdiction of the state and federal courts located within the State of Utah and the appropriate venue therein.</p>

                      <p>Furthermore, you consent and agree that despite any statute, jurisdiction, controlling law or law to the contrary, any cause of action or claim arising under, out of or related to the use of this website, these terms and conditions, or this website's privacy policy must be filed within one (1) calendar year after such claim or cause of action arose, whether you were aware that such a cause of action exists or not.</p>

                      <p>ReadyCrowns reserves the right to change these terms and conditions at any time and for any reason, without prior notice. The failure of ReadyCrowns to exercise or enforce any right, remedy or provision of these terms, conditions and privacy policy shall not constitute a waiver of such rights. The section titles contained herein are solely for the convenience and ease of our website visitors and have no legal or contractual effect.</p>

                      <p>If you have any questions regarding ReadyCrowns’ online disclaimers, terms and conditions of use, please contact info@readycrowns.com.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

                      

export default TermsAndConditionsOfUse;
