import React from 'react';
import CompanySettings from './settings/company_settings';
import ShippingLocation from './settings/shipping_locations';
import InvoiceLocation from './settings/invoice_locations';
import '../css/settings.css';
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount(){
    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", (business)=>{
      this.forceUpdate();
    }); 
  }
  componentWillUnmount(){
    window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged); 
  }


  render(){
    var {loading} = this.state;
    if(loading){
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent
        />
      );
    };
    return (
      <React.Fragment>
        {/*<h2>Settings</h2>*/}
        <div id="BackEndSettings">
          <CompanySettings />
          <ShippingLocation />
          <InvoiceLocation />
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
