import {GetFiltered, GetPagination, SaveToClass, toArray} from './helper-get-filtered';
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class UsersClass {
  constructor(props) {
  	this.Firestore = new window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  	this.data = [];
  	this.SaveToClass = SaveToClass.bind(this);
		this.toArray = toArray.bind(this);
  	this.page = 0;
  	this.GetPagination = GetPagination.bind(this);  	    	
  	this.GetFiltered = GetFiltered.bind(this, ()=>this.Firestore.collection("business").doc(window.GlobalUtil.getCurrentBusinessID()).collection("users"), ["ecbzs1cl79e8","kf8hj36o2gk5"]);
  }

	Get(userID){
		return new Promise((resolve, reject)=>{
			var businessID = window.GlobalUtil.getCurrentBusinessID();
			var isValidUser = this.ValidateAllowed();
			if(!businessID) return;
			if(!isValidUser || !businessID || !userID){
				window.GlobalUtil.apiFail("fqp9u7d48t", "Cannot Get Users Missing: isValidUser, businessID, or userID");
				return reject(false);
			}
			
			var Pointer = this.Firestore.collection("business").doc(businessID).collection("users").doc(userID); 
	    Pointer.get()
	    .then((doc) => {
	      resolve(doc.data());
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("pyvsm1vmph", error);
				reject(error);
	    });
		});
	}



	GetAll(businessID){
		return new Promise((resolve, reject)=>{
			if(!businessID) businessID = window.GlobalUtil.getCurrentBusinessID();
			var isValidUser = this.ValidateAllowed();
			if(!businessID) return;
			if(!isValidUser || !businessID){
				window.GlobalUtil.apiFail("6lbiu86bank", "Cannot Get Users Missing: isValidUser, businessID");
				return reject(false);
			}

			var Pointer = this.Firestore.collection("business").doc(businessID).collection("users"); 
	    Pointer.get()
	    .then((querySnapshot) => {
	    	var users = {};
	      querySnapshot.forEach(function(doc) {
	        users[doc.id] = doc.data();
	      });
	      return users;
	    })
	    .then((users) => {
	      resolve(users);
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("rguy4sflh0a", error);
				reject(error);
	    });
		});
	}
	

	Save(input){
		return this.AddOrUpdateUser(input);
	}

	Update(input){
		return this.AddOrUpdateUser(input);
	}

	AddOrUpdateUser(input){
		return new Promise((resolve, reject)=>{
			var businessID = window.GlobalUtil.getCurrentBusinessID();
			var isValidUser = this.ValidateAllowed();
			var {newObject, userID} = input;
			var UserPointer = this.Firestore.collection("business").doc(businessID).collection("users");

			if(!isValidUser || !businessID){
				console.log("UsersClass Save() or Update() FAILED: isValidUser, businessID");
				console.log(isValidUser, businessID,"\n\n");
				return reject(false);
			}

			if(!newObject.date) newObject.date = window.GlobalUtil.getTimeNow();
			if(newObject.status === undefined) newObject.status = "active";

			if(userID){ //IF HAS "userID" THEN THIS IS THE FIRST TIME CREATING A USER AND WE WILL BE MAKING A COPY OF THE NEW SITE USER
				UserPointer = UserPointer.doc(userID)
				newObject.id = userID;
				UserPointer.set(newObject);
				resolve(newObject);
			} else {
				if(!newObject.id){ //IF DOES NOT HAVE AN ID THEN WE WILL CREATE A NEW ONE AND PUSH THE ID VALUE INTO IT AND UPDATE THE OBJ BELOW
			    UserPointer = UserPointer.doc();
			    var newUserID = UserPointer.id;
			    UserPointer.set({id: newUserID});
		      newObject.id = newUserID;
		    } else {
		    	UserPointer = UserPointer.doc(newObject.id); //IF HAS AN ID THEN LETS POINT TO THAT SPOT AND UPDATE BELOW
		    }
				
				//UPDATE EXISTING OBJECTS HERE
				UserPointer.update(newObject)
				.then(obj=>{
					console.log("Success: Saved User");
        	console.log(newObject,"\n\n");
        	if(!window.Session.Business.users) window.Session.Business.users = {};
	        window.Session.Business.users[newObject.id] = newObject;
	        window.GlobalUtil.processEvent("businessChanged", window.Session.Business);

					resolve(newObject);
				})
				.catch(error=>{
					window.GlobalUtil.apiFail("g4hm6eiwb5", error);
					reject(error);
				});
			}
		});
	}

	Delete(){

	}

}
