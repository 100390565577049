import React from 'react';
const Loading = (props) => {
	var {Header, Text} = props;
	return(
		<div id="LoadingScreen">
      <div className="loadingScreen">
        <i className="fas fa-tooth" />
        {Header && <h3 className="">{Header}</h3>}
        {Text && <p className="">{Text}</p>}
      </div>
    </div>
	);
};

export default Loading;