import {ProductsClass, UsersClass, BusinessClass, PendingClass} from 'global-api-helper';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
 //STUFF TO DO IN ORDER WHEN LOGGED IN
const SessionSetup = (businessID) => {
	if(!window.Session.Business) window.Session.Business = new window.Session.SetupGlobalBusinessClass();
	window.Session.Business.Get(businessID)
	.then(newBusiness=>{
		//GET THE PRODUCTS          
		var ProductsClassNew = new ProductsClass();
		Promise.all([ProductsClassNew.GetAll()])
	  .then(responses => {
	  	console.log("responses");
	  	console.log(responses,"\n\n");
	  	    	
		  window.Session.Products = responses[0];
	    window.GlobalUtil.processEvent("productsChanged", responses[0]);
	  })
	  .catch(error => {
			console.log("error");
			console.log(error,"\n\n");
	  });
	});
};






//REMOVE ANYTHING SAVED IN SESSION
const SessionClear = () => {
	window.Session.Business = new window.Session.SetupGlobalBusinessClass();
	window.Session.AdminBusiness = undefined;
	window.Session.Products = [];
	window.Session.Invoices = undefined;
	window.Session.InvoiceLocations = undefined;
	window.Session.ShippingLocations = undefined;
	window.Session.Pending = undefined;
	window.GlobalUtil.processEvent("businessChanged", window.Session.Business);
};



//REMOVE ANYTHING SAVED IN SESSION
const SetupVRBusiness = (businessID) => {
	window.Session.Business = new window.Session.SetupGlobalBusinessClass();
	window.Session.Invoices = undefined;
	window.Session.InvoiceLocations = undefined;
	window.Session.ShippingLocations = undefined;
	window.Session.Pending = undefined;
	window.Session.Business.Get(businessID, false);
};



const ExitVRBusiness = () => {
	window.Session.Business = new window.Session.SetupGlobalBusinessClass();
	window.Session.Invoices = undefined;
	window.Session.InvoiceLocations = undefined;
	window.Session.ShippingLocations = undefined;
	window.Session.Business.Set(window.GlobalUtil.copyObject(window.Session.AdminBusiness), true);
	window.Session.Business.SubscribeBusiness(window.Session.AdminBusiness.id);
};



//MAGIC GLOBAL CLASS 
//IT GIVE VALUES, AND ALLOWS FOR SIMPLE API UPDATE AND GETS
class SetupGlobalBusinessClass {
  constructor(props) {
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  	this.UsersClass = new UsersClass();
  	this.BusinessClass = new BusinessClass();
  }

  SubscribeBusiness(businessID=this.id){
		if(!this.id && !businessID) return;
		return this.BusinessClass.SubscribeBusiness(businessID, (data)=>{
			this.Set(data)
		});
	}
	

	async Set(initalObj, shouldNotProcessEvents=false){
		await Object.keys(initalObj).forEach(key=>{
  		this[key] = initalObj[key];
  	});
  	if(!shouldNotProcessEvents) window.GlobalUtil.processEvent("businessChanged", this);
		return "success";
	}

	async Get(businessID=this.id, shouldNotProcessEvents=false){		    	
		return await Promise.all([this.BusinessClass.Get(businessID), this.UsersClass.GetAll(businessID)])
	  .then(responses => {
	  	var business = responses[0];
	  	if(business.active == 0 && !window.GlobalUtil.IsSuperAdminUser()){
      	if(window.Router){
					window.Router.history.push('/');
					//window.GlobalUtil.delay(300).then(()=>{window.location.reload(true)});
				} else {
					if(window.location.pathname === '/' || window.location.pathname === '') return;
					window.location.replace("/");
					window.GlobalUtil.delay(300).then(()=>{window.location.reload(true)});
				}
      	window.GlobalUtil.apiFail("f5zg0kaf40q", "Account Deleted");
      	return this;
    	}

	  	business.users = responses[1];	  	  	    	    	  	    	
			this.Set(business, shouldNotProcessEvents)
				.then(()=>{
					if((!window.Session.AdminBusiness || (window.Session.AdminBusiness.id === business.id)) && business.superUser) window.Session.AdminBusiness = window.GlobalUtil.copyObject(business); //COPY ADMIN BUSINESS TO HERE TOO
				})
				.catch(error=>{
					console.log("set-up-session this.Set error");
					console.log(error,"\n\n");
				});	
			window.Session.Business.SubscribeBusiness(businessID);


			var PendingClassNew = new PendingClass(); //THIS SETS THEIR EMAIL AS NOT CONFIRMED. 
			PendingClassNew.Get(businessID)
			.then((obj)=>{
				if(obj){
					window.Session.Pending = obj;
				} else {
					window.Session.Pending = {};
				}
			})
			.catch(()=>{}); //DONE SETTING AS NOT CONFRIMED
			return this;
		  //if(!shouldNotProcessEvents) window.GlobalUtil.processEvent("businessChanged", business);
	  })
	  .catch(error => {
			console.log("error");
			console.log(error,"\n\n");

			if(window.Router){
				window.Router.history.push('/');
				//window.GlobalUtil.delay(300).then(()=>{window.location.reload(true)});
			} else {
				if(window.location.pathname === '/' || window.location.pathname === '') return;
				window.location.replace("/");
				window.GlobalUtil.delay(300).then(()=>{window.location.reload(true)});
			}
	  });
	}
}










window.Session = {
	SessionSetup: SessionSetup,
	SessionClear: SessionClear,
	SetupGlobalBusinessClass: SetupGlobalBusinessClass,
	SetupVRBusiness: SetupVRBusiness,
	ExitVRBusiness: ExitVRBusiness,
};