import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';
import TabUsers from './tabsUsers/tab_users';


class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  render(){
    return (
      <React.Fragment>
        <Tabs
          tabs={[
            {"name": "Users", "url":"/panel/users/"}
          ]}>
          <Route exact path={["/panel/users", "/panel/users/"]} component={TabUsers} />
        </Tabs>
      </React.Fragment>
    );
  }
}
export default Users;







// 
// class Users extends React.Component {
//   render(){
//     return (
//       <Wrapper>
//         <div id="Backend">
//           <h2>Users</h2>
//           <div className="row">
//             <div className="col">
//               <p className=""><b className="">Tabs:</b> Users, Customers</p>
//               <ul>
//                 <li>USER TAB</li>
//                 <li>SHOW ALL USERS</li>
//                 <li>FILTERS FOR LIST</li>
//                 <li>ADD NEW USER BUTTON</li>
//                 <li>REMOVE USER BUTTON ON EACH</li>
//                 <li>SWITCH AS USER BUTTON ON EACH OPTION: (MODAL THAT IF FILLED OUT RIGHT WILL LOG YOU OUT AS YOU AND LOG YOU IN AS THAT PERSON)</li>
//               </ul>
//               <br/>
//               <ul>
//                 <li>CUSTOMER TABS</li>
//                 <li>LIST OF ALL CURRENT CUSTOMERS THEY'VE HAD</li>
//                 <li>FILTERS FOR LIST</li>
//                 <li>OPTION TO ADD NEW CUSTOMER</li>
//                 <li>OPTION TO DELETE ANY OF THEM</li>
//                 <li>OPTION TO EDIT THEM: MODAL WITH OPTIONS OF WHAT TO EDIT</li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }
// }













