import React from 'react';
const Doctor = (props) => {
  var {order, showEdit, onEdit} = props;
  var {doctor=""} = order; 
  if(doctor && doctor.name) doctor = doctor.name;
  return (
    <div className="sectionIconLeft">
      <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-user-md" /></div>
      <div className="sectionIconLeftContent">
        <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-user-md" /></div>Doctor {!doctor && window.GlobalComp.Required} {showEdit && <i className="fas fa-pen editIcon" onClick={()=>onEdit("doctor")}></i>}</div>
        <div className="inputPatient">
          <div className="">{doctor}</div>
        </div>
      </div>
    </div>
  );
}

export default Doctor;
