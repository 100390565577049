import React from 'react';
//import SignUpLogin from './signuplogin';
import Login from './loginDialog';
import {NavLink, Link} from 'react-router-dom';
import Logo from "img/logo1.png";
//<Link to={{"pathname": "/privacy", "hash":"Section1"}}>Privacy</Link>
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginSignup: false,
      defaultTab: 'signup',
      business: window.Session.Business
    };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  componentDidMount(){
    this.closeMobileEvent = window.GlobalUtil.subscribeEvent("closeMobileFront", (data)=>{
      this.setState({showMobileMenu: false});
    });
    this.businessStateChange = window.GlobalUtil.subscribeEvent("businessChanged", (business)=>{
      this.setState({business});
    }); 
  }

  componentWillUnmount(){
    if(this.closeMobileEvent) window.GlobalUtil.unsubscribeEvent("closeMobileFront", this.closeMobileEvent);
    if(this.businessStateChange) {
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessStateChange);
      this.businessStateChange = false;
    }
  }


  toggleMobileMenu(){
    this.setState({showMobileMenu: !this.state.showMobileMenu});
  }

  render(){
    var {showLoginSignup, showMobileMenu, defaultTab, business={}} = this.state;    
    console.log(`showLoginSignup`,"\n\n",showLoginSignup,"\n\n");
                      
    return (
      <div id="Header">
      {/*
        showLoginSignup
        ? <SignUpLogin 
            {...this.props}
            onSignUpTab={(defaultTab==='signup')}
            onLogin={()=>{}} 
            onCloseDialog={()=>{this.setState({"showLoginSignup": false})}}
          />
        : null
      */}
      {
        showLoginSignup
        ? <Login 
            {...this.props}
            onSignUpTab={(defaultTab==='signup')}
            onLogin={()=>{}} 
            onCloseDialog={()=>{this.setState({"showLoginSignup": false})}}
          />
        : null
      }
        <div className="section1 section">
          <div className="container">
              <div className="row">
                <div className="col-sm d-none d-md-block">
                  <div className="loginNav">
                    {
                      !window.Session.Business || !window.Session.Business.id
                      ? <ul className="nav">
                          <li className="nav-item"><a className="nav-link skipButton" onClick={()=>{this.setState({"showLoginSignup": true, defaultTab: 'login'})}}>Login</a></li>
                          {/*<li className="nav-item"><a className="nav-link skipButton" onClick={()=>{this.setState({"showLoginSignup": true, defaultTab: 'signup'})}}>Sign Up</a></li>*/}
                        </ul>
                      : <ul className="nav">
                          <li className="nav-item">
                            <NavLink className={"nav-link"} to={{"pathname": "/panel"}}>Panel</NavLink>
                          </li>
                          <li className="nav-item"><a className="nav-link" onClick={()=>{window.firebase.auth().signOut()}}>Logout</a></li>
                        </ul>
                    }
                  </div>
                </div>
                <div className="col-sm">
                  <div className="phone">Contact Us: <a className="number" href={`tel:${window.CONST.SITE.phone[2]}`}>{window.CONST.SITE.phone[0]} ({window.CONST.SITE.phone[1]})</a></div>
                </div>
            </div>
          </div>
        </div>
        <div className="section2 section">
          <div className="container">
            <div className="row">
                <div className="col col-sm col-md-2 col-lg-3">
                  <div className="logoContainer">
                    <NavLink to="/home"><img src={Logo} alt="" className="" /></NavLink>
                  </div>
                </div>
                <div className="col col-sm col-md-10 col-lg-9">
                  <div className="section2Navigation">
                    <div className="navDiv d-none d-sm-none d-md-block">
                      <ul className="nav justify-content-end">
                        <li className="nav-item">
                          <NavLink className={"nav-link " + (!window.location.hash && window.location.pathname === "/home" ? 'activeHash' : '')} to={{"pathname": "/home"}}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_124' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_124"}}>Why Choose Us</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_125' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_125"}}>Standard</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_127' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_127"}}>What We Do</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_128' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_128"}}>How It Works</NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="mobileButton d-block d-sm-block d-md-none">
                      <i className="fas fa-bars" onClick={this.toggleMobileMenu} />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        {
          showMobileMenu
          ? <div id="MobileMenu">
              <div className="menuWrapper">
                <div className="title">Menu</div>
                <i className="fas fa-times closeButton" onClick={this.toggleMobileMenu}></i>
                <ul className="nav justify-content-end">
                  <li className="nav-item">
                    <NavLink className={"nav-link " + (!window.location.hash && window.location.pathname === "/home" ? 'activeHash' : '')} to={{"pathname": "/home"}}>Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_124' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_124"}}>Why Choose Us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_125' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_125"}}>Standard</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_127' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_127"}}>Testimonials</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link " + (window.location.hash && window.location.hash === '#body_item_128' ? 'activeHash' : '')} to={{"pathname": "/home", "hash":"body_item_128"}}>How It Works</NavLink>
                  </li>
                  {/*
                  <li className="nav-item"><a className="nav-link" onClick={()=>{this.toggleMobileMenu(); this.setState({"showLogin": true})}}>Login</a></li>
                  <li className="nav-item"><a className="nav-link" onClick={()=>{this.toggleMobileMenu(); this.setState({"showLogin": true})}}>Sign Up</a></li>
                  */}
                </ul>
              </div>  
            </div>
          : null
        }
      </div>
    );
  }
}

export default Header;

