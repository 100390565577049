import React from 'react';
// import logo from '../logo.svg';
import '../../css/vr_business.css';
//import '../../css/backend.css';
import Index from '../../pages/index';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect, MemoryRouter
    // etc.
} from 'react-router-dom';


class VRBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionStyles: {"transform": "translate(100%,0)"}

    };
    this.onClose = this.onClose.bind(this);
    this.CloseVRListener = window.GlobalUtil.subscribeEvent("closeVREvent", (handleClose)=>{
      this.onClose();
    });
    
  }

  componentDidMount(){
    var {businessToVRInto} = this.props;
    window.GlobalUtil.delay(200).then(()=>{
      this.setState({transitionStyles: {"transform": "translate(0%,0%)"}})
    })    
  }

  componentWillUnmount(){
    if(this.CloseVRListener) window.GlobalUtil.unsubscribeEvent("closeVREvent", this.CloseVR);
  }

  onClose(){
    this.setState({transitionStyles: {"transform": "translate(100%,0%)"}})
    window.GlobalUtil.delay(1000).then(()=>{
      window.Session.ExitVRBusiness();
      this.props.onClose();
    })
  }



  render(){
    var {transitionStyles} = this.state;
    return (
      <div id="VRBusiness" style={transitionStyles}>
        <MemoryRouter ref={e => this.history = e} basename="/">
          <Index {...window.Router} admin={false} isVR={true}/>
        </MemoryRouter>
      </div>
    );
  }
}



export default VRBusiness;
