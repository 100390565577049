import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';

import Orders from './tabsOrders/tab_orders';
import FilledOrders from './tabsOrders/tab_filled_orders';

class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  render(){
    var hasProducts = (window.Session.Products && window.Session.Products[0] && window.Session.Products[0].add !== undefined);      
    return (
      <React.Fragment>
        <Tabs
          preTab={(<div id="AddNewOrder"><button className="buttonDiv purpleButton" onClick={()=>{if(hasProducts) window.GlobalUtil.processEvent("triggerNewOrderEvent", true)}}><i className="fas fa-tooth"></i> New Order</button></div>)}
          tabs={[
            {"name": "Current Orders", "url":"/panel/orders"},
            {"name": "Recent Filled Orders", "url":"/panel/orders/newFilledOrders"},
          ]}>
          <Route exact path={["/panel/orders", "/panel/orders/", "/panel/orders/newOrder"]} component={(props)=><Orders {...props} admin={window.GlobalUtil.IsSuperAdminUser()} />} />
          <Route exact path={["/panel/orders/newFilledOrders", "/panel/orders/newFilledOrders/"]} component={(props)=><FilledOrders {...props} admin={window.GlobalUtil.IsSuperAdminUser()} />} />
        </Tabs>
      </React.Fragment>
    );
  }
}
export default OrdersPage;
