/*
Example:
<DialogWrapper
  onSave={this.saveLink} //Return true/false
  onCancel={close_method} //Callback after model cancels
  headerText={"Daolog Box"} //If blank no header
  hideButtons={true/false} //Default false
  width={} //min width but will resize when screen smaller than this
  height={} //min height but will resize if width changes
  className={""} //Add to add custom css
  containerId={} //Add to add custom css
  acceptButtonText={} //Default to "done"
  acceptButtonEnabled={true/false} //Default is true
  cancelButtonText={} // default is 'Cancel'
  animationEnterClass={} //Class for when this enters, default "fadeIn"
  animationExitClass={} //Class for when this exits, default "fadeOut"
  positionTop=where the top of the model will be fixed
  positionLeft=where the left of the model will be fixed
  clickToCloseOverlay={true/false} set true to close model if something else is clicked
  clickToCloseOverlayIgnoreIds={classIds}
  clickToCloseOverlayIgnoreClassNames={classNames}
  zIndex={integer} whatever you want the model z index to be
  renderToBody={true/false} renders dialog box to body of html instead of parent html element
  listenForKeys={true} //if this is true then it will listen for enter and escape
  darkBackground={true/false} //default false
  />
*/
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

export default class DialogWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.isIOS = window.GlobalUtil.iOSMobileSafariChecker();

    if (this.props.renderToBody || this.isIOS) {
      this.el = document.createElement("div");
      this.el.setAttribute("class", "dialog-portal-container");
      document.body.appendChild(this.el);
    }

    this.state = {
      containerUID: this.props.containerId ? this.props.containerId : "dialog_" + window.GlobalUtil.getRandomId(),
      containerClasses: this.props.className ? this.props.className : "",
      animation: this.props.animationEnterClass ? this.props.animationEnterClass : "fadeIn"
    };

    this.clickOutside = this.clickOutside.bind(this);

    this.populateFooter = this.populateFooter.bind(this);
    this.populateHeader = this.populateHeader.bind(this);

    this.headerMouseDown = this.headerMouseDown.bind(this);
    this.headerMouseUp = this.headerMouseUp.bind(this);
    this.headerMouseMove = this.headerMouseMove.bind(this);
    this.callbackData = this.callbackData.bind(this);
    this.keyPressForm = this.keyPressForm.bind(this);
    this.removeAllListeners = this.removeAllListeners.bind(this);
  }

  componentDidMount() {
    this.containerRef.scrollTo(0, 0)
    document.addEventListener("click", this.clickOutside, true);
    this.clickOutsideListener = true;
    if (this.props.listenForKeys){
      document.addEventListener("keydown", this.keyPressForm);
      this.keydownListener = true;
    }
    // if (this.isIOS) {
    //   this.scrollTop = $(window).scrollTop();
    //   $("#elite-ws").hide();
    // }
          
    setTimeout(() => {
      this.setState({animation: ''})
    }, 1200);
  }

  componentWillUnmount() {
    this.removeAllListeners();
    if(this.props.renderToBody || this.isIOS) document.body.removeChild(this.el);
  }

  removeAllListeners(){
    if (this.clickOutsideListener){
      document.removeEventListener("click", this.clickOutside, true);
      this.clickOutsideListener = false;
    }
    if (this.keydownListener){
      document.removeEventListener("keydown", this.keyPressForm);
      this.keydownListener = false;
    }
    if(this.mousemoveListener) {
      document.removeEventListener("mousemove", this.headerMouseMove);
      this.mousemoveListener = false;
    }
    if(this.mouseupListener) {
      document.removeEventListener("mouseup", this.headerMouseUp);
      this.mouseupListener = false;
    }
  }

  keyPressForm(e) {
    let key = e.which || e.keyCode;

    //IF BUTTONS ARE DISABLED DONT ALLOW ENTER
    let disabledAcceptButton = this.props.acceptButtonEnabled === undefined || this.props.acceptButtonEnabled ? false : true;

    if (!this.props.hideButtons && key === 13) {
      // 13 is enter
      if (!disabledAcceptButton) this.callbackData(true);
    }
    if ((!this.props.hideButtons || this.props.headerText) && key === 27) {
      // 13 is esc
      this.callbackData(false);
    }
  }

  clickOutside(event) {
    if (!event.target.parentNode || !event.target.isConnected) return;
    if (this.props.clickToCloseOverlay && !$(event.target).closest(`#${this.state.containerUID}`).length) {
      let foundIgnoreElement = false;
      if (this.props.clickToCloseOverlayIgnoreIds) {
        this.props.clickToCloseOverlayIgnoreIds.split(",").map(ignoreId => {
          if ($(event.target).closest(`#${ignoreId}`).length) foundIgnoreElement = true;
        });
      }
      if (this.props.clickToCloseOverlayIgnoreClassNames) {
        this.props.clickToCloseOverlayIgnoreClassNames.split(",").map(ignoreCLass => {
          if ($(event.target).closest(`.${ignoreCLass}`).length) foundIgnoreElement = true;
        });
      }
      if (!foundIgnoreElement && this.clickOutsideListener) this.callbackData(false);
    }
  }


  callbackData(save) {
    this.removeAllListeners();
    if (save) {
      if (this.props.onSave) {
        const isAsync = this.props.onSave.constructor.name === "AsyncFunction";
        if(isAsync){
          this.props.onSave()
            .then(()=>{
              //Animate Out
              this.setState({
                animation: this.props.animationExitClass ? this.props.animationExitClass : "fadeOut"
              });
            })
            .catch(error=>{
              this.setState({
                animation: this.props.animationExitClass ? this.props.animationExitClass : "fadeOut"
              }, this.props.onCancel);
            });
        } else {
          this.setState({
            animation: this.props.animationExitClass ? this.props.animationExitClass : "fadeOut"
          });
          this.props.onSave();
        }
        
      }
    } else {
      if (this.props.onCancel) {
        //Animate Out
        this.setState({
          animation: this.props.animationExitClass ? this.props.animationExitClass : "fadeOut"
        });
        this.props.onCancel()
      } else {
        //Animate Out
        this.setState({
          animation: this.props.animationExitClass ? this.props.animationExitClass : "fadeOut"
        });
      }
    }
    
  }

  render() {
    let windowWidth = $(window).width();
    let windowHeight = $(window).height();

    let width = Math.min(windowWidth-20, this.props.width ? this.props.width : 400);
    let height = Math.min(windowHeight-20, this.props.height ? this.props.height : 250);

    let left = Math.max(0, Math.min(windowWidth - width, this.props.left ? this.props.left : windowWidth / 2 - width / 2));
    let top = Math.max(0, Math.min(windowHeight - height, this.props.top ? this.props.top : windowHeight / 2 - height / 2));

    if (this.state.overrideLeft !== undefined) left = this.state.overrideLeft;
    if (this.state.overrideTop !== undefined) top = this.state.overrideTop;

    let bodyHeight = height;
    if (this.props.headerText) bodyHeight -= 55;
    if (!this.props.hideButtons) bodyHeight -= 60;

    let styles = {
      container: {
        width: `100%`,
        maxWidth: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
        transition: "height 0.5s",
        position: this.isIOS ? "absolute" : "fixed",
        display: "flex", 
        flexDirection: "column"
      },
      body: {
        height: bodyHeight
      }
    };

    styles.container.zIndex = (this.props.zIndex ? this.props.zIndex : 1000);
    React.createElement('h1')      
    let dialogObject = (
      <React.Fragment>
        <div
          id={this.state.containerUID}
          className={`basic-dialog animated ${this.state.containerClasses} ${this.state.animation}`}
          style={styles.container}
        >
          {this.populateHeader()}
          <div ref={e=>this.containerRef=e} className="dialog-body" style={styles.body}>
            {this.props.children}
          </div>
          {this.populateFooter()}
        </div>
        {this.props.darkBackground 
          ? <div className="darkBackground animated fadeIn" style={{
              "background": "rgba(34, 34, 34, 0.7)",
              "left": "0",
              "right": "0",
              "top": "0",
              "bottom": "0",
              "position": "fixed",
              "zIndex": `${(this.props.zIndex ? this.props.zIndex : 1000)-1}`
            }}/>
          : null
        }
      </React.Fragment>
    );
    return this.props.renderToBody || this.isIOS ? ReactDOM.createPortal(dialogObject, this.el) : dialogObject;
  }

  headerMouseUp(e) {
    this.state.headerMouseDown = false;
    if(this.mousemoveListener){
      document.removeEventListener("mousemove", this.headerMouseMove);
      this.mousemoveListener = false;
    }
    if(this.mouseupListener){
      document.removeEventListener("mouseup", this.headerMouseUp);
      this.mouseupListener = false;
    }
  }

  headerMouseDown(e) {
    this.state.headerMouseDown = true;
    let headerRect = this.headerComponent.getBoundingClientRect();
    this.state.headerMouseOffsetX = e.clientX - headerRect.left;
    this.state.headerMouseOffsetY = e.clientY - headerRect.top;
    document.addEventListener("mousemove", this.headerMouseMove);
    document.addEventListener("mouseup", this.headerMouseUp);
    this.mousemoveListener = true;
    this.mouseupListener = true;
  }

  headerMouseMove(e) {
    if (this.state.headerMouseDown) {
      let windowWidth = $(window).width();
      let windowHeight = $(window).height();
      let width = Math.min(windowWidth-20, this.props.width ? this.props.width : 400);
      let height = Math.min(windowHeight-20, this.props.height ? this.props.height : 250);
      let left = Math.max(0, Math.min(windowWidth - width, e.clientX - this.state.headerMouseOffsetX));
      let top = Math.max(0, Math.min(windowHeight - height, e.clientY - this.state.headerMouseOffsetY));

      this.setState({
        overrideLeft: left,
        overrideTop: top
      });
    }
  }

  populateHeader() {
    let header = null;
    if (this.props.headerText) {
      header = (
        <div ref={e => (this.headerComponent = e)} className="header" onMouseDown={this.headerMouseDown}>
          <span className="headerText">{this.props.headerText}</span>
          <i className="fa fa-times dialog-cancel-times" aria-hidden="true" onClick={() => this.callbackData(false)} />
        </div>
      );
    }
    return header;
  }

  populateFooter() {
    var {acceptButtonEnabled, hideButtons, cancelButtonText="Cancel", acceptButtonText="Save", acceptButtonClassName=''} = this.props;
    let footer = null;

    let disabledAcceptButton = acceptButtonEnabled === undefined || acceptButtonEnabled ? false : true;

    if (!hideButtons) {
      footer = (
        <div className="footer" onMouseDown={this.headerMouseDown}>
          <div style={{display: "flex", flex: 1, "alignItems": "center"}}>
            {
              this.props.footerLeft
            }
            <div style={{flex: 1}}>
              <button className="button button-cancel" onClick={() => this.callbackData(false)}>
                {cancelButtonText}
              </button>
              <button
                disabled={disabledAcceptButton}
                className={`button button-accept ${acceptButtonClassName} ${(disabledAcceptButton ? 'disabled' : '')}`}
                onClick={() => this.callbackData(true)}
              >
                {acceptButtonText}
              </button>
            </div>
          </div>
        </div>
      );
    }
    return footer;
  }
}
