import React from 'react';
import '../css/backend.css';
import {
  withRouter, NavLink
} from 'react-router-dom';


const Tabs = (props) => {
  return (
    <div className="tabsComponentLinks">
      <div className="tabs">
        {props.preTab && <div id="PreTab">{props.preTab}</div>}
        {
          props.tabs.map((tab, index)=>{
            if(tab.onClick) return (
              <a onClick={tab.onClick} key={index}>{tab.name}</a>
            )

            return(
              <NavLink 
                exact
                key={index}
                to={tab.url}>{tab.name}
              </NavLink>
            )
          })
        }
        {props.postTab && <div id="PostTab">{props.postTab}</div>}
      </div>
      <div className="content">
        {props.children}
      </div>
    </div>
  );
}
export default Tabs;
