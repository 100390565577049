/*
<DropdownSelect
  options={ARRAY} //ARRAY OF ALL OPTIONS TO CHOOSE FROM. EACH OPTION IN ARRAY SHOULD BE AT LEAST {name: 'name', id: 'unique_id'}
  selected={ARRAY} //ARRAY OF CURRENTLY SELECTED OPTIONS
  onChange={FUNCTION} //ON ANY CHANGE RUNS THIS FUNCTION PASSING IN CURRENT ARRAY OF SELECTED OBJECTS
  onFilter={FUNCTION} //IF THIS EXIST IT RUNS IN PLACE OF THE FILTER, ALLOWING FOR FILTER TO BE DONE THOUGH API RATHER THAN IN BROWSER
  newFilter={ //THIS RUNS IN THE PLACE OF BUILT IN FILTER PASSES NEW FILTER PHRASE AND EXPECTS A CALLBACK TO BE RAN WITH NEW OPTIONS ARRAY
    FUNCTION(NEW_PHRASE, CALLBACK){ 
      //GET NEW OPTIONS THEN PASS THEM BACK IN WITH THE CALLBACK FUNCTION
      CALLBACK(NEW_OPTIONS) 
    }
  }  

/>
*/
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

export default class DropdownSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options.slice(),
      filteredOptions: this.props.options.slice(),
      selected: (this.props.selected ? this.props.selected : {}),
      filterText: (this.props.selected && this.props.selected.name ? this.props.selected.name : ''),
      active: undefined
    };

    this.filterServices = this.filterServices.bind(this);
    this.addService = this.addService.bind(this);
    this.onShowOptions = this.onShowOptions.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.keyPress = this.keyPress.bind(this);

    this.prevGen = {options: this.props.options.slice(), selected: $.extend(true, {}, this.props.selected)};
  }

  componentDidMount(){
    if(this.props.selected && this.props.selected.id) {           
      let {selected={}, options} = this.state;
      var filteredOptions = options.slice(); 
            
      this.setState({
        selected: selected,
        filteredOptions: filteredOptions,
        options: filteredOptions,
      });
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutside);
    document.removeEventListener('keydown', this.keyPress);
  }


  //WHEN CERTAIN IMPORTANT THINGS ARE CHANGED I MUST HARD REFRESH VIDEO LOADER IN ORDER TO GET JQUERY WORKING CORRECTLY AGAIN.
  componentDidUpdate(prevProps, prevState, snapshot) {
    let shouldHardUpdate = false;
    let whatToCheck = ["selected", "options"]; //LIST OF ITEMS IN GENERAL TO COMPARE
    whatToCheck.map((item, index)=>{
      if((!this.prevGen[item] || this.prevGen[item].length < 1) && this.props[item].length > 0) {
        this.prevGen = {options: this.props.options.slice(), selected: $.extend(true, {}, this.props.selected)}; 
        shouldHardUpdate = true;
      }
    });   
    if(shouldHardUpdate) {
      this.setState({
        options: this.props.options.slice(),
        filteredOptions: this.props.options.slice(),
        selected: $.extend(true, {}, this.props.selected),
        filterText: (this.props.selected && this.props.selected.name ? this.props.selected.name : '')
      });
    }
  }


  filterServices(event, hideDropdown) {          
    if(this.props.newFilter){ //IF THEY HIGHJACK THE FILTER THEN RUN THIS, PASS IN NEW SEARCH TERM, RETURN NEW FILTERED LIST OF OPTIONS
      let value = event.target.value;
      this.setState({
        filterText: value
      });
      this.props.newFilter(value, (newOptions)=>{      
        this.setState({
          options: newOptions,
          filteredOptions: newOptions
        });
        if(!this.state.showDropdownOptions && !hideDropdown) this.onShowOptions();
      });
    } else {
      let {options} = this.state;
      let filteredOptions = options.slice(); 
      let whatImSearchingFor = event.target.value.toLowerCase().replace(/[^a-zA-Z ]/g, ""); 
      filteredOptions = filteredOptions.filter(object => {
        //Set as lowercase and remove any extra stuff
        var currentItem = object.name.toLowerCase().replace(/[^a-zA-Z ]/g, "");
        return currentItem.search(whatImSearchingFor) !== -1;
      });
      this.setState({
        filteredOptions: filteredOptions,
        filterText: event.target.value
      });
      if(!this.state.showDropdownOptions && !hideDropdown) this.onShowOptions();
    }
  }

  
  addService(object) {
    let {options} = this.state;
    let filteredOptions = options.slice(); 
    if(!object.id) object.id = window.GlobalUtil.getRandomId();
    this.setState({
      selected: object,
      filteredOptions: filteredOptions,
      options: filteredOptions,
      filterText: object.name,
      active: undefined
    }, this.removeListener);
  }


  onShowOptions() {
    this.setState({
      showDropdownOptions: true,
      active: undefined
    });
    document.addEventListener('click', this.clickOutside);
    document.addEventListener('keydown', this.keyPress);
    this.inputRef.select();
  }

  clickOutside(event) {         
    if(!event.target.parentNode || !event.target.isConnected) return;       
    if (!$(event.target).closest(`#SingleSelect`).length) {
      this.removeListener()
    }
  }

  removeListener(){
    $(`#SingleSelect .searchResultsList`).focus()
    let {selected} = this.state;          
    document.removeEventListener('click', this.clickOutside);
    document.removeEventListener('keydown', this.keyPress);
    this.setState({
      showDropdownOptions: false
    });
    if(this.props.onChange) this.props.onChange(selected);
  }

  keyPress(e){
    let {active, filteredOptions} = this.state;
    active = (active !== undefined ? Number(active) : -1);
    var keyCode = e.keyCode;

    if(keyCode == 38 && active !== -1){  //PRESSED UP KEY
      active = (active == 0 ? undefined : active-1);
      this.setState({
        active: active,
        filterText: (active !== undefined && filteredOptions[active] ? filteredOptions[active].name : '')
      })
      //THIS HANDLES THE EXTRA SCROLLING
      //NO NEED TO RUN THIS IF THEIR ARE NOT MORE THAN 4 WITH AT LEAST 1 OFF SCREEN
      if(filteredOptions.length > 4) $(".searchResultsList").animate({scrollTop: ((active ? active : 0)*41)}, 200, 'swing');
      return;
    }
    
    if(keyCode == 40){ //PRESSED DOWN KEY
      active++;
      if(active >= filteredOptions.length) return; //CANT GO PAST CURRENT LIST SIZE
      this.setState({
        active: active,
        filterText: (filteredOptions[active] ? filteredOptions[active].name : '')
      })
      //THIS HANDLES THE EXTRA SCROLLING
      //NO NEED TO RUN THIS IF THEIR ARE NOT MORE THAN 4 WITH AT LEAST 1 OFF SCREEN
      if(filteredOptions.length > 4) $(".searchResultsList").animate({scrollTop: (active*41)}, 200, 'swing');
      return;
    }
    
    if(keyCode == 13 && active > -1){ //PRESSED ENTER AND HAVE SELECTED ON THEN ADD IT
      this.addService(filteredOptions[active]);
      return;
    }

    if(keyCode == 27){ //PRESSED ESCAPE
      this.removeListener();
      return;
    }
  }


  render() {
    let {filteredOptions=[], selected={}, showDropdownOptions, filterText='', options, active} = this.state;
    let {placeholder="Select all services to include"} = this.props;
          
    return (
      <div id="SingleSelect">
        <div className="searchItems">
          <div className="inputZone">
            <input 
              ref={(e)=>{this.inputRef = e}}
              className="searchBox"
              type="text" 
              placeholder={placeholder}
              name="filterServices" 
              onChange={this.filterServices} 
              value={filterText} 
              onClick={this.onShowOptions}
              onKeyDown={()=>{if(!showDropdownOptions) this.onShowOptions()}} 
            />
            <i  
              className="fas fa-sort-down" 
              aria-hidden="true" 
              style={{
                "position": "absolute",
                "top": "50%",
                "right": "10px",
                "transform": "translate(0, -50%)",
                "color": "#8e8e8e",
                "pointerEvents": "none"
              }} 
            />
          </div>

          { //SHOW DROPDOWN OPTIONS
            showDropdownOptions && filteredOptions.length > 0
            ? <div className="searchResultsList">
                {
                  filteredOptions.map((object, index) => {
                    return (
                      <div 
                        key={index} 
                        className={`searchResultsListItem ${((selected.id && object.id == selected.id) ? "active" : "")}`}
                        onClick={this.addService.bind(this, object)}>
                        {object.name}
                      </div>
                    )
                  }) 
                }
              </div>
            : null
          }
        </div>
      </div>
    ) 
  }  
}