import React from 'react';
import Wrapper from '../components/wrapper';
import {NavLink, Link} from 'react-router-dom';


const TermsAndConditionsOfUse = (props) => {
  return (
    <Wrapper>
      <div id="termsPage">
        <div id="body">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                  <div className="contactSection">
                    <h5>Terms Quick Nav</h5>
                    <ul className="sectionQuickNav">
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section1"}}>Intro</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section2"}}>Guarantee</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section3"}}>Product Liability – general</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section4"}}>Product Liability – specific</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section5"}}>Staged cases</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section6"}}>Bad Impression</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section7"}}>Financial Settlement</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section8"}}>Cancellation</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section10"}}>Exclusion of warranties</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section12"}}>Not a Consumer</NavLink>
                      </li>
                      <li>
                        <NavLink className={" "} to={{"pathname": "/terms_of_trade", "hash":"Section13"}}>Law</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                  <div className="centerText">
                    <div className="textSection">
                      <h1>Terms and Conditions of Trade</h1>
                      <h4 id="Section1">Intro</h4>
                      <p>Conditions shall apply to all trading between Ready Crowns, and the dentist or laboratory or any other customer (‘Client’) with regards to custom made dental pieces (‘dental pieces’, ‘work’, ‘product’) from Ready Crowns. The dental patient is not considered a party to the agreement between Client and Ready Crowns.</p>



                      <h4 id="Section2">Guarantee</h4>
                      <p>All dental products created at Ready Crowns carry a full 1-year guarantee. This guarantee is your assurance that quality materials have been used during the manufacturing process and that our carefully designed production process has been followed.</p>
                      <p>If within the guarantee period the product proves defective in circumstances of normal use, a replacement will be provided free of charge by Ready Crowns. This guarantee will only be applied post evaluation of the defective device on the original model. The guarantee does not cover any defect arising from incorrect prescription or fitting. The guarantee becomes null and void in case of restoration failure due to oral health problems. No guarantee can be given on products replacing immediate extractions. Damage caused by physical trauma or impact (for instance due to high risk sports) is specifically excluded from our product guarantees. </p>
                      <div className="">
                        <strong>Other situations where the guarantee applies includes:</strong>
                        <ul>
                          <li>Incorrect interproximal contact due to production error.</li>
                          <li>Shade or esthetics failure, not related to the dentist’s prescription.</li>
                          <li>Occlusion errors, where intraoral adjustments cannot adequately correct.</li>
                          <li>Crown breakage or fracture prematurely</li>
                          <li>Other situations as evaluated on a per case basis by one of our lab managers.</li>
                        </ul>

                        <strong className="">Specific situations not covered by our year guarantee include:</strong>
                        <ul>
                          <li>Errors in prescription from doctor, i.e. shade, material, tooth number, etc.</li>
                          <li>Crown damaged, dropped, lost after delivery to dentist has been made.</li>
                          <li>Situations outside of dentist’s or lab’s control, i.e. caries from neglect to adequately clean area, trauma from an accident, natural disasters.</li>
                        </ul>
                      </div>

                      <h4 id="Section3">Product Liability – general</h4>
                      <p>Dental pieces are constructed to the specification supplied by Client. Ready Crowns do not guarantee any dental piece, which is based on a faulty model. If Ready Crowns feels that the quality of the impression is not good enough to continue the work, it will consult with Client to discuss possible solutions.</p>
                      <p>Ready Crowns endeavors to interpret and follow Client’s instructions correctly. If, however, a mistake is made, Ready Crowns will rectify the situation free of charge as quickly as production processes allow. Ready Crowns will not compensate dentist’s fees, consequential losses or expenses of any kind. Client is responsible for any additional costs or charges incurred through changing instructions or delivery dates after the work has been accepted by Ready Crowns.</p>


                      <h4 id="Section4">Product Liability – specific returns and remake policy</h4>
                      <p>Client allows Ready Crowns 5 working days after receipt of the returned dental piece, to remake the work. If – after remake – the quality of the work still does not meet the guaranteed standard, the work may be returned within 15 working days after the invoicing date for a refund which will be processed upon receipt of the returned work.</p>

                      <h4 id="Section5">Staged cases</h4>
                      <p>If Ready Crowns is responsible for the loss or damage of a product, this responsibility will not exceed to more than the actual value of the product.</p>
                      <p>In case of loss or damage by shipping company, Client will allow for 5 working days after the product was sent out for delivery, before the work is being remade. Another 2 working days should be allowed to remake the work.</p>

                      <h4 id="Section6">Bad Impression</h4>
                      <p>If the Client indicates to Ready Crowns to go ahead after a verbal notification of a poor-quality impression the full price of the product is due even if the product did not fit the patient. Any remakes for such case will be charged in full.</p>

                      <h4 id="Section7">Financial Settlement</h4>
                      <p>Statements are sent out to Client on a bi-monthly basis and are available online. The Dental Practice, where the dentist issuing the prescription is hired, is held responsible for payment. However, Ready Crowns may hold the dentist, issuing the prescription, responsible for full payment at its discretion.</p>
                      <p className="">All accounts must be settled within 7 days from date of statement.</p>
                      <p className="">Any legal fees or other costs required to collect an unpaid balance may be charged to Client. Any work in progress will be halted if the account of any of the prescribers in a practice remains unpaid for over 30 days. Any cost or loss of income to Client due to an account being halted cannot in anyway be forwarded to Ready Crowns nor can it be deducted from any outstanding.</p>

                      <h4 id="Section8">Cancellation</h4>
                      <p>The Client acknowledges that Ready Crowns will incur costs and expenses in order to perform any order placed with it and that once an order has been accepted by Ready Crowns it may not be cancelled without the express prior written consent of Ready Crowns which consent Ready Crowns may in its absolute discretion withhold.</p>

                      <h4 id="Section10">Exclusion of warranties</h4>
                      <p>Save as expressly set out in these Terms and Conditions, no warranty, condition or representation, express or implied, (including but not limited to any implied warranties of merchantability or fitness for a particular purpose or any representation as to performance quality or absence of hidden defects) has been or is given by Ready Crowns, its agents (with respect to or in respect of any dental products created by Ready Crowns). All conditions and warranties which would otherwise be implied by statute, or under common law are hereby excluded to the fullest extent permitted by law. Although Ready Crowns will use all reasonable efforts to meet delivery dates, any and all delivery dates are estimates only. Ready Crowns shall not be liable to the Client for any loss or damage, whether direct, indirect or consequential arising out of any delay in delivering any dental products ordered from Ready Crowns which is accepted.</p>


                      <h4 id="Section12">Not a consumer</h4>
                      <p>The Client represents warrants and undertakes that it is not a consumer and that any contract it enters into with Ready Crowns is on its own behalf in the conduct of its business and that it is not acting as agent for any third party.</p>

                      <h4 id="Section13">Law</h4>
                      <p>Ready Crowns operates within the United States of America, in the State of Utah, in the County of Utah. As such any claim relating to these conditions of trade shall be governed by the applicable laws of the United States and the State of Utah. Therefore, you hereby consent to the personal jurisdiction of the state and federal courts located within the State of Utah and the appropriate venue therein.</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

                      

export default TermsAndConditionsOfUse;
