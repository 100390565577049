/*
	this.IssuesClass = new IssuesClass();

	THIS CLASS SHOULD HANDLE ALL API CALLS FOR BASIC ORDERS
	GETTING ORDERS
	CREATING ODERS
	DELETE ORDERS, 
	UPDATING ORDERS, 
	CLONE ORDERS, 
	ETC.
*/
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class IssuesClass {
  constructor(props) {
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }

	Get(id, callbackSuccess=()=>{}, callbackFail=()=>{}){
    var Pointer = this.Firestore.collection("issues").doc(id);
		Pointer.get()
			.then((querySnapshot) => {
				return querySnapshot.data();
	    })
	    .then(data=>{
	    	callbackSuccess(data)
	    })
	    .catch(info=>{
	      callbackFail(false);
	      console.log(info);
	      return undefined;
	    }); 	
	}


	Set(issue){
		var newPointer = this.Firestore.collection("issues").doc();
    issue.id = newPointer.id;
    return newPointer.set(issue);
	}

	
	Delete(id){
		if(!id) return;
		var Pointer = this.Firestore.collection("issues").doc(id);
		return Pointer.delete()
	}


	Update(issue){
		return this.Save(issue);
	}


	Save(issue){  //THIS UPDATES EXISTING ORDER OR CREATES A NEW ONE
		if(!issue.id) return; //MUST HAVE ID AS IT CORILATES WITH ORDER_ID

		var isValidUser = this.ValidateAllowed();
		if(!isValidUser) return;
		    	
		// if(!issue.id){ 
	 //    var newPointer = this.Firestore.collection("issues").doc();
	 //    var issueID = newPointer.id;
	 //    newPointer.set({id: issueID});
  //     issue.id = issueID;
  //   }
		    	
		var Pointer = this.Firestore.collection("issues").doc(issue.id);
		return Pointer.set(issue)
	}

}
