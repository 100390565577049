import React from 'react';
// import logo from '../logo.svg';
import '../../css/new_order_dialog.css';
//import '../../css/backend.css';

import TabNewOrders from './tabsNewOrder/tab_new_order';
import TabChoosePayment from './tabsNewOrder/tab_choose_payment';
import TabChooseAddress from './tabsNewOrder/tab_choose_address';
import TabOrderSummary from './tabsNewOrder/tab_summary';
import TabSuccessMessage from './tabsNewOrder/tab_success';
import TabFailMessage from './tabsNewOrder/tab_fail';

/*
<strong>NewOrder TODO:</strong>
<ul>
  <li>Header with each step we are on</li>
  <li>Handle page change and animation</li>
  <li>Handle Keeping all order info here</li>
  <li>Handle ALL APIS in their screens. Like create patients, search patients, submit order, etc.</li>
</ul>

*/



const PAGES = [
  {
    title: "New Orders",
    icon: (<i className="fas fa-cart-plus" />),
    page: TabNewOrders
  },
  {
    title: "Payment",
    icon: (<i className="fas fa-money-bill-wave" />),
    page: TabChoosePayment
  },
  {
    title: "Shipping",
    icon: (<i className="fas fa-map-pin" />),
    page: TabChooseAddress
  },
  {
    title: "Summary",
    icon: (<i className="fas fa-receipt" />),
    page: TabOrderSummary
  },
  {
    title: "Success",
    icon: (<i className="fas fa-truck" />),
    page: TabSuccessMessage
  },
  {
    title: "Fail",
    icon: (<i className="fas fa-exclamation" />),
    page: TabFailMessage
  }
]




class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      newPageClass: "newPage",
      curOrderObject: {
        products: [], //[], //array of objects
        payment: {},
        shipping: {}
      },
      validatedPages: [true, false, false, false]
    };
    this.onChangePage = this.onChangePage.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onUpdateObject = this.onUpdateObject.bind(this);
  }

  componentDidMount(){
    
  }

  componentWillUnmount(){
    if(this.transitionTimeout) clearTimeout(this.transitionTimeout);
  }

  onChangePage(newPageIndex){
    if(this.transitionTimeout) return;
    var {page} = this.state;
    if(newPageIndex === page) return;
    var movingRight = page > newPageIndex;
    var oldPageClass = movingRight ? 'animated slideOutRight oldPage' : 'animated slideOutLeft oldPage';
    var newPageClass = movingRight ? 'animated slideInLeft newPage' : 'animated slideInRight newPage';
    this.setState({
      oldPage: PAGES[page].page,
      page: newPageIndex,
      oldPageClass: oldPageClass,
      newPageClass: newPageClass
    }, ()=>{
      this.transitionTimeout = setTimeout(() => {
        this.setState({oldPage: undefined});
        clearTimeout(this.transitionTimeout);
        this.transitionTimeout = false;
      }, 1000);
    })
  }

  onCancel(closeAll){
    this.setState({curOrderObject: {
      products: [], //[], //array of objects
      payment: {},
      shipping: {}
    }});
    this.onChangePage(0);
    if(closeAll) this.props.onClose();
  }

  onUpdateObject(newObject){
    this.setState({
      curOrderObject: newObject,
      validatedPages: [true, VALIDATE_PRODUCTS(newObject), VALIDATE_PAYMENT(newObject), VALIDATE_SHIPPING(newObject)]
    });
  }

  render(){
    var {page, curOrderObject, oldPage, oldPageClass, newPageClass, validatedPages} = this.state;
    var NewPage = PAGES[page].page;
    var OldPage = oldPage;
    return (
      <React.Fragment>
        <h5 className="panelTitle">Create a New Order</h5>
        <div id="NewOrder">
          {
            page < 4 && 
            <div className="headerNav">
              {
                 PAGES.map((object, index)=>{
                  if(index > 3) return;
                  let disabled = !validatedPages[index];
                  return(
                    <div key={index} 
                      className={"tab "+(page === index ? "active " : '')+(disabled ? "disabled " : '')} 
                      onClick={()=>{if(!disabled)this.onChangePage(index)}}>
                      <div className="icon">{object.icon}</div>
                      <div className="d-none d-lg-block d-xl-block">
                        <div className="title">{object.title}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
          <div className="pageZone">
            <div className={`pages ${newPageClass}`}>
              <NewPage 
                className={newPageClass}
                curOrderObject={curOrderObject}
                onUpdateObject={this.onUpdateObject}
                currentPage={page}
                onNextPage={this.onChangePage.bind(this, page+1)}
                onPrevPage={this.onChangePage.bind(this, page-1)}
                onCancel={()=>this.onCancel(true)}
                onGotoPage={this.onChangePage}
                onRestart={()=>this.onCancel(false)}
                disabled={!validatedPages[page+1]}
              />
            </div>
            {
              oldPage && 
              <div className={`pages ${oldPageClass}`}>
                <OldPage 
                  className={oldPageClass}
                  curOrderObject={curOrderObject}
                  onUpdateObject={this.onUpdateObject}
                  currentPage={page}
                  onNextPage={this.onChangePage.bind(this, page+1)}
                  onPrevPage={this.onChangePage.bind(this, page-1)}
                  onCancel={()=>this.onCancel(true)}
                  onRestart={()=>this.onCancel(false)}
                  disabled={!validatedPages[page+1]}
                />
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}



const VALIDATE_PRODUCTS = (curOrder) => {
  var {products=[], doctor={}, patient} = curOrder;
  return(products.length > 0 && doctor.name && patient)
}

const VALIDATE_PAYMENT = (curOrder) => {
  var valid = (curOrder.payment.id);
  return(valid)
}

const VALIDATE_SHIPPING = (curOrder) => {
  var valid = (curOrder.shipping.id);
  return(valid)
}


export default NewOrder;
