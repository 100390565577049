import React from 'react';
import ReactTooltip from 'react-tooltip';
import {NavLink} from 'react-router-dom';
import AddNewInvoiceLocation from '../dialogs/add_new_invoice_location';
import {InvoiceLocationsClass} from 'global-api-helper';
class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
      currentLocation: false
    };
    this.onGetInvoiceLocations = this.onGetInvoiceLocations.bind(this);
    this.onChoosePayment = this.onChoosePayment.bind(this);
    this.onAddNew = this.onAddNew.bind(this);
    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", (newBusiness)=>{
      if(window.Session.Business && window.Session.Business.id) this.onGetInvoiceLocations();
    });  
    this.InvoiceLocationsClass = new InvoiceLocationsClass();
  }

  componentDidMount(){
    if(window.Session.Business && window.Session.Business.id && !this.state.locationsArray) this.onGetInvoiceLocations();
  }

  componentWillUnmount(){
    if(this.businessChanged){
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
      this.businessChanged = false;
    }
  }

  onGetInvoiceLocations(){
    this.InvoiceLocationsClass.GetMultiple()
    .then((locationsArray)=>{
      var primaryLocation = locationsArray.filter(obj=>obj.primary);  
      this.setState({
        locationsArray: locationsArray
      });
    })
    .catch((fail)=>{
      console.log("fail");
      console.log(fail,"\n\n");   
      this.setState({
        locationsArray: []
      });
    });
  }

  onChoosePayment(newLocation){
    var {order} = this.state;
    order.payment = newLocation;
    this.setState({order: order});
    this.props.onChange(order);  
  }


  onAddNew(newLocation){
    var {locationsArray, currentLocation={}} = this.state;
    var updated = false;
    locationsArray = locationsArray.map((object, index)=>{
      if(!updated && object.id === currentLocation.id){
        updated = true;
        return(newLocation);
      }
      return(object);
    });
    if(!updated) locationsArray.push(newLocation);
    this.setState({
      currentLocation: undefined,
      locationsArray: locationsArray
    });
    this.onChoosePayment(newLocation);
  }



  render(){
    var {order={}, locationsArray=[], currentLocation} = this.state;
    var {payment} = order;
    return (
      <div id="EditOrderInvoice" className="editOrder">
        <h2 className="tabHeader">Whom to Invoice</h2>
        <div className="row">
          {
            locationsArray.length > 0
            && locationsArray.map((location, index)=>{
                var active = (payment && payment.id == location.id);
                return(
                  <div key={index} className={"col-12 col-md-4 col-lg-4"}>
                    <div className={`paymentOption ${active ? 'active' : ''}`} onClick={this.onChoosePayment.bind(this, location)}>
                      {
                        active
                        && <div className="checkMark"><i className="fas fa-check-circle"></i></div>
                      }
                      <div className="textZone">
                        <div className="inputArea">
                          <strong className="responce">{location.name}</strong>
                        </div>
                        <div className="name">
                          <span className="responce">{location.email}</span>
                        </div>
                        <div className="name">
                          <span className="responce">{location.street}</span>
                        </div>
                        {
                          location.street2 &&
                          <div className="name">
                            <span className="responce">{location.street2}</span>
                          </div>
                        }
                        <div className="name">
                          <span className="responce">{location.city}</span>, <span className="responce">{location.state}</span> <span className="responce">{location.zip}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <div className={"col-12 col-md-4 col-lg-4"}>
              <div className="paymentOption" data-tip="Add New Payment" onClick={()=>{this.setState({currentLocation: DummyNewLocation})}}>
                <div className="textZone">
                  <div className="type">
                    <i className="fas fa-plus-circle"></i>
                  </div>
                  <div className="name">
                    New Payment
                  </div>
                </div>
              </div>
            </div>
          </div>
        {
          currentLocation &&
          <AddNewInvoiceLocation
            currentLocation={currentLocation}
            onSave={this.onAddNew}
            onCancel={()=>{
              this.setState({currentLocation: undefined});
            }}
          />
        }
        <ReactTooltip offset={{top: 10}}/>
      </div>
    );
  }
}

const DummyNewLocation = {
  name: '',
  email: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
};

export default Invoice;
