import React from 'react';
const Invoice = (props) => {
  var {order, showEdit, onEdit} = props;
  var {payment=""} = order; 
  return (
    <div className="sectionIconLeft">
      <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-file-invoice-dollar" /></div>
      <div className="sectionIconLeftContent">
        <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-file-invoice-dollar" /></div> Send Invoice to {(!payment || !payment.street) && window.GlobalComp.Required} {showEdit && <i className="fas fa-pen editIcon" onClick={()=>onEdit("invoice")}/>}</div>
        <div className="inputPatient">
          <div className="">Name: {payment.name}</div>
          <div className="">Email: {payment.email}</div>
          <div className="">Street: {payment.street}</div>
          <div className="">City: {payment.city}</div>
          <div className="">State: {payment.state}</div>
          <div className="">Zip: {payment.zip}</div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
