import React from 'react';

class Total extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(newvalue){
    var newTotalOverride = newvalue.priceOverride;
    var {order} = this.state;
    order.priceOverride = Number(newTotalOverride);
    this.setState({order: order});
    this.props.onChange(order);      
  }

  render(){
    const FormBuilder = window.GlobalComp.FormBuilder;
    const {order={}} = this.state;
    var {priceOverride, products} = order;
    var total = products.reduce((accumulator, obj) => {
      return Number(accumulator)+Number(obj.total);
    }, 0);
    return (
      <div id="EditOrderTotal" className="editOrder">
        <div className="sectionIconLeft">
          <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-dollar-sign" /></div>
          <div className="sectionIconLeftContent">
            <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-dollar-sign" /></div>Order Total</div>
            <div className="inputPatientTotal">
              <div className="">{window.GlobalUtil.convertToMoney(total)}</div>
              <FormBuilder
                initialValues={{
                  "priceOverride": priceOverride
                }}
                listOfFields={[
                  {
                    title: <span className="">Price Override<span className="red-text"><i className="fas fa-star-of-life" /></span><div><small>Admin can put price he want's here</small></div></span>,
                    type: "number",
                    name: "priceOverride",
                    required: true
                  }
                ]}
                onChange={this.onChange}
              />
            </div>
            <div className=""><small>Order total does not reflect any discounts added on the invoices. Order total only reflects base price of products selected.</small></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Total;