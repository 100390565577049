import React from 'react';
// import logo from '../logo.svg';
import $ from 'jquery';
//import Google from '../../icons/google.svg';
import {NavLink} from 'react-router-dom';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
const GoToCorrectPlace = ()=>{
  console.log("GoToCorrectPlace");
  var pathArray = [];
  window.location.pathname.split('/').map((location, index)=>{
    if(location && location !== 'login') pathArray.push(location);
  })
  if(pathArray && pathArray.length > 0) window.Router.history.push('/'+pathArray.join('/'));
  else window.Router.history.push('/panel');
}


class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onSignUpTab: (this.props.onSignUpTab !== undefined ? this.props.onSignUpTab : true)
    };
  }

  componentDidMount(){
    // if(window.firebase.auth().currentUser){
    //   GoToCorrectPlace();
    // } else {
    //   GlobalAPINew.onAuthStateChanged((user)=>{ 
    //     // GetUserDBInfo(UID); 
    //     if(window.firebase.auth().currentUser) GoToCorrectPlace()
    //   });
    // }
  }

  // componentWillUnmount(){
  //   if(this.clickListener) document.removeEventListener("click", this.clickListener);
  // }

  render(){
    var {onSignUpTab} = this.state;
    return (
      <div id="Login">
        <div className="wrapper">
          <div className="content">
            <Login 
              onLogin={this.props.onLogin}
              onCloseDialog={this.props.onCloseDialog}
            />
          </div>
        </div>
      </div>
    );
  }
}


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.currentObject = {};
    this.onLogin = this.onLogin.bind(this);
    this.FormBuilder = window.GlobalComp.FormBuilder;
  }

  onLogin(){
    if(!this.state.isValid) return;
    window.firebase.auth().signInWithEmailAndPassword(this.currentObject.email, this.currentObject.password)
    .then(() => {
      if(this.props.onCloseDialog) this.props.onCloseDialog();
      if(this.props.onLogin) this.props.onLogin();
      setTimeout(()=>{GoToCorrectPlace()}, 100);
    })
    .catch((error) => {
      console.log("error");
      console.log(error,"\n\n");
            
      if(this.props.onCloseDialog) this.props.onCloseDialog();
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  render(){
    const FormBuilder = this.FormBuilder;
    var {isValid} = this.state;
    return (
      <div id="LoginDialog">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="loginNav">
                <FormBuilder
                  initialValues={this.currentObject}

                  listOfFields={[
                    {
                      title: <span className="">Email<span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                      type: "email",
                      name: "email",
                      placeholder: "",
                      hideMessage: true,
                      required: true
                    },
                    {
                      title: <span className="">Password<span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                      type: "password",
                      name: "password",
                      placeholder: "",
                      required: true
                    },
                  ]}
                  onChange={newObj => this.currentObject = newObj}
                  onValidate={(isValid)=>{this.setState({isValid: isValid})}}
                  onSubmit={this.onLogin}
                />

                <div className="clearfix">
                  <button 
                    onClick={this.onLogin} 
                    className="btn yellowButton"
                    disabled={!isValid}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginContainer;
