import React from 'react';
import $ from 'jquery';
// import PageType1 from '../../components/templatePages/page_type_1';

class EditPricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: JSON.parse(JSON.stringify(this.props.product)) //DEEP COPY NOT A POINTER SO WE CAN REVERT LATER IF NEEDED
    };
    this.onUpdateProduct = this.onUpdateProduct.bind(this);
    this.saveUpdatedProduct = this.saveUpdatedProduct.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
  }
  componentDidMount(){}
  componentWillUnmount(){}

  onUpdateProduct(newProductObject){
    this.setState({product: newProductObject});
  }

  saveUpdatedProduct(){
    this.props.updateProducts(this.state.product);
  }

  cancelChanges(){
    const product = JSON.parse(JSON.stringify(this.props.product));
    this.setState({product: product});
  }

  render(){
    //var {} = this.state;
    var {product} = this.state;
    const InputNumberMoney = window.GlobalComp.InputNumberMoney;
    const InputText = window.GlobalComp.InputText;
    return (
      <div className="borderZone">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <InputText
              onChange={(newValue)=>{
                product["name"] = newValue;
                this.onUpdateProduct(product);
              }}
              title={"Product Name"}
              value={product.name}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputNumberMoney
              onChange={(newValue)=>{
                product["add"] = newValue;
                this.onUpdateProduct(product);
              }}
              title={"Base Price"}
              value={product.costPerTooth}
            />
          </div>
        </div>
        
        
        <br/>
        {
          product.teeth &&
          <TeethList
            onUpdateProduct={(newProduct)=>{
              product["teeth"] = newProduct;
              this.onUpdateProduct(product);
            }}
            inputField={product["teeth"]}
          />
        }

        {
          Object.keys(product).map((key, index)=>{
            var option = product[key];
            if(option.type === "select") return(
              <SelectList
                key={index}
                onUpdateProduct={(newProduct)=>{
                  product[option.name] = newProduct;
                  this.onUpdateProduct(product);
                }}
                inputField={option}
              />
            ) 
          })
        }


        {
          Object.keys(product).map((key, index)=>{
            var option = product[key];
            if(option.type === "shades") return(
              <ShadesList
                key={index}
                onUpdateProduct={(newProduct)=>{
                  product[option.name] = newProduct;
                  this.onUpdateProduct(product);
                }}
                inputField={option}
              />
            ) 
          })
        }


        {
          Object.keys(product).map((key, index)=>{
            var option = product[key];
            if(option.type === "radio") return(
              <RadioList
                key={index}
                onUpdateProduct={(newProduct)=>{
                  product[option.name] = newProduct;
                  this.onUpdateProduct(product);
                }}
                inputField={option}
              />
            ) 
          })
        }

        <div className="saveButtons">
          <div className="buttonGroup">
            <button className="button save" onClick={this.saveUpdatedProduct}>Save</button>
            <button className="button cancel" onClick={this.cancelChanges}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}




const SelectList = ({inputField, onUpdateProduct})=>{
  const AccordionSingle = window.GlobalComp.AccordionSingle;
  const InputBoolRadio = window.GlobalComp.InputBoolRadio;
  const InputNumberMoney = window.GlobalComp.InputNumberMoney;
  const InputText = window.GlobalComp.InputText;
  return(
    <AccordionSingle
      inner={true}
      header={(
        <div className="title">
          <div style={{margin: "auto"}}>{inputField.title} - <strong>Required {(inputField.required ? "True" : "False")}</strong></div>
        </div>
      )}
    >
      <div className="productInputField">
        <h4 className="title">{inputField.title}</h4>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <InputText
              onChange={(newValue)=>{
                inputField["title"] = newValue;
                onUpdateProduct(inputField)
              }}
              title={<div className="inputTitle">Title</div>}
              value={inputField.title}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputBoolRadio 
              title="Required"
              value={inputField.required}
              onChange={(newValue)=>{
                inputField["required"] = newValue;
                onUpdateProduct(inputField)
              }}
            />
          </div>
        </div>
            
        <hr/>
        <div className="optionsWrapper">
          <div className="optionsTitle">Options</div>
          <div className="options">
            {
              inputField.options.map((option, index)=>{
                return(
                  <div className="option" key={index}>
                    <div className="row">
                      <div className="col-12 col-lg-6 col-xl-6">
                        <InputText
                          onChange={(newValue)=>{
                            inputField.options[index]["title"] = newValue;
                            onUpdateProduct(inputField)
                          }}
                          title={<div className="inputTitle">Title</div>}
                          value={option.title}
                        />
                      </div>
                      <div className="col-12 col-lg-6 col-xl-6">
                        <InputNumberMoney
                          onChange={(newValue)=>{
                            inputField.options[index]["add"] = newValue;
                            onUpdateProduct(inputField)
                          }}
                          title={<div className="inputTitle">Add</div>}
                          value={option.add}
                        />
                      </div>
                    </div>
                    <hr/>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </AccordionSingle>
  )
}


const TeethList = ({inputField, onUpdateProduct})=>{
  const AccordionSingle = window.GlobalComp.AccordionSingle;
  const InputBoolRadio = window.GlobalComp.InputBoolRadio;
  const InputNumberMoney = window.GlobalComp.InputNumberMoney;
  const InputText = window.GlobalComp.InputText;
  return(
    <AccordionSingle
      inner={true}
      header={(
        <div className="title">
          <div style={{margin: "auto"}}>{inputField.title} - <strong>Required {(inputField.required ? "True" : "False")}</strong></div>
        </div>
      )}
    >
      <div className="productInputField">
        <h4 className="title">{inputField.title}</h4>
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-4">
            <InputBoolRadio 
              title="Required"
              value={inputField.required}
              onChange={(newValue)=>{
                inputField["required"] = newValue;
                onUpdateProduct(inputField)
              }}
            />
          </div>
          <div className="col-12 col-lg-4 col-xl-4">
            <InputText
              onChange={(newValue)=>{
                inputField.title = newValue;
                onUpdateProduct(inputField)
              }}
              title={<div className="inputTitle">Title</div>}
              value={inputField.title}
            />
          </div>
          <div className="col-12 col-lg-4 col-xl-4">
            <InputNumberMoney
              onChange={(newValue)=>{
                inputField.add = newValue;
                onUpdateProduct(inputField)
              }}
              title={<div className="inputTitle">Add Per Tooth</div>}
              value={inputField.add}
            />
          </div>
        </div>
      </div>
    </AccordionSingle>
  )
}


const ShadesList = ({inputField, onUpdateProduct})=>{
  const AccordionSingle = window.GlobalComp.AccordionSingle;
  const InputBoolRadio = window.GlobalComp.InputBoolRadio;
  const InputNumberMoney = window.GlobalComp.InputNumberMoney;
  const InputText = window.GlobalComp.InputText;
  return(
    <AccordionSingle
      inner={true}
      header={(
        <div className="title">
          <div style={{margin: "auto"}}>{inputField.title} - <strong>Required {(inputField.required ? "True" : "False")}</strong></div>
        </div>
      )}
    >
      <div className="productInputField">
        <h4 className="title">{inputField.title}</h4>
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-4">
            <InputBoolRadio 
              title="Required"
              value={inputField.required}
              onChange={(newValue)=>{
                inputField["required"] = newValue;
                onUpdateProduct(inputField)
              }}
            />
          </div>
          <div className="col-12 col-lg-4 col-xl-4">
            <InputText
              onChange={(newValue)=>{
                inputField.title = newValue;
                onUpdateProduct(inputField)
              }}
              title={<div className="inputTitle">Title</div>}
              value={inputField.title}
            />
          </div>
          <div className="col-12 col-lg-4 col-xl-4">
            <InputNumberMoney
              onChange={(newValue)=>{
                inputField.add = newValue;
                onUpdateProduct(inputField)
              }}
              title={<div className="inputTitle">Add</div>}
              value={inputField.add}
            />
          </div>
        </div>
      </div>
    </AccordionSingle>
  )
}


const RadioList = ({inputField, onUpdateProduct})=>{
  const AccordionSingle = window.GlobalComp.AccordionSingle;
  const InputBoolRadio = window.GlobalComp.InputBoolRadio;
  const InputNumberMoney = window.GlobalComp.InputNumberMoney;
  const InputText = window.GlobalComp.InputText;
  return(
    <AccordionSingle
      inner={true}
      header={(
        <div className="title">
          <div style={{margin: "auto"}}>{inputField.title} - <strong>Required {(inputField.required ? "True" : "False")}</strong></div>
        </div>
      )}
    >
      <div className="productInputField">
        <h4 className="title">{inputField.title}</h4>
            
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <InputText
              onChange={(newValue)=>{
                inputField["title"] = newValue;
                onUpdateProduct(inputField)
              }}
              title={<div className="inputTitle">Title</div>}
              value={inputField.title}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputBoolRadio 
              title="Required"
              value={inputField.required}
              onChange={(newValue)=>{
                inputField["required"] = newValue;
                onUpdateProduct(inputField)
              }}
            />
          </div>
        </div>
        <hr/>
        <div className="optionsWrapper">
          <div className="optionsTitle">Options</div>
          <div className="options">
            {
              inputField.options.map((option, index)=>{
                return(
                  <div className="option" key={index}>
                    <div className="row">
                      <div className="col-12 col-lg-6 col-xl-6">
                        <InputText
                          onChange={(newValue)=>{
                            inputField.options[index]["title"] = newValue;
                            onUpdateProduct(inputField)
                          }}
                          title={<div className="inputTitle">Title</div>}
                          value={option.title}
                        />
                      </div>
                      <div className="col-12 col-lg-6 col-xl-6">
                        <InputNumberMoney
                          onChange={(newValue)=>{
                            inputField.options[index]["add"] = newValue;
                            onUpdateProduct(inputField)
                          }}
                          title={<div className="inputTitle">Add</div>}
                          value={option.add}
                        />
                      </div>
                    </div>
                    <hr/>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </AccordionSingle>
  )
}




export default EditPricing;






        









