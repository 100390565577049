import React from 'react';
import AddNewInvoiceLocation from 'backend/components/dialogs/add_new_invoice_location';
import ReactTooltip from 'react-tooltip';
import {InvoiceLocationsClass} from 'global-api-helper';
class InvoiceLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationsArray: [],
      loading: false
    };
    this.onGetInvoiceLocations = this.onGetInvoiceLocations.bind(this);
    this.onRemoveLocation = this.onRemoveLocation.bind(this);
    this.onSetDefaultLocation = this.onSetDefaultLocation.bind(this);
    this.onSave = this.onSave.bind(this);
    this.InvoiceLocationsClass = new InvoiceLocationsClass();
  }
  componentDidMount(){
    if(window.Session.Business && window.Session.Business.id) this.onGetInvoiceLocations();
    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", (newBusiness)=>{
      if(window.Session.Business && window.Session.Business.id) this.onGetInvoiceLocations();
    });  
  }

  componentWillUnmount(){
    if(this.businessChanged){
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
      this.businessChanged = false;
    }
  }

  onGetInvoiceLocations(force){
    this.setState({loading: true});
    this.InvoiceLocationsClass.GetMultiple(force)
    .then((locationsArray)=>{
      var primaryLocation = locationsArray.filter(obj=>obj.primary);  
      this.setState({
        locationsArray: locationsArray,
        loading: false
      });
    })
    .catch((fail)=>{
      console.log("fail");
      console.log(fail,"\n\n");   
      this.setState({
        locationsArray: [],
        loading: false
      });
    });
  }


  onSetDefaultLocation(newObject){
    this.setState({loading: true})
    this.InvoiceLocationsClass.ChangeDefault(newObject)
    .then(success=>{
      this.onGetInvoiceLocations(true);
    });
  }


  onRemoveLocation(index){
    var locationsArray =  this.state.locationsArray.slice();
    var objectToRemove = locationsArray.splice(index, 1);
    if(!objectToRemove[0]) return;
    this.InvoiceLocationsClass.Delete(objectToRemove[0].id)
    .then((deleted)=>{
      this.setState({loading: true})
      window.GlobalUtil.delay(500).then(()=>{
        if(deleted) this.setState({locationsArray: locationsArray, loading: false});  
        else this.setState({loading: false});  
      })
    })
    .catch((error)=>{
    })
  }

  onSave(newLocation){
    var {locationsArray, currentLocation} = this.state;
    var updated = false;
    locationsArray = locationsArray.map((object, index)=>{
      if(!updated && object.id === currentLocation.id){
        updated = true;
        return(newLocation);
      }
      return(object);
    });
    if(!updated) locationsArray.push(newLocation);
    window.Session.InvoiceLocations.arrayWithAll = locationsArray;
    this.setState({
      currentLocation: undefined,
      locationsArray: locationsArray
    });
  }

  render(){
    var {locationsArray=[], currentLocation, loading} = this.state;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    return (
      <div id="InvoiceLocations" className="panelWrapper">
        {
          currentLocation &&
          <AddNewInvoiceLocation
            currentLocation={currentLocation}
            onSave={this.onSave}
            onCancel={()=>{
              this.setState({currentLocation: undefined});
            }}
          />
        }
        <h5 className="panelTitle">Invoice Locations {!loading && locationsArray.length < 1 && <div style={{"paddingLeft":"5px","color":"red","display":"inline-block","fontSize":"15px"}}><strong>Must have at least 1 invoice locations <i className="fas fa-exclamation-circle"></i></strong></div>}</h5>
        <div className="row location_boxes">
          {
            loading && <div className="col col-sm">
              <div className="inputZone" style={{position:'relative', height: "100%", top: "0", bottom: "0"}}>
                <LoadingComponent 
                  Text="Loading Locations"
                />
              </div>
            </div>
          }
          {
            (!loading && locationsArray.length > 0)
            && locationsArray.map((location, index)=>{
                return(
                  <div key={index} className="col col-sm">
                    <div className={`${location.default ? 'active' : ''} panelItemWrapper`}>
                      <div className="panelItem active location">
                        <div className="inputZone">
                          <div className="zoneIcons">
                            <i className={`fas fa-star ${location.default ? 'active' : ''}`} onClick={()=>{
                              this.onSetDefaultLocation(location);
                            }} data-tip="Make Default Location"/>
                            <i className="fas fa-edit" onClick={()=>{this.setState({currentLocation: location})}} data-tip="Edit Location"/>
                            <i className="fas fa-trash-alt" 
                              onClick={()=>{
                                window.GlobalComp.Confirm({
                                  title: 'Deleting',
                                  message: 'Are you SURE you wish to remove this location?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => {
                                        this.onRemoveLocation(index);
                                      }
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => {
                                        //Do nothing, just close;
                                      }
                                    }
                                  ]
                                })
                              }} data-tip="Remove Location"/>
                            <ReactTooltip offset={{top: 10}}/>
                          </div>
                          <div className="inputArea">
                            <span className="title">Name</span>
                            <span className="responce">{location.name}</span>
                          </div>
                          <div className="inputArea">
                            <span className="title">Email</span>
                            <span className="responce">{location.email}</span>
                          </div>
                          <div className="inputArea">
                            <span className="title">Street</span>
                            <span className="responce">{location.street}</span>
                          </div>
                          {
                            location.street2 &&
                            <div className="inputArea">
                              <span className="title">Street 2</span>
                              <span className="responce">{location.street2}</span>
                            </div>
                          }
                          <div className="inputArea">
                            <span className="title">City</span>
                            <span className="responce">{location.city}</span>
                          </div>
                          <div className="inputArea">
                            <span className="title">State</span>
                            <span className="responce">{location.state}</span>
                          </div>
                          <div className="inputArea">
                            <span className="title">Zipcode</span>
                            <span className="responce">{location.zip}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
          }
          <div className="col col-sm" onClick={()=>{this.setState({currentLocation: DummyNewLocation})}}>
            <div className="panelItemWrapper">
              <div className="panelItem addNew">
                <div className="inputZone">
                  <div className="addnewArea">
                    <span className="title">Add New Invoice Location</span>
                    <span className="responce">Click Here to Add a New Location</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const DummyNewLocation = {
  name: '',
  email: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
};


export default InvoiceLocations;
