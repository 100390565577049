import React from 'react';
import ProductsList from './tabProducts/products_list';
import {ProductsClass} from 'global-api-helper';
class TabAdminProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: (window.Session.Products ? window.Session.Products : [])
    };
    this.updateProducts = this.updateProducts.bind(this);
    this.ProductsClass = new ProductsClass();
          
  }
  componentDidMount(){

  }
  componentWillUnmount(){

  }
  updateProducts(newProducts){
    newProducts.forEach((product)=>{
      this.ProductsClass.Update(product)
      .then(success=>{
        this.setState({products: success});
      })
      .catch(fail=>{
      });
    });
    
  }
  render(){
    var {business, products, loading} = this.state;
    return (
      <div className="scrolableMaxHeight">
        <h5 className="panelTitle panelTitleWithButtons"><span className="text">Product Price Setter</span></h5>
        <div className="panelItem">
          <ProductsList
            products={products}
            updateProducts={this.updateProducts}
          />
        </div>
      </div>
    );
  }
}


export default TabAdminProducts;







        









