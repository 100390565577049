import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    ///flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  row: {
    flexDirection: 'row'
  },
  header: {
    backgroundColor: "#444",
    color: "#FFF",
    padding: 10,
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1
  },
  sectionEnd: {
    margin: 10,
    padding: 10,
  },


  invoiceStampRows: {
    fontSize: 10,
    paddingTop: 2
  },

  invoiceInfo: {
    fontSize: 10,
    paddingTop: 2
  },

  headerText: {
    paddingBottom: 5
  },

  
  orderList: {
  },
  orderListHeaderText: {
    fontSize: 12
  },
  orderListItem: {
    flexDirection: 'row',
    paddingTop: 10,
    //marginTop: 10,
    paddingBottom: 10,
    borderTopWidth: 1,
    borderTopColor: '#aaa'
  },
  orderListItemDate: {
    width: 70
  },
  orderListItemId: {
    flex: 1
  },
  orderListItemPatient: {
    width: 100
  },
  orderListItemNumber: {
    width: 80
  },
  orderListItemTotal: {
    width: 100
  },
  orderListText: {
    fontSize: 10
  },
  orderListItemBlock: {
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "left"
  },



  footerRow: {
    borderTopWidth: 1,
    borderTopColor: '#aaa',
    margin: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  invoiceTermsText: {
    fontSize: 10,
    fontWeight: 200
  },
  totalTextWrapper: {
    flexDirection: 'row',
    paddingTop: 3
  },
  totalTextHeader: {
    flex: 1,
    fontSize: 10,
    color: "#0097e9",
    fontWeight: "bold"
  },
  totalText: {
    fontSize: 10
  },
});

// Create Document Component
const MyDocument = ({invoice, primaryLocation={}}) => {
  var CleanIssued = new Date(invoice.date).formatDate('n/d/y');
  var CleanStart = new Date(invoice.startDate).formatDate('n/d/y');
  var CleanEnd = new Date(new Date().setDate(new Date(invoice.endDate).getDate()-1)).formatDate('n/d/y');
  var discounts = (invoice.subTotal*(invoice.discount/100));
  var taxes = 0;
  var total = (invoice.subTotal - discounts + taxes);
  var orders = invoice.orders;
        
  return(
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={{flex: 1}}>Invoice</Text>
          <Image src={window.CONST.SITE.logo.light} />
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.headerText}>Bill To</Text>
            {
              window.CONST.SITE.invoiceStampArray && 
              window.CONST.SITE.invoiceStampArray.map((newText, index)=>{
                return(
                  <Text key={index} style={styles[`invoiceStampRows`]}>{newText}</Text>
                )
              })
            }
          </View>
          <View style={styles.sectionEnd}>
            <Text style={styles.headerText}>Invoice Info</Text>
            <Text style={styles.invoiceInfo}>ID: {invoice.id}</Text>
            <Text style={styles.invoiceInfo}>Starting: {CleanStart}</Text>
            <Text style={styles.invoiceInfo}>Ending: {CleanEnd}</Text>
            <Text style={styles.invoiceInfo}>Issued: {CleanIssued}</Text>
            <Text style={styles.invoiceInfo}>Status: {invoice.status}</Text>
            <Text style={styles.invoiceInfo}>Total: {window.GlobalUtil.convertToMoney(total)}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerText}>Send Invoice To</Text>
          <Text style={styles.invoiceInfo}>{primaryLocation.name}</Text>
          <Text style={styles.invoiceInfo}>{primaryLocation.street}</Text>
          <Text style={styles.invoiceInfo}>{primaryLocation.city}, {primaryLocation.state} {primaryLocation.zip}</Text>
          <Text style={styles.invoiceInfo}>{primaryLocation.email}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.headerText}>Orders</Text>
          <OrderList 
            orders={orders}
          />
        </View>
        <View style={[styles.row, styles.footerRow]}>
          <View style={styles.section}>
            <Text style={styles.headerText}>Invoice Terms</Text>
            <Text style={styles.invoiceTermsText}>All invoices should be paid within 2 weeks of issuing. Each will be issued within 1 week of new month.</Text>
          </View>
          <View style={[styles.sectionEnd, {minWidth: 180}]}>
            <Text style={styles.headerText}>Total Summary</Text>
            <View style={styles.totalTextWrapper}>
              <Text style={styles.totalTextHeader}>Subtotal:</Text>
              <Text style={styles.totalText}>{window.GlobalUtil.convertToMoney(invoice.subTotal)}</Text>
            </View>
            <View style={styles.totalTextWrapper}>
              <Text style={styles.totalTextHeader}>Discount:</Text>
              <Text style={styles.totalText}>{window.GlobalUtil.convertToMoney(discounts)}</Text>
            </View>
            <View style={styles.totalTextWrapper}>
              <Text style={styles.totalTextHeader}>Tax:</Text>
              <Text style={styles.totalText}>{window.GlobalUtil.convertToMoney(taxes)}</Text>
            </View>
            <View style={styles.totalTextWrapper}>
              <Text style={styles.totalTextHeader}>Total:</Text>
              <Text style={styles.totalText}>{window.GlobalUtil.convertToMoney(total)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};





const OrderList = ({orders}) => {
  return(
    <View style={styles.orderList}>
      <View style={styles.orderListItem}>
        <View style={[styles.orderListItemDate, styles.orderListItemBlock]}>
          <Text style={styles.orderListHeaderText}>
            Date
          </Text>
        </View> 
        <View style={[styles.orderListItemId, styles.orderListItemBlock]}>
          <Text style={styles.orderListHeaderText}>
            Order ID
          </Text>
        </View>   
        <View style={[styles.orderListItemPatient, styles.orderListItemBlock]}>
          <Text style={styles.orderListHeaderText}>
            Patient
          </Text>
        </View> 
        <View style={[styles.orderListItemNumber, styles.orderListItemBlock]}>
          <Text style={styles.orderListHeaderText}>
            # Products
          </Text>
        </View>
        <View style={[styles.orderListItemTotal, styles.orderListItemBlock]}>
          <Text style={styles.orderListHeaderText}>
           Total
          </Text>
        </View> 
      </View>
      {
        orders.map((object, index)=>{
          var date = new Date(object.date).formatDate('n/d/y');
          return(
            <View style={styles.orderListItem} key={index}>
              <View style={[styles.orderListItemDate, styles.orderListItemBlock]}>
                <Text style={styles.orderListText}>
                  {date}
                </Text>
              </View> 
              <View style={[styles.orderListItemId, styles.orderListItemBlock]}>
                <Text style={styles.orderListText}>
                  {object.id}
                </Text>
              </View>   
              <View style={[styles.orderListItemPatient, styles.orderListItemBlock]}>
                <Text style={styles.orderListText}>
                  {object.patient}
                </Text>
              </View> 
              <View style={[styles.orderListItemNumber, styles.orderListItemBlock]}>
                <Text style={styles.orderListText}>
                  {object.productNumber}
                </Text>
              </View>
              <View style={[styles.orderListItemTotal, styles.orderListItemBlock]}>
                <Text style={styles.orderListText}>
                  {window.GlobalUtil.convertToMoney(object.total)}
                </Text>
              </View> 
            </View>
          )
        })
      }
    </View>
  )
}

export default MyDocument;