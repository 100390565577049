import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import SettingsDialogWrapper from '../settings_dialog_wrapper';
import {UsersClass} from 'global-api-helper';
const DummyNew = {
  name: '',
  type: ''
};


class AddNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: (this.props.currentObject ? this.props.currentObject : DummyNew),
      isValid: false
    };
    this.onAddNew = this.onAddNew.bind(this);
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.UsersClass = new UsersClass();
    this.onValidate = this.onValidate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onAddNew(){
    var {currentObject} = this.state;
    this.UsersClass.Save({
      newObject: currentObject
    })
    .then(success => {
      this.props.onSave(currentObject);
    })
    .catch(fail=>{
      this.props.onCancel();
    });
  }

  onValidate(isValid){
    this.setState({isValid: isValid})
  }

  onCancel(){
    this.props.onCancel();
  }


  render(){
    var {currentObject, isValid} = this.state;          
    var states = window.GlobalUtil.states();
    const FormBuilder = this.FormBuilder;
    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="600"
        height="600"
        onCancel={this.onCancel}
        onSave={this.onAddNew}
        acceptButtonEnabled={isValid}
      >
        <FormBuilder
          initialValues={currentObject}
          listOfFields={[
            {
              title: <span className="">Name <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
              type: "text",
              name: "name",
              placeholder: "",
              required: true
            },
            {
              title: <span className="">Position <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
              type: "select",
              name: "type",
              options: POSITIONS,
              required: true
            },
          ]}
          onChange={newObj => currentObject = newObj}
          onValidate={this.onValidate}
        />
    </SettingsDialogWrapper>
    )
  }
}
const POSITIONS = [
  {
    title: "Choose a Position",
    value: "",
    disabled: true,
  },
  {
    title: "Doctor",
    value: "doctor"
  },
  {
    title: "Office Manager",
    value: "officeManager"
  },
  {
    title: "Branch Manager",
    value: "branchManager"
  },
  {
    title: "Secretary",
    value: "secretary"
  },
  {
    title: "Assistant",
    value: "assistant"
  },
  {
    title: "Intern",
    value: "intern"
  },
  {
    title: "Other",
    value: "other"
  },
];

export default AddNewUser;