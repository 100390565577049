export default class TableAllBusinessesClass {
  constructor(props) {
  	console.log("TableAllBusinessesClass");
  	    	
  }

	Get(input){
		var {admin=false, orderBy} = input;
		if(!admin) return;
		return new Promise((resolve, reject) => {
	    let Pointer = window.firebase.firestore().collection("business").where("active", "==", 1);
	    if(orderBy && orderBy.length > 0){
	    	if(orderBy[1]) Pointer = Pointer.orderBy(orderBy[0], orderBy[1]);
	    	else Pointer.orderBy(orderBy[0]);
	    } else {
				Pointer = Pointer.orderBy("companyName")
	    }
			//Pointer = Pointer.where("completedDate", "<=", Date.now()).orderBy("completedDate")
	        	
			Pointer.get()
	    .then((querySnapshot) => {
	    	//console.log("querySnapshot");
	    	//console.log(querySnapshot,"\n\n");
	    	    	
	    	let index = 0;
	    	querySnapshot.forEach((doc) => {
	    		this[index] = doc.data();
	    		index++;
			  });
				
			  return index;
			})
			.then(index=>{
				//console.log("this");
				//console.log(this,"\n\n");
				    	
				resolve(this);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});
	}


	

	async SaveToClass(initalObj){
		await Object.keys(initalObj).forEach(key=>{
  		this[key] = initalObj[key];
  	});
	}


	toArray(){
		return Object.keys(this).map((key, index)=>{
			return(this[key])
		})
	}
	


	Delete(id){
		return window.firebase.firestore().collection("business").doc(id).delete();
	}

	
	//ADD AS NEW IF DOES NOT EXIST OR UPDATE CURRENT OBJ WITH NEW DATA IF EXIST
	Update(newObject){
		if(!window.Session.Business.id) return;
		return new Promise((resolve, reject) => {
			newObject.business = window.Session.Business.id;
			var Pointer = window.firebase.firestore().collection("business");
			if(!newObject.id){ //update
				var PointerTemp = Pointer.doc();
				newObject.id = PointerTemp.id;
				PointerTemp.set({id: newObject.id});
			}
			//console.log("newObject");
			//console.log(newObject,"\n\n");
			    	
			if(newObject.status == "complete") newObject.trueStatus = "complete";
			else newObject.trueStatus = "active";

			Pointer.doc(newObject.id).update(newObject)
				.then(obj=>{
					resolve("success");
				})
				.catch(obj=>{
					reject(obj);
				});
		});
	}

	//REMOVE EXISTING DATA TO NEW DATA IF EXSITS
	Set(newObject){
		if(!window.Session.Business.id) return;
		return new Promise((resolve, reject) => {
			newObject.business = window.Session.Business.id;
			var Pointer = window.firebase.firestore().collection("business");
			if(!newObject.id){ //update
				var PointerTemp = Pointer.doc();
				newObject.id = PointerTemp.id;
				PointerTemp.set({id: newObject.id});
			}

			Pointer.doc(newObject.id).set(newObject)
				.then(obj=>{
					resolve("success");
				})
				.catch(obj=>{
					reject(obj);
				});
		});
	}



}
