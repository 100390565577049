import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import SettingsDialogWrapper from '../settings_dialog_wrapper';
import {BusinessClass} from 'global-api-helper';
class EditBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false
    };
    this.fullObject = {...window.Session.Business};
    this.onSubmit = this.onSubmit.bind(this);
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.BusinessClass = new BusinessClass();
  }

  onSubmit(){
    if(!this.state.isValid) return;
    this.BusinessClass.update(this.fullObject)
    .then((success)=>{
      this.props.onSave(this.fullObject);
    })
    .catch((error)=>{
      this.props.onCancel();
    });
  }


  render(){
    var {isValid} = this.state;          
    const FormBuilder = this.FormBuilder;
    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="600"
        height="600"
        onCancel={this.props.onCancel}
        onSave={this.onSubmit}
        acceptButtonEnabled={isValid}>
        <FormBuilder
          initialValues={{...this.fullObject}}
          listOfFields={[
            {
              title: <span className="">Business Name <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
              type: "text",
              name: "companyName",
              placeholder: "Add Business Name",
              required: true
            },
          ]}
          onChange={newObj => this.fullObject = newObj}
          onValidate={(isValid)=>{this.setState({isValid: isValid})}}
        />
      </SettingsDialogWrapper>
    )
  }
}

export default EditBusiness;