import React from 'react';
import AllOrders from '../tabsOrders/index';

const HEADER_ON_ADMIN = [
  {
    key: 'edit', 
    name: 'Edit', 
    width: '50',
    style: {},
    disableOrderBy: true
  },
  {
    key: 'delete', 
    name: 'Delete', 
    width: '50',
    style: {},
    disableOrderBy: true
  },
  {
    key: 'change_status', 
    name: 'Change Status', 
    width: '200',
    style: {},
    disableOrderBy: true
  }
];

const HEADER = [
  {
    key: 'id', 
    name: 'ID', 
    width: "200",
    style: {},
    disableOrderBy: true
  },
  {
    key: 'patient', 
    name: 'Patient', 
    width: undefined,
    style: {}
  },
  {
    key: 'doctor', 
    name: 'Doctor', 
    width: undefined,
    style: {}
  },
  {
    key: 'date', 
    name: 'Date', 
    width: undefined,
    style: {}
  },
  {
    key: 'status', 
    name: 'Status', 
    width: undefined,
    style: {}
  },
  {
    key: 'total', 
    name: 'Total', 
    width: undefined,
    style: {}
  },
  {
    key: 'products', 
    name: 'Products', 
    width: "100",
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'reOrder', 
    name: 'Order Again', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  }, 
  {
    key: 'preview', 
    name: 'Preview', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'review', 
    name: 'Review', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'review', 
    name: 'Report', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
];


const TabFilledOrders = (props) => {
  const titleFunction = (totalTotal)=><div style={{display: "flex"}}><div style={{flex: 1}}>Completed List of Orders</div></div>;
  return (
    <AllOrders {...props} titleFunction={titleFunction} HEADER_ON_ADMIN={HEADER_ON_ADMIN} HEADER={HEADER}/>
  );
}

export default TabFilledOrders;