/*
	this.EmailClass = new EmailClass();
*/
import React from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
//import {EmailClass, OrdersClass} from 'global-api-helper';
export default class EmailClass {
  constructor(props) {
    this.SendEmailAPI = this.SendEmailAPI.bind(this);
  }

  SendEmailAPI(EmailContent){
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(EmailContent), process.env.REACT_APP_SECRET).toString(); 
    axios.defaults.baseURL = ((process.env.NODE_ENV === 'development') ? `http://localhost:3001` : `https://clubsonriasa.com`);
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    return axios.post('/api/email', 
      JSON.stringify({
        data: ciphertext
      })
    );
  }

	SendWelcome(EMAIL, PERSONS_NAME, COMPANTY_NAME, KEY_VALUE_TO_CONFIRM){
		return new Promise((resolve, reject)=>{
			var templateData = {
	      name: PERSONS_NAME,
	      companyName: COMPANTY_NAME,
	      confirmKey: KEY_VALUE_TO_CONFIRM
	    };

	    var Destination = {
	      "ToAddresses": [EMAIL]//, [EMAIL]
	    };

	    var params = {
	      Destination: Destination,
	      Template: "emailtemplatewelcome1",
	      TemplateData: JSON.stringify(templateData)
	    };
	    

      this.SendEmailAPI(params)
        .then((data)=>{
          resolve(data)
        })
        .catch((error)=>{
          reject(error)
        });
		});
	}



	SendComfirmedEmail(EMAIL, PERSONS_NAME, COMPANTY_NAME){
		return new Promise((resolve, reject)=>{
			var templateData = {
	      name: PERSONS_NAME,
	      companyName: COMPANTY_NAME
	    };

	    var Destination = {
	      "ToAddresses": [EMAIL]//, [EMAIL]
	    };

	    var params = {
	      Destination: Destination,
	      Template: "emailtemplateconfirmed1",
	      TemplateData: JSON.stringify(templateData)
	    };
	    
	    
	    this.SendEmailAPI(params)
        .then((data)=>{
          resolve(data)
        })
        .catch((error)=>{
          reject(error)
        });
		});

		/*
		TEST WITH
		var EmailClassNew = new EmailClass()
		EmailClassNew.SendComfirmedEmail("danielezaldivar@gmail.com", "Person Name", "Company Name");
		*/	
	}


	SendInvoice(){
		//return this.AddOrUpdate(input);
	}


	SendOrderStatus(){
		//return this.AddOrUpdate(input);
	}


	SendOrderSent(EMAIL, ORDER){
		//return this.AddOrUpdate(input);
		const OrderType = {
			"bridge": "https://firebasestorage.googleapis.com/v0/b/readycrown.appspot.com/o/bridge-icon.png?alt=media&token=b1af664a-e90c-45a8-9a7c-d7981ca0c786",
			"crown": "https://firebasestorage.googleapis.com/v0/b/readycrown.appspot.com/o/crown-icon.png?alt=media&token=7221a2fc-d824-453f-9426-b6e567da1840",
			"implant": "https://firebasestorage.googleapis.com/v0/b/readycrown.appspot.com/o/tooth-icon.png?alt=media&token=38c0f31b-aec0-4102-874d-64e6a3dd35ef",
		}
		const product = ORDER.products[0];
		return new Promise((resolve, reject)=>{
			var templateData = {
				typeIcon: OrderType[product.type.value],
				type: product.type.value,
				patient: ORDER.patient,
				doctor: ORDER.doctor,
				date: new Date().formatDate("n/d/Y"),
				id: ORDER.id,
				street: ORDER.shipping.street,
				street2: (ORDER.shipping.street2 ? ' '+ORDER.shipping.street2 : ''),
				city: ORDER.shipping.city,
				state: ORDER.shipping.state,
				zip: ORDER.shipping.zip,
	    };

	    var Destination = {
	      "ToAddresses": [EMAIL]//, [EMAIL]
	    };

	    var params = {
	      Destination: Destination,
	      Template: "emailtemplateordersent1",
	      TemplateData: JSON.stringify(templateData)
	    };

	    this.SendEmailAPI(params)
        .then((data)=>{
          resolve(data)
        })
        .catch((error)=>{
          reject(error)
        });
		});

		/*
		TEST WITH
		var OrderClassNew = new OrdersClass()
		OrderClassNew.GetSingle("Tp5ad89zPjQMNcspbVjO", orderObj=>{
		    var order = orderObj.data();
		    var EmailClassNew = new EmailClass()
		    EmailClassNew.SendOrderSent("danielezaldivar@gmail.com",order);
		})

		*/	
	}





	SendComfirmedEmail(EMAIL){
		return new Promise((resolve, reject)=>{
			var templateData = {
	    };

	    var Destination = {
	      "ToAddresses": [EMAIL]//, [EMAIL]
	    };

	    var params = {
	      Destination: Destination,
	      Template: "emailtemplateweeklysummary1",
	      TemplateData: JSON.stringify(templateData)
	    };
	    
	    
	    this.SendEmailAPI(params)
        .then((data)=>{
          resolve(data)
        })
        .catch((error)=>{
          reject(error)
        });
		});

		/*
		TEST WITH
		var EmailClassNew = new EmailClass()
		EmailClassNew.SendComfirmedEmail("danielezaldivar@gmail.com");
		*/	
	}


	SendWeeklySummary(EMAIL, ORDERS){
		//return this.AddOrUpdate(input);
		const OrderType = {
			"bridge": "https://firebasestorage.googleapis.com/v0/b/readycrown.appspot.com/o/bridge-icon.png?alt=media&token=b1af664a-e90c-45a8-9a7c-d7981ca0c786",
			"crown": "https://firebasestorage.googleapis.com/v0/b/readycrown.appspot.com/o/crown-icon.png?alt=media&token=7221a2fc-d824-453f-9426-b6e567da1840",
			"implant": "https://firebasestorage.googleapis.com/v0/b/readycrown.appspot.com/o/tooth-icon.png?alt=media&token=38c0f31b-aec0-4102-874d-64e6a3dd35ef",
		}
		var OrdersArray = ORDERS.map((order, index)=>{
			var type = order.product[0].type.value;
			return({
				"date": (order.completedDate ? order.completedDate : new Date().formatDate("n/d/Y")),
				"patient": order.patient,
				"doctor": order.doctor,
				"type": type,
				"typeIcon": OrderType[type]
			})
		});

		return new Promise((resolve, reject)=>{
			var templateData = {
				"orders": OrdersArray
	    };
	    var Destination = {
	      "ToAddresses": [EMAIL]//, [EMAIL]
	    };

	    var params = {
	      Destination: Destination,
	      Template: "emailtemplateweeklysummary1",
	      TemplateData: JSON.stringify(templateData)
	    };

	    this.SendEmailAPI(params)
        .then((data)=>{
          resolve(data)
        })
        .catch((error)=>{
          reject(error)
        });
		});
	}

}



/*
NEW ACCOUNT:
  Email new user with link to confirm page
  When click confirm page link, go to link
  When load confirm page get id from url, go to DB and update that ID to confirmed. Then show "success message"


RESET PASSWORD:
  

INVOICE:
  

ARRIVING MESSAGE:




*/