import React from 'react';
import ReactTooltip from 'react-tooltip';
import {InputFormsClass} from 'global-api-helper';
import {InvoicesClass} from 'global-api-helper';
class TabInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      loading: true,
      locationsArray: []
    };
    this.InvoicesClass = new InvoicesClass();
    this.apiGetInvoices = this.apiGetInvoices.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }

  componentDidMount(){
    this.apiGetInvoices();
  }

  componentWillUnmount(){}

  apiGetInvoices(){
    this.setState({loading: true});
    if(this.props.status == "paid"){
      this.InvoicesClass.GetAllPaid()
      .then(invoices=>{
        this.setState({
          invoices: invoices,
          loading: false
        });
      })
      .catch((fail)=>{
        console.log("fail");
        console.log(fail,"\n\n");   
        this.setState({
          invoices: [],
          loading: false
        });
      });
    } else {
      this.InvoicesClass.GetAllUnpaid()
      .then(invoices=>{
        this.setState({
          invoices: invoices,
          loading: false
        });
      })
      .catch((fail)=>{
        console.log("fail");
        console.log(fail,"\n\n");   
        this.setState({
          invoices: [],
          loading: false
        });
      });
    }
  }

  onChangeStatus(invoice, newStatus){
    invoice.status = newStatus.target.value;
    this.setState({loading: true});
    this.InvoicesClass.Save(invoice)
    .then(()=>{
      this.apiGetInvoices();
    })
    .catch(()=>{
      this.setState({loading: false});
    });   
  }



  render(){
    var {invoices=[], loading, locationsArray} = this.state;
    if(loading) {
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent 
        />
      );
    };
    return (
      <div id="Invoices">
        <h5 className="panelTitle">
          <span className="text">List of Invoices</span>
        </h5>
        {
          invoices.length > 0
          ? invoices.map((invoice, index)=>{
              return(
                <Invoice
                  key={index}
                  invoice={invoice}
                  onChangeStatus={this.onChangeStatus.bind(this, invoice)}
                />
              )
            })
          : <div className="nonFoundMessage">
              <div className="header">No Invoices Found</div>
            </div>
        }
        

      </div>
    );
  }
}

const Invoice = ({invoice, onChangeStatus}) => {
  var {id, status, startDate, endDate} = invoice;
  var CleanStart = new Date(startDate).formatDate('n/d/y');
  var CleanEnd = new Date(new Date().setDate(new Date(endDate).getDate()-1)).formatDate('n/d/y');
  return(
    <div className={"invoice " + status}>
      <div className="text" style={{
          "flex": "inherit",
          "display": "flex",
          "alignItems": "center",
          "color": "rgb(255, 255, 255)",
          "backgroundColor": ( status === "unpaid" ? "rgb(179, 55, 203)" : "#0097E9"),
          "margin": "-15px 15px -15px -15px",
          "padding": "0px 25px",
          "overflow": "hidden",
          "minWidth": "300px",
          "maxWidth": "300px"
        }}>
        <div className="month">{invoice.companyName}</div>
      </div>
      <div className="text">
        <div className="month">{new Date(startDate).formatDate('M')}</div>
        <div className="range">From: {CleanStart} - To: {CleanEnd}</div>
      </div>
      <div className="text" style={{"flex":"inherit","paddingRight":"20px","display":"flex","alignItems":"center"}}>
        <div className="month">Total: {window.GlobalUtil.convertToMoney(invoice.subTotal)}</div>
      </div>
      <div className="status">
        <div className={"statusText " + status}>
          <span className="">{status}</span>
        </div>
      </div>
      <StatusDropdown onChange={onChangeStatus} object={invoice}/>
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}


const StatusDropdown = ({object, onChange}) => {
  var {status} = object;
  return(
    <select type="select" name="status" value={status} onChange={onChange} style={{"width":"140px","height":"40px","margin":"auto auto auto 20px"}}>
      <option value={'unpaid'}>Unpaid</option>
      <option value={'paid'}>Paid</option>
    </select>
  )
}



export default TabInvoices;