import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';

import OrdersPending from './tabsOrders/tab_orders_pending';
import OrdersActive from './tabsOrders/tab_orders_active';
import OrdersReviewing from './tabsOrders/tab_orders_reviewing';
import OrdersShipping from './tabsOrders/tab_orders_shipping';
import OrdersComplete from './tabsOrders/tab_orders_complete';

class OrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  render(){
          
    return (
      <React.Fragment>
        <Tabs
          tabs={[
            {"name": "Pending Orders", "url":"/panel/admin/orders/pending"},
            {"name": "Active Orders", "url":"/panel/admin/orders/active"},
            {"name": "Reviewing Orders", "url":"/panel/admin/orders/reviewing"},
            {"name": "Shipping Orders", "url":"/panel/admin/orders/shipping"},
            {"name": "Complete Orders", "url":"/panel/admin/orders/complete"},
          ]}>
          <Route exact path={["/panel/admin/orders/", "/panel/admin/orders/pending"]} component={OrdersPending} />
          <Route exact path={["/panel/admin/orders/active"]} component={OrdersActive} />
          <Route exact path={["/panel/admin/orders/reviewing"]} component={OrdersReviewing} />
          <Route exact path={["/panel/admin/orders/shipping"]} component={OrdersShipping} />
          <Route exact path={["/panel/admin/orders/complete"]} component={OrdersComplete} />
        </Tabs>
      </React.Fragment>
    );
  }
}
export default OrdersPage;
