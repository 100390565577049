import React from 'react';
import {InvoicesClass} from 'global-api-helper';
export default class SuperUserAdminClass {
  constructor(props) {
  }
	

	GetAllPending(){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
		return new Promise((resolve, reject) => {
			var Pointer = window.firebase.firestore().collection("orders").where("status", "==", "pending");
			Pointer.get()
			.then((querySnapshot) => {
	    	this.pending = querySnapshot.size;
	    	resolve({pending: this.pending});
			})
			.catch(info=>{
			  console.log(info);
			  reject(info);
			});
		});
	}


	GetAllActive(){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
		return new Promise((resolve, reject) => {
			var Pointer = window.firebase.firestore().collection("orders").where("status", "==", "active");
			Pointer.get()
			.then((querySnapshot) => {
	    	this.active = querySnapshot.size;
	    	resolve({active: this.active});
			})
			.catch(info=>{
			  console.log(info);
			  reject(info);
			});
		});
	}


	GetAllReviewing(){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
		return new Promise((resolve, reject) => {
			var Pointer = window.firebase.firestore().collection("orders").where("status", "==", "reviewing");
			Pointer.get()
			.then((querySnapshot) => {
	    	this.reviewing = querySnapshot.size;
	    	resolve({reviewing: this.reviewing});
			})
			.catch(info=>{
			  console.log(info);
			  reject(info);
			});
		});
	}

	GetAllShipping(){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
		return new Promise((resolve, reject) => {
			var Pointer = window.firebase.firestore().collection("orders").where("status", "==", "shipping");
			Pointer.get()
			.then((querySnapshot) => {
	    	this.shipping = querySnapshot.size;
	    	resolve({shipping: this.shipping});
			})
			.catch(info=>{
			  console.log(info);
			  reject(info);
			});
		});
	}


	GetAllComplete(){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
		return new Promise((resolve, reject) => {
			var Pointer = window.firebase.firestore().collection("orders").where("status", "==", "complete");
			Pointer.get()
			.then((querySnapshot) => {
	    	this.complete = querySnapshot.size;
	    	resolve({complete: this.complete});
			})
			.catch(info=>{
			  console.log(info);
			  reject(info);
			});
		});
	}


	Get(callback=()=>{}){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
	  if(window.Session.BusinessSuperAdmin && window.Session.BusinessSuperAdmin.lastUpdate && window.GlobalUtil.compairTimes(window.Session.BusinessSuperAdmin.lastUpdate) < 10){
	  	return callback(window.Session.BusinessSuperAdmin);
	  }
		Promise.all([this.GetAllPending(), this.GetAllActive(), this.GetAllReviewing(), this.GetAllShipping(), this.GetAllComplete()])
		.then((values) => {
		  var cleanValues = {};
		  values.map(obj=>cleanValues[Object.keys(obj)[0]] = obj[Object.keys(obj)[0]])
			window.Session.BusinessSuperAdmin = {
				ordersByStatus: {
					active: cleanValues["active"],
					complete: cleanValues["complete"],
					pending: cleanValues["pending"],
					reviewing: cleanValues["reviewing"],
					shipping: cleanValues["shipping"]
				},
				lastUpdate: window.GlobalUtil.getTimeNow()
			};	
			window.GlobalUtil.processEvent("superAdminChangedListener", window.Session.BusinessSuperAdmin);
			callback(window.Session.BusinessSuperAdmin)
		});
	}

	async SaveToClass(initalObj){
		await Object.keys(initalObj).forEach(key=>{
  		this[key] = initalObj[key];
  	});
	}


	toArray(){
		return Object.keys(this).map((key, index)=>{
			return(this[key])
		})
	}
	


	Delete(id){
		return window.firebase.firestore().collection("business").doc(id).delete();
	}


	CreateInvoicesForThisMonth(){
		this.InvoicesClass = new InvoicesClass();
		//GET EACH BUSINESS
		//FOR EACH GENORATE INVOICES
		this.InvoicesClass.CreateAll();

	}




}

//window.GlobalUtil.processEvent("superUserAdminChangedListener")
//var SuperUserAdminClass = new SuperUserAdminClass()
//SuperUserAdminClass.GetAllPending()