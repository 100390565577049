import React from 'react';
import '../css/right_side_bar.css';
export default class RightSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionStyles: {"transform": "translate(100%,0)"}
    };
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount(){
    window.GlobalUtil.delay(200).then(()=>{
      this.setState({transitionStyles: {"transform": "translate(0%, 0%)"}})
    })
  }
  onClose(callBack=()=>{}){
    this.setState({transitionStyles: {"transform": "translate(100%, 0%)"}})
    window.GlobalUtil.delay(600).then(()=>{
      callBack();
    })
  }


  render(){
    var {transitionStyles} = this.state;
    return (
      <div id="RightSideBar" style={transitionStyles}>
        {this.props.children}
      </div>
    );
  }
}