/*
	this.MetaDataClass = new MetaDataClass();

	THIS CLASS SHOULD HANDLE ALL API CALLS FOR BASIC ORDERS
	GETTING ORDERS
	CREATING ODERS
	DELETE ORDERS, 
	UPDATING ORDERS, 
	CLONE ORDERS, 
	ETC.
*/ 
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class MetaDataClass {
  constructor(props) {
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }

	Get(docName){
		return new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("metadata").doc(docName); 	
			Pointer.get()
	    .then((doc) => {
	    	this.data = doc.data();
	    	this.currentPointer = Pointer;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});
	}


	Set(newObject, docName){
		return new Promise((resolve, reject) => {
			var Pointer = this.currentPointer;
			if(docName) Pointer = this.Firestore.collection("metadata").doc(docName); 	
			Pointer.set(newObject)
	    .then((doc) => {
	    	this.data = newObject;
	    	this.currentPointer = Pointer;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});
	}



	
	Delete(docName){
		return new Promise((resolve, reject) => {
			if(!docName) return reject();
			var Pointer = this.currentPointer;
			if(docName) Pointer = this.Firestore.collection("metadata").doc(docName); 	
			Pointer.delete()
	    .then((doc) => {
	    	this.data = undefined;
	    	this.currentPointer = Pointer;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});
	}



	Update(newObject, docName){	
		return this.SaveMetaData(newObject, docName);
	}

	Save(newObject, docName){
		return this.SaveMetaData(newObject, docName);
	}


	SaveMetaData(newObject, docName){
		return new Promise((resolve, reject) => {
			var Pointer = this.currentPointer;
			if(docName) Pointer = this.Firestore.collection("metadata").doc(docName); 	
			Pointer.update(newObject)
	    .then((doc) => {
	    	this.data = newObject;
	    	this.currentPointer = Pointer;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});
	}



}
