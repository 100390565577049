import React from 'react';

const CONTENT = [
  "Section 1",
  "CROWNS IN 2-3 DAYS!",
  "This page is focused on our speed! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus. Cras vel lacus eu turpis porttitor condimentum vel sed tortor. Donec pulvinar dui vitae mi aliquam consequat.",
  "ORDER MORE INFO ON YOUR FREE 3D SCANNER!",
  "Section 2",
  "Get the Latest tech without the $20,000 commitment",
  (<React.Fragment>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus. Cras vel lacus eu turpis porttitor condimentum vel sed tortor. Donec pulvinar dui vitae mi aliquam consequat.<br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus.
  </React.Fragment>),
  "Cras eget nisl est. Nam maximus mattis orci, sed condimentum metus porttitor a. Phasellus aliquet porta ullamcorper.",
  "Cras eget nisl est. Nam maximus mattis orci, sed condimentum metus porttitor a. Phasellus aliquet porta ullamcorper.",
  "Cras eget nisl est. Nam maximus mattis orci, sed condimentum metus porttitor a. Phasellus aliquet porta ullamcorper.",
  "Cras eget nisl est. Nam maximus mattis orci, sed condimentum metus porttitor a. Phasellus aliquet porta ullamcorper.",
  "Contact Me Now!",
  "Section 3",
  "Could you use this product?",
  "Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus.",
  "Donec malesuada auctor",
  "Donec malesuada auctor enim in mollis. Suspendisse potenti. Pellentesque laoreet orci ac tortor euismod, quis malesuada lacus egestas. Aenean in laoreet nisi, eu sodales est. Nam consequat tempus tellus, sit amet gravida eros fringilla sit amet.",
  "Pellentesque laoreet orci",
  "Donec malesuada auctor enim in mollis. Suspendisse potenti. Pellentesque laoreet orci ac tortor euismod, quis malesuada lacus egestas. Aenean in laoreet nisi, eu sodales est. Nam consequat tempus tellus, sit amet gravida eros fringilla sit amet.",
  "Suspendisse potenti pellentesque",
  "Donec malesuada auctor enim in mollis. Suspendisse potenti. Pellentesque laoreet orci ac tortor euismod, quis malesuada lacus egestas. Aenean in laoreet nisi, eu sodales est. Nam consequat tempus tellus, sit amet gravida eros fringilla sit amet.",
  "Aenean in laoreet nisi",
  "Donec malesuada auctor enim in mollis. Suspendisse potenti. Pellentesque laoreet orci ac tortor euismod, quis malesuada lacus egestas. Aenean in laoreet nisi, eu sodales est. Nam consequat tempus tellus, sit amet gravida eros fringilla sit amet.",
  "Section 4",
  "Suspendisse potenti pellentesque",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus. Cras vel lacus eu turpis porttitor condimentum vel sed tortor. Donec pulvinar dui vitae mi aliquam consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus.",
  "Section 5",
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus. Cras vel lacus eu turpis porttitor condimentum vel sed tortor. Donec pulvinar dui vitae mi aliquam consequat.",
  "Learn More",
  "Ready to Cut Costs, Get Crowns Faster, and Increase Patient Satisfaction? ",
  "Contact us now to ORDER YOUR FREE CROWN! One of our representatives will follow up with you to answer all your questions and explain how Ready Crowns can exceed your expectations.",
  "Contact Us",
  "Section 7",
  "Frequently Asked Questions",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium nec tellus sed tincidunt. Aenean lacinia nisl in arcu ornare placerat. Proin mauris magna, ultricies sit amet velit ac, ullamcorper accumsan lacus. Cras vel lacus eu turpis porttitor condimentum vel sed tortor.",
  [ //#3
    {
      "title": "What do I get? What does the system look like?",
      "text": (<div className="">
          <p>Dental Tech’s intraoral system is a complete intraoral dental scanner system (IDS), NOT just a scanner. Nothing to setup, just plug-and-play.</p>
            <ul>
              <li>A high-quality intraoral digital scanner. -- SKU: Aoralscan-Shining-3D.</li>
              <li>Top end, high performance computer. -- SKU: ASUS ROG Strix G G731GU - 17.3" FHD.</li>
              <li>State-of-the art, intuitive, digital imaging software.</li>
              <li>Dental trolley for portability between operatories.</li>
              <li>Remote training.</li>
              <li>Installation, remote maintenance and support.</li>
            </ul>
        </div>)
    },
    {
      "title": "How can I get the system for free?",
      "text": (<p>You can get $20 per crown discount. Every time you send 15 crowns to one of our Lab Partners, you`ll get a $300 check & reduce your monthly cost to $0</p>)
    },
    {
      "title": "Is the scanner powderless?",
      "text": (<p>Yes! No need for powder, guaranteeing a simplified scanning process and improving patients` clinical experience.</p>)
    },
    {
      "title": "How accurate is the scanner?",
      "text": (<p>It is very accurate with a 20 microns precision per tooth.</p>)
    },
    {
      "title": "Is the scan color realistic? ",
      "text": (<p>Yes! Our system provides real-time scan, realistic color, and a clear margin line.</p>)
    },
    {
      "title": "How easy it is to operate?",
      "text": (<div className="">
          <p>Our system is easy and intuitive to operate; it requires very little training to be able to operate it like a pro. It has user-friendly interface that allows sterile scanning by a single technician or dentist using the motion sensing function.</p>
          <p>Self-developed scanning software integrates rich data processing functions to obtain high-quality digital impressions.</p>
        </div>)
    },
    {
      "title": "Do you use artificial intelligence (AI) to optimize the scan?",
      "text": (<p>Yes! Through the use of Al, the software can automatically identify and delete miscellaneous data such as the buccal/lingual side to realize automated data optimization.</p>)
    },
    {
      "title": "Can I go back and re-scan a missing spot?",
      "text": (<p>Yes! When some part of the scan is missing during scanning, this retracing function allows users to jump back to missing area for rescan, aligning the data smoothly.</p>)
    },
    {
      "title": "Do you include a clinical toolkit?",
      "text": (<div className="">
          <p>Yes! The clinical toolkit can help users to evaluate the scanned data at clinics. This includes functions such as "adjust coordinate", "extract margin line", "check bite", "mark teeth", "check undercut", etc. </p>
          <p>These functions facilitate effective communication between clinics and dental labs while saving time, ensuring high efficiency and quality for design and restoration projects.</p>
        </div>)
    },
    {
      "title": "What indications can the scanner be used for?",
      "text": (<p>Dental Tech’s intraoral system meets requirements for a variety of indications, including single crowns, inlays/onlays, bridges, veneers, orthodontics, and implants. </p>)
    },
    {
      "title": "How does the lab receive the impressions?",
      "text": (<p>Dental Tech’s cloud data platform enables data transmission, making it convenient for order tracking and facilitating communication between clinics and dental labs.</p>)
    }
  ]
]
export default CONTENT;
