import React from 'react';
import '../css/admin.css';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Wrapper from '../components/wrapper';

import BackHome from './home';
import BackInvoices from './invoices';
import BackSettings from './settings';
import BackUsers from './users';
import BackOrders from './orders';
//ADMIN PAGES
import AdminBusinesses from '../adminPages/businesses';
import AdminPricing from '../adminPages/pricing';
import AdminBackOrders from '../adminPages/orders';
import AdminBackInvoices from '../adminPages/invoices';
import AdminBackContacts from '../adminPages/contacts';

/*
  THIS PAGE DOES BACKEND ROUTING AND LOGOUT
*/

const Index = (props) => {
  function onLogout(){
    console.log("YOU LOGGED OUT")
    //window.Router.history.push('/');
    window.firebase.auth().signOut().then(() => {
      // Sign-out successful.
      window.Router.history.push('/'); //change to login page
      document.location.href="/";
    })
    .catch(function(error) {
      // An error happened.
      window.Router.history.push('/'); //change to login page
      document.location.href="/";
    });
  }

  var {admin, isVR} = props;
  var history = {};
  return (
    <Wrapper {...props} admin={admin} isVR={isVR} onLogout={onLogout}>
      <Route exact path={["/","/panel"]} render={(props)=><BackHome {...props} />} />
      <Route path={["/panel/orders"]} render={(props)=><BackOrders {...props} />} />
      <Route path={["/panel/invoices"]} render={(props)=><BackInvoices {...props} />} />
      <Route path={["/panel/users"]} render={(props)=><BackUsers {...props} />} />
      <Route path={["/panel/settings"]} render={(props)=><BackSettings {...props} />} />
      <Route 
        path={["/panel/admin/pricing"]}
        render={props => {
          return(
            admin 
            ? <AdminPricing {...props} admin={admin} /> 
            : <Redirect
                to={{
                  pathname: "/panel"
                }}
              />
          )
        }}
      />
      <Route 
        path={["/panel/admin/businesses"]}
        render={props => {
          return(
            admin 
            ? <AdminBusinesses {...props} admin={admin} /> 
            : <Redirect
                to={{
                  pathname: "/panel"
                }}
              />
          )
        }}
      />
      <Route 
        path={["/panel/admin/orders"]}
        render={props => {
          return(
            admin 
            ? <AdminBackOrders {...props} admin={admin} /> 
            : <Redirect
                to={{
                  pathname: "/panel"
                }}
              />
          )
        }}
      />
      <Route 
        path={["/panel/admin/invoices"]}
        render={props => {
          return(
            admin 
            ? <AdminBackInvoices {...props} admin={admin} /> 
            : <Redirect
                to={{
                  pathname: "/panel"
                }}
              />
          )
        }}
      />
      <Route 
        path={["/panel/admin/contacts"]}
        render={props => {
          return(
            admin 
            ? <AdminBackContacts {...props} admin={admin} /> 
            : <Redirect
                to={{
                  pathname: "/panel"
                }}
              />
          )
        }}
      />
      
    </Wrapper>
  );
}
export default Index;