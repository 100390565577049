import React from 'react';
import {BusinessClass} from 'global-api-helper';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
const DummyNewLocation = {
  name: '',
  email: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  default: false,
  id: ''
};


export default class InvoiceLocation {
  constructor(props) {
    console.log(`props`,"\n\n",props,"\n\n");
          
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }

	async Create(newObj={}){ //TAKE WHATEVER OBJ EXIST AND TURNS IT INTO A CLEAN SHIPPING LOCATION CLASS
		var isValidUser = this.ValidateAllowed();
		if(!isValidUser){
			return
		}
		var CurrentObj = {...DummyNewLocation};
		Object.keys(newObj).map((key, index)=>{
			if(CurrentObj[key] !== undefined) CurrentObj[key] = newObj[key];
		});

		return this.SaveToClass(CurrentObj)
		.then(obj=>{
			return(this);
		})
		.catch(error=>{
			console.log("error");
			console.log(error,"\n\n");
		});
	}


	async SaveToClass(initalObj){
		await Object.keys(initalObj).forEach(key=>{
  		this[key] = initalObj[key];
  	});
  	if(!window.Session.Business.hasInvoiceLocations) {
  		window.Session.Business.hasInvoiceLocations = true;
  		const BusinessClass = new BusinessClass()
  		await BusinessClass.update(window.Session.Business)
  		.then(()=>{
  		})
  		.catch(()=>{
  		});
  	}
	}


	async Set(){
		var newObject = JSON.parse(JSON.stringify(window.GlobalUtil.decycle(this))); //GET LOCAL OBJ
		delete newObject.Firestore;
		if(!window.Session.Business.id) return;
		return new Promise((resolve, reject) => {
			newObject.business = window.Session.Business.id;
			var Pointer = this.Firestore.collection("invoiceLocation");
			if(!newObject.id){ //update
				var PointerTemp = Pointer.doc();
				newObject.id = PointerTemp.id;
				PointerTemp.set({id: newObject.id});
			}

			Pointer.doc(newObject.id).update(newObject)
				.then(obj=>{
					window.Session.InvoiceLocations.lastModified = undefined;
					resolve(newObject);
				})
				.catch(obj=>{
					reject(obj);
				});
		});
	}

	Get(){
		if(!window.Session.Business.id) return;
		return new Promise((resolve, reject) => {
		});
	}
	


	Delete(id){
		return this.Firestore.collection("invoiceLocation").doc(id).delete();
	}



	async ChangeDefault(newDefault){
		if(!window.Session.Business.id) return;

		//REMOVE UPDATE OLD AN NO LONGER DEFAULT
		var RemoveOldDefault = await new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("invoiceLocation");
			Pointer.where("business", "==", window.Session.Business.id).where("default", "==", true).get()
	    .then((querySnapshot) => {
	    	querySnapshot.forEach((doc) => {
	    		doc.ref.update({default: false});
			  });
				resolve(true);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});

		
		//SET NEW AS DEFAULT
		var SetNewDefault = await new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("invoiceLocation").doc(newDefault.id);
			Pointer.update({default: true})
	    .then(obj => {
				resolve(true);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			}); 
		});

		var multiple = await this.GetMultiple();

		return multiple;
	}

}
