import React from "react";
import ReactTooltip from "react-tooltip";
import TabsTable from "../../components/tabsTables/index";
import {TableAllBusinessesClass, BusinessClass} from 'global-api-helper';

class TabAllBusinesses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: window.Session.Business,
      loading: true,
      disableNext: true,
      disablePrev: true,
      orderBy: "companyName",
      ascDesc: "desc"
    };
          
    this.TableAllBusinessesClass = new TableAllBusinessesClass(); ///Users/danielezaldivar/Sites/ready-crown/src/global-api-classes/table-orders.js
    this.apiCall = this.apiCall.bind(this);

    this.createRows = this.createRows.bind(this);
    this.createHeaders = this.createHeaders.bind(this);

    this.onCancel = this.onCancel.bind(this);


    this.DeleteBusinessComp = this.DeleteBusinessComp.bind(this);
    this.EditBusinessComp = this.EditBusinessComp.bind(this);
  }
  
  componentDidMount(){
    this.apiCall();
  }

  componentWillUnmount(){}

  apiCall(){
    if(!window.GlobalUtil.IsSuperAdminUserAndTab()) return;
    this.setState({loading: true});
    this.TableAllBusinessesClass.Get({
      admin: window.GlobalUtil.IsSuperAdminUserAndTab(),
      orderBy: this.state.orderBy ? [this.state.orderBy, this.state.ascDesc] : null
    })
    .then(NewTableAllBusinessesClass=>{
      this.TableAllBusinessesClass = NewTableAllBusinessesClass;
      this.createRows(NewTableAllBusinessesClass.toArray());
    })
    .catch(error=>{
      console.log("error");
      console.log(error,"\n\n");
      this.setState({loading: false});
    });
  }


  createHeaders(){
    var {orderBy, ascDesc} = this.state;
    var header = [
      //{width, name, isActive, style, onClick, ascDesc},
      {
        key: "companyName", 
        name: "Company Name", 
        width: undefined,
        style: {},
        isActive: false, //(orderBy === "patient"),
        onClick: ()=>{
          //this.handleSortBy("patient");
        },
        ascDesc: ascDesc
      },
      {
        key: "ordersByMonth", 
        name: "Orders This Month", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false, //(orderBy === "doctor"),
        onClick: ()=>{
          //this.handleSortBy("doctor");
        },
        ascDesc: ascDesc
      },
      {
        key: "ordersByLastMonth", 
        name: "Orders Last Month", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false, //(orderBy === "doctor"),
        onClick: ()=>{
          //this.handleSortBy("doctor");
        },
        ascDesc: ascDesc
      },
      {
        key: "pending", 
        name: "Pending Orders", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false, //false, //(orderBy === "date"),
        onClick: ()=>{
          //this.handleSortBy("date");
        },
        ascDesc: ascDesc
      },
      {
        key: "active", 
        name: "Active Orders", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false, //(orderBy === "status"),
        onClick: ()=>{
          //this.handleSortBy("status");
        },
        ascDesc: ascDesc
      },
      {
        key: "complete", 
        name: "Complete Orders", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false, //(orderBy === "total"),
        onClick: ()=>{
          //this.handleSortBy("total");
        },
        ascDesc: ascDesc
      },
      {
        key: "reviewing", 
        name: "Reviewing Orders", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false, //(orderBy === "products"),
        onClick: ()=>{
          //this.handleSortBy("products");
        },
        ascDesc: ascDesc
      },
      {
        key: "​shipping", 
        name: "Shipping Orders", 
        width: undefined,
        style: {
          textAlign: "center"
        },
        isActive: false,
        onClick: ()=>{
          //this.handleSortBy("products");
        },
        ascDesc: ascDesc
      },
      {
        key: "id", 
        name: "ID", 
        width: "300",
        style: {},
        isActive: false, //(orderBy === "id"),
        onClick: ()=>{
          //this.handleSortBy("id");
        },
        ascDesc: ascDesc
      },
      {
        key: "edit", 
        name: "Edit", 
        width: "100",
        style: {
          textAlign: "center"
        },
        isActive: false,
        onClick: ()=>{
          //NO FILTER BY THIS
        },
        ascDesc: ascDesc
      },
      {
        key: "delete", 
        name: "Delete", 
        width: "100",
        style: {
          textAlign: "center"
        },
        isActive: false,
        onClick: ()=>{
          //NO FILTER BY THIS
        },
        ascDesc: ascDesc
      } 
    ];
    return header;
  }
  

  createRows(arrayOfItems){
  //   console.log("arrayOfItems");
  //   console.log(arrayOfItems,"\n\n");
    var newRows = arrayOfItems.map((object, index)=>{
      var startOfMonth = window.GlobalUtil.getFirstOfMonth();     
      var startOfLastMonth = window.GlobalUtil.getFirstOfLastMonth();

      var ordersByMonthClean = {}; 
      if(object.ordersByMonth){
        Object.keys(object.ordersByMonth).map((key, index)=>{
          let monthTime = new Date(Number(key)).formatDate(`n/${1}/y`);
          ordersByMonthClean[new Date(monthTime).getTime()] = object.ordersByMonth[key];
        })
      }

      var ordersByMonth = (ordersByMonthClean[startOfMonth] ? ordersByMonthClean[startOfMonth] : 0)
      var ordersByLastMonth = (ordersByMonthClean[startOfLastMonth] ? ordersByMonthClean[startOfLastMonth] : 0)
      var ordersByStatus = (object.ordersByStatus ? object.ordersByStatus : {});
      const items = ([object.companyName, ordersByMonth, ordersByLastMonth, ordersByStatus.pending, ordersByStatus.active, ordersByStatus.complete, ordersByStatus.reviewing, ordersByStatus.shipping, object.id, this.EditBusinessComp(object), this.DeleteBusinessComp(object)]);
      return({
        items: items, 
        disableEdit: true, 
        object: object,
        customStyles: [
          {},
          {
            textAlign: "center"
          },
          {
            textAlign: "center"
          },
          {
            textAlign: "center"
          },
          {
            textAlign: "center"
          },
          {
            textAlign: "center"
          },
          {
            textAlign: "center"
          },
          {
            textAlign: "center"
          },
          {
            fontSize: "12px"
          },
        ]
      });
    })
    this.setState({loading: false, rows: newRows});
  }


  DeleteBusinessComp(object){
    return <DeleteBusiness 
      object={object} 
      onDelete={()=>{
        window.GlobalComp.Confirm({
          title: "Deleting",
          message: "Are you SURE you wish to delete this order?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                let BusinessClassNew = new BusinessClass();
                BusinessClassNew.Delete(object.id)
                .then(e=>{
                  this.apiCall();
                })
                .catch(e=>{});
              }
            },
            {
              label: "No",
              onClick: () => {
                //Do nothing, just close;
              }
            }
          ]
        });
      }} 
      admin={window.GlobalUtil.IsSuperAdminUserAndTab()}
    />
  }

  EditBusinessComp(object){
    return <EditBusniess 
      object={object} 
      onEdit={()=>{if(object.id) window.GlobalUtil.processEvent("triggerShowVRBusinessEvent", object.id)}} 
      admin={window.GlobalUtil.IsSuperAdminUserAndTab()}
    />
  }


  onCancel(){
    this.setState({orderToEdit: false, orderToPreview: false});
  }




  render(){
    var {orderToEdit, orderToPreview, totalTotal, loading, rows=[], disableNext, disablePrev} = this.state;
    var {admin} = this.props;
    var {business={}} = window.GlobalUtil;
    const Title = <div style={{display: "flex"}}><div style={{flex: 1}}>List of Businesses on Our Platform</div></div>;
    
    return (
      <React.Fragment>
        <TabsTable 
          ref={e=>this.TabsTableRef=e}
          loading={loading}
          title={(!admin ? "List of Businesses On Platform" : Title)}
          headers={this.createHeaders()}
          rows={rows}
          onNext={()=>{}}
          onPrev={()=>{}} 
          disableNext={disableNext}
          disablePrev={disablePrev}
          actionButton={undefined}
        />
        {/*
          orderToEdit &&
          <EditOrderDialog
            headerText={"Edit Order"}
            currentObject={orderToEdit}
            onSave={()=>{}}
            onCancel={this.onCancel}
          />
        */}
      </React.Fragment>
    );
  }
}



const DeleteBusiness = ({object, onDelete, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: "center", "maxWidth":"none"}} className={"tableTrashIcon " + ((status!="pending" && !admin) ? " disabled" : "")} data-tip="Delete Order" onClick={()=>{onDelete(id)}}>
      <i className="fas fa-trash-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}



const EditBusniess = ({object, onEdit, admin}) => {
  var {id, status, priceOverride} = object;
  //DONT ALLOW EDITING IF priceOverride EXIST OR THEY COULD ADD PRODUCT LATER AND KEEP OVERRIDE PRICE.
  return(
    <div style={{textAlign: "center", "maxWidth":"none"}} className={"tableTrashIcon " + (((status != "pending" || priceOverride) && !admin) ? " disabled" : "")} data-tip="Edit Order" onClick={onEdit}>
      <i className="fas fa-pencil-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}




export default TabAllBusinesses;