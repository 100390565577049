import React from 'react';
const Shipping = (props) => {
  var {order, showEdit, onEdit} = props;
  var {shipping=""} = order; 
  return (
    <div className="sectionIconLeft">
      <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-truck" /></div>
      <div className="sectionIconLeftContent">
        <div className="sectionHeader">
          <div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none">
            <i className="fas fa-truck" /></div> Ship to {(!shipping || !shipping.street) && window.GlobalComp.Required} {showEdit && <i className="fas fa-pen editIcon" onClick={()=>onEdit("shipping")}/>}
        </div>
        <div className="inputPatient">
          <div className="">Name: {window.Session.Business.companyName}</div>
          <div className="">Street: {shipping.street}</div>
          <div className="">City: {shipping.city}</div>
          <div className="">State: {shipping.state}</div>
          <div className="">Zip: {shipping.zip}</div>
        </div>
      </div>
    </div>
  );
}

export default Shipping;
