import React from 'react';
// import logo from '../logo.svg';
import '../../backend/css/backend.css';

class TeethBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      activeTeeth: (this.props.activeTeeth ? this.props.activeTeeth : [])
    };
    this.onClickTooth = this.onClickTooth.bind(this);
  }
  onClickTooth(tooth){
    var {activeTeeth=[]} = this.props;
    var newActiveTeeth = activeTeeth.length > 0 ? activeTeeth.filter(obj=>obj.value != tooth) : [];
    if(newActiveTeeth.length === activeTeeth.length) newActiveTeeth.push({
      add: this.props.add,
      value: tooth
    });
    this.setState({newActiveTeeth});
    this.props.onSelectTooth(newActiveTeeth);
  }

  render(){
    var {showLogin, id} = this.state;
    var {activeTeeth=[]} = this.props;
    if(!Array.isArray(activeTeeth)) activeTeeth = [];
    return (
      <div id="TeethBottom">
        <svg width="100%" height="100%" viewBox="0 0 413 322" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{
            "fillRule": "evenodd",
            "clipRule": "evenodd",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
            "strokeMiterlimit": "1.5"
          }}>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 16).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(16)}}>
            <path d="M389.226,264.058l-42.355,0.011c-4.561,2.355 -4.653,8.676 -3.406,14.451l-0.14,25.311c1.892,4.584 5.626,8.751 11.38,12.461l17.192,0.035c9.144,-6.743 16.907,-14.473 23.173,-23.277c2.026,-5.171 2.441,-12.521 1.751,-21.363c-1.002,-3.016 -3.775,-5.483 -7.595,-7.629Z" 
            className="vectorTooth"/>
            <text x="353.829px" y="297.084px" className="vectorText">16</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 15).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(15)}}>
            <path d="M382.075,216.121c5.498,3.468 10.123,7.045 10.712,10.102c1.675,9.028 2.18,18.183 0.739,27.549c-0.947,3.205 -3.113,6.333 -6.717,9.369l-37.523,0.875c-3.592,-2.46 -5.985,-5.866 -7.454,-9.999c-0.568,-7.878 -0.701,-15.956 -0.274,-24.297c0.256,-3.725 3.969,-7.263 6.852,-9.924l26.159,0.13c3.05,-1.133 5.569,-2.396 7.506,-3.805Z" 
            className="vectorTooth"/>
            <text x="353.647px" y="247.96px" className="vectorText">15</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 14).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(14)}}>
            <path d="M367.065,165.651c8.979,2.294 13.116,7.265 14.706,13.643c1.642,8.965 1.761,18.591 0.665,28.748c0.118,2.834 -0.501,5.614 -1.855,8.34c-1.323,1.006 -3.265,2.115 -6.067,3.369c-11.006,0.012 -20.308,-0.064 -26.03,-0.32c-4.817,-3.132 -7.994,-6.473 -9.37,-10.046l-2.7,-10.676c-1.364,-2.875 -6.889,-17.01 -2.761,-23.268c3.723,-5.644 27.559,-9.997 33.412,-9.79Z" 
            className="vectorTooth"/>
            <text x="344.551px" y="200.726px" className="vectorText">14</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 13).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(13)}}>
            <path d="M347.864,122.11c9.792,4.675 16.302,11.662 20.566,20.231c2.37,9.592 0.804,16.678 -2.549,22.623c-4.363,0.685 -8.442,1.545 -12.284,2.551c-6.397,1.674 -12.142,3.748 -17.465,6.082c-7.656,-2.553 -12.955,-8.793 -16.941,-17.087c-2.438,-5.927 -3.334,-12.908 0.368,-23.039c10.572,-1.982 20.008,-5.77 28.305,-11.361Z" 
            className="vectorTooth"/>
            <text x="330.186px" y="157px" className="vectorText">13</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 12).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(12)}}>
            <path d="M318.047,83.459l9.898,0.021c8.638,3.762 13.711,7.899 17.063,15.739c1.447,3.382 2.563,15.665 1.118,24.2c-13.832,8.513 -19.661,8.809 -26.546,9.246c-9.569,-3.432 -16.129,-8.58 -19.058,-15.801c-2.862,-6.951 -3.508,-13.927 -0.671,-20.948c8.478,-4.136 14.7,-8.287 18.196,-12.457Z" 
            className="vectorTooth"/>
            <text x="309.361px" y="116.09px" className="vectorText">12</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 11).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(11)}}>
            <path d="M296.077,48.562l13.285,-0.056c4.051,0.579 7.407,5.062 9.143,9.489c1.076,8.659 0.726,17.399 -0.665,26.196c-11.365,10.097 -21.656,14.182 -30.262,8.813c-7.095,-3.373 -9.254,-12.11 -4.541,-28.317c2.164,-7.112 5.973,-12.913 13.04,-16.125Z" 
            className="vectorTooth"/>
            <text x="289.073px" y="79.969px" className="vectorText">11</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 10).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(10)}}>
            <path d="M257.457,22.586l16.013,0.268c8.993,1.8 26.975,17.702 19.673,26.386c-2.778,3.302 -8.701,4.046 -13.082,8.263c-20.096,19.339 -28.644,4.274 -28.723,1.632c-0.772,-16.757 -0.449,-31.732 6.119,-36.549Z" 
            className="vectorTooth"/>
            <text x="256.84px" y="51px" className="vectorText">10</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 9).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(9)}}>
            <path d="M253.47,18.065c-1.224,-4.812 -4.309,-7.453 -8.106,-9.261c-5.5,-3.764 -26.832,-4.262 -34.581,-0.797c-6.896,3.085 -3.182,19.008 -2.34,22.766c1.84,11.421 7.104,20.034 15.793,25.837c17.334,-7.775 28.295,-19.811 29.234,-38.545Z" 
            className="vectorTooth"/>
            <text x="221.896px" y="39.032px" className="vectorText">9</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 8).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(8)}}>
            <path d="M159.53,18.065c1.224,-4.812 4.309,-7.453 8.106,-9.261c5.5,-3.764 26.833,-4.262 34.582,-0.797c6.895,3.085 3.182,19.008 2.34,22.766c-1.84,11.421 -7.104,20.034 -15.793,25.837c-17.334,-7.775 -28.295,-19.811 -29.235,-38.545Z" 
            className="vectorTooth"/>
            <text x="178.748px" y="39.032px" className="vectorText">8</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 7).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(7)}}>
            <path d="M155.544,22.586l-16.013,0.268c-8.994,1.8 -26.975,17.702 -19.674,26.386c2.778,3.302 8.701,4.046 13.083,8.263c20.096,19.339 28.643,4.274 28.722,1.632c0.773,-16.757 0.449,-31.732 -6.118,-36.549Z" 
            className="vectorTooth"/>
            <text x="136.823px" y="52.791px" className="vectorText">7</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 6).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(6)}}>
            <path d="M116.923,48.562l-13.285,-0.056c-4.05,0.579 -7.407,5.062 -9.143,9.489c-1.075,8.659 -0.726,17.399 0.665,26.196c11.365,10.097 21.657,14.182 30.262,8.813c7.095,-3.373 9.255,-12.11 4.542,-28.317c-2.164,-7.112 -5.974,-12.913 -13.041,-16.125Z" 
            className="vectorTooth"/>
            <text x="107.049px" y="79.832px" className="vectorText">6</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 5).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(5)}}>
            <path d="M94.954,83.459l-9.898,0.021c-8.638,3.762 -13.711,7.899 -17.063,15.739c-1.448,3.382 -2.564,15.665 -1.119,24.2c13.833,8.513 19.662,8.809 26.547,9.246c9.569,-3.432 16.129,-8.58 19.057,-15.801c2.862,-6.951 3.508,-13.927 0.672,-20.948c-8.479,-4.136 -14.7,-8.287 -18.196,-12.457Z" 
            className="vectorTooth"/>
            <text x="84.362px" y="115.811px" className="vectorText">5</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 4).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(4)}}>
            <path d="M65.136,122.11c-9.791,4.675 -16.301,11.662 -20.566,20.231c-2.369,9.592 -0.804,16.678 2.55,22.623c4.363,0.685 8.441,1.545 12.283,2.551c6.398,1.674 12.142,3.748 17.465,6.082c7.656,-2.553 12.955,-8.793 16.942,-17.087c2.437,-5.927 3.333,-12.908 -0.368,-23.039c-10.572,-1.982 -20.008,-5.77 -28.306,-11.361Z" 
            className="vectorTooth"/>
            <text x="63.869px" y="155.848px" className="vectorText">4</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 3).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(3)}}>
            <path d="M45.935,165.651c-8.978,2.294 -13.116,7.265 -14.706,13.643c-1.641,8.965 -1.761,18.591 -0.664,28.748c-0.118,2.834 0.5,5.614 1.855,8.34c1.322,1.006 3.264,2.115 6.066,3.369c11.006,0.012 20.308,-0.064 26.03,-0.32c4.817,-3.132 7.995,-6.473 9.37,-10.046l2.701,-10.676c1.364,-2.875 6.888,-17.01 2.76,-23.268c-3.722,-5.644 -27.558,-9.997 -33.412,-9.79Z" 
            className="vectorTooth"/>
            <text x="49.178px" y="200.584px" className="vectorText">3</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 2).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(2)}}>
            <path d="M30.925,216.121c-5.498,3.468 -10.123,7.045 -10.712,10.102c-1.674,9.028 -2.179,18.183 -0.739,27.549c0.947,3.205 3.114,6.333 6.717,9.369l37.523,0.875c3.592,-2.46 5.985,-5.866 7.455,-9.999c0.567,-7.878 0.7,-15.956 0.273,-24.297c-0.256,-3.725 -3.968,-7.263 -6.851,-9.924l-26.159,0.13c-3.05,-1.133 -5.57,-2.396 -7.507,-3.805Z" 
            className="vectorTooth"/>
            <text x="39.202px" y="248.096px" className="vectorText">2</text>
          </g>
          <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 1).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(1)}}>
            <path d="M23.775,264.058l42.354,0.011c4.562,2.355 4.653,8.676 3.406,14.451l0.14,25.311c-1.892,4.584 -5.625,8.751 -11.38,12.461l-17.192,0.035c-9.144,-6.743 -16.907,-14.473 -23.172,-23.277c-2.027,-5.171 -2.441,-12.521 -1.751,-21.363c1.001,-3.016 3.775,-5.483 7.595,-7.629Z" 
            className="vectorTooth"/>
            <text x="37.668px" y="298.22px" className="vectorText">1</text>
          </g>
        </svg>
      </div>
    );
  }
}


const STYLES = {
  text: {
    "fontFamily": "'ArialMT', 'Arial', sans-serif",
    "fontSize": "22.337px",
    "pointerEvents": "none"
  }
}
export default TeethBottom;
