import React from 'react';

class Patient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order
    };
    this.onPatientChange = this.onPatientChange.bind(this);
  }

  onPatientChange(newvalue){
    var newName = newvalue.patient;
    var {order} = this.state;
    order.patient = newName;
    this.setState({order: order});
    this.props.onChange(order);      
  }

  render(){
    const FormBuilder = window.GlobalComp.FormBuilder;
    const {order={}} = this.state;
    var {patient} = order;
    return (
      <div id="EditOrderPatient" className="editOrder">
        <div className="sectionIconLeft">
          <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-user-injured" /></div>
          <div className="sectionIconLeftContent">
            <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-user-injured" /></div>Enter Patients Name
              {!patient && <span className="form-group" style={{"margin": "-10px 0 0 0"}}><small className="form-text red-text text-muted">Required</small></span>}
            </div>
            <div className="inputPatient">
              <FormBuilder
                initialValues={{
                  "patient": patient
                }}
                listOfFields={[
                  {
                    type: "text",
                    name: "patient",
                    required: true,
                    header: ""
                  }
                ]}
                onChange={this.onPatientChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Patient;
