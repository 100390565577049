import React from 'react';
// import logo from '../logo.svg';
////import '../../css/backend.css';
/*
Pull all existing payments
Let them choose one by clicking
Let them add a new one button
add new payment dialog
buttons cancel, back, next
Validate next for when payment method is choosen
*/

import EditPayment from '../../editOrder/invoice';
class TabChoosePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.curOrderObject
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave(newOrder){
    this.setState({
      order: newOrder
    });
    this.props.onUpdateObject(newOrder);
  }

  render(){
    var {order={}} = this.state;
    return (
      <React.Fragment>
        <div id="PageContent">
          <EditPayment
            order={order}
            onChange={this.onSave}
          />
        </div>
        <div id="NavButtons">
          <div className="leftButtons">
            <button className="button cancleButton" onClick={this.props.onCancel}>Cancel</button>
          </div>
          <div className="rightButtons">
            <button className={`button prevButton ${!this.props.currentPage ? 'disabled' : ''}`} onClick={this.props.onPrevPage}>Previous</button>
            <button className={`button nextButton ${this.props.disabled ? 'disabled' : ''}`} onClick={this.props.onNextPage}>Next</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default TabChoosePayment;