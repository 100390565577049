import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class ShippingLocations {
  constructor(props) {
  	this.data = new Array(0);
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }

	async SaveLocal(initalObj){
		this.data = [];
		await Object.keys(initalObj).forEach(key=>{
  		this.data.push(initalObj[key]);
  	});
	}

	GetMultiple(force){
		return new Promise((resolve, reject)=>{
			if(!force && window.Session.ShippingLocations && window.Session.ShippingLocations.lastModified && window.GlobalUtil.compairTimes(window.Session.ShippingLocations.lastModified) < 2){ //IF RECENTLY LOADED THEN USE CACHE
				return resolve(window.Session.ShippingLocations.arrayWithAll);
			}

			var isValidUser = this.ValidateAllowed();
			var businessID = window.GlobalUtil.getCurrentBusinessID();
			if(!isValidUser || !businessID){
				console.log("InvoicesClass.Get() FAILED: isValidUser, businessID");
				console.log(isValidUser, businessID,"\n\n");
				return reject(false);
			}
			var Pointer = this.Firestore.collection("shippingLocation");
			Pointer.where("business", "==", businessID).get()
	    .then((querySnapshot) => {
				var arrayWithAll = [];
				querySnapshot.forEach(function(doc) {
			  	arrayWithAll.push(doc.data());
			  });
			  return arrayWithAll;
	    })
			.then((arrayWithAll) => {
				window.Session.ShippingLocations = {
	    		arrayWithAll: arrayWithAll,
	    		lastModified: window.GlobalUtil.dateSeconds()
	    	};
				resolve(arrayWithAll);
	    })
			.catch(failed=>{
	      console.log("failed", failed);
	      reject(failed);
	    }); 
		});
	}


	Delete(id){
		return new Promise((resolve, reject)=>{
	    window.GlobalComp.Confirm({
	      title: 'Deleting',
	      message: 'Are you SURE you wish to remove this location?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {
	            this.Firestore.collection("shippingLocation").doc(id).delete().then((obj)=>{resolve(true)}).catch((error)=>{reject(error)});
	          }
	        },
	        {
	          label: 'No',
	          onClick: () => {
	            //Do nothing, just close;
							resolve(false)
	          }
	        }
	      ]
	    })
		})
	}



	async ChangeDefault(newDefault){
		if(!window.Session.Business.id) return;

		//REMOVE UPDATE OLD AN NO LONGER DEFAULT
		var RemoveOldDefault = await new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("shippingLocation");
			Pointer.where("business", "==", window.Session.Business.id).where("default", "==", true).get()
	    .then((querySnapshot) => {
	    	querySnapshot.forEach((doc) => {
	    		doc.ref.update({default: false});
			  });
				resolve(true);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});

		
		//SET NEW AS DEFAULT
		var SetNewDefault = await new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("shippingLocation").doc(newDefault.id);
			Pointer.update({default: true})
	    .then(obj => {
				resolve(true);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			}); 
		});

		var multiple = await this.GetMultiple();

		return multiple;
	}

}
