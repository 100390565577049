import React from 'react';
// import logo from '../logo.svg';
import '../../backend/css/backend.css';
import $ from "jquery";

class TeethBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: window.GlobalUtil.getRandomId(),
      showLogin: false,
      activeTeeth: (this.props.activeTeeth ? this.props.activeTeeth : [])
    };
    this.onClickTooth = this.onClickTooth.bind(this);
  }
  onClickTooth(tooth){
    var {activeTeeth=[]} = this.props;
    var newActiveTeeth = activeTeeth.length > 0 ? activeTeeth.filter(obj=>obj.value != tooth) : [];
    if(newActiveTeeth.length === activeTeeth.length) newActiveTeeth.push({
      add: this.props.add,
      value: tooth
    });
    this.setState({newActiveTeeth});
    this.props.onSelectTooth(newActiveTeeth);
  }

  render(){
    var {showLogin, id} = this.state;
    var {activeTeeth=[]} = this.props;
    if(!Array.isArray(activeTeeth)) activeTeeth = [];
    return (
      <div id="TeethBottom">
        <svg width="100%" height="100%" viewBox="0 0 413 326" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{
            "fillRule": "evenodd",
            "clipRule": "evenodd",
            "strokeLinecap": "round",
            "strokeLinejoin": "round",
            "strokeMiterlimit": "1.5"
          }}>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 32).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(32)}}>
              <path d="M19.54,11.795c7.369,-4.727 17.06,-4.75 28.123,-1.997c5.589,2.086 8.97,5.937 11.024,10.849c2.225,9.097 2.371,18.899 -0.736,29.569c-1.324,6.582 -4.593,11.739 -8.401,15.565c-8,-1.127 -18.958,-1.671 -29.705,1.31c-4.544,-3 -7.274,-6.893 -8.351,-11.599c-2.181,-15.724 2.66,-42.204 8.046,-43.697Z" 
                className="vectorTooth"/>
              <text x="22.585px" y="45px" 
                className="vectorText">32</text>
               
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 31).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(31)}}>
              <path d="M11.305,77.881c-1.947,12.029 -2.055,17.267 3.357,21.949c0.639,3.27 1.222,8.738 1.645,15.679c0.39,3.081 3.208,7.358 7.302,10.532c7.119,-6.51 16.852,-9.47 29.712,-7.526c5.261,-12.659 7.578,-25.65 5.597,-39.127c-0.988,-6.37 -3.587,-11.186 -9.249,-13.053c-9.828,-2.165 -19.335,-1.374 -30.165,1.067c-3.973,3.135 -7.557,6.163 -8.199,10.479Z"
                className="vectorTooth"/>
              <text x="22.585px" y="101px" className="vectorText">31</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 30).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(30)}}>
              <path d="M42.17,181.23c9.16,-7.539 18.43,-10.994 27.799,-10.856c1.732,-11.372 2.343,-21.877 1.089,-30.941c-3.278,-9.181 -8.567,-16.666 -17.198,-21.336c-11.32,-1.058 -21.655,1.084 -30.553,7.904c-3.895,3.409 -4.485,10.62 -3.127,20.074c3.704,3.919 6.138,11.89 7.593,22.988c1.875,4.051 6.829,8.107 14.397,12.167Z" 
                className="vectorTooth"/>
              <text x="33.301px" y="156px" className="vectorText">30</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 29).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(29)}}>
              <path d="M73.578,228.331c4.865,-6.3 10.882,-10.956 18.396,-13.486c3.391,-17.079 -1.544,-32.344 -22.074,-44.207c-10.291,-0.145 -19.7,3.435 -28.14,11.107c-4.132,16.232 4.666,31.262 19.104,45.769c1.646,1.825 5.465,1.978 12.714,0.817Z" 
                className="vectorTooth"/>
              <text x="52.301px" y="207px" className="vectorText">29</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 28).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(28)}}>
              <path d="M106.82,268.598c3.624,-5.416 7.257,-8.055 13.777,-10.764c1.241,-8.482 1.845,-16.509 -1.377,-21.791c-6.513,-12.466 -16.139,-18.384 -27.426,-20.805c-6.036,1.657 -12.314,6.243 -18.293,13.078c-0.751,9.389 0.507,16.583 3.315,22.082c4.528,10.345 13.898,18.227 30.004,18.2Z" 
                className="vectorTooth"/>
              <text x="84.301px" y="250px" className="vectorText">28</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 27).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(27)}}>
              <path d="M106.131,292.109c0.188,1.396 7.623,1.969 25.646,1.336c2.752,-5.608 5.918,-9.637 9.658,-11.47c0.079,-9.326 -0.223,-17.331 -2.088,-19.921c-0.454,-3.627 -7.202,-4.573 -19.04,-3.206c-5.459,0.928 -10.133,4.142 -13.861,10.112c-3.801,8.577 -3.395,16.174 -0.315,23.149Z" 
                className="vectorTooth"/>
              <text x="111.301px" y="285px" className="vectorText">27</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 26).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(26)}}>
              <path d="M160.651,274.985c-14.654,2.41 -24.852,8.416 -29.118,19.21c6.091,13.914 17.467,19.277 33.326,17.389c2.064,-12.136 0.434,-30.638 -0.524,-32.448c-1.233,-2.331 -1.577,-2.92 -3.684,-4.151Z" 
                className="vectorTooth"/>
              <text x="138.301px" y="301.327px" className="vectorText">26</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 25).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(25)}}>
              <path d="M166.077,311.336c6.428,-13.244 13.461,-23.452 21.886,-26.697c8.132,1.559 13.872,10.781 17.972,25.247c-0.287,3.436 -2.906,6.153 -7.46,8.274c-11.02,0.935 -18.014,0.876 -22.062,0.09c-4.601,-0.698 -7.829,-3.303 -10.336,-6.914Z" 
                className="vectorTooth"/>
              <text x="174.301px" y="312.327px" className="vectorText">25</text>
            </g>

            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 17).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(17)}}>
              <path d="M394.46,11.795c-7.369,-4.727 -17.06,-4.75 -28.124,-1.997c-5.588,2.086 -8.97,5.937 -11.023,10.849c-2.225,9.097 -2.371,18.899 0.736,29.569c1.323,6.582 4.593,11.739 8.4,15.565c8,-1.127 18.959,-1.671 29.706,1.31c4.544,-3 7.273,-6.893 8.351,-11.599c2.181,-15.724 -2.66,-42.204 -8.046,-43.697Z" 
                className="vectorTooth"/>
              <text x="366px" y="45px" className="vectorText">17</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 18).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(18)}}>
              <path d="M402.695,77.881c1.947,12.029 2.054,17.267 -3.357,21.949c-0.639,3.27 -1.222,8.738 -1.645,15.679c-0.39,3.081 -3.208,7.358 -7.302,10.532c-7.119,-6.51 -16.852,-9.47 -29.712,-7.526c-5.261,-12.659 -7.578,-25.65 -5.597,-39.127c0.988,-6.37 3.587,-11.186 9.249,-13.053c9.828,-2.165 19.335,-1.374 30.165,1.067c3.973,3.135 7.557,6.163 8.199,10.479Z" 
                className="vectorTooth"/>
              <text x="366px" y="101px" className="vectorText">18</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 19).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(19)}}>
              <path d="M371.83,181.23c-9.16,-7.539 -18.43,-10.994 -27.799,-10.856c-1.732,-11.372 -2.343,-21.877 -1.09,-30.941c3.279,-9.181 8.568,-16.666 17.199,-21.336c11.32,-1.058 21.655,1.084 30.553,7.904c3.895,3.409 4.485,10.62 3.127,20.074c-3.704,3.919 -6.138,11.89 -7.593,22.988c-1.875,4.051 -6.83,8.107 -14.397,12.167Z" 
                className="vectorTooth"/>
              <text x="352.301px" y="156px" className="vectorText">19</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 20).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(20)}}>
              <path d="M340.422,228.331c-4.865,-6.3 -10.883,-10.956 -18.396,-13.486c-3.391,-17.079 1.544,-32.344 22.073,-44.207c10.292,-0.145 19.701,3.435 28.141,11.107c4.132,16.232 -4.666,31.262 -19.104,45.769c-1.646,1.825 -5.465,1.978 -12.714,0.817Z" 
                className="vectorTooth"/>
              <text x="334.301px" y="207px" className="vectorText">20</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 21).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(21)}}>
              <path d="M307.179,268.598c-3.623,-5.416 -7.256,-8.055 -13.776,-10.764c-1.241,-8.482 -1.845,-16.509 1.377,-21.791c6.513,-12.466 16.139,-18.384 27.426,-20.805c6.036,1.657 12.314,6.243 18.293,13.078c0.751,9.389 -0.507,16.583 -3.315,22.082c-4.528,10.345 -13.899,18.227 -30.005,18.2Z" 
                className="vectorTooth"/>
              <text x="304.301px" y="250px" className="vectorText">21</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 22).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(22)}}>
              <path d="M307.869,292.109c-0.188,1.396 -7.623,1.969 -25.646,1.336c-2.752,-5.608 -5.918,-9.637 -9.658,-11.47c-0.079,-9.326 0.223,-17.331 2.088,-19.921c0.454,-3.627 7.202,-4.573 19.04,-3.206c5.459,0.928 10.133,4.142 13.861,10.112c3.801,8.577 3.395,16.174 0.315,23.149Z" 
                className="vectorTooth"/>
              <text x="278.301px" y="285px" className="vectorText">22</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 23).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(23)}}>
              <path d="M253.349,274.985c14.654,2.41 24.851,8.416 29.118,19.21c-6.091,13.914 -17.467,19.277 -33.326,17.389c-2.064,-12.136 -0.434,-30.638 0.524,-32.448c1.233,-2.331 1.577,-2.92 3.684,-4.151Z" 
                className="vectorTooth"/>
              <text x="250.301px" y="301.327px" className="vectorText">23</text>
            </g>
            <g className={`vectorTeeth ${activeTeeth.filter(obj=>obj.value == 24).length > 0 ? 'active' : ''}`} onClick={()=>{this.onClickTooth(24)}}>
              <path d="M247.922,311.336c-6.427,-13.244 -13.461,-23.452 -21.885,-26.697c-8.132,1.559 -13.872,10.781 -17.972,25.247c0.287,3.436 2.906,6.153 7.46,8.274c11.02,0.935 18.013,0.876 22.062,0.09c4.601,-0.698 7.829,-3.303 10.335,-6.914Z" 
                className="vectorTooth"/>
              <text x="214.301px" y="312.327px" className="vectorText">24</text>
            </g>
        </svg>
      </div>
    );
  }
}


const STYLES = {
  text: {
    "fontFamily": "'ArialMT', 'Arial', sans-serif",
    "fontSize": "22.337px",
    "pointerEvents": "none"
  }
}
export default TeethBottom;
