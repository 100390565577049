import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';

import BackProducts from './tabsPricing/tab_products';
import BackDiscount from './tabsPricing/tab_discount';

class AdminTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  render(){
    return (
      <React.Fragment>
        <Tabs
          tabs={[
            {"name": "Product Prices", "url":"/panel/admin/pricing/"},
            {"name": "Discount Tiers", "url":"/panel/admin/pricing/discounts"}, 
          ]}>
          <Route exact path={["/panel/admin/pricing"]} component={BackProducts} />
          <Route exact path={["/panel/admin/pricing/discounts"]} component={BackDiscount} />
        </Tabs>
      </React.Fragment>
    );
  }
}
export default AdminTools;













