import React from 'react';
import TabInvoices from './tab_invoices';
class TabInvoicesUnpaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){}
  componentWillUnmount(){}

  render(){
    return (
      <TabInvoices
        {...this.props}
        status="unpaid"
      />
    );
  }
}


export default TabInvoicesUnpaid;