import React from 'react';
import ListTemplate from '../../components/tabsTables/list_template';
import ReactTooltip from 'react-tooltip';
import AddNewUsersDialog from '../../components/dialogs/add_new_user';
import ViewContactDialog from '../../components/dialogs/view_contact';
import TabsTable from '../../components/tabsTables/index';
import {InputFormsClass} from 'global-api-helper';
const LIST_TO_CANCEL = ["viewing"];
class Index extends ListTemplate {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showNewUserModal: false,
      pushOrRemove: 'push',
      rows: [],
      forceRefresh: false,
      limit: 20,
      defaultFilters: this.props.filters.slice(),
      filters: this.props.filters.slice(),
      filtersListOfFields: [
        {
          title: "Date Start",
          type: "datePicker",
          name: "dateStart",
          required: false,
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["date", ">=", newValue],
              "id": "dateStart"
            })
          }
        },
        {
          title: "Date End",
          type: "datePicker",
          name: "dateEnd",
          required: false,
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["date", "<=", newValue],
              "id": "dateEnd"
            })
          }
        }
      ],
    };
    this.apiCall = this.apiCall.bind(this);
    this.createRows = this.createRows.bind(this);
    
    this.StatusButtonComp = this.StatusButtonComp.bind(this);
    this.DeleteButtonComp = this.DeleteButtonComp.bind(this);
    this.PreviewButtonComp = this.PreviewButtonComp.bind(this);
    this.onClickContact = this.onClickContact.bind(this);
  }

  componentDidMount(){
    this.apiCall();
  }


  apiCall(){
    this.ListClass = new InputFormsClass(); ///Users/danielezaldivar/Sites/ready-crown/src/global-api-classes/table-orders.js
    var {filters, orderBy, limit} = this.state;
    this.setState({loading: true});
    this.onApiLoaded(()=>this.ListClass.GetFiltered({ //this is in /Users/danielezaldivar/Sites/ready-crown/src/backend/components/tabsTables/list_template.js
      filters: filters,
      orderBy: orderBy,
      limit: limit
    }));
  }


  createRows(arrayOfItems){
    var {business={}} = this.state
    var newRows = arrayOfItems.map((object, index)=>{
      var newDate = new Date(Number(object.date)).toLocaleDateString();
      
      var TurnToDateString = (currentValue) => {    
        currentValue = window.GlobalUtil.convertToDate(currentValue, 'M d, Y H:m A')
        return currentValue;
      }

      var items = [object.id, object.firstName, object.lastName, object.email, TurnToDateString(object.date), TurnToDateString(object.lastChanged), object.type, this.StatusButtonComp(object), this.PreviewButtonComp(object), this.DeleteButtonComp(object)];
      var Rows = {
        items: items, 
        user: object,
        disableEdit: true, 
        object: object,
        customStyles: [
          {
            fontSize: "12px"
          },
          {
            fontSize: "14px"
          },
          {
            fontSize: "14px"
          },
          {
            fontSize: "14px"
          },
          {
            fontSize: "14px"
          },
          {
            fontSize: "14px"
          },
          {
            //textAlign: 'center'
          }
        ]
      };
      return(Rows);
    })
    this.setState({loading: false, rows: newRows});
  }


  onClickContact(viewing){
    this.setState({viewing})
  }


  StatusButtonComp(currentObject){
    var onChangeStatus = (e)=>{
      var value = e.target.value;
      var {currentObject={}} = this.state;
      currentObject.status = value;    
      if(value === 'complete'){
        currentObject.doneDate = window.GlobalUtil.getTimeNow();
      } else {
        currentObject.doneDate = 0;
      }
            
      this.ListClass.Update(currentObject)
      .then((success)=>{
        this.setState({
          currentObject: currentObject
        });
        this.props.reloadAPI();
      })
      .catch((error)=>{
        this.setState({
          currentObject: currentObject
        });
      });
    }
    return(
      <StatusDropdown 
        currentObject={currentObject}
        onChangeStatus={onChangeStatus} 
      />
    )
  }

  DeleteButtonComp(object){
    var onDelete = ()=>{
      this.ListClass = new InputFormsClass();
      this.ListClass.TrueDelete(object.id)
      .then((success)=>{
        this.apiCall();
      })
      .catch((error)=>{
      });
    }
    return(
      <DeleteButton 
        onDelete={onDelete} 
      />
    )
  }

  PreviewButtonComp(object){
    return(
      <PreviewButton
        onPreview={()=>{
          this.setState({viewing: object})
        }}
      />
    )
  }


  render(){
    var {
      viewing, 
      business, 
      forceRefresh, 
      rows, 
      loading, 
      orderBy,

      filters, 
      defaultFilters,
      filtersListOfFields
    } = this.state;
    var {users={}} = business;
    if(forceRefresh) return(null);
    return (
      <React.Fragment>
        <TabsTable 
          ref={e=>this.PageTypeRef=e}
          loading={loading}
          actionButton={
            <button className="button" data-tip="Add a New User" onClick={this.onClickNewUser}><i className="fas fa-user"/> New User</button>
          }
          title={this.props.title}
          headers={this.createHeaders(HEADER, HEADER_ON_ADMIN)}
          rows={rows}

          CurrentClass={this.ListClass}
          onPaginate={(asyncLoadedFunction)=>{
            this.setState({loading: true});
            this.onApiLoaded(asyncLoadedFunction);
          }}

          //ORDER BY OPTIONS
          orderBy={orderBy}
          onChangeOrderBy={(orderByNew)=>{
            this.setState({orderBy: orderByNew}, this.apiCall);
          }}


          //TO SET UP FILTERS FILL THIS OUT
          filters={filters}
          defaultFilters={defaultFilters}
          filtersListOfFields={filtersListOfFields}
          onChangeFilters={(newFilters)=>{
            this.setState({
              filters: newFilters,
              orderBy: ["date", "desc"]
            }, this.apiCall);
          }}
        />
        {
          viewing &&
          <ViewContactDialog
            headerText={"View Contact"}
            currentObject={viewing}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
      </React.Fragment>
    );
  }
}


const PreviewButton = (props) => {
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className="tableTrashIcon" data-tip="View Full"  onClick={props.onPreview}>
      <i className="fas fa-eye" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}


const StatusDropdown = (props) => {
  var {currentObject={}} = props;
  var {status='active'} = currentObject;
  return(
    <select type="select" name="status" value={status} onChange={props.onChangeStatus}>
      <option value={'active'}>Active</option>
      <option value={'complete'}>Complete</option>
    </select>
  )
}



const DeleteButton = (props) => {
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className="tableTrashIcon" data-tip="Delete Contact" onClick={props.onDelete}>
      <i className="fas fa-trash-alt"></i>
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}

const HEADER = [
  {
    key: 'businessName', 
    name: 'Business Name', 
    width: "200",
    style: {}
  },
  {
    key: 'firstName', 
    name: 'First Name', 
    width: "200",
    style: {}
  },
  {
    key: 'lastName', 
    name: 'Last Name', 
    width: "200",
    style: {}
  },
  {
    key: 'email', 
    name: 'Email', 
    width: "200",
    style: {}
  },
  {
    key: 'date', 
    name: 'Date', 
    width: "200",
    style: {}
  },
  {
    key: 'lastChanged', 
    name: 'Last Changed', 
    width: "200",
    style: {}
  },
  {
    key: 'type', 
    name: 'Type', 
    width: "200",
    style: {}
  },

  {
    key: 'status', 
    name: 'Status', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'preview', 
    name: 'Preview', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  }, 
  {
    key: 'delete', 
    name: 'Delete', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  }
];

const HEADER_ON_ADMIN = [];

export default Index;