import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';

import BackContactsCurrent from './tabsContacts/current';
import BackContactsArchives from './tabsContacts/archived';

class AdminTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  render(){
    return (
      <React.Fragment>
        <Tabs
          tabs={[
            {"name": "Current Contacts", "url":"/panel/admin/contacts/"},
            {"name": "Archives Contacts", "url":"/panel/admin/contacts/archives"} 
          ]}>
          <Route exact path={["/panel/admin/contacts", "/panel/admin/contacts/current"]} component={BackContactsCurrent} />
          <Route exact path={["/panel/admin/contacts/archives"]} component={BackContactsArchives} />
        </Tabs>
      </React.Fragment>
    );
  }
}
export default AdminTools;






