import React from 'react';
class ProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOnUpdate = this.handleOnUpdate.bind(this);
    this.AccordionSingle = window.GlobalComp.AccordionSingle;
  }
  componentDidMount(){}
  componentWillUnmount(){}

  handleOnUpdate(newDiscount){
    this.props.updateDiscount(newDiscount);
  }

  render(){
    //var {} = this.state;
    var {discounts=[]} = this.props;
    const AccordionSingle = this.AccordionSingle;
    return (
      <div id="productsListAccordion">
        {
          discounts.length > 0
          ? discounts.map((discount, index)=>{
              return(
                <AccordionSingle 
                  key={index}
                  header={(
                    <h5 style={{textTransform: "uppercase", margin: "auto"}}>Name: <strong>{discount.name}</strong></h5>
                  )}
                >
                  <DiscountTier
                    discount={discount}
                    onUpdate={this.handleOnUpdate}
                  />
                </AccordionSingle>
              )
            })
          : <div>No Discounts found</div>
        }
      </div>
    );
  }

}
//emailTesterTest123@gmail.com

class DiscountTier extends React.Component {
  constructor(props) {
    super(props);
    this.FormBuilder = window.GlobalComp.FormBuilder;
  }
  render(){
    var {discount={}} = this.props;
    const FormBuilder = this.FormBuilder;
    return (
      <div className="discountTier">
        <FormBuilder
          initialValues={discount}
          listOfFields={[
            {
              title: <span className="">Name <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
              type: "text",
              name: "name",
              placeholder: "Tier Name",
              required: true
            },
            {
              title: <span className="">Discount Amount <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
              type: "number",
              name: "percent_discount",
              placeholder: "Number Percentage",
              min: 0,
              max: 100,
              required: true
            }
          ]}
          onChange={newDiscountObj => {
            if(window.Glo){
              console.log("New Discount Obj");
              console.log(newDiscountObj,"\n\n");
            }
            this.discount = newDiscountObj;
          }}
          onValidate={(isValid)=>{
            
          }}
        />
        <button className="button" onClick={()=>{this.props.onUpdate(this.discount)}}>Save</button>
      </div>
    );
  }
}





export default ProductsList;