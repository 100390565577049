import React from 'react';
// import logo from '../logo.svg';
import $ from 'jquery';
import Google from '../../icons/google.svg';
import {NavLink} from 'react-router-dom';
import {BusinessClass} from 'global-api-helper';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();


class LoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onSignUpTab: (this.props.onSignUpTab !== undefined ? this.props.onSignUpTab : true)
    };
    this.clickListener = this.clickListener.bind(this);
  }

  componentDidMount(){
    document.addEventListener("click", this.clickListener);
  }

  componentWillUnmount(){
    document.removeEventListener("click", this.clickListener);
  }



  clickListener(event){
    if(event.target.id === 'LoadingScreen' || event.target.classList.contains("yellowButton") || event.target.classList.contains("skipButton")) return;
    var closest = $(event.target).parent().closest(this.closesRef);
    console.log(`closest`,"\n\n",closest,"\n\n");
          
    if(closest.length){ //CLICKED INSIDE
      console.log('inside');
    } else { //CLICKED OUTSIDE
      this.props.onCloseDialog();
    }
  }

  render(){
    var {onSignUpTab} = this.state;
    return (
      <div id="SignUpLogin" ref={e=>this.closesRef=e}>
        <div className="loginHeaderColor"></div>
        <div className="wrapper">
          <div className="tabButtons">
            <div className={`loginTab ${(onSignUpTab ? 'inactive' : '')}`} onClick={()=>{this.setState({onSignUpTab: false})}}>Log In</div>
          </div>
          <div className="content">
            <Login 
              onLogin={this.props.onLogin}
              onCloseDialog={this.props.onCloseDialog}
            />
          </div>
        </div>
        <div className="loginFooterColor"></div>
      </div>
    );
  }
}



class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      loading: false
    };
    this.currentObject = {};
    this.onLogin = this.onLogin.bind(this);
    this.FormBuilder = window.GlobalComp.FormBuilder;
  }

  onLogin(){
    this.setState({loading: true});
    GlobalAPINew.signInWithEmailAndPassword({
      email: this.currentObject.email, 
      password: this.currentObject.password
    })
    .then(success=>{
      this.props.onCloseDialog();
      if(this.props.onLogin) this.props.onLogin();
      window.Router.history.push('/panel');
    })
    .catch(fail=>{
      //DO THIS WHEN FAILED TO CREATE NEW USER
      this.props.onCloseDialog();
    });
  }

  render(){
    const FormBuilder = this.FormBuilder;
    var {isValid, loading} = this.state;
    if(loading) {
      var LoadingComponent = window.GlobalComp.Loading;            
      return(
        <LoadingComponent 
          Header={"Logging In"}
          Text={"Please Stand by"}
        />
      );
    };

    return (
      <div id="LoginDialog">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="loginNav">
                <FormBuilder
                  initialValues={this.currentObject}
                  listOfFields={[
                    {
                      title: <div className="formTitle"><span className="">Email</span><span className="red-text requiredStar"><i className="fas fa-star-of-life" /></span></div>,
                      type: "email",
                      name: "email",
                      placeholder: "",
                      required: true
                    },
                    {
                      title: <div className="formTitle"><span className="">Password</span><span className="red-text requiredStar"><i className="fas fa-star-of-life" /></span></div>,
                      type: "password",
                      name: "password",
                      placeholder: "",
                      required: true
                    },
                  ]}
                  onChange={newObj => this.currentObject = newObj}
                  onValidate={(isValid)=>{this.setState({isValid: isValid})}}
                />

                <div className="clearfix">
                  <button 
                    onClick={this.onLogin} 
                    className={`btn yellowButton ${!isValid ? 'disabled' : ''}`}
                    disabled={!isValid}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginDialog;
