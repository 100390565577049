import React from 'react';
import ListTemplate from '../../components/tabsTables/list_template';

import ReactTooltip from 'react-tooltip';
import AddNewUsersDialog from '../../components/dialogs/add_new_user';
import TabsTable from '../../components/tabsTables/index';
import {UsersClass} from 'global-api-helper';
const LIST_TO_CANCEL = ["newModal",];
class TabUsers extends ListTemplate {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showNewUserModal: false,
      pushOrRemove: 'push',
      forceRefresh: false,
      orderBy: ["name", "asc"], //desc
      defaultFilters: [
        {
          "key": "where",
          "value": ["status", "==", 'active'],
          "id": "status1"
        }
      ],
      filters: [
        {
          "key": "where",
          "value": ["status", "==", 'active'],
          "id": "status1"
        }
      ],
      filtersListOfFields: [
        {
          title: "Filter by Position ",
          type: "select",
          name: "type",
          required: false,
          options: [
            {
              title: "Choose a Position",
              value: "",
              disabled: true,
            },
            {
              title: "Doctor",
              value: "doctor"
            },
            {
              title: "Office Manager",
              value: "officeManager"
            },
            {
              title: "Branch Manager",
              value: "branchManager"
            },
            {
              title: "Secretary",
              value: "secretary"
            },
            {
              title: "Assistant",
              value: "assistant"
            },
            {
              title: "Intern",
              value: "intern"
            },
            {
              title: "Other",
              value: "other"
            }
          ],
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["type", "==", newValue],
              "id": "type"
            })
          }
        }
      ],
    };
    this.apiCall = this.apiCall.bind(this);
    this.createRows = this.createRows.bind(this);

    this.onClickNewUser = this.onClickNewUser.bind(this);
    this.onClickEditUser = this.onClickEditUser.bind(this);


    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", business => {
      window.Session.Business = business;
      this.setState({business: business, forceRefresh: true}, ()=>{
        this.apiCall();
        this.setState({forceRefresh: false});
      });
    });
  }

  componentDidMount(){
    this.apiCall();
  }

  componentWillUnmount(){
    if(this.businessChanged){
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
      this.businessChanged = false;
    }
  }


  apiCall(){
    this.ListClass = new UsersClass(); ///Users/danielezaldivar/Sites/ready-crown/src/global-api-classes/table-orders.js
    var {filters, orderBy, limit} = this.state;
    this.setState({loading: true});
    if(orderBy[0] === 'type' && ((filters[0] && filters[0].id === 'type') || (filters[1] && filters[1].id === 'type'))) orderBy = undefined;
    this.onApiLoaded(()=>this.ListClass.GetFiltered({
      filters: filters,
      orderBy: orderBy,
      limit: limit
    }))
  }


  createRows(arrayOfItems){
    var {business={}} = this.state
    var newRows = arrayOfItems.map((object, index)=>{
      var Edit = (<EditUser object={object} onEdit={this.onClickEditUser.bind(this, object)} admin={this.props.admin}/>);
      var newDate = new Date(Number(object.date)).toLocaleDateString();
      var items = [object.id, SplitCammelCase(object.type), object.name, Edit];
      var Rows = {
        items: items, 
        user: object,
        disableEdit: true, 
        object: object,
        customStyles: [
          {
            fontSize: "12px"
          },
          {
            //fontSize: "12px"
          },
          {
            //fontSize: "12px"
          },
          {
            textAlign: 'center'
          }
        ]
      };
      return(Rows);
    })
    this.setState({loading: false, rows: newRows});
  }


  onClickNewUser(){
    this.setState({newModal: {
      name: '',
      type: ''}
    });
  }

  onClickEditUser(userObj){
    this.setState({newModal: userObj});
  }


  render(){
    var {
      newModal, 
      business, 
      forceRefresh, 
      rows, 
      loading, 
      orderBy,

      filters, 
      defaultFilters,
      filtersListOfFields
    } = this.state;
    var {users={}} = business;
    if(forceRefresh) return(null);
    return (
      <React.Fragment>
        <TabsTable 
          ref={e=>this.PageTypeRef=e}
          loading={loading}
          actionButton={
            <button className="button" data-tip="Add a New User" onClick={this.onClickNewUser}><i className="fas fa-user"/> New User</button>
          }
          title={"List of Users"}
          headers={this.createHeaders(HEADER, [])}
          rows={rows}

          CurrentClass={this.ListClass}
          onPaginate={(asyncLoadedFunction)=>{
            this.setState({loading: true});
            this.onApiLoaded(asyncLoadedFunction);
          }}

          //ORDER BY OPTIONS
          orderBy={orderBy}
          onChangeOrderBy={(orderByNew)=>{
            this.setState({orderBy: orderByNew}, this.apiCall);
          }}


          //TO SET UP FILTERS FILL THIS OUT
          filters={filters}
          defaultFilters={defaultFilters}
          filtersListOfFields={filtersListOfFields}
          onChangeFilters={(newFilters)=>{
            this.setState({
              filters: newFilters,
              //orderBy: ["date", "desc"]
            }, this.apiCall);
          }}
        />
        {/*<ReactTooltip offset={{top: 10}}/>*/}
        {
          newModal &&
          <AddNewUsersDialog 
            headerText={"New Patient"}
            currentObject={newModal}
            onSave={(newPatient)=>{
              this.onCancel(LIST_TO_CANCEL)
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
      </React.Fragment>
    );
  }
}

const SplitCammelCase = (textToChange)=>{
  return(textToChange.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); }))
}


const EditUser = ({object, onEdit, admin}) => {
  var {id, user} = object;
  return(
    <div className={"tableTrashIcon"} onClick={onEdit} data-tip="Edit User" style={{margin: "auto"}} >
      <i className="fas fa-pencil-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}


const HEADER = [
  {
    key: 'id', 
    name: 'ID', 
    width: "200",
    style: {},
    disableOrderBy: true
  },
  {
    key: 'type', 
    name: 'Type', 
    width: "200",
    style: {}
  },
  {
    key: 'name', 
    name: 'Name', 
    width: undefined,
    style: {}
  },
  {
    key: 'edit', 
    name: 'Edit', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  }
];

export default TabUsers;