import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {InputFormsClass} from 'global-api-helper';
class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this._reCaptchaRef = React.createRef();
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.InputFormsClass = new InputFormsClass();
    this.currentObject = {
      type: "home_page",
      typeName: "Home Page"
    };
  }
  onRecaptchaChange(value){
    console.log("Captcha value:", value);
  };

  onSubmit(){
    const recaptchaValue = this._reCaptchaRef.current.getValue();
    if(!recaptchaValue){
      alert("Please click the ReCAPTCHA at bottom of form.");
      return;
    }  
    this.InputFormsClass.Submit(window.GlobalUtil.copyObject(this.currentObject, 0, 2))
    .then((success)=>{
      this.setState({formSubmitted: true});
    })
    .catch((error)=>{
      this.setState({formSubmitted: true});
    });
  };
  render(){
    const FormBuilder = this.FormBuilder;
    var {isValid, formSubmitted} = this.state; 
    var {whiteText, customHeader} = this.props;
    return (
      <div id="Form">
        {
          formSubmitted
          ? <div className="successFormDiv">
              <i className="fas fa-check-circle"></i>
              <h3>Congrats!</h3>
              <p className="">Your from has been submitted.</p>
            </div>
          : <React.Fragment>
              {
                customHeader
              }
              <FormBuilder
                initialValues={this.currentObject}
                listOfFields={[
                  {
                    title: <div className="formTitle"><span className={whiteText && 'white-text'}>First Name</span><span className="orange-text requiredStar"><i className="fas fa-star-of-life" /></span></div>,
                    type: "text",
                    name: "firstName",
                    placeholder: "First Name",
                    required: true
                  },
                  {
                    title: <div className="formTitle"><span className={whiteText && 'white-text'}>Last Name</span><span className="orange-text requiredStar"><i className="fas fa-star-of-life" /></span></div>,
                    type: "text",
                    name: "lastName",
                    placeholder: "Last Name",
                    required: true
                  },
                  // {
                  //   title: <div className="formTitle"><span className={whiteText && 'white-text'}>Business Name</span></div>,
                  //   type: "text",
                  //   name: "businessName",
                  //   placeholder: "Business Name"
                  // },
                  {
                    title: <div className="formTitle"><span className={whiteText && 'white-text'}>Email</span><span className="orange-text requiredStar"><i className="fas fa-star-of-life" /></span></div>,
                    type: "email",
                    name: "email",
                    placeholder: "Email",
                    required: true
                  },
                  {
                    title: <div className="formTitle"><span className={whiteText && 'white-text'}>Message</span><span className="orange-text requiredStar"><i className="fas fa-star-of-life" /></span></div>,
                    type: "textArea",
                    name: "message",
                    placeholder: "Message Here",
                    required: true
                  },
                ]}
                onChange={newObj => this.currentObject = newObj}
                onValidate={(isValid)=>{this.setState({isValid: isValid})}}
              />
              <div className="form-group">
                <button  
                  onClick={this.onSubmit}
                  className={`btn yellowButton ${!isValid ? 'disabled' : ''}`}
                  disabled={!isValid}>
                  Contact Us
                </button>
                <div style={{
                  "margin": "20px 0 0",
                  "width": "100%"
                }}>
                  <ReCAPTCHA
                    ref={this._reCaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={this.onRecaptchaChange}
                  />
              </div>
            </div>
          </React.Fragment>
        }
        
      </div>
    );
  }
}
export default FormComponent;
