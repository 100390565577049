import React from 'react';
export default class AccordionSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      expanded: false
    };
    this.onSizeToggle = this.onSizeToggle.bind(this);
  }
  componentDidMount(){}
  componentWillUnmount(){}

  onSizeToggle(){
    var {maxHeight, expanded} = this.state;
    if(this.maxHeightTimout) clearTimeout(this.maxHeightTimout);
    if(!maxHeight || maxHeight === "0px"){  //GROW
      maxHeight = `${this.mainContainerRef.childNodes[0].offsetHeight}px`;
      this.setState({
        maxHeight: maxHeight,
        expanded: true
      }, ()=>{
          if(maxHeight){
            this.maxHeightTimout = setTimeout(() => {
              this.setState({maxHeight: "none"});
              clearTimeout(this.maxHeightTimout);
            }, 500);
          }
      });
    } else { //SHIRINK
      maxHeight = `${this.mainContainerRef.childNodes[0].offsetHeight}px`; 
      this.setState({
        maxHeight: maxHeight
      }, 
      ()=>{
        this.maxHeightTimout = setTimeout(() => {
          this.setState({
            maxHeight: "0px",
            expanded: false
          });
          clearTimeout(this.maxHeightTimout);
        }, 90);
      });
    }
  }

  render(){
    var {maxHeight, expanded} = this.state;
    var {inner} = this.props;
    return(
      <div className={`AccordionSingle ${!expanded ? "compressed" : "expanded"} ${inner ? "inner" : ""}`}>
        <div className="headerWrapper" onClick={this.onSizeToggle}>
          <div className="header">
            {this.props.header}
          </div>
          <div className="icon">
            <i className={`fas fa-${!expanded ? "plus" : "minus"}`}></i>
          </div>
        </div>
        <div className="bodyWrapper" ref={e=>this.mainContainerRef=e} style={{
          "maxHeight": maxHeight,
        }}>
          {this.props.children}
        </div>
      </div>
    )
  }
}