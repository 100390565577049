import React from 'react';
class GlobalAPI {
  constructor() {
    this.ValidateAllowed = this.ValidateAllowed.bind(this);
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.PaginationFunction = this.PaginationFunction.bind(this);
    this.signInWithEmailAndPassword = this.signInWithEmailAndPassword.bind(this);


    this.UploadFile = this.UploadFile.bind(this);
    this.RemoveFile = this.RemoveFile.bind(this);
    this.GetRefFile = this.GetRefFile.bind(this);
    this.AsyncUploadImage = this.AsyncUploadImage.bind(this);
  }

	ValidateAllowed(){
		var user = window.firebase.auth().currentUser;
		return(user)
	}

	//DOES NOT NEED VALIDATION TO CHECK IF USER EXIST
	//RETURNS USER TO CALLBACK
	onAuthStateChanged(callback=()=>{}){  
		window.firebase.auth().onAuthStateChanged((user) => {
      var dt = new Date();
      if(user) {
      	callback(user);
      } else {
      	callback(false);
      }
    });
	}

	/*
		INPUT SHOULD BE:
			[{file: BLOB, name: STRING},{file: BLOB, name: STRING},{file: BLOB, name: STRING}]

		TAKES ARRAY OF IMAGES AND INITIAL PATH
		RETURNS:
			ASYNC ARRAY OF IMAGES WITH CORRECT FINAL PATH
			.THEN({file: CLEAN_REF, name: STRING, ref: REF_PATH})
	*/
	AsyncUploadImage(ArrayOfImages){
    var allPomises = [];
    ArrayOfImages.forEach((image, index)=>{
      allPomises.push(new Promise((resolve, reject)=>{
      	if(image.ref) return resolve(image); //IF WE ALREADY UPLOADED THIS IMAGE THEN JUST SKIP IT
        this.UploadFile({name: `${image.path}/${image.name}`, file: image.file},
          refPath => {
            this.GetRefFile(refPath, (cleanRef)=>{
              image.file = cleanRef;
              image.ref = refPath;
              resolve(image);
            }); //TURN THE REF INTO CLEAN PATHS
          },
          fail=>{
            //FAILED
            console.log("AsyncUploadImage Fail");
            console.log(fail,"\n\n");
            reject(fail)
          }
        );
      }));
    }); ///FINISH ArrayOfImages.forEach()
    return Promise.all(allPomises);
  }




	PaginationFunction(ASCOrder, DESOrder, snapShot){		    	
		//SET UP FUNTION TO GET NEXT VALUES AND HAVE A SUCCESS, AND FAIL CALL BACK JUST LIKE OLD FUNCTION
		var Pointer = ASCOrder;
		var NextFunction = (callbackSuccess, callbackFail, direction)=>{
			var lastVisible = snapShot.docs[snapShot.docs.length-1];
			var newPaginationPointer = ASCOrder.startAfter(lastVisible);

			if(direction === 'prev'){
				newPaginationPointer = DESOrder.startAfter(lastVisible);
			}

			newPaginationPointer.get()
			.then((querySnapshot) => {
				var fullList = [];
	      querySnapshot.forEach(function(doc) {
	        fullList.push(doc.data());
	      });
	      callbackSuccess(fullList, {}, this.PaginationFunction(ASCOrder, DESOrder, querySnapshot));
	    })
	    .catch(info=>{
	      callbackFail(false);
	      console.log(info);
	      return undefined;
	    }); 
		};

		return NextFunction;
	}


	signInWithEmailAndPassword(input){
		return new Promise((resolve, reject)=>{
			var {email, password} = input;
			window.firebase.auth().signInWithEmailAndPassword(email, password)
	    .then((success) => {
	    	resolve(true);
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("kwl45qpypj", error);
	      reject(error);
	    });
    });
	}





	UploadFile(input, callbackSuccess=()=>{}, callbackFail=()=>{}, progressChanged=()=>{}){
		var businessID = window.GlobalUtil.getCurrentBusinessID();
		var {name, file} = input;
		var path = `${businessID}/${name}`;
		var isValidUser = this.ValidateAllowed();
		if(!businessID || !file || !name || !isValidUser){
			console.log("Missing field in UploadFile");
			return;
		}
		var storage = window.firebase.storage();
		var Pointer = storage.ref(path);
		var task = Pointer.put(file);
		task.on('state_changed', 
			function progress(snapshot){
				var percentage = snapshot.bytesTransferred / snapshot.totalBytes;
				progressChanged(percentage);
			},
			function error(error){
				callbackFail();
		  	console.log(error);
			},
			()=>{ //complete
				callbackSuccess(path);
			}
		);
	}




	RemoveFile(path, callbackSuccess=()=>{}, callbackFail=()=>{}){
		var isValidUser = this.ValidateAllowed();
		if(!path || !isValidUser){
			console.log("Missing field path to delete, in RemoveFile");
			return;
		}
		var storage = window.firebase.storage();
		var Pointer = storage.ref(path);
		Pointer.delete()
			.then(function(snapshot) {
			  callbackSuccess(snapshot);
			})
			.catch(function(error) {
			  callbackFail();
		  	console.log(error);
			});
	}


	GetRefFile(path, callbackSuccess=()=>{}){
		var storage = window.firebase.storage();
		var pathReference = storage.ref(path);
		console.log("pathReference");
		console.log(pathReference,"\n\n");
		pathReference.getDownloadURL().then(function(url){
			callbackSuccess(url)
		})
	}

}


export default GlobalAPI;