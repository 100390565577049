import React from 'react';
//import {NavLink, Link} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

//<Link to={{"pathname": "/privacy", "hash":"Section1"}}>Privacy</Link>
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginSignup: false,
      defaultTab: 'signup',
      business: window.Session.Business,
      headerClass: ''
    };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.scrollChecker = this.scrollChecker.bind(this);
  }

  componentDidMount(){
    this.closeMobileEvent = window.GlobalUtil.subscribeEvent("closeMobileFront", (data)=>{
      this.setState({showMobileMenu: false});
    });
    this.ScrollListener = window.addEventListener("scroll", this.scrollChecker); 
  }

  componentWillUnmount(){
    if(this.closeMobileEvent) window.GlobalUtil.unsubscribeEvent("closeMobileFront", this.closeMobileEvent);
    if(this.ScrollListener) window.removeEventListener("scroll", this.scrollChecker); 
  }


  toggleMobileMenu(){
    this.setState({showMobileMenu: !this.state.showMobileMenu});
  }
  

  scrollChecker(e){
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if(st > 0){
      if(!this.state.headerClass) this.setState({headerClass: 'darken'});
    } else {
      if(this.state.headerClass) this.setState({headerClass: ''});
    }
  }

  render(){
    var {showLoginSignup, showMobileMenu, defaultTab, business={}, headerClass=''} = this.state;  
    var {onClickLink=()=>{}} = this.props;        
    return (
      <div id="Header">
        <div className={"section1 section " + headerClass}>
          <div className="container">
            <div className="row">
                <div className="col col-sm col-md-2 col-lg-3">
                  <div className="logoContainer">
                    <Link to="/home"><img src={require("../../img/logo1.png")} alt="" className="" /></Link>
                  </div>
                </div>
                <div className="col col-sm col-md-10 col-lg-9">
                  <div className="section2Navigation">
                    <div className="navDiv d-none d-sm-none d-md-block">
                      <ul className="nav justify-content-end">
                        <li className="nav-item">
                          <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row1' ? 'activeHash' : '')} to="#Row1" onClick={onClickLink}>Home</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row2' ? 'activeHash' : '')} to="#Row2" onClick={onClickLink}>Section 1</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row3' ? 'activeHash' : '')} to="#Row3" onClick={onClickLink}>Section 2</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row4' ? 'activeHash' : '')} to="#Row4" onClick={onClickLink}>Section 3</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row5' ? 'activeHash' : '')} to="#Row5" onClick={onClickLink}>Section 4</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row6' ? 'activeHash' : '')} to="#Row6" onClick={onClickLink}>Section 5</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mobileButton d-block d-sm-block d-md-none">
                      <i className="fas fa-bars" onClick={this.toggleMobileMenu} />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        {
          showMobileMenu
          ? <div id="MobileMenu">
              <div className="menuWrapper">
                <div className="title">Menu</div>
                <i className="fas fa-times closeButton" onClick={this.toggleMobileMenu}></i>
                <ul className="nav justify-content-end">
                  <li className="nav-item">
                    <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row1' ? 'activeHash' : '')} to="#Row1" onClick={onClickLink}>Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row2' ? 'activeHash' : '')} to="#Row2" onClick={onClickLink}>Section 1</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row3' ? 'activeHash' : '')} to="#Row3" onClick={onClickLink}>Section 2</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row4' ? 'activeHash' : '')} to="#Row4" onClick={onClickLink}>Section 3</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row5' ? 'activeHash' : '')} to="#Row5" onClick={onClickLink}>Section 4</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (window.location.hash && window.location.hash === '#Row6' ? 'activeHash' : '')} to="#Row6" onClick={onClickLink}>Section 5</Link>
                  </li>
                </ul>
              </div>  
            </div>
          : null
        }
      </div>
    );
  }
}

export default Header;

