/*
	THIS SHOULD PULL ANY PENDING STUFF NEEDED BY USERS

	this.PendingClass = new PendingClass();

	THIS CLASS SHOULD HANDLE ALL API CALLS FOR BASIC ORDERS
	GETTING ORDERS
	CREATING ODERS
	DELETE ORDERS, 
	UPDATING ORDERS, 
	CLONE ORDERS, 
	ETC.
*/
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class PendingClass {
  constructor(props) {
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
 	}

	Get(businessID){
		if(!businessID) businessID = window.GlobalUtil.getCurrentBusinessID();
		return new Promise((resolve, reject) => {
			if(!businessID){
				window.GlobalUtil.apiFail("333jxcetykfa5n", "Missing Business ID");
				return reject("Missing Business ID");
			}
			var Pointer = this.Firestore.collection("pending").doc(businessID);
			Pointer.get()
	    .then((doc) => {
			  resolve(doc.data());
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("5555fer3bji91bo", info);
				reject(info);
			});
		});
	}
	
	GetAll(){
		return new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("pending");
			Pointer.get()
	    .then((querySnapshot) => {
	    	var index = 0;
	    	this.fullArray = [];
	    	querySnapshot.forEach((doc) => {
	    		this.fullArray.push(doc.data());
	    		index++;
			  });
			  return index;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("2229jfnseeil06", info);
				reject(info);
			});
		});
	}


	toArray(){
		return (this.fullArray ? this.fullArray : []);
	}

	cleanBusinessForUpload(updatedObj){ //THIS SHOULD APPLY TO ("business") IN THE DATABASE AND REMOVE ANY UNNEEDED STUFF FROM window.Session.Business
		delete updatedObj.Firestore;
		delete updatedObj.ValidateAllowed;
		updatedObj = JSON.parse(JSON.stringify(updatedObj))
		return updatedObj;
	}
	
	
	update(updatedObj){
		return new Promise((resolve, reject) => {
			if(!updatedObj.id){
				window.GlobalUtil.apiFail("333ggw859cqm5g", "Missing Business ID");
				return reject("Missing Business ID");
			} 
			updatedObj = this.cleanBusinessForUpload(updatedObj);
			var Pointer = this.Firestore.collection("pending").doc(updatedObj.id);
			    	
			this.Get(updatedObj.id)
			.then(existingObj=>{
				var isSame = true;
				Object.keys(updatedObj).map((key, index)=>{
					var value = updatedObj[key];
					if(!existingObj || existingObj[key] === undefined || existingObj[key] != value) isSame = false;
				});
				if(!isSame){
					Pointer.update(updatedObj)
					.then(obj=>{
						resolve("all worked");
					})
					.catch(obj=>{
						if(obj.code === "not-found"){ //IF NOT FOUND THEN JUST ADD IT AS IS
							//console.log("NOT FOUND");
							Pointer.set(updatedObj);
							resolve("all worked");
							return;
						}
						window.GlobalUtil.apiFail("55536iere93cxe", obj);
						reject("failed to confirm");
					});
				} else {
					resolve("already confirmed");
				}
			})
			.catch(existingObj=>{
				resolve("got nothing");
			});
		});
	}

}
