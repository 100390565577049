import React from 'react';
// import logo from '../logo.svg';
import '../../css/new_order_dialog.css';
//import '../../css/backend.css';

import NewOrder from "./tab_new_order";


class NewOrderWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionStyles: {"transform": "translate(100%,0)"}
    };
  }

  componentDidMount(){
    window.GlobalUtil.delay(200).then(()=>{
      this.setState({transitionStyles: {"transform": "translate(0%,0%)"}})
    })
  }

  componentWillUnmount(){
  }


  render(){
    var {transitionStyles} = this.state;

    return (
      <div id="NewOrderWrapper" style={transitionStyles}>
        <NewOrder
          onClose={()=>{
            this.setState({transitionStyles: {"transform": "translate(100%,0%)"}})
            window.GlobalUtil.delay(1000).then(()=>{
              this.props.onClose();
            })
          }}
        />
      </div>
    );
  }
}



export default NewOrderWrapper;
