import React from 'react';
import Form from './form';
import {NavLink} from 'react-router-dom';
import seal from '../../img/instantssl_trust_seal_md_159x42.png';
import TriosReadyLogo from "../../img/3shapeTriosReadyLogo.png";
const SimulateClick = function (elem) {
  // Create our event (with options)
  var evt = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  });
  // If cancelled, don't dispatch our event
  var canceled = !elem.dispatchEvent(evt);
};


const Footer = () => {
  var today = new Date();
  if(window.TrustLogo) window.TrustLogo("https://www.readycrowns.com/instantssl_trust_seal_md_159x42.png", "SC5", "none");
  return (
    <div id="Footer">
      <div className="section1 section blueBackground">
        <div className="container">
            <div className="row">
              <div className="col-md">
                <h3>Contact Info</h3>
                <ul>
                  <li>                
                    <strong><i className="fas fa-map-marker-alt"></i> Address:</strong> 
                    <div>{window.CONST.SITE.address}</div>
                  </li>
                  <li>                
                    <strong><i className="fas fa-mobile-alt"></i> Phone:</strong> 
                    <a href={`tel:${window.CONST.SITE.phone[2]}`}><div>{window.CONST.SITE.phone[0]} ({window.CONST.SITE.phone[1]})</div></a>
                  </li>
                  <li>                
                    <strong><i className="fas fa-clock"></i> Hours:</strong> 
                    <div>{window.CONST.SITE.hours}</div>
                  </li>
                  <li>                
                    <strong><i className="far fa-envelope"></i> E-mail:</strong> 
                    <a href={`mailto:${window.CONST.SITE.email[0]}`}><div>{window.CONST.SITE.email[0]}</div></a>
                  </li>
                </ul>
                <img src={seal} alt="" style={{"cursor": "pointer"}} onClick={()=>{
                  var stupid = document.querySelectorAll('body > a');
                  SimulateClick(stupid[0]);
                }}/> 
                <div style={{width: "200px", background: "#FFF", "borderRadius": "10px", "marginTop": "30px"}}><img src={TriosReadyLogo} alt="" style={{width: "100%"}}/></div>
              </div>
              <div className="col-md">
                <div className="contactSection">
                  <Form whiteText={true}/>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="section2 section">
        <div className="container">
            <div className="row">
              <div className="col-sm">
                Ready Crowns © {today.getFullYear()}. All Rights Reserved
              </div>
              <div className="col-sm">
                <ul id="FooterNav2">
                  <li><NavLink to="/privacy">Privacy</NavLink></li>
                  <li><NavLink to="/terms">Terms of Use</NavLink></li>
                  <li><NavLink to="/terms_of_trade">Terms of Trade</NavLink></li>
                  <li><NavLink to="/faq">FAQ</NavLink></li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

