import React from 'react';
import ReactTooltip from 'react-tooltip';
import AddNewProductDialog from '../dialogs/add_new_product';


class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
    };
    this.onSave = this.onSave.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
  }

  onSave(order){
    this.setState({order: order});
    this.props.onChange(order);      
  }

  saveProduct(newProduct){
    var {editProductIndex, order} = this.state;
    if(editProductIndex !== undefined){
      order.products[editProductIndex] = newProduct;
    } else {
      order.products.push(newProduct);
    }
    this.setState({
      editProductIndex: undefined,
      showPoductModal: false
    });
    this.onSave(order);     
  }

  render(){
    const DropdownSelect = window.GlobalComp.DropdownSelect;
    const {order={}, showPoductModal, editProductIndex} = this.state;
    var {products} = order;
    var hasProducts = (window.Session.Products && window.Session.Products[0] && window.Session.Products[0].add !== undefined);
    return (
      <div id="EditOrderProducts" className="editOrder">
        <div className="sectionIconLeft">
          <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-tooth" /></div>
          <div className="sectionIconLeftContent">
            <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-tooth" /></div>Add Products
              {!products || products.length < 1 && <span className="form-group" style={{"margin": "-10px 0 0 0"}}><small className="form-text red-text text-muted">Required</small></span>}
            </div>
            {
              products && products.length > 0
              ? products.map((product, index)=>{
                  var {type, material, options, enclosed, teeth, shade, notes, total=0} = product;
                  return(
                    <div key={index} className="productSum">
                      <div className="productSumText" style={{flex: 1, display: "flex"}}>
                        <div className="productPrice">
                          {window.GlobalUtil.convertToMoney(total)}
                        </div>
                        {
                          type.value
                          && <div className="productOption">
                              <span className="productSumOptionTitle">Type: </span> {type.value}
                            </div>
                        }
                        {
                          teeth && teeth.length > 0
                          && <div className="productTeeth">
                              <span className="productSumOptionTitle">Teeth:</span> {teeth.map(obj=>obj.value).join()}
                            </div>
                        }
                        {
                          enclosed.value
                          && <div className="productOption">
                              <span className="productSumOptionTitle">Enclosed:</span> {enclosed.value}
                            </div>
                        }
                        {
                          material.value
                          && <div className="productOption">
                              <span className="productSumOptionTitle">Material:</span> {material.value}
                            </div>
                        }
                      </div>
                      <div className={"icons editProduct " + (hasProducts ? '' : 'disabled')} data-tip="Edit Product" onClick={()=>{this.setState({editProductIndex: index, showPoductModal: true})}}>
                        <i className="fas fa-pencil-alt"></i>
                      </div>
                      <div className="icons deleteProduct" data-tip="Delete Product" onClick={()=>{
                        products.splice(index, 1);
                        order.products = products;
                        this.onSave(order);
                      }}>
                        <i className="fas fa-trash-alt"></i>
                      </div>
                    </div>
                  )  
                })
              : <div className="noProducts">No Products Added yet</div>
            }
            <div className={"addAProduct " + (hasProducts ? '' : 'disabled')} onClick={()=>{this.setState({showPoductModal: true})}}>{(hasProducts ? 'Add a Product' : 'Server Errors: Please try again later')}</div>
          </div>
        </div>
        {
          showPoductModal
          && <AddNewProductDialog 
                headerText={editProductIndex == undefined ? "New Product" : "Edit Product"}
                onSave={(newProduct)=>{
                  this.setState({showPoductModal: undefined}, ()=>{
                    this.saveProduct(newProduct);
                  });
                }}
                onCancel={()=>{this.setState({editProductIndex: undefined, showPoductModal: false})}}
                newProduct={editProductIndex !== undefined ? products[editProductIndex] : undefined}
              />
        }
        <ReactTooltip offset={{top: 10}}/>
      </div>
    );
  }
}
export default Products;
