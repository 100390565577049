/*
	this.DiscountsClass = new DiscountsClass();
*/
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class DiscountsClass {
  constructor(props) {
  	this.Firestore = new window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }
 

	Get(discountID){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser  || !discountID){
				window.GlobalUtil.apiFail("q7jh14fzvcj", "Cannot Get Product Missing: discountID");
				return reject(false);
			}
			
			var Pointer = this.Firestore.collection("discounts").doc(discountID); 
	    Pointer.get()
	    .then((doc) => {
	      resolve(doc.data());
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("3x7xsd634oq", error);
				reject(error);
	    });
		});
	}


	GetAll(){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser){
				window.GlobalUtil.apiFail("abhped6ump", "Cannot Get Discounts");
				return reject(false);
			}

			var Pointer = this.Firestore.collection("discounts"); 
	    Pointer.get()
	    .then((querySnapshot) => {
	    	var cleanItems = [];
	      querySnapshot.forEach(function(doc) {
	        cleanItems.push(doc.data());
	      });
	      return cleanItems;
	    })
	    .then((cleanItems) => {
	      resolve(cleanItems);
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("vdfavts0c9", error);
				reject(error);
	    });
		});

	}

	Save(input){
		return this.AddOrUpdate(input);
	}

	Update(input){
		return this.AddOrUpdate(input);
	}

	AddOrUpdate(input){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			var Pointer = this.Firestore.collection("discounts");

			if(!isValidUser || !input){
				console.log("DiscountsClass Save() or Update() FAILED: isValidUser");
				console.log(isValidUser,"\n\n");
				return reject(false);
			}

			if(!input.id){ //IF DOES NOT HAVE AN ID THEN WE WILL CREATE A NEW ONE AND PUSH THE ID VALUE INTO IT AND UPDATE THE OBJ BELOW
		    Pointer = Pointer.doc();
		    var newUserID = Pointer.id;
		    Pointer.set({id: newUserID});
	      input.id = newUserID;
	    } else {
	    	Pointer = Pointer.doc(input.id); //IF HAS AN ID THEN LETS POINT TO THAT SPOT AND UPDATE BELOW
	    }
			
			//UPDATE EXISTING OBJECTS HERE
			Pointer.update(input)
			.then(obj=>{
				console.log("Updated Discounts");
				resolve(input);
			})
			.catch(error=>{
				window.GlobalUtil.apiFail("p0pfnjw9gl", error);
				reject(error);
			});
			
		});
	}

	Delete(){

	}

}
