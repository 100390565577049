import React from 'react';
import Index from './index';
import NewOrder from '../components/newOrder/index';
import VRBusinessDialog from "../components/vrBusiness/index";
import {SuperUserAdminClass} from 'global-api-helper';
/*
  ALL THIS LAYER DOES IS CHECK IF ALLOWED TO BE ON BACK END. IF SO THEN RUNS INDEX, ELSE FORWARDS TO FRONT END.
  I NEEDED THIS SO I WOULDN'T HAVE TO RUN THIS WHEN USING THE ADMIN VR'S
*/

class ValidateBackendLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      businessLoaded: (window.Session.Business && window.Session.Business.id ?  true : false), 
      productsLoaded: (window.Session.Products && window.Session.Products.length > 0  ?  true : false),
      admin: false,
      showNewOrder: false,
      businessToVRInto: false
    };
    this.stopLoading = this.stopLoading.bind(this);
    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", (business)=>{            
      if(!business.id) return;
      var {businessLoaded, productsLoaded} = this.state;
      if(business && business.id){
        if(!businessLoaded) this.setState({businessLoaded: true});
        if(productsLoaded) this.stopLoading();
      }
    }); 
    
    this.productsChanged = window.GlobalUtil.subscribeEvent("productsChanged", (products)=>{
      var {businessLoaded, productsLoaded} = this.state;
      if(!productsLoaded) this.setState({productsLoaded: true});
      if(businessLoaded) this.stopLoading();
    }); 

    this.TriggerNewOrderEvent = window.GlobalUtil.subscribeEvent("triggerNewOrderEvent", (trigger)=>{
      this.setState({showNewOrder: true});
    }); 
    
    this.TriggerShowVRBusinessEvent = window.GlobalUtil.subscribeEvent("triggerShowVRBusinessEvent", (businessID)=>{
      window.Session.SetupVRBusiness(businessID);
      this.setState({businessToVRInto: businessID});
    }); 
    
  }

  componentDidMount(){
    var {businessLoaded, productsLoaded} = this.state;
    if(!window.Session.Business || !window.Session.Business.id) {
      this.logoutIfNoUser = setTimeout(() => {
        //window.Router.history.push('/');
      }, 6000);
    } else {
      if(businessLoaded && productsLoaded) this.setState({loading: false});
    }

  }

  componentWillUnmount(){
    if(this.businessChanged){
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
      this.businessChanged = false;
    }
    if(this.productsChanged){
      window.GlobalUtil.unsubscribeEvent("productsChanged", this.productsChanged);
      this.productsChanged = false;
    }
    if(this.TriggerNewOrderEvent){
      window.GlobalUtil.unsubscribeEvent("triggerNewOrderEvent", this.TriggerNewOrderEvent);
      this.TriggerNewOrderEvent = false;
    }
    if(this.TriggerShowVRBusinessEvent){
      window.GlobalUtil.unsubscribeEvent("triggerShowVRBusinessEvent", this.TriggerShowVRBusinessEvent);
      this.TriggerShowVRBusinessEvent = false;
    }
  }

  stopLoading(){
    var {businessLoaded, productsLoaded} = this.state;
    if(this.logoutIfNoUser) clearTimeout(this.logoutIfNoUser);
    window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
    this.setState({
      loading: (businessLoaded && productsLoaded ? false : true)
    }, ()=>{
      if(this.state.loading === false) window.Session.Business.SubscribeBusiness();
    });
    this.businessChanged = false;
  }


  render(){
    var {loading, admin, showNewOrder, businessToVRInto} = this.state;
    if(loading){
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent
        />
      );
    };
    return (
      <div id="Backend" className={(admin ? "admin" : '')}>
        {
          window.GlobalUtil.IsSuperAdminUser() && !this.props.isVR &&
          <div className="adminToggle">
            <div className={`adminToggleButtons ${(!admin && "active")}`} onClick={()=>{
              window.Session.isAdminTab = false;
              this.setState({admin: false});
              window.GlobalUtil.processEvent("isAdminTabListener", false)
            }}>
              Show Nomal
            </div>
            <div className={`adminToggleButtons ${(admin && "active")}`} onClick={()=>{
              window.Session.isAdminTab = true;
              this.setState({admin: true});
              var SuperUserAdminClassNew = new SuperUserAdminClass()
              SuperUserAdminClassNew.Get(()=>window.GlobalUtil.processEvent("isAdminTabListener", true));
            }}>
              Show Admin
            </div>
          </div>
        }
        {
          showNewOrder &&
          <NewOrder 
            onClose={()=>{
              this.setState({showNewOrder: false});
            }}
          />
        }
        {
          businessToVRInto &&
          <VRBusinessDialog 
            onClose={()=>{
              this.setState({businessToVRInto: false});
            }}
            businessToVRInto={businessToVRInto}
          />
        }
        <Index {...this.props} admin={admin} />
      </div>
    );
  }
}


export default ValidateBackendLayer;