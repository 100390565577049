import React from 'react';
const BoolRadio = (props)=>{
  var {title, value} = props;
  return(
    <div className="boolRadio">
     {  
        title && 
        <div className="boolRadioTitle">{title}</div>
      } 
      <div className="boolRadioButtons">
        <div className={`boolRadioButton ${value ? "active" : ""}`} onClick={()=>props.onChange(true)}>
          True
        </div>
        <div className={`boolRadioButton ${!value ? "active" : ""}`} onClick={()=>props.onChange(false)}>
          False
        </div>
      </div>
    </div>
  )
}
export default BoolRadio;