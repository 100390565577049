import React from 'react';
// import logo from '../logo.svg';

import Header from './header';
import Footer from './footer';
import './css/styles.css';

class FrontWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false
    };
  }
  componentDidMount(){
    var location = window.location;
    if(!location.hash) window.scrollTo(0, 0); 
    else window.GlobalUtil.scrollTo(location.hash.slice(1));
    window.GlobalUtil.processEvent("closeMobileFront", true);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var location = window.location;
      if(!location.hash) window.scrollTo(0, 0); 
      else window.GlobalUtil.scrollTo(location.hash.slice(1)); 
      window.GlobalUtil.processEvent("closeMobileFront", true);
    }
  }
  render(){
    var {showLogin} = this.state;
    return (
      <div id="Landing2">
        <Header onLogin={this.onLogin} onClickLink={()=>{}}/>
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
export default FrontWrapper;
