import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect
    // etc.
} from 'react-router-dom';
import Tabs from '../components/tab_links';
import TabBusinesses from './tabsBusinesses/tab_all_businesses';


class Businesses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){  
  }

  componentWillUnmount(){
  }

  render(){
    return (
      <React.Fragment>
        <Tabs
          tabs={[
            {"name": "All Businesses", "url":"/panel/admin/businesses/"}
          ]}>
          <Route path={["/panel/admin/businesses"]} component={TabBusinesses} />
        </Tabs>
      </React.Fragment>
    );
  }
}
export default Businesses;




