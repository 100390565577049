import React, { useState } from 'react';
import TeethBottom from './productFormBuilderComponents/teeth_bottom';
import TeethTop from './productFormBuilderComponents/teeth_top';



class ProductFormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: (this.props.initialValues ? this.props.initialValues : {}),
      id: window.GlobalUtil.getRandomId(),
      touched: {},
      isValid: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnTouched = this.handleOnTouched.bind(this);
    this.handleChangeValid = this.handleChangeValid.bind(this);
          
    this.props.listOfFields.map((field, index)=>{ //SET ALL REQUIRED AS INVALID
      if(field.required) this.state.isValid[field.name] = false;
      this.state.touched[field.name] = false;
    });
  }
  onSubmit(values){//asdflkjsd
    var newObject = JSON.parse(JSON.stringify(values, 0, 2));
    this.setState({currentObject: newObject})
    this.props.onSubmit(newObject);
  };

  handleOnChange(path, newValue){
    var {currentObject} = this.state;
    window.GlobalUtil.deepSetFromString(currentObject, path, newValue);
    this.setState({currentObject});
    if(this.props.onChange) this.props.onChange(currentObject);
  }

  handleOnTouched(name){
    var {touched} = this.state;
    touched[name] = true;
    this.setState({touched});
  }

  handleChangeValid(name, newState){
    var {isValid} = this.state;
    if(isValid[name] && isValid[name] === newState) return;
    isValid[name] = newState;
    this.setState({isValid});

    var isValid = Object.keys(isValid).filter(key=>isValid[key] === false);
    if(this.props.onValidate){
      if(this.ValidateTimeout) clearTimeout(this.ValidateTimeout);
      this.ValidateTimeout = setTimeout(() => {
        this.props.onValidate(isValid.length === 0);
      }, 100);
    }
  }

  render(){
    var {currentObject, id, touched, isValid} = this.state;
    var {listOfFields=[], values={}, useExternalValues} = this.props;
    if(useExternalValues) currentObject = {...values};
    return (
      <div className="">
        {
          listOfFields.length > 0 &&
          listOfFields.map((field, index)=>{
            if(!FIELD_MAPPER[field.type]) return;
            var FieldObject = FIELD_MAPPER[field.type];  
                  
            if(field["condition"] && window.GlobalUtil.deepGetFromString(currentObject, field.condition.path, false) !== field.condition.is){
              if(currentObject[field.name]) delete currentObject[field.name];  //IF ANY FILES EXISTED BUT THEY SHOULD NOT ANYMORE THEN LETS REMOVE THEM
              return;
            }
                  
            return(
              <div className={(field.type !== "sectionDivider" ? "form-group" : "")} key={index}>
                <FieldObject
                  field={field}
                  id={`${id}${index}`}
                  value={currentObject[field.name]}
                  meta={{
                    touched: touched[field.name],
                    error: (isValid[field.name] != undefined ? !isValid[field.name] : false),
                  }}
                  onTouch={this.handleOnTouched}
                  onChangeValid={this.handleChangeValid} //SET IF ERROR OR NOT, FOR REQUIRED FIELDS
                  onChange={this.handleOnChange}
                />
              </div>
            )
          })  
        }
      </div>
    );
  }
}

const SectionDivider = ({field, id, value='', onChange, onTouch, onChangeValid, meta})=>{
  var {title="", className="", styles={}} = field;
  return(
    <div className={className} style={styles}>{title}</div>
  )
}

const FileUpload = ({field, id, value=[], onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, options=[], add, accept, def} = field;
  var tempStyles = {
    "height": "auto",
    "display": "block",
    "padding": "15px 10px",
    "margin": "0 0 15px 0",
    "borderColor": "#ddd"
  }
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }
    var valid = true;
    if(!newValue || !newValue.length < 1) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title}</div>}
      <div className={`form-control ${(meta.error && meta.touched && 'error')}`} style={{height: "auto"}}>
        {
          value.length > 0 &&
          <div className="fileList">
            {
              value.map((obj, index)=>{
                return(
                  <div className="fileLink" key={index}>
                    <div className="fileName">
                      {obj.name}
                    </div>
                    <div className="fileIcon" onClick={()=>{
                      value.splice(index, 1);
                      onChange(name, value);
                    }}>
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        <input 
          type="file" 
          accept={accept} 
          style={tempStyles}
          value={[]}  
          id={"files"}
          hidden
          onBlur={()=>{
            onTouch(name);
            if(required) Validate(value);
          }}
          onChange={e=>{
            if(!value) value = [];
            console.log("e.target.files[0]");
            console.log(e.target.files[0],"\n\n");
                  
            value.push({name: e.target.files[0].name, file: e.target.files[0]});
            onChange(name, value);
          }}
        /> 
        <label htmlFor="files"><span className="buttonAddFile">Add a File</span></label>
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
      
    </div>
  )

}



const SelectTeethBox = ({field, id, value=[], onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, add} = field;
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }

    var valid = true;
    if(!newValue || newValue.length < 1) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title} {(add ? ` +${window.GlobalUtil.convertToMoney(add)}/tooth` : null)} {required && value.length < 1 && <small style={{display:"inline-block"}} className="form-text red-text text-muted">Required</small>}</div>}
      <div className={`radioListInline radio-form-control ${((meta.error && meta.touched) ? 'error' : '')}`}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <TeethTop 
              add={add}
              activeTeeth={value}
              onSelectTooth={newValue=>{
                onChange(name, newValue);
                if(required) Validate(newValue);
                onTouch(name);
              }}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            <TeethBottom 
              add={add}
              activeTeeth={value}
              onSelectTooth={newValue=>{
                onChange(name, newValue);
                if(required) Validate(newValue);
                onTouch(name);      
              }}
            />
          </div>
        </div>
      </div>
      {required && value.length < 1 && <small className="form-text red-text text-muted">Required</small>}
    </div>
      
  )
}

const ShadesBox = ({field, id, value={add: 0, value: ''}, onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, add} = field;
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }

    var valid = true;
    if(!newValue || !newValue.value) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title} {(add ? ` +${window.GlobalUtil.convertToMoney(add)}` : null)} </div>}
      <div className={`radioListInline radio-form-control ${((meta.error && meta.touched) ? 'error' : '')}`}>
        {
          SHADES_COLORS.length > 0 && 
          SHADES_COLORS.map((color, index)=>{
            var isActive = false;
            return(
              <div key={index} className="radioBoxes">
                <label>
                  <div className="box" style={{background: color.color}}>
                    {color.title} {(color.add ? <span className="add">{` +${window.GlobalUtil.convertToMoney(color.add)}`}</span> : null)}
                  </div>
                  <input 
                    onBlur={()=>{
                      onTouch(name);
                      if(required) Validate(value);
                    }}
                    type="radio" 
                    value={color.value} 
                    checked={(color.value === value.value)} 
                    onChange={e=>{
                      const addNew = (color.add ? color.add : 0);
                      var newValue = {
                        add: addNew,
                        value: e.target.value
                      }
                      onChange(name, newValue);
                      if(required) Validate(newValue);
                    }}
                  />
                </label>
              </div>
            )
          })
        }
      </div>
      {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
    </div>
        
  )
}


const RadioBox = ({field, id, value={add: 0, value: ''}, onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, options=[], add} = field;
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }

    var valid = true;
    if(!newValue || !newValue.value) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title}</div>}
      <div className={`radioListInline radio-form-control ${((meta.error && meta.touched) ? 'error' : '')}`}>
        {
          options.length > 0 && 
          options.map((option, index)=>{
            var isActive = false;
            return(
              <div key={index} className="radioListItem">
                <input 
                  onBlur={()=>{
                    onTouch(name);
                    if(required) Validate(value);
                  }}
                  value={value.value}
                  onChange={e=>{
                    const addNew = (option.add ? option.add : 0);
                    var newValue = {
                      add: addNew,
                      value: e.target.value
                    }
                    onChange(name, newValue);
                    if(required) Validate(newValue);
                  }}
                  id={`${id}${index}`} 
                  type="radio" 
                  value={option.value} 
                  checked={(option.value === value.value)} 
                />
                <label htmlFor={`${id}${index}`}>
                  <div className={"box " + (isActive ? 'active' : '')}>{option.title} {(option.add ? <span className="add">{` +${window.GlobalUtil.convertToMoney(option.add)}`}</span> : null)}</div>
                </label>
              </div>
            )
          })
        }
      </div>
      {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
    </div>
        
  )
}


const SelectBox = ({field, id, value={add: 0, value: ''}, onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, options=[], defaultOption} = field;
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }

    var valid = true;
    if(!newValue || !newValue.value) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title} {required && !value.value && <small style={{display:"inline-block"}} className="form-text red-text text-muted">Required</small>}</div>}
      <select  
        type="select" 
        className={`form-control ${(meta.error && meta.touched && 'error')}`}
        value={value.value}
        onBlur={()=>{
          onTouch(name);
          if(required) Validate(value);
        }} 
        onChange={e=>{
          const optionValues = options.filter(obj=>obj.value == e.target.value)[0];
          const addNew = (optionValues && optionValues.add ? optionValues.add : 0);
          var newValue = {
            add: addNew,
            value: e.target.value
          }
          onChange(name, newValue);
          if(required) Validate(newValue);
        }}
      >
        {defaultOption && <option value={''} disabled hidden>{defaultOption}</option>}
        {
          options.length > 0 && 
          options.map((option, index)=>{
            if(option.disabled) return(<option key={index} value={option.value} disabled hidden>{option.title}</option>)
            return(
              <option key={index} value={option.value}>{option.title} {(option.add ? ` +${window.GlobalUtil.convertToMoney(option.add)}`:  null)}</option>
            )
          })
        }
      </select>
      {required && !value.value && <small className="form-text red-text text-muted">{meta.error}</small>}
    </div>
        
  )
}


const TextBox = ({field, id, value={add: 0, value: ''}, onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, placeholder="", add} = field;
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }

    var valid = true;
    if(!newValue || !newValue.value) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title}</div>}
      <input 
        onBlur={()=>{
          onTouch(name);
          if(required) Validate(value);
        }} 
        type="text" 
        className={`form-control ${(meta.error && meta.touched && 'error')}`}  
        placeholder={placeholder} 
        value={value.value}
        onChange={e=>{
          const addNew = (add ? add : 0);
          var newValue = {
            add: addNew,
            value: e.target.value
          }
          onChange(name, newValue);
          if(required) Validate(newValue);
        }}
      />
      {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
    </div>
  )
}


const TextAreaBox = ({field, id, value={add: 0, value: ''}, onChange, onTouch, onChangeValid, meta})=>{
  var {name="", required, title, placeholder, rows, columns, add} = field;
  const [count, setCount] = useState(0);
  const Validate = (newValue) => {
    if(!required){
      onChangeValid(name, true);
      setCount(count + 1);
      return;
    }

    var valid = true;
    if(!newValue || !newValue.value) valid = false;
    onChangeValid(name, valid);
    setCount(count + 1);
  };
  if(count == 0) Validate(value); //THIS SHOULD ONLY RUN ONCE WHEN FIRST LOAD IN ORDER TO GET IF VALID WITH INITIAL DATA
  return(
    <div>
      {title && <div className="input-header">{title}</div>}
      <textarea  
        value={value.value}
        onChange={e=>{
          const addNew = (add ? add : 0);
          var newValue = {
            add: addNew,
            value: e.target.value
          }
          onChange(name, newValue);
          if(required) Validate(newValue);
        }}
        onBlur={()=>{
          onTouch(name);
          if(required) Validate(value);
        }} 
        className={`form-control ${(meta.error && meta.touched && 'error')}`}  
        placeholder={placeholder} 
        rows={rows} 
        columns={columns}>
      </textarea>
      {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
    </div>
  )
}


var FIELD_MAPPER = {
  text: TextBox,
  textArea: TextAreaBox,
  select: SelectBox,
  radio: RadioBox,
  shades: ShadesBox,
  teeth: SelectTeethBox,
  sectionDivider: SectionDivider,
  fileUpload: FileUpload
};




const SHADES_COLORS = [
  {
    title: "A1",
    value: "A1",
    color: "#E2D9C8"
  },
  {
    title: "A2",
    value: "A2",
    color: "#E2CEB6"
  },
  {
    title: "A3",
    value: "A3",
    color: "#DAC5AA"
  },
  {
    title: "A3.5",
    value: "A3.5",
    color: "#E2C4A0"
  },
  {
    title: "A4",
    value: "A4",
    color: "#D4B48D"
  },
  {
    title: "B1",
    value: "B1",
    color: "#DAD7D2"
  },
  {
    title: "B2",
    value: "B2",
    color: "#E6D7C4"
  },
  {
    title: "B3",
    value: "B3",
    color: "#E3CBA7"
  },
  {
    title: "B4",
    value: "B4",
    color: "#E7C89A"
  },
  {
    title: "C1",
    value: "C1",
    color: "#D9CBC0"
  },
  {
    title: "C2",
    value: "C2",
    color: "#CFBEA7"
  },
  {
    title: "C3",
    value: "C3",
    color: "#DDC4A5"
  },
  {
    title: "C4",
    value: "C4",
    color: "#D3B183"
  },
  {
    title: "D2",
    value: "D2",
    color: "#E0D0C0"
  },
  {
    title: "D3",
    value: "D3",
    color: "#E4CBB5"
  },
  {
    title: "D4",
    value: "D4",
    color: "#D3C3AD"
  },
];


export default ProductFormBuilder;
