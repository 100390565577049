import React from 'react';
import ReactTooltip from 'react-tooltip';

// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/order_summary_dialog.css';
import '../../css/invoice_preview_dialog.css';

import SettingsDialogWrapper from '../settings_dialog_wrapper';

import PreviewDoctor from '../previewInvoice/doctor';
import PreviewPatient from '../previewInvoice/patient';
import PreviewProducts from '../previewInvoice/products';
import PreviewShipping from '../previewInvoice/shipping';
import PreviewInvoice from '../previewInvoice/invoice';
import PreviewTotal from '../previewInvoice/total';



import {OrderSummary} from './view_order';
import {OrdersClass} from 'global-api-helper';
/*

-------IMPORTANT
LATER ADD OPTION TO EDIT SEND INVOICE TO

ALSO LIKE WITH THE (editOrder) LETS PUT EACH INTO THEIR OWN JS FILE 
THEN USE THEM WITH BOTH HERE AND THE pdf_invoice.js if possible

*/
class ViewInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: undefined
    };
  }

  render(){
    var {currentObject, headerText, primaryLocation} = this.props;
    var {orderID} = this.state;
    return(
      <SettingsDialogWrapper
        headerText={headerText}
        width="1200"
        height="1000"
        onCancel={this.props.onCancel}
        onSave={this.props.onCancel}
        acceptButtonEnabled={true}
        clickToCloseOverlay={false}
        zIndex={100}
        className={"overrideDialogBody"}
        acceptButtonText={"Done"}>
        <div id="InvoicePreviewDialog">
          <InvoiceSummary
            invoice={currentObject}
            primaryLocation={primaryLocation}
            onViewOrder={(newOrderID)=>{this.setState({orderID: newOrderID})}}
          />
          {
            orderID &&
            <SlideOutTemp 
              orderID={orderID} 
              onClose={()=>{this.setState({orderID: undefined})}}
            />
          }
        </div>
      </SettingsDialogWrapper>
    )
  }
}


class SlideOutTemp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderToPreview: undefined,
      animationClass: 'slideInRight',
      loading: true
    };
    this.onCloseSlideout = this.onCloseSlideout.bind(this);
    this.handleViewOrder = this.handleViewOrder.bind(this);
    this.OrdersClass = new OrdersClass();
  }

  componentDidMount(){
    this.handleViewOrder();
  }

  onCloseSlideout(){
    if(this.closingTimout) return;
    this.setState({animationClass: 'slideOutRight'}, ()=>{
      this.closingTimout = setTimeout(()=>{
        this.props.onClose();
      }, 800);
    });
  }

  handleViewOrder(){
    this.setState({loading: true});
    this.OrdersClass.GetSingle(this.props.orderID,
      success=>{
        this.setState({loading: false, orderToPreview: success.data()});
      },
      fail=>{
        this.setState({loading: false, orderToPreview: undefined});
      }
    );
  }

  render(){
    var {animationClass, loading, orderToPreview} = this.state;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    return(
      <div className="slideOutTemp">
        <ReactTooltip offset={{top: 10}}/>
        <div className="overlay" data-tip="Close Quick View" onClick={this.onCloseSlideout}>
        </div>
        <div className={`content animated ${animationClass}`}>
          <div className="">
            <div className="slideOutHeader">
              <div className="slideOutHeaderText">Order Quck View</div>
              <div className="slideOutHeaderButton" onClick={this.onCloseSlideout} data-tip="Close Quick View"><i className="fas fa-window-close"></i></div>
            </div>
            {
              loading
              ? <div id="InvoicePreviewDialog">
                  <LoadingComponent 
                    Header={"Loading Order"}
                  />
                </div>
              : <OrderSummary
                  order={orderToPreview}
                  onEdit={()=>{}}
                />
            }
          </div>
        </div>
      </div>
    )
  }
}





class InvoiceSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: undefined,
      loading: false,
      CleanIssued: new Date(this.props.invoice.date).formatDate('n/d/y'),
      CleanStart: new Date(this.props.invoice.startDate).formatDate('n/d/y'),
      CleanEnd: new Date(new Date().setDate(new Date(this.props.invoice.endDate).getDate()-1)).formatDate('n/d/y'),
    };
  }

  componentDidMount(){
    if(this.InvoiceSummaryRef) this.InvoiceSummaryRef.scrollTo(0,0)
  }

  render(){
    var {invoice, primaryLocation} = this.props;
    var {orderID, loading, CleanIssued, CleanStart, CleanEnd} = this.state;
    var discounts = (invoice.subTotal*(invoice.discount/100));
    var taxes = 0;
    var total = (invoice.subTotal - discounts + taxes);
    var orders = invoice.orders;

    return (
      <div id="InvoiceSummary" ref={e=>this.InvoiceSummaryRef=e}>
        <div className="scrollableZone">
          <div id="InvoiceHeader">
            <div className="headerTitle">Invoice</div>
            <img className="headerLogo" src={window.CONST.SITE.logo.light} />
          </div>
          <div id="InvoiceSummaryContent">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contentSection">
                  <div className="sectionHeader">Bill To</div>
                  {
                    window.CONST.SITE.invoiceStampArray && 
                    window.CONST.SITE.invoiceStampArray.map((newText, index)=>{
                      return(
                        <div key={index} className="contentSectionRow">{newText}</div>
                      )
                    })
                  }
                </div>
                <br/>
              </div>
              <div className="col-12 d-md-none d-lg-none d-xl-none">
                <hr/>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contentSection">
                  <div className="contentSectionRight">
                    <div className="sectionHeader">Invoice Info</div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">ID:</div>
                      <div className="twoItemRowContent">{invoice.id}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Starting:</div>
                      <div className="twoItemRowContent">{CleanStart}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Ending:</div>
                      <div className="twoItemRowContent">{CleanEnd}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Issued:</div>
                      <div className="twoItemRowContent">{CleanIssued}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Status:</div>
                      <div className="twoItemRowContent">{invoice.status}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Total:</div>
                      <div className="twoItemRowContent">{window.GlobalUtil.convertToMoney(total)}</div>
                    </div>
                  </div>
                </div>
                <br/>
              </div>
            </div>


            <div className="row"><div className="col"><hr/></div></div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contentSection">
                  <div className="sectionHeader">Send Invoice To</div>
                  <div className="contentSectionRow">{primaryLocation.name}</div>
                  <div className="contentSectionRow">{primaryLocation.street}</div>
                  <div className="contentSectionRow">{primaryLocation.city}, {primaryLocation.state} {primaryLocation.zip}</div>
                  <div className="contentSectionRow">{primaryLocation.email}</div>
                </div>
                <br/>
              </div>
              <div className="col-12 d-md-none d-lg-none d-xl-none">
                <hr/>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <br/>
              </div>
            </div>


            <div className="row"><div className="col"><hr/></div></div>

            <div className="row">
              <div className="col-12">
                <div className="contentSection">
                  <div className="sectionHeader">Orders</div>
                  <OrderList 
                    orders={orders}
                    onViewOrder={this.props.onViewOrder}
                  />
                </div>
                <br/>
              </div>
            </div>


            <div className="row"><div className="col"><hr/></div></div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contentSection">
                  <div className="sectionHeader">Invoice Terms</div>
                  <div className="">All invoices should be paid within 2 weeks of issuing. Each will be issued within 1 week of new month.</div>
                </div>
                <br/>
              </div>
              <div className="col-12 d-md-none d-lg-none d-xl-none">
                <hr/>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contentSection">
                  <div className="contentSectionRight">
                    <div className="sectionHeader">Total Summary</div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Subtotal:</div>
                      <div className="twoItemRowContent">{window.GlobalUtil.convertToMoney(invoice.subTotal)}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Discount:</div>
                      <div className="twoItemRowContent">{window.GlobalUtil.convertToMoney(discounts)}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Tax:</div>
                      <div className="twoItemRowContent">{window.GlobalUtil.convertToMoney(taxes)}</div>
                    </div>
                    <div className="twoItemRow">
                      <div className="twoItemRowText">Total:</div>
                      <div className="twoItemRowContent">{window.GlobalUtil.convertToMoney(total)}</div>
                    </div>
                  </div>
                </div>
                <br/>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}




const OrderList = ({orders, onViewOrder}) => {
  return(
    <div className="orderList">
      <div className="orderListHeader">
        <div className="orderListItem orderListItem1">
          <div className="orderListItemInner">
            Date
          </div>
        </div> 
        <div className="orderListItem orderListItem2">
          <div className="orderListItemInner">
            Order ID
          </div>
        </div>   
        <div className="orderListItem orderListItem3">
          <div className="orderListItemInner">
            Patient
          </div>
        </div> 
        <div className="orderListItem orderListItem4">
          <div className="orderListItemInner">
            # Products
          </div>
        </div>
        <div className="orderListItem orderListItem5">
          <div className="orderListItemInner">
           Total
          </div>
        </div> 
        <div className="orderListItem orderListItem6">
          <div className="orderListItemInner">
           View
          </div>
        </div> 
      </div>
      {
        orders.map((object, index)=>{
          var date = new Date(object.date).formatDate('n/d/y');
          return(
            <div className="orderListRow" key={index}>
              <div className="orderListItem orderListItem1">
                <div className="orderListItemInner">
                  {date}
                </div>
              </div> 
              <div className="orderListItem orderListItem2">
                <div className="orderListItemInner">
                  {object.id}
                </div>
              </div>   
              <div className="orderListItem orderListItem3">
                <div className="orderListItemInner">
                  {object.patient}
                </div>
              </div> 
              <div className="orderListItem orderListItem4">
                <div className="orderListItemInner">
                  {object.productNumber}
                </div>
              </div>
              <div className="orderListItem orderListItem5">
                <div className="orderListItemInner">
                  {window.GlobalUtil.convertToMoney(object.total)}
                </div>
              </div> 
              <div className="orderListItem orderListItem6">
                <div className="orderListItemInner">
                  <div className="button" data-tip="View this Order" onClick={()=>onViewOrder(object.id)}>
                    <i className="fas fa-eye" />
                  </div>
                </div>
              </div> 
            </div>
          )
        })
      }
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}





export default ViewInvoice;