import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/add_new_product_dialog.css';
import SettingsDialogWrapper from '../settings_dialog_wrapper';

class AddNewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: (window.Session.Products ? window.Session.Products : []),
      product: this.props.newProduct ? this.props.newProduct : {
        id: window.GlobalUtil.getRandomId(),
        add: window.Session.Products[0].add,
        type: {
          value: window.Session.Products[0].value,
          add: window.Session.Products[0].add
        }
      },
      isValid: false,
      isAllValid: [false, false, true]
    };
    this.fullProduct = window.GlobalUtil.copyObject(this.state.product);
    this.onSubmit = this.onSubmit.bind(this);
    this.getProductOptions = this.getProductOptions.bind(this);
    this.sortOrder = this.sortOrder.bind(this);
    this.loadNewProductOptions = this.loadNewProductOptions.bind(this);
    this.ProductFormBuilder = window.GlobalComp.ProductFormBuilder;
    this.onSetupProducts = this.onSetupProducts.bind(this);
    this.ProductFormBuilderNew = this.ProductFormBuilderNew.bind(this);
  }

  componentDidMount(){
    this.onSetupProducts();
  }
  
  onSetupProducts(){
    var {product={}, products, isValid, hideProducts, total=0, isAllValid} = this.state;
    var productOptions = products.map((object, index)=>{
    var title = object.name;
    if(object.add) title+= ` +${window.GlobalUtil.convertToMoney(object.add)}`;
      return({title: object.name, value: object.value});
    });
    productOptions.unshift({title: "Choose a Product", value: "", disabled: true});
    var listOfFields = [
      {
        title: "Choose Product",
        type: "select",
        name: "type",
        required: true,
        options: productOptions,
        zone: 2,
        row: 0
      }
    ];
    if(product.type) {
      listOfFields = listOfFields.concat(this.getProductOptions());
    }
    listOfFields = this.sortOrder(listOfFields);
    this.setState({listOfFields: listOfFields});
  }

  onSubmit(){
    this.props.onSave(this.fullProduct);
  };

  getProductOptions(){
    var {products} = this.state;
    var productOptions = [];
    var currentProduct = products.filter(pro=>pro.value===this.fullProduct.type.value)[0];
    Object.keys(currentProduct).forEach(key=>{
      var tempValue = currentProduct[key];
      if(tempValue && tempValue.type){
        productOptions.push(tempValue);
      }
    });
    return(productOptions);
  }

  sortOrder(listOfFields){
    //sort by row
    listOfFields = [
      listOfFields.filter(field=>field.zone == 1).sort((a, b) => (a.row > b.row) ? 1 : -1),
      listOfFields.filter(field=>field.zone == 2).sort((a, b) => (a.row > b.row) ? 1 : -1),
      listOfFields.filter(field=>field.zone == 3).sort((a, b) => (a.row > b.row) ? 1 : -1),
    ];
          
    return(listOfFields);
  }

  loadNewProductOptions(newProduct, callBack=()=>{}){
    //WHEN THEY CHANGE PRODUCTS LOAD NEW OPTIONS AND RESET CURRENT PRODUCT
    this.setState({
      product: newProduct,
      hideProducts: true,
      total: 0
    }, ()=>{
      this.onSetupProducts(); //RUN WHEN YOU'VE CHOSEN A NEW PRODUCT
      this.fullProduct = {...newProduct};
      this.setState({hideProducts: false});
      callBack();
    });
  }


  calculateTotal(){
    this.fullProduct.total = this.fullProduct.add; //START BY SETTING BASE PRODUCT PRICE
    Object.keys(this.fullProduct).forEach((key, index)=>{
      let option = this.fullProduct[key];
      if(option.add) this.fullProduct.total += option.add;
      if(option && Array.isArray(option) && option.length > 0){
        option.forEach((option, index)=>{
          if(option.add) this.fullProduct.total += option.add;
        })
      }
    });
    if(this.fullProduct.total != this.state.total) this.setState({total: this.fullProduct.total});          
  }


  ProductFormBuilderNew(listOfFields, setValid){
    const ProductFormBuilder = this.ProductFormBuilder;
    var {product={}, products} = this.state;
    return(
      <ProductFormBuilder
        initialValues={{...product}}
        listOfFields={listOfFields}
        onChange={newObj => {
          /*
            IF THEY CHANGE THE PRODUCT THEN REMOVE OLD CHOSEN OPTIONS AND PULL NEW OPTIONS
          */
          this.fullProduct = {...this.fullProduct, ...newObj};
          if(product.type.value !== newObj.type.value){
            var currentProduct = products.filter(pro=>pro.value===newObj.type.value)[0];
            this.loadNewProductOptions({
              id: newObj.id,
              add: currentProduct.add,
              type: {
                value: newObj.type.value,
                add: newObj.type.add
              }
            }, ()=>{
              /*
                RECALCULATE CURRENT PRICE BASED ON WHAT THEY'VE SELECTED
              */
              this.calculateTotal();
            });
          } else {
            /*
              RECALCULATE CURRENT PRICE BASED ON WHAT THEY'VE SELECTED
            */
            this.calculateTotal();
          }
        }}
        onValidate={setValid}
      />
    )
  }

  render(){
    const {newProduct} = this.props;
    var {product={}, products, isValid, hideProducts, total=0, isAllValid, listOfFields} = this.state;
    var isAllValidSingle = (isAllValid.indexOf(false) == -1);     
    
    if(!listOfFields) return null;
          
    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width={1200}
        height={800}
        onSave={this.onSubmit}
        onCancel={this.props.onCancel}
        className="additionalSettings"
        acceptButtonEnabled={isAllValidSingle}
        clickToCloseOverlay={false}
        footerLeft={(<div><span style={{"fontWeight": "500"}}>Total:</span> {window.GlobalUtil.convertToMoney(total)}</div>)}
      >
        {
          !hideProducts &&
          <div id="AddNewProductDialog">
            <div className="row">
              <div className="col-12 col-md-5">
                <div id="MainHeader" className="d-block ">
                  <div className="headers" style={{marginTop: 0}}>Select Teeth</div>
                </div>
                {this.ProductFormBuilderNew(listOfFields[0], (isValid)=>{
                  var {isAllValid=[]} = this.state;
                  if(isAllValid[0] == isValid) return;
                  isAllValid[0] = isValid;
                  this.setState({isAllValid: isAllValid})
                })}
              </div>
              <div className="col-12 col-md-7">
                <div className="headers" style={{"marginTop": "0"}}>Select a Product</div>
                {this.ProductFormBuilderNew(listOfFields[1], (isValid)=>{
                  var {isAllValid=[]} = this.state;
                  if(isAllValid[1] == isValid) return;
                  isAllValid[1] = isValid;
                  this.setState({isAllValid: isAllValid})
                })}
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12">
                {this.ProductFormBuilderNew(listOfFields[2], (isValid)=>{
                  var {isAllValid=[]} = this.state;
                  if(isAllValid[2] == isValid) return;
                  isAllValid[2] = isValid;
                  this.setState({isAllValid: isAllValid})
                })}
              </div>
            </div>
          </div>
        }
      </SettingsDialogWrapper>
    )
  }
}

export default AddNewProduct;