import React from 'react';
import BasicContacts from './index';
class CurrentTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render(){
    var {} = this.state;
    return (
      <BasicContacts 
        title={"List of Current Contacts"}
        filters={[
          {
            key: "where",
            value: ["status", "==", 'active']
          }
        ]}
      />
    );
  }
}



export default CurrentTabs;