import React from 'react';
import Wrapper from '../components/wrapper';
import Login from '../components/login';

const LoginPage = (props) => {
  return (
    <div id="LoginPage">
      <div className="container">
        <div className="loginContainer">
          <div className="loginText purpleBackground">
            <div className="loginTextOverlay">
              <h2>User Login</h2>
              <p className="">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget sapien non tortor mattis sagittis. Nunc commodo quam id eros feugiat mattis. Sed ornare malesuada erat, vel tristique ex cursus ac.
              </p>
            </div>
          </div>
          <Login
            onLogin={()=>{

            }}
            onCloseDialog={()=>{

            }}
          />
        </div>
      </div>    
    </div>  
  );
}

                      

export default LoginPage;
