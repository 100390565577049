import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const ConfirmationDialog = (props)=>{
  var {title, message, buttons} = props;
  const options = {
    title: title,
    message: message,
    buttons: buttons,
    childrenElement: () => <div />,
    //customUI: ({ onClose }) => <div>Custom UI</div>,
    closeOnEscape: true,
    closeOnClickOutside: true,
    //willUnmount: () => {},
    //onClickOutside: () => {},
    //onKeypressEscape: () => {}
  };
  return confirmAlert(options);
}

export default ConfirmationDialog;