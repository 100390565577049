import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/order_summary_dialog.css';
import '../../css/new_order_dialog.css';

import SettingsDialogWrapper from '../settings_dialog_wrapper';

import EditDoctor from '../editOrder/doctor';
import EditPatient from '../editOrder/patient';
import EditProducts from '../editOrder/products';
import EditShip from '../editOrder/shipping';
import EditSendInvoice from '../editOrder/invoice';
import EditTotal from '../editOrder/total';


import PreviewDoctor from '../previewOrder/doctor';
import PreviewPatient from '../previewOrder/patient';
import PreviewProducts from '../previewOrder/products';
import PreviewShipping from '../previewOrder/shipping';
import PreviewInvoice from '../previewOrder/invoice';
import PreviewTotal from '../previewOrder/total';
import {OrdersClass} from 'global-api-helper';
/*

-------IMPORTANT
WHEN THEY CLICK ON THE DARK AREA DON'T ALLOW IT TO CLOSE. 
MAKE THEM CHOOSE FORM ACCEPT OR CANCEL


THEN SET UP THE EDITING ORDERS DIALOGS IN "edit_order_dialogs"
EACH SHOULD BE API CALL THEN FORM WITH VALIDATION 


NEXT DO THE onUpdateLocalOrder() BELOW REGETTING THE NEW ORDER PRICE
AND UPDATING THE ORDER


FINALY POINT THE onUpdateOrder TO A GLOBAL API UTIL THAT CAN BE USED HERE, ON THE ACCTUAL SUBMITTION, AND ON THE TRY AGAIN SCREEN


*/


class EditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: this.props.currentObject,
      loading: false,
      showFailMessage: false,
      whatToEdit: undefined,
      isValid: true
    };
    this.onUpdateOrder = this.onUpdateOrder.bind(this);
    this.onUpdateLocalOrder = this.onUpdateLocalOrder.bind(this);
    this.onEditField = this.onEditField.bind(this);
    this.onChangeOrderObj = this.onChangeOrderObj.bind(this);
    this.OrdersClass = new OrdersClass();
  }

  async onUpdateOrder(){
    var {currentObject} = this.state;
    this.setState({loading: true});

    const onStart = ()=>{
      this.setState({loading: true});
    };

    const onSuccess = (message)=>{
      this.setState({loading: false});
      this.props.onSave();
    };

    const onFail = (message)=>{
      this.setState({loading: false, showFailMessage: true});
      this.props.onFail(message);
    };

    await window.GlobalUtil.delay(500);
    await this.OrdersClass.Update(currentObject, onStart, onSuccess, onFail);
  }

  onUpdateLocalOrder(updatedOrder){
    var isValid = window.GlobalUtil.checkOrderIsValid(updatedOrder);
    this.setState({
      currentObject: updatedOrder,
      whatToEdit: undefined,
      isValid: isValid
    });
  }

  onEditField(whatToEdit){
    this.setState({whatToEdit: whatToEdit});
  }

  onChangeOrderObj(updateOrder){
    this.setState({currentObject: updateOrder});
  }

  render(){
    var {currentObject, isValid, loading, showFailMessage, whatToEdit} = this.state;
    var states = window.GlobalUtil.states();
    if(showFailMessage) isValid = false;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
          
    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="1200"
        height="900"
        onCancel={this.props.onCancel}
        onSave={this.onUpdateOrder}
        acceptButtonEnabled={isValid}
        clickToCloseOverlay={false}
        zIndex={100}
      >
      <React.Fragment>
        {
          !loading && !showFailMessage &&
          <OrderSummary
            order={currentObject}
            onEdit={this.onEditField}
            onChangeOrderObj={this.onChangeOrderObj}
          />
        }
        {
          loading &&
          <LoadingComponent 
            Header={"Submitting Order"}
            Text={"Please Stand by"}
          />
        }
        {
          showFailMessage &&
          <div id="FailMessage">
            <div className="failMessageWrapper">
              <i className="far fa-surprise"></i>
              <h3>ERROR!</h3>
              <h4>Oh No, something went wrong!</h4>
              <p className="">I could be that the servers are too stressed right now, or we just had a problem receving your order.<br/>
              So please try again in a minute. We will work on fixing the problem soon.</p>
            </div>
          </div>
        }
        {
          whatToEdit &&
          <ShowEditDialog
            order={currentObject}
            whatToEdit={whatToEdit}
            onCancel={()=>this.setState({whatToEdit: undefined})}
            onSave={this.onUpdateLocalOrder}
          />
        }
      </React.Fragment>
    </SettingsDialogWrapper>
    )
  }
}



const ShowEditDialog = (props) => {
  const WhatToEdit = {
    doctor: {
      form: EditDoctor,
      title: "Edit Doctor"
    },
    patient: {
      form: EditPatient,
      title: "Edit Patient"
    },
    products: {
      form: EditProducts,
      title: "Edit Products"
    },
    shipping: {
      form: EditShip,
      title: "Edit Shipping"
    },
    invoice: {
      form: EditSendInvoice,
      title: "Edit Payment"
    }
  }
  const EditOrderItem = WhatToEdit[props.whatToEdit].form;
  var EditOrderItemRef = {};
  return(
    <SettingsDialogWrapper
      headerText={WhatToEdit[props.whatToEdit].title}
      width="900"
      height="700"
      onCancel={props.onCancel}
      onSave={()=>{
        if(EditOrderItemRef) props.onSave(EditOrderItemRef.state.order);
      }}
      acceptButtonEnabled={true}
      clickToCloseOverlay={false}
      zIndex={200}
    >
      <EditOrderItem
        ref={e=>EditOrderItemRef=e}
        order={props.order}
        onChange={()=>{}}
      />
    </SettingsDialogWrapper>
  )
}




const OrderSummary = (props) => {
  var {order} = props;
  var {patient="", doctor="", products=[], payment={}, shipping={}, id, date} = order; 
  var total = products.reduce((accumulator, obj) => {
    return Number(accumulator)+Number(obj.total);
  }, 0);
  var newDate = new Date(Number(date)).toLocaleDateString();
  return (
    <div id="OrderSummaryEdit" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          {window.CONST.SITE.InvoiceStamp()}
          <br/>
        </div>
        <div className="col-12 d-md-none d-lg-none d-xl-none">
          <hr/>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="invoiceStamp">
            <div className="name">Order Summary</div>
            <div className="id">Id: {id}</div>
            <div className="date">Submitted: {newDate}</div>
            <div className="date">Total: {window.GlobalUtil.convertToMoney(total)}</div>
          </div>
          <br/>
        </div>
      </div>
      <div className="row"><div className="col"><hr/></div></div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <PreviewDoctor
            order={order}
            showEdit={true}
            onEdit={props.onEdit}
          />
          <br/>
        </div>
        <div className="col-12 d-md-none d-lg-none d-xl-none">
          <hr/>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <PreviewPatient
            order={order}
            showEdit={true}
            onEdit={props.onEdit}
          />
          <br/>
        </div>
      </div>
      

      <div className="row"><div className="col"><hr/></div></div>
      

      <div className="row">
        <div className="col-12">
          <PreviewProducts
            order={order}
            showEdit={true}
            onEdit={props.onEdit}
          />
        </div>
      </div>

      <div className="row"><div className="col"><hr/></div></div>

      
      <div className="row">
        <div className="col-12 col-md-4 col-lg-4">
          <PreviewShipping
            order={order}
            showEdit={true}
            onEdit={props.onEdit}
          />
          <br/>
        </div>

        <div className="col-12 d-md-none d-lg-none d-xl-none"><hr/></div>

        <div className="col-12 col-md-4 col-lg-4">
          <PreviewInvoice
            order={order}
            showEdit={true}
            onEdit={props.onEdit}
          />
          <br/>
        </div>

        <div className="col-12 d-md-none d-lg-none d-xl-none"><hr/></div>

        <div className="col-12 col-md-4 col-lg-4">
          {
            !window.Session.isAdminTab
            ? <PreviewTotal
                order={order}
                showEdit={true}
                onEdit={props.onEdit}
              />
            : <EditTotal
                order={order}
                showEdit={true}
                onChange={props.onChangeOrderObj}
              />
          }
        </div>
      </div>
    </div>
  );
}





export default EditOrder;