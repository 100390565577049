import React from 'react';
import PreviewDoctor from '../../previewOrder/doctor';
import PreviewPatient from '../../previewOrder/patient';
import PreviewProducts from '../../previewOrder/products';
import PreviewShipping from '../../previewOrder/shipping';
import PreviewInvoice from '../../previewOrder/invoice';
import PreviewTotal from '../../previewOrder/total';
import EditTotal from '../../editOrder/total';
import {OrdersClass} from 'global-api-helper';

class TabOrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeOrderObj = this.onChangeOrderObj.bind(this);
    this.OrdersClass = new OrdersClass();
  }

  async onSubmit(){
    var {curOrderObject={}} = this.props;
    const onStart = ()=>{
      this.setState({loading: true});
    };
    
    const onSuccess = (message)=>{
      this.setState({loading: false});
      this.props.onGotoPage(4);
      this.props.onRestart();
    };

    const onFail = (message)=>{
      this.setState({loading: false});
      this.props.onGotoPage(5);
    };

    await window.GlobalUtil.delay(500);
    await this.OrdersClass.Update(curOrderObject, onStart, onSuccess, onFail);
  };

  onChangeOrderObj(newOrderObj){
    this.props.onUpdateObject(newOrderObj);
  }


  render(){
    var {curOrderObject={}} = this.props;
    var {loading} = this.state;
    var {patient="", doctor={}, products=[], payment={}, shipping={}} = curOrderObject;       
    var productsTotal = products.reduce(function(accumulator, obj) {
        return Number(accumulator)+Number(obj.total);
      }, 0);
    if(loading) {
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent 
          Header={"Submitting Order"}
          Text={"Please Stand by"}
        />
      );
    };
    return (
      <React.Fragment>
        <div id="PageContent">
          <div id="OrderSummary" className={this.props.className}>
            <h4>Order Summary</h4>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <PreviewDoctor
                  order={curOrderObject}
                />
                <br/>
              </div>
              <div className="col-12 d-md-none d-lg-none d-xl-none">
                <hr/>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <PreviewPatient
                  order={curOrderObject}
                />
                <br/>
              </div>
            </div>
            

            <div className="row"><div className="col"><hr/></div></div>
            

            <div className="row">
              <div className="col-12">
                <PreviewProducts
                  order={curOrderObject}
                />
              </div>
            </div>

            <div className="row"><div className="col"><hr/></div></div>

            
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <PreviewShipping
                  order={curOrderObject}
                />
                <br/>
              </div>

              <div className="col-12 d-md-none d-lg-none d-xl-none"><hr/></div>

              <div className="col-12 col-md-4 col-lg-4">
                <PreviewInvoice
                  order={curOrderObject}
                />
                <br/>
              </div>

              <div className="col-12 d-md-none d-lg-none d-xl-none"><hr/></div>

              <div className="col-12 col-md-4 col-lg-4">
                {
                  !window.Session.isAdminTab
                  ? <PreviewTotal
                      order={curOrderObject}
                    />
                  : <EditTotal
                      order={curOrderObject}
                      showEdit={true}
                      onChange={this.onChangeOrderObj}
                    />
                }
              </div>
            </div>
          </div>
        </div>

        <div id="NavButtons">
          <div className="leftButtons">
            <button className="button cancleButton" onClick={this.props.onCancel}>Cancel</button>
          </div>
          <div className="rightButtons">
            <button className={`button prevButton ${!this.props.currentPage ? 'disabled' : ''}`} onClick={this.props.onPrevPage}>Previous</button>
            <button className={`button nextButton submitButton`} onClick={this.onSubmit}>Submit</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default TabOrderSummary;


/*
*/