import React from 'react';
import '../css/frontend.css';

export default class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
    this.rotateActiveSteps = this.rotateActiveSteps.bind(this);
  }
  componentDidMount(){
    this.rotateActiveStepsRunning = setTimeout(() => {
      this.rotateActiveSteps();
    }, 1000);
  }
  
  rotateActiveSteps(){
    var nextStep = this.state.activeStep+1;
    if(nextStep > 3) nextStep = 0;
    this.setState({activeStep: nextStep});
    if(this.rotateActiveStepsRunning) clearTimeout(this.rotateActiveStepsRunning);
    this.rotateActiveStepsRunning = setTimeout(() => {
      this.rotateActiveSteps();
    }, 2000);
  }

  componentWillUnmount(){
    if(this.rotateActiveStepsRunning) clearTimeout(this.rotateActiveStepsRunning);
  }

  render(){
    var {activeStep} = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <h2 className="largest">How it Works</h2>
            <p className="subMenu">No complicated training or equipment required.</p>
          </div>
        </div>
        <div className="row" 
          onMouseEnter={()=>{clearTimeout(this.rotateActiveStepsRunning)}} 
          onMouseLeave={()=>{
          this.rotateActiveStepsRunning = setTimeout(() => {
            this.rotateActiveSteps();
          }, 1000);
        }}>
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div className="stepWrapper" onMouseEnter={()=>{clearTimeout(this.rotateActiveStepsRunning); this.setState({activeStep: 0}); }}>
            <div className={`steps ${activeStep == 0 ? 'active' : ''}`}>
              <div className="icon">
                <i className="fas fa-teeth-open"/>
              </div>
              <div className="text">
                <h3>Step 1</h3>
                <h4>Create Intraoral Impression</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div className="stepWrapper" onMouseEnter={()=>{clearTimeout(this.rotateActiveStepsRunning); this.setState({activeStep: 1});}}>
            <div className={`steps ${activeStep == 1 ? 'active' : ''}`}>
              <div className="icon">
                <i className="fas fa-tasks"/>
              </div>
              <div className="text">
                <h3>Step 2</h3>
                <h4>Open New Order</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div className="stepWrapper" onMouseEnter={()=>{clearTimeout(this.rotateActiveStepsRunning); this.setState({activeStep: 2});}}>
            <div className={`steps ${activeStep == 2 ? 'active' : ''}`}>
              <div className="icon">
                <i className="fas fa-truck"/>
              </div>
              <div className="text">
                <h3>Step 3</h3>
                <h4>Request Pickup</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div className="stepWrapper" onMouseEnter={()=>{clearTimeout(this.rotateActiveStepsRunning); this.setState({activeStep: 3});}}>
            <div className={`steps ${activeStep == 3 ? 'active' : ''}`}>
              <div className="icon">
                <i className="fas fa-mobile-alt"/>
              </div>
              <div className="text">
                <h3>All Done</h3>
                <h4>Track Order</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
