import React from 'react';
// import logo from '../logo.svg';
////import '../../css/backend.css';
/*
Pull all existing addresss
Let them choose one by clicking
Let them add a new one button
add new address dialog
buttons cancel, back, next
Validate next for when address method is choosen
*/


import EditShipping from '../../editOrder/shipping';
class TabChooseAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.curOrderObject
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave(newOrder){
    this.setState({
      order: newOrder
    });
    this.props.onUpdateObject(newOrder);
  }

  render(){
    var {showLogin, order={}, currentLocation} = this.state;
    return (
      <React.Fragment>
        <div id="PageContent">
          <EditShipping
            order={order}
            onChange={this.onSave}
          />
        </div>
        <div id="NavButtons">
          <div className="leftButtons">
            <button className="button cancleButton" onClick={this.props.onCancel}>Cancel</button>
          </div>
          <div className="rightButtons">
            <button className={`button prevButton ${!this.props.currentPage ? 'disabled' : ''}`} onClick={this.props.onPrevPage}>Previous</button>
            <button className={`button nextButton ${this.props.disabled ? 'disabled' : ''}`} onClick={this.props.onNextPage}>Next</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default TabChooseAddress;

