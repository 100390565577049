/*
	this.BusinessClass = new BusinessClass();
*/
import React from 'react';
import {MetaDataClass, UsersClass, EmailClass, PendingClass} from 'global-api-helper';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class BusinessClass {
  constructor(props) {
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
 	}

	Get(businessID){
		if(!businessID) businessID = window.GlobalUtil.getCurrentBusinessID();
		return new Promise((resolve, reject) => {
			if(!businessID){
				window.GlobalUtil.apiFail("jxcetykfa5n", "Missing Business ID");
				return reject("Missing Business ID");
			}
			var Pointer = this.Firestore.collection("business").doc(businessID);
			Pointer.get()
	    .then((doc) => {
			  resolve(doc.data());
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("fer3bji91bo", info);
				reject(info);
			});
		});
	}
	
	GetAll(){
		return new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("business");
			Pointer.get()
	    .then((querySnapshot) => {
	    	let index = 0;
	    	this.fullArray = [];
	    	querySnapshot.forEach((doc) => {
	    		this.fullArray.push(doc.data());
	    		index++;
			  });
			  return index;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("9jfnseeil06", info);
				reject(info);
			});
		});
	}

	// async SaveToClass(initalObj){
	// 	await Object.keys(initalObj).forEach(key=>{
 //  		this[key] = initalObj[key];
 //  	});
	// }


	toArray(){
		return (this.fullArray ? this.fullArray : []);
	}
	
	Delete(id){
		return new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("business").doc(id);
			Pointer.update({active: 0})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("7requi0ucms", info);
				reject(info);
			});
		});
	}

	TrueDelete(id){
		return new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("business").doc(id);
			Pointer.delete()
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("7requi0ucms", info);
				reject(info);
			});
		});
	}
	
	cleanBusinessForUpload(updatedObj){ //THIS SHOULD APPLY TO ("business") IN THE DATABASE AND REMOVE ANY UNNEEDED STUFF FROM window.Session.Business
		delete updatedObj.users;
		delete updatedObj.Firestore;
		delete updatedObj.ValidateAllowed;
		delete updatedObj.UsersClass;
		delete updatedObj.BusinessClass;
		updatedObj = JSON.parse(JSON.stringify(updatedObj))
		return updatedObj;
	}

	update(updatedObj){
		return new Promise((resolve, reject) => {
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser || !updatedObj.id){
				window.GlobalUtil.apiFail("ggw859cqm5g", "Missing Business ID");
				return reject("Missing Business ID");
			} 
			updatedObj = this.cleanBusinessForUpload(updatedObj);
			var Pointer = this.Firestore.collection("business").doc(updatedObj.id);
			Pointer.update(updatedObj)
			.then(obj=>{
				window.Session.Business.Set(updatedObj, true);
			  window.GlobalUtil.processEvent("businessChanged", window.Session.Business);
				resolve(obj);
			})
			.catch(obj=>{
				window.GlobalUtil.apiFail("36iere93cxe", obj);
				reject(obj);
			});
		});
	}



	CreateNewBusiness(input){
		var AddBusinessToMetaDataList = (newId)=>{
			var MetaDataClassNew = new MetaDataClass()
			MetaDataClassNew.Get("business")
			.then(newClass=>{
				MetaDataClassNew.data.allIds.push(newId);
				MetaDataClassNew.Update(MetaDataClassNew.data)
				.then((success)=>{
					console.log("Success: New MetaData Added");
				})
				.catch((error)=>{
					window.GlobalUtil.apiFail("b044eij7276", error);
				});
			})
			.catch((error)=>{
				window.GlobalUtil.apiFail("b044eij7276", error);
			});
		}



		return new Promise((resolve, reject) => {
			var {email, password, companyName='', name=''} = input;
			window.firebase.auth().createUserWithEmailAndPassword(email, password)
	    .then((success) => {
	    	var Pointer = this.Firestore.collection("business").doc(success.user.uid);
	    	Pointer.set({
	        'id': success.user.uid,
	        'email': success.user.email,
	        'companyName': companyName,
	        'ordersByStatus': {
						"active": 0,
						"complete": 0,
						"pending": 0,
						"reviewing": 0,
						"shipping": 0
					},
					'ordersByMonth': {},
					'ordersByWeek': {},
					'totalOrders': 0,
					'tier': 'basic',
					'discount': 0,
					'hasInvoiceLocations': false,
					'hasShippingLocations': false,
					'active': 1
	      })
	      .then((docRef) => {
	        var defaultUser = {"name": name, "type": "doctor"};
	        var UsersClassNew = new UsersClass(); //USE THE USER CLASS TO SAVE A NEW USER. ANY CHANGES TO DEFAULT NEW USER SHOULD BE DONE IN USER CLASS NOT HERE
	        UsersClassNew.Save({userID: success.user.uid, newObject: defaultUser}) 
	        .then((success)=>{
	        	window.toastr.success('New user added', 'SUCCESS');
	        	resolve(success);
	        })
	        .catch((error)=>{
	        	window.GlobalUtil.apiFail("g5epxl2xwzu", error);
	        	reject(error);
	        });
	      })
	      .then(()=>{
	      	AddBusinessToMetaDataList(success.user.uid); //ADD BUSINESS NAME TO METADATA

	      	var EmailClassNew = new EmailClass(); //SEND A CONFIRM YOUR EMAIL ADDRESS TO THEIR EMAIL ADDRESS
	      	EmailClassNew.SendWelcome(email, name, companyName, success.user.uid);

	      	var PendingClassNew = new PendingClass(); //THIS SETS THEIR EMAIL AS NOT CONFIRMED. 
			    PendingClassNew.update({
			      id: success.user.uid,
			      emailConfirmation: false
			    }).then(()=>{}).catch(()=>{}); //DONE SETTING AS NOT CONFRIMED

			    
	      })
	      .catch((error) => {
	          window.GlobalUtil.apiFail("b044eij7w88", error);
	          window.toastr.error('User could not be added to database', 'API ERROR');
	          reject(success.user);
	      }); 
	    })
	    .catch((error) => {
	      window.GlobalUtil.apiFail("b044eij7w88", error);
	      window.toastr.error('User could not be created', 'API ERROR');
	      // Handle Errors here.
	      var errorCode = error.code;
	      var errorMessage = error.message;
	      reject();
	    });
	  });
	}

	
	setOrdersLastMonth(){
		/*
			GET ALL BUSINESSES
			GET LAST MONTH DATE
			FOR EACH THAT HAVE VALUES IN 
		
			ordersByStatus.complete
		*/
		var firstOfLastMonth = window.GlobalUtil.getFirstOfLastMonth();
		var firstOfThisMonth = window.GlobalUtil.getFirstOfMonth();
		this.GetAll()
		.then(snapShot=>{
			this.fullArray.map(obj=>{
				var lastMonthCompleted = obj.ordersByStatus.complete;
				if(!obj.ordersByMonth[firstOfLastMonth]) obj.ordersByMonth[firstOfLastMonth] = lastMonthCompleted;
				obj.ordersByStatus.complete = 0;
				obj.ordersByMonth[firstOfThisMonth] = 0;
				this.update(obj);
			})
		})
	}

	SubscribeBusiness(businessID, onChange=()=>{}){
		if(!businessID) businessID = window.GlobalUtil.getCurrentBusinessID();
		if(!businessID) return;
		return this.Firestore.collection("business").doc(businessID).onSnapshot((doc) => {
      onChange(doc.data());
    });
	}


}
