const GetFiltered = function(PointerFunction, uidArray, input){
	var {filters=[], orderBy=[], limit, startAfter} = input;
	return new Promise((resolve, reject)=>{
		var isValidUser = this.ValidateAllowed();
		if(!isValidUser){
			window.GlobalUtil.apiFail(uidArray[0], "Cannot Get Forms");
			return reject(false);
		}
		var AddedOrderBy = {};
		var Pointer = PointerFunction();
		filters.map((whr, index)=>{
			if(whr.key === 'where' && whr.value.length > 0){
				if(whr.value.length == 1) Pointer = Pointer.where(whr.value[0]);
				if(whr.value.length == 2) Pointer = Pointer.where(whr.value[0], whr.value[1]);
				if(whr.value.length == 3){
					Pointer = Pointer.where(whr.value[0], whr.value[1], whr.value[2]);

					if(whr.value[1] == ">" || whr.value[1] == "<" || whr.value[1] == ">=" || whr.value[1] == "<="){ //IF FILTER EXST THEN THE SORT BY MUST EXIST WITH IT
						if((!orderBy || orderBy[0] !== whr.value[0]) && !AddedOrderBy[whr.value[0]]){
							Pointer = Pointer.orderBy(whr.value[0]);
							AddedOrderBy[whr.value[0]] = true;
						} 	
					}
				}
			}
		})

		if(limit){
			Pointer = Pointer.limit(limit);
		}

		if(orderBy.length > 0){
			if(orderBy.length == 1) Pointer = Pointer.orderBy(orderBy[0]);
			if(orderBy.length == 2) Pointer = Pointer.orderBy(orderBy[0], orderBy[1]);
		}

		if(startAfter){
			Pointer = Pointer.startAfter(startAfter);
		}

		   		  
    Pointer.get()
    .then((querySnapshot) => {
			if(input.limit) this.GetPagination(querySnapshot, input); //Add pagination if we have a certain number per page
    	var cleanItems = [];
      querySnapshot.forEach(function(doc) {
        cleanItems.push(doc.data());
      });
      return cleanItems;
    })
    .then((cleanItems) => {
    	this.SaveToClass(cleanItems)
    	.then(()=>{
	      resolve(this);
    	});
    })
    .catch((error) => {
    	window.GlobalUtil.apiFail(uidArray[1], error);
			reject(error);
    });
	});
}



const GetPagination = function(querySnapshot, input){
	//USED IN /Users/danielezaldivar/Sites/ready-crown/src/backend/components/tabsTables/index.js
	var lastQuery = querySnapshot.docs[querySnapshot.docs.length-1];
	var queryFirst = querySnapshot.docs[querySnapshot.docs[0]];
	if((input.limit && querySnapshot.docs.length < input.limit) || querySnapshot.docs.length == 0) this.disableNext = true;
	else this.disableNext = false;
	this.getNext = () => { //SET UP GET NEXT FUNCTION
		var newInput = {...input};
		newInput.startAfter = lastQuery;
		if(!this.page) this.page = 0;
		this.page = this.page+1;
		if(!this.prevQueryStart) this.prevQueryStart = [];
		this.prevQueryStart.push(queryFirst);
		return this.GetFiltered(newInput);
	};
	this.getPrev = () => { //SET UP GET NEXT FUNCTION
		if(!this.prevQueryStart || this.prevQueryStart.length < 1) return;
		var newInput = {...input};
		newInput.startAfter = this.prevQueryStart.pop();
		this.page = this.page-1;
		return this.GetFiltered(newInput);
	};
}


const	SaveToClass = async function(initalArray){
	if(!initalArray || initalArray.length < 1) return;
	this.data = initalArray.slice();
	return true;
}

const	toArray = function(){
	return this.data;
}


export {
	GetFiltered,
	GetPagination,
	SaveToClass,
	toArray
};