/*
THIS CLASS SHOULD HANDLE ANY API CALLS FOR TABLE LIST
*/
import {GetFiltered, GetPagination, SaveToClass, toArray} from './helper-get-filtered';
import React from 'react';
import {OrdersClass} from 'global-api-helper';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class TableListOrdersClass {
  constructor(props) {
  	this.Firestore = new window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  	this.data = [];
  	this.SaveToClass = SaveToClass.bind(this);
		this.toArray = toArray.bind(this);
  	this.page = 0;
  	this.GetPagination = GetPagination.bind(this);
  	this.GetFiltered = GetFiltered.bind(this, ()=>this.Firestore.collection("orders"), ["vj387kl424","ggy1n29k06u"]);
  }

	Get(input){
		var {admin=false, trueStatus, status, orderBy} = input;
		var isValidUser = this.ValidateAllowed();
		var BusinessID = window.GlobalUtil.getCurrentBusinessID();
		if(!BusinessID || !isValidUser) return;
		return new Promise((resolve, reject) => {
			var Pointer = this.Firestore.collection("orders");
	    if(trueStatus) Pointer = Pointer.where("trueStatus", "==", trueStatus);
	    if(status) Pointer = Pointer.where("status", "==", status);
	    if(!admin) Pointer = Pointer.where("business", "==", BusinessID);

	    if(orderBy && orderBy.length > 0){
	    	if(orderBy[1]) Pointer = Pointer.orderBy(orderBy[0], orderBy[1]);
	    	else Pointer.orderBy(orderBy[0]);
	    } else {
				Pointer = Pointer.orderBy("date")
	    }
			//Pointer = Pointer.where("completedDate", "<=", Date.now()).orderBy("completedDate")
	        	
			Pointer.get()
	    .then((querySnapshot) => {
	    	let index = 0;
	    	this.data = [];
	    	querySnapshot.forEach((doc) => {
	    		this.data.push(doc.data());
	    		index++;
			  });

			  return index;
			})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				reject(info);
			  console.log(info);
			});
		});
	}


	Delete(id, callbackSuccess=()=>{}, callbackFail=()=>{}){
		var isValidUser = this.ValidateAllowed();
		if(!id || !isValidUser) return;
		var OrdersClassNew = new OrdersClass();
		OrdersClassNew.Delete(id, callbackSuccess, callbackFail);
	}

	
	//ADD AS NEW IF DOES NOT EXIST OR UPDATE CURRENT OBJ WITH NEW DATA IF EXIST
	Update(newObject){
		var isValidUser = this.ValidateAllowed();
		var BusinessID = window.GlobalUtil.getCurrentBusinessID();
		if(!BusinessID || !isValidUser) return;
		return new Promise((resolve, reject) => {
			if(!newObject.business) newObject.business = BusinessID;
			var Pointer = this.Firestore.collection("orders");
			if(!newObject.id){ //update
				var PointerTemp = Pointer.doc();
				newObject.id = PointerTemp.id;
				PointerTemp.set({id: newObject.id});
			}

			if(!newObject.date) newObject.date = window.GlobalUtil.getTimeNow();
			    	
			if(newObject.status == "complete") newObject.trueStatus = "complete";
			else newObject.trueStatus = "active";

			Pointer.doc(newObject.id).update(newObject)
				.then(obj=>{
					resolve("success");
				})
				.catch(obj=>{
					reject(obj);
				});
		});
	}

	//REMOVE EXISTING DATA TO NEW DATA IF EXSITS
	Set(newObject){
		var isValidUser = this.ValidateAllowed();
		var BusinessID = window.GlobalUtil.getCurrentBusinessID();
		if(!BusinessID || !isValidUser) return;
		return new Promise((resolve, reject) => {
			newObject.business = BusinessID
			if(!newObject.date) newObject.date = window.GlobalUtil.getTimeNow();
			var Pointer = this.Firestore.collection("orders");
			if(!newObject.id){ //update
				var PointerTemp = Pointer.doc();
				newObject.id = PointerTemp.id;
				PointerTemp.set({id: newObject.id});
			}

			Pointer.doc(newObject.id).set(newObject)
				.then(obj=>{
					resolve("success");
				})
				.catch(obj=>{
					reject(obj);
				});
		});
	}



}
