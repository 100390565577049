import React from 'react';
//import '../../css/backend.css';
import TableList from '../table_list';
import Filter from './filter';


class TableLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onChangeOrderBy = this.onChangeOrderBy.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  onPrev(){
    if(!this.props.CurrentClass.page) return;
    this.props.onPaginate(this.props.CurrentClass.getPrev);
  }

  onNext(){
    if(this.props.CurrentClass.disableNext) return;
    this.props.onPaginate(this.props.CurrentClass.getNext);
  }

  onChangeOrderBy(orderByValue){
    var {orderBy} = this.props;
    if(orderBy[0] === orderByValue){
      if(orderBy[1] === "desc"){
        orderBy[1] = "asc";
      } else {
        orderBy[1] = "desc";
      }
    } else {
      orderBy[0] = orderByValue;
      orderBy[1] = "desc";
    }

    this.props.onChangeOrderBy(orderBy);
  }

  render(){
    var {actionButton, loading, title, rows, headers, filters, defaultFilters, filtersListOfFields=[], orderBy, CurrentClass={}} = this.props;
    var disablePrev = !CurrentClass.page;
    var disableNext = CurrentClass.disableNext;
    if(loading) {
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent 
        />
      )
    }
    var FilterComponent = (
      filtersListOfFields.length > 0
      ? <Filter 
          filters={filters} 
          defaultFilters={defaultFilters}
          filtersListOfFields={filtersListOfFields}
          onChangeFilters={this.props.onChangeFilters} 
        /> 
      : null
    );
    return (
      <React.Fragment>
        <h5 className="panelTitle panelTitleWithButtons">
          <span className="text">{title}</span>
          {FilterComponent}
          {actionButton}
        </h5>
        <div className="scrolableMaxHeight">
          {
            rows.length > 0
            ? <TableList
                rows={rows}
                headers={headers}
                onChangeOrderBy={this.onChangeOrderBy}
                orderBy={orderBy}
              />
            : <div className="nonFoundMessage">
                <div className="header">No items found</div>
              </div>
          }
          {
            (disablePrev && disableNext)
            ? null
            : <div className="navButtons">
                <div className="navButtonZone navButonRight">
                  <button onClick={this.onPrev} disabled={disablePrev} className={`navButton navButtonPrevious ${disablePrev ? 'disabled' : ''}`}>Previous</button>
                </div>
                <div className="navButtonZone navButonLeft">
                  <button onClick={this.onNext} disabled={disableNext} className={`navButton navButtonNext ${disableNext ? 'disabled' : ''}`}>Next</button>
                </div>
              </div>
          }
        </div>
      </React.Fragment>
    );
  }
}
export default TableLists;
