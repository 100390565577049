import React from 'react';
import Wrapper from '../components/wrapper';


class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
  }
  componentDidMount(){
  }

  render(){
    var {activeStep=0} = this.state;

    return (
      <Wrapper>
        <div id="termsPage">
          <div id="body">
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                    <div className="contactSection">
                      <h5>FAQ Quck Nav</h5>
                      <ul>
                        <li>
                          <a href="/faq#Question1">Question 1</a>
                        </li>
                        <li>
                          <a href="/faq#Question2">Question 2</a>
                        </li>
                        <li>
                          <a href="/faq#Question3">Question 3</a>
                        </li>
                        <li>
                          <a href="/faq#Question4">Question 4</a>
                        </li>
                        <li>
                          <a href="/faq#Question5">Question 5</a>
                        </li>
                        <li>
                          <a href="/faq#Question6">Question 6</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                    <div className="centerText">
                      <div className="textQuestion">
                        <h1>Frequently Asked Questions</h1>
                        <h4 id="Question1">Question 1</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin in nulla sed fermentum. Praesent maximus, magna ac viverra condimentum, eros nunc dignissim neque, vel volutpat eros turpis eget dolor. Fusce ac est sed purus congue mollis sit amet ac ligula. Etiam vitae varius urna. Proin faucibus vitae mauris at convallis. Nulla sagittis, nunc et euismod sagittis, dolor massa finibus sem, id condimentum risus est quis augue. Cras ut tortor et dui pharetra porta eu at ipsum. Nulla suscipit luctus lobortis. Aenean nec elit ac est bibendum convallis. Donec sollicitudin tortor bibendum tellus luctus tempor. Quisque facilisis ex at elementum dapibus. Nulla vel odio purus. Mauris sit amet auctor enim. Ut vulputate, nisl at hendrerit interdum, risus mi lacinia neque, tempus facilisis purus tortor ut tortor. Nulla ultricies ornare diam consequat congue.
                        </p>
                        <h4 id="Question2">Question 2</h4>
                        <p>Quisque dapibus ipsum vel eros tincidunt vulputate. Suspendisse sed diam egestas, auctor elit id, iaculis libero. Aliquam feugiat purus justo, a rutrum ante congue ut. Curabitur congue sem consequat aliquam semper. Nunc commodo eros nec felis molestie rhoncus. Nullam sem metus, dictum ut lectus in, ultricies molestie risus. Sed porta vel risus mollis commodo. Fusce rhoncus rutrum odio. Mauris tincidunt quam at libero venenatis feugiat vitae a dui.</p>
                        <h4 id="Question3">Question 3</h4>
                        <p>Pellentesque et orci ut arcu volutpat tincidunt. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent sagittis lacus quis pellentesque fringilla. Cras ut dui sit amet diam porta lobortis. Nunc magna mauris, ultrices quis porttitor eget, euismod et justo. Etiam mattis tortor blandit, posuere justo vel, tempus lorem. Sed lacinia molestie augue.</p>
                        <h4 id="Question4">Question 4</h4>
                        <p>Duis placerat nisi eget nulla mollis dapibus. Nunc massa sapien, vehicula sed placerat vel, volutpat at lorem. Aliquam erat volutpat. Pellentesque aliquet erat a mi tristique, id molestie elit egestas. Donec a tempus mauris. Donec nec pretium metus, non cursus eros. Sed ut tortor tincidunt, dictum magna vel, sagittis lectus. Nulla gravida lobortis leo ac molestie. Donec scelerisque ultricies venenatis. Suspendisse quis pretium purus, a vulputate velit.</p>
                        <h4 id="Question5">Question 5</h4>
                        <p>Donec pulvinar ante sem, ac imperdiet magna tincidunt ut. Nam porta vestibulum mauris, id dapibus neque tempor sit amet. Phasellus porttitor, tellus in sollicitudin hendrerit, nibh mauris gravida lacus, eu posuere tellus turpis quis felis. Sed lobortis nisl in lobortis suscipit. Integer vitae tortor urna. Duis nec magna urna. Nullam ultricies pellentesque odio. Sed eget odio tortor. Sed aliquet ac erat quis rhoncus. Nam malesuada, purus vel rutrum commodo, lectus nisi consequat sapien, et convallis mauris enim vitae nisl. Sed vehicula turpis massa, quis mollis quam consequat vel. Vivamus vel nisi magna. Etiam aliquam interdum orci a efficitur. Suspendisse viverra rutrum ipsum vitae vestibulum.</p>
                        <h4 id="Question6">Question 6</h4>
                        <p>Pellentesque accumsan sed dui ut facilisis. Integer quis ante facilisis, volutpat dolor non, scelerisque ipsum. Cras mollis id mauris ut viverra. Nullam orci lectus, vestibulum nec risus vel, egestas convallis felis. Curabitur pulvinar arcu sed turpis feugiat pretium eu vel risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sit amet nunc non velit maximus ullamcorper. Suspendisse vulputate ligula sit amet quam malesuada, id placerat mauris elementum. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default FAQ;
