import React from 'react';

class ListTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: window.Session.Business,
      row: [],
      loading: true,
      orderBy: ["date", "desc"],
      limit: 15,
      defaultFilters: [ //THIS IS WHAT IT WILL REVERT TO WHEN FILTERS ARE REMOVED
      ],
      filters: [
      ],
      filtersListOfFields: [
      ]
    };
    this.onApiLoaded = this.onApiLoaded.bind(this);
    this.createHeaders = this.createHeaders.bind(this);
    this.onCancel = this.onCancel.bind(this);

  }

  
  onApiLoaded(asyncLoadedFunction){
    asyncLoadedFunction()
    .then(NewTableListOrdersClass=>{
      this.createRows(this.ListClass.toArray());
    })
    .catch(error=>{
      console.log("error");
      console.log(error,"\n\n");
      this.setState({loading: false});
    });
  }

  createHeaders(header, onAdmin){
    var {orderBy, ascDesc} = this.state;
    if(this.props.admin){
      header = header.concat(onAdmin);
    };
    return header;
  }

  onCancel(arrayToRemove=[]){
    arrayToRemove.map((whatToCancel, index)=>{
      this.state[whatToCancel] = undefined;
    })
    this.setState({...this.state});
  }
}


export default ListTemplate;