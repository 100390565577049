import React from 'react';
import Wrapper from '../components/wrapper';
import {
  LineChart, BarChart, Bar, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import OrderTracker from '../components/order_tracker';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  chart1DataMaker(){
    var {ordersByWeek={}} = window.Session.Business;

    //IF NOTHING THEN MAKE DUMMY
    if(!ordersByWeek || Object.keys(ordersByWeek).length === 0){
      new Array(6).fill(1).map((object, index)=>{
        var newDT = new Date();
        newDT.setDate(newDT.getDate()-(7*index))
        let weekNumber = window.GlobalUtil.getWeekNumber(newDT);
        ordersByWeek[weekNumber] = 0;
      }) 
    }


    const FullData = Object.keys(ordersByWeek).slice(-6).map((weekNumber, index)=>{
      return {
        name: window.GlobalUtil.weekNumberToDate(weekNumber).formatDate('n/d/y'), 
        Orders: ordersByWeek[weekNumber],
      }    
    });

    
    //IF NOT ENOUGH THEN PUSH ZEROS BEFORE
    // if(FullData.length < 6){ //IF NOTHING THEN MAKE DUMMY
    //   new Array(6-FullData.length).fill(1).map((object, index)=>{
    //     let lastDate = new Date(FullData[0].name)
    //     lastDate.setDate(lastDate.getDate()-(7*(index+1)))
    //     FullData.unshift({
    //       name: new Date(lastDate).formatDate('n/d/y'), 
    //       Orders: 0
    //     });
    //   }) 
    // }
          
    return FullData;
  }



  chart2DataMaker(){
    var {ordersByMonth={}, ordersByStatus={}, ordersByMonth={}} = window.Session.Business;

    //IF NOTHING THEN MAKE DUMMY
    if(!ordersByMonth || Object.keys(ordersByMonth).length === 0){
      new Array(6).fill(1).map((object, index)=>{
        var newDT = new Date();
        newDT.setMonth(newDT.getMonth()-index)
        let weekNumber = window.GlobalUtil.getFirstOfMonth(newDT);
        ordersByMonth[weekNumber] = 0;
      }) 
    }


    const FullData = Object.keys(ordersByMonth).slice(-6).map((monthNumber, index)=>{
      let date = new Date(Number(monthNumber));
      return {
        name: date.getMonthName().slice(0,3), 
        Orders: ordersByMonth[monthNumber],
      }    
    });

          
    //IF NOT ENOUGH THEN PUSH ZEROS BEFORE
    // if(FullData.length < 6){ //IF NOTHING THEN MAKE DUMMY
    //   new Array(6-FullData.length).fill(1).map((object, index)=>{
    //     let lastDate = new Date(Number(Object.keys(ordersByMonth)[0]))
    //     lastDate.setMonth(lastDate.getMonth()-(index+1))
    //     FullData.unshift({
    //       name: new Date(lastDate).getMonthName().slice(0,3), 
    //       Orders: 0
    //     });
    //   }) 
    // }
          
    return FullData;
  }

  render(){
    var {ordersByMonth={}, ordersByStatus={}, ordersByWeek={}} = window.Session.Business;
    var {shipping=0, active=0, reviewing=0} = ordersByStatus;
    var ordersThisMonth = (ordersByMonth[window.GlobalUtil.getFirstOfMonth()] ? ordersByMonth[window.GlobalUtil.getFirstOfMonth()] : 0);
    var ordersThisWeek = (ordersByWeek[window.GlobalUtil.getWeekNumber()] ? ordersByWeek[window.GlobalUtil.getWeekNumber()] : 0);
    if(!ordersThisMonth) ordersThisMonth = 0;
    return (
      <React.Fragment>
        <div className="homePageOrderTracker">
          <OrderTracker />
        </div>

        <div className="row">
          <div className="col">
            <h5 className="panelTitle">Quick View</h5>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm-12 col-lg-4">
            <div className="panelWrapper">
              <div className="panelItem">
                <div className="textAndNumbers">
                  <div className="numberArea">
                    <div className="icon">
                      <i className="fas fa-calendar-alt iconColorColor1">
                        <div className="number">{ordersThisMonth}</div>
                      </i>
                    </div>
                  </div>
                  <div className="textAreas">
                    <h5 className="panelInnerTitle">Orders this Month</h5>
                    <p className="">This includes all processing, in route, and those you've already received.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-sm-12 col-lg-4">
            <div className="panelWrapper">
              <div className="panelItem">
                <div className="textAndNumbers">
                  <div className="numberArea">
                    <div className="icon">
                      <i className="fas fa-calendar-week iconColorColor2">
                        <div className="number">{ordersThisWeek}</div>
                      </i>
                    </div>
                  </div>
                  <div className="textAreas">
                    <h5 className="panelInnerTitle">Orders this Week</h5>
                    <p className="">This includes all processing, in route, and those you've already received.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-sm-12 col-lg-4">
            <div className="panelWrapper">
              <div className="panelItem">
                <div className="textAndNumbers">
                  <div className="numberArea">
                    <div className="icon">
                      <i className="fas fa-truck iconColorColor4">
                        <div className="number">{shipping}</div>
                      </i>
                    </div>
                  </div>
                  <div className="textAreas">
                    <h5 className="panelInnerTitle">Orders Shipping</h5>
                    <p className="">These are either in transit or soon will be. You will see these today.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm-12 col-lg-6">
            <h5 className="panelTitle">Orders by Week</h5>
            <div className="panelWrapper">
              <div className="panelItem">
                <div className="charts">
                  <ResponsiveContainer width='100%' aspect={4.0/2.0}>
                    <BarChart
                      width={500}
                      height={400}
                      data={this.chart1DataMaker()}
                      margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                      }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Orders" fill="#0097e9" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-sm-12 col-lg-6">
            <h5 className="panelTitle">Orders by Month</h5>
            <div className="panelWrapper">
              <div className="panelItem">
                <div className="charts">
                  <ResponsiveContainer width='100%' aspect={4.0/2.0}>
                    <BarChart
                      width={500}
                      height={400}
                      data={this.chart2DataMaker()}
                      margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                      }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Orders" fill="#b337cb" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;


