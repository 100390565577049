import React from 'react';
import '../css/settings_dialog.css';
const SettingsDialogWrapper = (props) => {
  var DialogWrapper = window.GlobalComp.DialogWrapper;
  return (
    <DialogWrapper
      {...props}
      clickToCloseOverlay={true}
      renderToBody={true}
      containerId={"SettingsDialog"}
      darkBackground={true}
    >
      {props.children}
    </DialogWrapper>
  );
}



export default SettingsDialogWrapper;
