import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/order_summary_dialog.css';
import '../../css/new_order_dialog.css';

import SettingsDialogWrapper from '../settings_dialog_wrapper';
import {OrdersClass} from 'global-api-helper';
/*

*/


class ReviewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: this.props.currentObject,
      loading: false,
      showFailMessage: false,
      whatToEdit: undefined,
      isValid: true
    };
    this.onUpdateOrder = this.onUpdateOrder.bind(this);
    this.currentReviews = (this.props.currentObject.review ? {...this.props.currentObject.review} : {});
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.OrdersClass = new OrdersClass();
  }

  async onUpdateOrder(){
    var {currentObject} = this.state;
    const onSuccess = (message)=>{
      this.setState({loading: false});
      this.props.onSave();
    };
    const onFail = (message)=>{
      this.setState({loading: false, showFailMessage: true});
      this.props.onFail(message);
    };
    const onStart = () => {};

    this.setState({loading: true});
    currentObject.review = this.currentReviews;      
    currentObject.date = window.GlobalUtil.getTimeNow();      

    await window.GlobalUtil.delay(500);
    await this.OrdersClass.Save(currentObject, onStart, onSuccess, onFail);
  }


  render(){
    var {currentObject, isValid, loading, showFailMessage, whatToEdit} = this.state;
    var states = window.GlobalUtil.states();
    if(showFailMessage) isValid = false;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    const FormBuilder = this.FormBuilder;

    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="600"
        height="600"
        onCancel={this.props.onCancel}
        onSave={this.onUpdateOrder}
        acceptButtonEnabled={isValid}
        clickToCloseOverlay={false}
        zIndex={100}
      >
      <React.Fragment>
        {
          !loading && !showFailMessage &&
          <div className="ReviewOrder">
            <FormBuilder
              initialValues={{...this.currentReviews}}
              listOfFields={[
                {
                  title: <span className="">Satisfaction: <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "stars",
                  name: "satisfaction",
                  placeholder: 0,
                  required: true
                },
                {
                  title: <span className="">Delivery Speed: </span>,
                  type: "stars",
                  name: "speed",
                  placeholder: 0,
                  required: true
                },
                {
                  title: <span className="">Additional Notes:</span>,
                  type: "textArea",
                  name: "notes",
                  rows: 5,
                  placeholder: "Leave any additional notes for us here. This is where you would enter if the crowns are a bit large or barely too small."
                }
              ]}
              onChange={newObj => this.currentReviews = newObj}
              onValidate={(isValid)=>{this.setState({isValid: isValid})}}
            />
          </div>
        }
        {
          loading &&
          <LoadingComponent 
            Header={"Submitting Order"}
            Text={"Please Stand by"}
          />
        }
        {
          showFailMessage &&
          <div id="FailMessage">
            <div className="failMessageWrapper">
              <i className="far fa-surprise"></i>
              <h3>ERROR!</h3>
              <h4>Oh No, something went wrong!</h4>
              <p className="">I could be that the servers are too stressed right now, or we just had a problem receving your order.<br/>
              So please try again in a minute. We will work on fixing the problem soon.</p>
            </div>
          </div>
        }
      </React.Fragment>
    </SettingsDialogWrapper>
    )
  }
}






export default ReviewOrder;