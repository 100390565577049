import React from 'react';
import NumberFormat from 'react-number-format';
const InputText = (props)=>{
  var {name, title, value, placeholder=""} = props;
  var onChange = (e)=>{          
    props.onChange(e.target.value)
  }
  var inputRef = {};
  return(
    <div className="inputText">
     {  
        title && 
        <div className="inputTextTitle" onClick={()=>{if(inputRef) inputRef.focus()}}>{title}</div>
      } 
      <div className="inputTextInputWrapper">
        <input
          ref={e=>inputRef = e}
          type="text" 
          className="inputTextInput" 
          name={name} 
          value={value} 
          onChange={onChange} 
          placeholder={placeholder} 
        />
      </div>
    </div>
  )
}
export default InputText;