/*
	this.ProductsClass = new ProductsClass();
*/
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class ProductsClass {
  constructor(props) {
  	this.Firestore = new window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }

	Get(productID){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser  || !productID){
				window.GlobalUtil.apiFail("o7qslcmqlq", "Cannot Get Product Missing: productID");
				return reject(false);
			}
			
			var Pointer = this.Firestore.collection("products").doc(productID); 
	    Pointer.get()
	    .then((doc) => {
	      resolve(doc.data());
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("l6q90gjje4", error);
				reject(error);
	    });
		});
	}


	GetAll(){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser){
				window.GlobalUtil.apiFail("3v8x6c4sclg", "Cannot Get Products");
				return reject(false);
			}

			var Pointer = this.Firestore.collection("products"); 
	    Pointer.get()
	    .then((querySnapshot) => {
	    	var cleanItems = [];
	      querySnapshot.forEach(function(doc) {
	        cleanItems.push(doc.data());
	      });
	      return cleanItems;
	    })
	    .then((cleanItems) => {
	      resolve(cleanItems);
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("xta4yl0mqag", error);
				reject(error);
	    });
		});

	}

	Save(input){
		return this.AddOrUpdate(input);
	}

	Update(input){
		return this.AddOrUpdate(input);
	}

	AddOrUpdate(input){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			var Pointer = this.Firestore.collection("products");

			if(!isValidUser || !input){
				console.log("ProductsClass Save() or Update() FAILED: isValidUser");
				console.log(isValidUser,"\n\n");
				return reject(false);
			}

			if(!input.id){ //IF DOES NOT HAVE AN ID THEN WE WILL CREATE A NEW ONE AND PUSH THE ID VALUE INTO IT AND UPDATE THE OBJ BELOW
		    Pointer = Pointer.doc();
		    var newUserID = Pointer.id;
		    Pointer.set({id: newUserID});
	      input.id = newUserID;
	    } else {
	    	Pointer = Pointer.doc(input.id); //IF HAS AN ID THEN LETS POINT TO THAT SPOT AND UPDATE BELOW
	    }
			
			//UPDATE EXISTING OBJECTS HERE
			Pointer.update(input)
			.then(obj=>{
				console.log("Updated Producst");
      	if(!window.Session.Products) window.Session.Products = [];
      	var addedProduct = false;
				window.Session.Products = window.Session.Products.map((product, index)=>{
					if(product.id == input.id){
						addedProduct = true;
						return input;
					}
					return product;
				});
				if(!addedProduct) window.Session.Products.push(input);
        window.GlobalUtil.processEvent("productsChanged", window.Session.Products);
				resolve(window.Session.Products);
			})
			.catch(error=>{
				window.GlobalUtil.apiFail("mv39fceaccl", error);
				reject(error);
			});
			
		});
	}

	Delete(){

	}

}
