//THIS HUGE THING IS JUST TO CREATE INVOICES FOR EACH USER
/*
	CREATE LIST OF ALL FINISHED ORDERS INTO A NEW INVOICE FOR THIS COMPANY
		FUNCTION GET EACH ORDER THAT HAS NOT BEEN PAID FROM THIS DAY BACK FOR A CERTAIN USER
		FUNCTION PUT TOGETHER WITH this.whatToSaveFromInvoices()
		FUNCTION SAVE TO invoices IN FIREBASE
			THEN SHOULD ADD 1 TO THE USERS NUMBER OF UNPAID INVOICES


	GET INVOICE FOR SPECIFIC COMPANY

	SHOULD BE ABLE TO CHANGE INVOICES STATUS 
		CHANGE TO PAID
		ADD DATE PAID
		THEN GO TO THAT USER AND UPDATE THEIR NUMBERT OF UNPAID INVOICES -1
	
*/



import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class InvoicesClass {
  constructor(props) {
  	this.Firestore = window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }


	Get(){
		return new Promise((resolve, reject)=>{
			if(window.Session.Invoices && window.Session.Invoices.lastModified && window.GlobalUtil.compairTimes(window.Session.Invoices.lastModified) < 2){ //IF RECENTLY LOADED THEN USE CACHE
				return resolve(window.Session.Invoices.arrayWithAll);
			}

			var isValidUser = this.ValidateAllowed();
			var businessID = window.GlobalUtil.getCurrentBusinessID();
			if(!isValidUser || !businessID){
				console.log("InvoicesClass.Get() FAILED: isValidUser, businessID");
				console.log(isValidUser, businessID,"\n\n");
				return reject(false);
			}

	    var Pointer = this.Firestore.collection("invoices").where("business", "==", businessID).orderBy("date");
	    return Pointer.get()
	    .then((querySnapshot) => {
				var arrayWithAll = [];
				querySnapshot.forEach(function(doc) {
			  	arrayWithAll.push(doc.data()); 
			  });
			  return arrayWithAll;
	    })
	    .then((arrayWithAll) => {
	    	window.Session.Invoices = {
	    		arrayWithAll: arrayWithAll,
	    		lastModified: window.GlobalUtil.dateSeconds()
	    	};
				resolve(arrayWithAll);
	    })
	    .catch(failed=>{
	      console.log("failed", failed);
	      reject(failed);
	    }); 
		});
	}


	/*
		MODIFY TO ALLOW FILTER BY UNPAID
	*/
	GetAll(callbackSuccess=()=>{}, callbackFail=()=>{}){
		var isValidUser = this.ValidateAllowed();
		var businessID = window.GlobalUtil.getCurrentBusinessID();
		var isAdmin = window.GlobalUtil.IsSuperAdminUser();
		if(!isValidUser || !businessID || !isAdmin) return;

		var Pointer = this.Firestore.collection("invoices").orderBy("date");
    Pointer.get()
    .then((querySnapshot) => {
			var arrayWithAll = [];
			querySnapshot.forEach(function(doc) {
		  	arrayWithAll.push(doc.data());
		  });
		  return arrayWithAll;
    })
    .then((arrayWithAll) => {
			callbackSuccess(arrayWithAll);
    })
    .catch(failed=>{
      console.log("failed", failed);
      callbackFail(failed);
    }); 
	}


	
	GetAllPaid(){
		return new Promise((resolve, reject)=>{
			// if(!force && window.Session.InvoiceLocations && window.Session.InvoiceLocations.lastModified && window.GlobalUtil.compairTimes(window.Session.InvoiceLocations.lastModified) < 2){ //IF RECENTLY LOADED THEN USE CACHE
			// 	return resolve(window.Session.InvoiceLocations.arrayWithAll);
			// }
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser){
				console.log("InvoicesClass.GetAllPaid() FAILED: isValidUser");
				console.log(isValidUser,"\n\n");
				return reject(false);
			}
			var Pointer = this.Firestore.collection("invoices").where("status", "==", "paid").orderBy("date");
			Pointer.get()
	    .then((querySnapshot) => {
				var arrayWithAll = [];
				querySnapshot.forEach(function(doc) {
			  	arrayWithAll.push(doc.data());
			  });
			  return arrayWithAll;
	    })
			.then((arrayWithAll) => {
				// window.Session.InvoiceLocations = {
	   //  		arrayWithAll: arrayWithAll,
	   //  		lastModified: window.GlobalUtil.dateSeconds()
	   //  	};
				resolve(arrayWithAll);
	    })
			.catch(failed=>{
	      console.log("failed", failed);
	      reject(failed);
	    }); 
		});
	}

	GetAllUnpaid(){
		return new Promise((resolve, reject)=>{
			// if(!force && window.Session.InvoiceLocations && window.Session.InvoiceLocations.lastModified && window.GlobalUtil.compairTimes(window.Session.InvoiceLocations.lastModified) < 2){ //IF RECENTLY LOADED THEN USE CACHE
			// 	return resolve(window.Session.InvoiceLocations.arrayWithAll);
			// }
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser){
				console.log("InvoicesClass.GetAllUnpaid() FAILED: isValidUser");
				console.log(isValidUser,"\n\n");
				return reject(false);
			}
			var Pointer = this.Firestore.collection("invoices").where("status", "==", "unpaid").orderBy("date");
			Pointer.get()
	    .then((querySnapshot) => {
				var arrayWithAll = [];
				querySnapshot.forEach(function(doc) {
			  	arrayWithAll.push(doc.data());
			  });
			  return arrayWithAll;
	    })
			.then((arrayWithAll) => {
				// window.Session.InvoiceLocations = {
	   //  		arrayWithAll: arrayWithAll,
	   //  		lastModified: window.GlobalUtil.dateSeconds()
	   //  	};
	   	    	
				resolve(arrayWithAll);
	    })
			.catch(failed=>{
	      console.log("failed", failed);
	      reject(failed);
	    }); 
		});
	}


	Update(invoice){	
		return this.SaveInvoice(invoice);
	}

	Save(invoice){
		return this.SaveInvoice(invoice);
	}

	SaveInvoice(invoice){  //THIS UPDATES EXISTING ORDER OR CREATES A NEW ONE
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser || !invoice.business) return;
			    	
			if(invoice.id){ //update
				var Pointer = this.Firestore.collection("invoices").doc(invoice.id);
				Pointer.update(invoice)
				.then(obj=>{
					resolve(obj);
					//window.toastr.success('Update Order', 'Success');
				})
				.catch(obj=>{
					reject(obj);
				});
			} else { //Add new
				var Pointer = this.Firestore.collection("invoices");
				Pointer.add(invoice)
				.then(obj=>{
					Pointer.doc(obj.id).update({id: obj.id});
					resolve(obj);
				})
				.catch(obj=>{
					reject(obj);
				});
			}
		});

	}





	//EVERYTHING BELOW THIS HAS NOT BEEN TESTED OR NOT SURE IF NEEDED YET--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



	whatToSaveFromInvoices(fullObj){
		return({
			id: fullObj.id,
			date: fullObj.completedDate,
			total: fullObj.total,
			products: fullObj.products.map((product, index)=>{
				return({
					teeth: product.teeth,
					type: product.type,
					total: product.total,
					id: product.id,
					shipping: product.shipping,
				});
			}),
			productNumber: fullObj.products.length,
			patient: fullObj.patient
		});
	};


	GetOrders(business, startDate, endDate){
		return new Promise((resolve, reject)=>{
		console.log("business, startDate, endDate");
		console.log(business, startDate, endDate,"\n\n");
			var Pointer = this.Firestore.collection("orders").where("business", "==", business.business).where("completedDate", ">", startDate).where("completedDate", "<", endDate);
			Pointer.get().then(function(querySnapshot) {
				var arrayOfOrders = [];
			  querySnapshot.forEach(function(doc) {
			  	arrayOfOrders.push(doc.data());
			  });
			  return arrayOfOrders;
			})
			.then(function(arrayOfOrders) {
				resolve(arrayOfOrders);
			})
			.catch(info=>{
			  console.log(info);
			  reject(info);
			}); 
		});
	};

	CreateNewInvoice(invoiceObj){
		var newPointer = this.Firestore.collection("invoices").doc(); //CREATE ID TO USE LATER
	  var orderID = newPointer.id; //CREATE ID TO USE LATER
	  invoiceObj.id = orderID;
	  newPointer.set(window.GlobalUtil.copyObject(invoiceObj));

	  //We may want to change their tier here according to whatever conditions we decide later
	};

	CreateAll(){
		var today = new Date();
		var endDate = window.GlobalUtil.getFirstOfMonth();
		var startDate = window.GlobalUtil.getFirstOfLastMonth();
		var Pointer = this.Firestore.collection("business");
		Pointer.get()
		.then((querySnapshot)=>{
			var dataClean = [];
		  querySnapshot.forEach((doc)=>{
		  	var data = doc.data()
		  	dataClean.push({
		  		business: data.id,
					tier: data.tier,
					companyName: data.companyName,
					discount: data.discount,
		  	});
		  });
		  return dataClean;
		})
		.then(cleanData=>{
			cleanData.map((businessObj, index)=>{
				this.GetOrders(businessObj, startDate, endDate)
				.then((arrayOfOrders)=>{
					var subTotal = arrayOfOrders.reduce((accumulator, order)=>{
					  return accumulator+Number(order.total);
					}, 0);
					var invoiceObj = {
						orders: arrayOfOrders,
						subTotal: subTotal,
						date: today.getTime(),
						startDate: endDate, 
						endDate: startDate,
						business: businessObj.business,
						tier: businessObj.tier,
						companyName: businessObj.companyName,
						discount: businessObj.discount,
						status: (!subTotal ? "paid" : "unpaid"),
					};
					this.CreateNewInvoice(invoiceObj);
				})
				.catch(info=>{
				  console.log(info);
				}); 
			})
		})
		.catch(info=>{
		  console.log(info);
		}); 
	};

	SetAllOrdersThisMonthToZeroIfNone(){
		var Pointer = this.Firestore.collection("business");
		Pointer.get()
		.then(function(querySnapshot) {
			querySnapshot.forEach(function(doc) {
				var newObj = doc.data();
				if(!newObj.ordersByMonth[window.GlobalUtil.getFirstOfMonth()]){
					newObj.ordersByMonth[window.GlobalUtil.getFirstOfMonth()] = 0;
					doc.ref.update(newObj)
				}
			});
		})
		.catch(info=>{
			console.log(info);
		}); 
	}

	CreateInvoiceForBusiness(){
		var businessId = window.Session.Business.id;
		if(!businessId) return;
	}

	GetInvoices(){
		var businessId = window.Session.Business.id;
		if(!businessId) return;
	}

	UpdateInvoiceStatus(invoiceObj, newStatus){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
	}

	SendInvoiceReminderEmail(invoiceObj){
		if(!window.GlobalUtil.IsSuperAdminUser()) return;
	}


}

