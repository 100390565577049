import React from 'react';
// import logo from '../logo.svg';
import '../css/backend.css';
import SideMenu from './side_menu';
import Header from './header';
class BackWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      toggleMenu: false,
      loadingTooth: true
    };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    //this.onSelectBusiness = this.onSelectBusiness.bind(this);
          
  }

  componentDidMount(){
    
  }
  componentWillUnmount(){
  }

  toggleMobileMenu(){
    this.setState({toggleMenu: !this.state.toggleMenu})
  }

  render(){
    var {showLogin, toggleMenu, loadingTooth, showSelectBusiness, updateSideMenu} = this.state;
    return (
      <div id="BackendWrapper">
        <SideMenu 
          onLogout={this.props.onLogout}
          showSideMenu={toggleMenu}
          toggleMobileMenu={this.toggleMobileMenu}
          admin={this.props.admin}
          isVR={this.props.isVR}
        />
        {toggleMenu ? <div id="SideMenuUnderlay" onClick={this.toggleMobileMenu}></div> : null}
        <div id="Content">
          <Header onLogout={this.props.onLogout} onShowMenu={this.toggleMobileMenu} />
          <div id="BackendInner">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
export default BackWrapper;
