import React from 'react';
import Wrapper from '../components/wrapper';
import {NavLink, Link} from 'react-router-dom';


const Privacy = (props) => {
  return (
    <Wrapper>
      <div id="termsPage">
        <div id="body">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
                  <div className="contactSection">
                    <h5>Privacy Quick Nav</h5>
                    <ul className="sectionQuickNav">
                      <li>
                        <NavLink className={" "} to={{"pathname": "/privacy", "hash":"Section1"}}>ReadyCrowns Privacy Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section2"}}>Personal identification information</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section3"}}>Non-personal identification information</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section4"}}>Web browser cookies</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section5"}}>How we use collected information</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section6"}}>How we protect your information</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section7"}}>Sharing your personal information</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section8"}}>Advertising</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section9"}}>Compliance with children's online privacy protection act</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section10"}}>Changes to this privacy policy</NavLink>
                      </li>
                      <li>
                        <NavLink to={{"pathname": "/privacy", "hash":"Section11"}}>Contacting Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                  <div className="centerText">
                    <div className="textSection">
                      <h1>Privacy Policy</h1>
                      <h4 id="Section1">ReadyCrowns Privacy Policy</h4>
                      <p>This Privacy Policy governs the manner in which ReadyCrowns collects, uses, maintains and discloses information collected from users, hereinafter <strong id="userDef">[users]</strong>, of the <a href="www.readycrowns.com">www.readycrowns.com</a> website, hereinafter <strong id="websiteDef">[website]</strong>. By using ReadyCrowns website, you consent to data practices described in this statement.</p>

                      <h4 id="Section2">Personal identification information</h4>
                      <p>At ReadyCrowns, protecting our customers' private information is important to us. We may collect personal identification information from {<USER_DEF/>} in a variety of ways, including, but not limited to, when {<USER_DEF/>} visit our site, place an order, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. {<USER_DEF/>} may be asked for, as appropriate, name, email address, mailing address, phone number, credit card information, social security number, and other related information. {<USER_DEF/>} may, however, visit our {<WEBSITE_DEF/>} anonymously. We will collect personal identification information from {<USER_DEF/>} only if they voluntarily submit such information to us. {<USER_DEF/>} can always refuse to supply personal identification information, however, doing so, may prevent them from engaging in certain {<WEBSITE_DEF/>} related activities.</p>

                      <h4 id="Section3">Non-personal identification information</h4>
                      <p>ReadyCrowns may collect non-personal identification information about {<USER_DEF/>} whenever they interact with our {<WEBSITE_DEF/>}. Non-personal identification information may include the browser name, your IP address, domain name, access times, and referring website addresses. Information about your computer software and hardware as well as technical information about {<USER_DEF/>} their means of connection to our {<WEBSITE_DEF/>}, (ie: operating systems and the Internet service providers) This information is used for operation of the service and to maintain quality of the service, as well as provide general statistics regarding the use of ReadyCrowns websites.</p>

                      <h4 id="Section4">Web browser cookies</h4>
                      <p>Our {<WEBSITE_DEF/>} may use "cookies" to enhance {<USER_DEF/>} experience. The {<USER_DEF/>} web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. {<USER_DEF/>} may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. Please note, that when choosing to refuse cookies it can make some parts of the {<WEBSITE_DEF/>} not function properly.</p>

                      <h4 id="Section5">How we use collected information</h4>
                      <p>ReadyCrowns collects and uses {<USER_DEF/>} personal information for the following purposes:</p>
                      <ul>
                        <li><strong>To improve customer service</strong>
                          <p>Your information helps ReadyCrowns to more effectively respond to your customer service requests and support your needs.</p></li>
                        <li><strong>To process transactions</strong>
                          <p>ReadyCrowns may use the information {<USER_DEF/>} provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</p></li>
                        <li><strong>To administer a content, promotion, survey or other Site feature</strong>
                          <p>ReadyCrowns may send {<USER_DEF/>} information they agreed to receive about topics we think will be of interest to them.</p></li>
                        <li><strong>To send periodic emails</strong>
                          <p>The email address {<USER_DEF/>} provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If {<USER_DEF/>} decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the {<USER_DEF/>} would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</p></li>
                      </ul>

                      <h4 id="Section6">How we protect your information</h4>
                      <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, including user name, password, transaction information and data stored on our {<WEBSITE_DEF/>}.</p>

                      <p>Sensitive and private data exchange between the {<WEBSITE_DEF/>} and its {<USER_DEF/>} happens over a SSL (Secured Socket Layers) secured communication channel and is encrypted and protected with digital signatures.</p>

                      <h4 id="Section7">Sharing your personal information</h4>
                      <p>We do not sell, trade, or rent {<USER_DEF/>} personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may share data with trusted partners to help perform statistical analysis, send you email, fax or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to these services to ReadyCrowns, and they are required to maintain the proprietary confidentiality of your information.</p>

                      <h4 id="Section8">Advertising</h4>
                      <p>Advertisements appearing on our {<WEBSITE_DEF/>} may be delivered to {<USER_DEF/>} by advertising partners. We do not have links to other websites at the present time. If we add any links to other company's websites in the future, we encourage you to review the privacy statements of those sites. ReadyCrowns is not responsible for the privacy statements or other content on websites outside of the ReadyCrowns website.</p>

                      <h4 id="Section9">Compliance with children's online privacy protection act</h4>
                      <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our {<WEBSITE_DEF/>} from those we actually know are under 13, and no part of our website is structured to attract anyone under 13. If you are under age of 13, you should ask your parent or guardian for permission to use this website.</p>

                      <h4 id="Section10">Changes to this privacy policy</h4>
                      <p>ReadyCrowns has the discretion and right to update this privacy policy at any time. When doing so, we will revise the updated date at the bottom of this page. We encourage {<USER_DEF/>} to frequently check this page for any changes, and to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of any modifications.</p>

                      <p>Your acceptance of these terms By using this {<WEBSITE_DEF/>}, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, we request that you do not use our {<WEBSITE_DEF/>}. Your continued use of our {<WEBSITE_DEF/>} following the posting of changes made to this policy will be deemed your acceptance of those changes.</p>
                      
                      <h4 id="Section11">Contacting us</h4>
                      <p>If you have any questions about this Privacy Policy, the practices of this {<WEBSITE_DEF/>}, or your dealings with this {<WEBSITE_DEF/>}, please feel free to contact us at:</p>
                      <p>Ready Crowns LLC
                        <br/>www.ReadyCrowns.com
                        <br/>3397 N 1200 E Suite 112, Lehi, UT 84043
                        <br/>info@readycrowns.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const USER_DEF = () => {
  return(
    <NavLink className={"definedAnchors"} to={{"pathname": "/privacy", "hash":"userDef"}}>[users]</NavLink>
  )
}
const WEBSITE_DEF = () => {
  return(
    <NavLink className={"definedAnchors"} to={{"pathname": "/privacy", "hash":"websiteDef"}}>[website]</NavLink>
  )
}

export default Privacy;
