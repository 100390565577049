/*
	this.FilterByClass = new FilterByClass();
*/
import React from 'react';
import GlobalAPI from 'global-api';
const GlobalAPINew = new GlobalAPI();
export default class FilterByClass {
  constructor(props) {
  	this.Firestore = new window.firebase.firestore();
  	this.ValidateAllowed = GlobalAPINew.ValidateAllowed;
  }
 

	Get(formID){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser  || !formID){
				window.GlobalUtil.apiFail("yy568znbzy", "Cannot Get Product Missing: formID");
				return reject(false);
			}
			
			var Pointer = this.Firestore.collection("filters").doc(formID); 
	    Pointer.get()
	    .then((doc) => {
	      resolve(doc.data());
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("yid9t1ysdz", error);
				reject(error);
	    });
		});
	}


	GetAll(){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser){
				window.GlobalUtil.apiFail("wbts5iivzi", "Cannot Get Forms");
				return reject(false);
			}

			var Pointer = this.Firestore.collection("filters"); 
	    Pointer.get()
	    .then((querySnapshot) => {
	    	var cleanItems = [];
	      querySnapshot.forEach(function(doc) {
	        cleanItems.push(doc.data());
	      });
	      return cleanItems;
	    })
	    .then((cleanItems) => {
	      resolve(cleanItems);
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("yc8s33s1fn", error);
				reject(error);
	    });
		});
	}

	GetFiltered(filters){
		return new Promise((resolve, reject)=>{
			var isValidUser = this.ValidateAllowed();
			if(!isValidUser){
				window.GlobalUtil.apiFail("wbts5iivzi", "Cannot Get Forms");
				return reject(false);
			}

			var Pointer = this.Firestore.collection("filters"); 
			filters.map((filter, index)=>{
				if(filter.key === 'where'){
					if(filter.value.length == 1) Pointer = Pointer.where(filter.value[0]);
					if(filter.value.length == 2) Pointer = Pointer.where(filter.value[0], filter.value[1]);
					if(filter.value.length == 3) Pointer = Pointer.where(filter.value[0], filter.value[1], filter.value[2]);
				}
				if(filter.key === 'limit'){
					Pointer = Pointer.limit(filter.value);
				}
				if(filter.key === 'orderBy'){
					if(filter.value.length == 1) Pointer = Pointer.orderBy(filter.value[0]);
					if(filter.value.length == 2) Pointer = Pointer.orderBy(filter.value[0], filter.value[1]);
				}
			})
			   		  
	    Pointer.get()
	    .then((querySnapshot) => {
	    	var cleanItems = [];
	      querySnapshot.forEach(function(doc) {
	        cleanItems.push(doc.data());
	      });
	      return cleanItems;
	    })
	    .then((cleanItems) => {
	      resolve(cleanItems);
	    })
	    .catch((error) => {
	    	window.GlobalUtil.apiFail("yc8s33s1fn", error);
				reject(error);
	    });
		});
	}

	Save(input){
		return this.AddOrUpdate(input);
	}

	Update(input){
		return this.AddOrUpdate(input);
	}

	Submit(input){
		return this.AddOrUpdate(input);
	}

	Set(input){
		return new Promise((resolve, reject)=>{
			var Pointer = this.Firestore.collection("filters");
			if(!input){
				console.log("FilterByClass Save() or Update() FAILED");
				console.log(input,"\n\n");  	
				return reject(false);
			}
			
			//UPDATE EXISTING OBJECTS HERE
			Pointer.doc(input.id).set(input)
			.then(obj=>{
				console.log("Updated Form");
				resolve(input);
			})
			.catch(error=>{
				window.GlobalUtil.apiFail("botkfltnz7a", error);
				reject(error);
			});
			
		});
	}


	AddOrUpdate(input){
		return new Promise((resolve, reject)=>{
			var Pointer = this.Firestore.collection("filters");
			if(!input){
				console.log("FilterByClass Save() or Update() FAILED");
				console.log(input,"\n\n");  	
				return reject(false);
			}

			if(!input.id){ //IF DOES NOT HAVE AN ID THEN WE WILL CREATE A NEW ONE AND PUSH THE ID VALUE INTO IT AND UPDATE THE OBJ BELOW
		    Pointer = Pointer.doc();
		    var newUserID = Pointer.id;
		    Pointer.set({id: newUserID});
	      input.id = newUserID;
	    } else {
	    	Pointer = Pointer.doc(input.id); //IF HAS AN ID THEN LETS POINT TO THAT SPOT AND UPDATE BELOW
	    }
	    
			var timeInSeconds = window.GlobalUtil.dateSeconds();
			if(!input.date) input.date = timeInSeconds;
			input.lastChanged = timeInSeconds;
			if(!input.status) input.status = 'active';

			//UPDATE EXISTING OBJECTS HERE
			Pointer.update(input)
			.then(obj=>{
				console.log("Updated Form");
				resolve(input);
			})
			.catch(error=>{
				window.GlobalUtil.apiFail("botkfltnz7a", error);
				reject(error);
			});
			
		});
	}

	Delete(id){
		return new Promise((resolve, reject) => {
			if(!id) reject();
			var Pointer = this.Firestore.collection("filters").doc(id);
			Pointer.update({status: 'complete'})
			.then(index=>{
				resolve(this);
			})
			.catch(info=>{
				window.GlobalUtil.apiFail("wjcrigdb1e", info);
				reject(info);
			});
		});
	}

	TrueDelete(id){
		return new Promise((resolve, reject) => {
			if(!id) reject();
			window.GlobalComp.Confirm({
	      title: 'Deleting',
	      message: 'Are you SURE you wish to delete this?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => {
	            var Pointer = this.Firestore.collection("filters").doc(id);
							Pointer.delete()
							.then(index=>{
								resolve(this);
							})
							.catch(info=>{
								window.GlobalUtil.apiFail("tc839juqee", info);
								reject(info);
							});
	          }
	        },
	        {
	          label: 'No',
	          onClick: () => {
	            //Do nothing, just close;
							resolve(false)
	          }
	        }
	      ]
	    })	
		});
	}

}
