import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import {PendingClass, EmailClass} from 'global-api-helper';
/*
SHOW CONFIRMING MESSAGE
GET HEADER ID
GO TO FIREBASE AND CHANGE IT TO CONFIRMED
  SHOW SUCCESS MESSAGE
OR
  SHOW FAIL MESSAGE
*/
class EmailConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.setAsConfirmed = this.setAsConfirmed.bind(this);
    this.sendEmailConfirmed = this.sendEmailConfirmed.bind(this);
  }

  componentDidMount(){
    this.setAsConfirmed();
  }

  setAsConfirmed(){
    var emailKey = document.location.href.slice(document.location.href.search("emailkey=")+9);
    if(!emailKey){
      this.setState({
        loading: false,
        condition: "no email key"
      });
      return;
    }
    
    //REMOVE THIS ONCE STYLES DONE
    this.setState({
      loading: false,
      condition: 
        //"all worked",
        "already confirmed",
      emailKey: emailKey
    });
    return;
    //REMOVE TO HERE ASWELL


    var PendingClassNew = new PendingClass();
    PendingClassNew.update({
      id: emailKey,
      emailConfirmation: true
    })
    .then((status)=>{
      console.log("success");
      this.setState({
        loading: false,
        condition: status,
        emailKey: emailKey
      }, ()=>{
        if(status === "all worked") this.sendEmailConfirmed();
      });      
    })
    .catch((status)=>{
      console.log("FAil");
      this.setState({
        loading: false,
        condition: status
      });     
    });

  }

  sendEmailConfirmed(){
    var EmailClassNew = new EmailClass()
    EmailClassNew.SendComfirmedEmail("danielezaldivar@gmail.com", "Daniel Zaldivar", "COMPANY_NAME")
  }

  render(){ 
    var {loading, condition, emailKey} = this.state;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    return (
      <div id="Wrapper">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md">
                <div className="centerText">
                  <h1>Email Confirmation</h1>
                  <div className="" style={{"minHeight": "200px", "display": "grid"}}>
                    {
                      loading
                      ? <LoadingComponent 
                          Text="Confirming Email"
                        />
                      : <div className="animated fadeIn">
                        {
                          condition === "all worked" &&
                          <div className="">
                            <h2>CONFIRMED</h2>
                            <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget sapien non tortor mattis sagittis. Nunc commodo quam id eros feugiat mattis.</p>
                          </div> 
                        }
                        {
                          condition === "already confirmed" &&
                          <div className="">
                            <h2>IT'S ALREADY BEEN CONFIRMED</h2>
                            <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget sapien non tortor mattis sagittis. Nunc commodo quam id eros feugiat mattis.</p>
                          </div> 
                        }
                        
                        {
                          condition === "no email key" &&
                          <div className="">
                            <h2>ERROR</h2>
                            <p className="">No email key</p>
                          </div> 
                        }
                        {
                          condition === "failed to confirm" &&
                          <div className="animated fadeIn">
                            <h2>FAILED TO CONFIRM</h2>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailConfirm;
