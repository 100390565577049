import React from 'react';
import EditBusinessDialog from '../../components/dialogs/edit_business';
import {BusinessClass} from 'global-api-helper';
class CompanySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: (window.Session.Business ? window.Session.Business : {})
    };
    this.showEdit = this.showEdit.bind(this);
    this.changeEmailSendingSettings = this.changeEmailSendingSettings.bind(this);
    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", (newObject)=>{
      this.setState({business: newObject})
    });  
  }
  componentWillUnmount(){
    if(this.businessChanged){
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
      this.businessChanged = false;
    }
  }

  showEdit(whatToEdit){
    this.setState({showEditSettings: true});
  }

  changeEmailSendingSettings(name, currentValue){
    var BusinessClassNew = new BusinessClass();
    var {business} =  this.state;
    business[name] = !currentValue;

    BusinessClassNew.update(business)
    .then((success)=>{
      console.log("updated DB");
    })
    .catch((error)=>{
    });

  
  }
  
  render(){
    var {showEditSettings, business} =  this.state;
    var {id, companyName, email, sendEmailOrderRecevied, sendEmailOrderShipped, sendEmailOrderSummary} = business;
    return (
      <div id="CompanySettings" className="panelWrapper">
        {
          showEditSettings &&
          <EditBusinessDialog
            headerText="Edit Business"
            onSave={(newObj)=>{
              this.setState({showEditSettings: undefined});
            }}
            onCancel={()=>{
              this.setState({showEditSettings: undefined});
            }}
          />
        }
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h5 className="panelTitle">Company Settings</h5>
            <div className="panelSubTitle">
              Be sure all the following information is correct.
            </div>
            <div className="panelItem">
              <div className="inputZone">
                <div className="row">
                  <div className="col">
                    <div className="inputArea">
                      <span className="title">Business</span>
                      <span className="responce">{companyName} <i className={"fas fa-edit " + (id ? "" : "disabled")} onClick={()=>{
                        if(id) this.showEdit();
                      }}></i></span>
                    </div>
                    <div className="inputArea">
                      <span className="title">Email Address</span>
                      <span className="responce">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <br />
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h5 className="panelTitle">Email Settings</h5>
            <div className="panelSubTitle">
              Check any of the following to revive email when those events take place.
            </div>
            <div className="panelItem">
              <div className="inputZone">
                <div className="row">
                  <div className="col">
                    <div className="inputArea">
                      <span className="title">Send Email When:</span>
                      <span className="responce">
                        <form action="">
                          <label htmlFor="" className="checkboxLabel" onClick={this.changeEmailSendingSettings.bind(this, "sendEmailOrderRecevied", sendEmailOrderRecevied)}>
                            Order Recevied <input hidden={true} type="checkbox" className="checkbox" checked={sendEmailOrderRecevied} onChange={()=>{}}/>
                            <i className={`far fa${ sendEmailOrderRecevied ? '-check': ''}-square`}></i>
                          </label>
                        </form>
                      </span>
                    </div>
                    <div className="inputArea">
                      <span className="title">Send Email When:</span>
                      <span className="responce">
                        <form action="">
                          <label htmlFor="" className="checkboxLabel" onClick={this.changeEmailSendingSettings.bind(this, "sendEmailOrderShipped", sendEmailOrderShipped)}>
                            Order Shipped <input hidden={true} type="checkbox" className="checkbox" checked={sendEmailOrderShipped} onChange={()=>{}}/>
                            <i className={`far fa${ sendEmailOrderShipped ? '-check': ''}-square`}></i>
                          </label>
                        </form>
                      </span>
                    </div>
                    <div className="inputArea">
                      <span className="title">Send:</span>
                      <span className="responce">
                        <form action="">
                          <label htmlFor="" className="checkboxLabel" onClick={this.changeEmailSendingSettings.bind(this, "sendEmailOrderSummary", sendEmailOrderSummary)}>
                            Weekly Order Summary <input hidden={true} type="checkbox" className="checkbox" checked={sendEmailOrderSummary} onChange={()=>{}}/>
                            <i className={`far fa${ sendEmailOrderSummary ? '-check': ''}-square`}></i>
                          </label>
                        </form>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default CompanySettings;
