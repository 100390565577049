import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/order_summary_dialog.css';
import '../../css/new_order_dialog.css';

import SettingsDialogWrapper from '../settings_dialog_wrapper';
import {OrdersClass} from 'global-api-helper';
/*

*/


class ReportOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: this.props.currentObject,
      loading: false,
      showFailMessage: false,
      whatToEdit: undefined,
      isValid: true
    };
    this.onUpdateOrder = this.onUpdateOrder.bind(this);
    this.currentIssue = (this.props.currentObject.issue ? {...this.props.currentObject.issue} : {});
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.OrdersClass = new OrdersClass();
  }

  async onUpdateOrder(){
    var {currentObject} = this.state;
    const onSuccess = (message)=>{
      this.setState({loading: false});
      this.props.onSave();
    };
    const onFail = (message) => {
      this.setState({loading: false, showFailMessage: true});
      console.log("message");
      console.log(message,"\n\n");
            
      if(this.props.onCancel) this.props.onCancel(message);
    };

    const onStart = () => {};

    this.setState({loading: true});
    currentObject.issue = this.currentIssue;      
    currentObject.date = window.GlobalUtil.getTimeNow();      

    await window.GlobalUtil.delay(500);
    await this.OrdersClass.ReportOrderError(currentObject, onStart, onSuccess, onFail);
  }


  render(){
    var {currentObject, isValid, loading, showFailMessage, whatToEdit} = this.state;
    var states = window.GlobalUtil.states();
    if(showFailMessage) isValid = false;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    const FormBuilder = this.FormBuilder;

    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="600"
        height="600"
        onCancel={this.props.onCancel}
        onSave={this.onUpdateOrder}
        acceptButtonEnabled={isValid}
        clickToCloseOverlay={false}
        zIndex={100}
      >
      <React.Fragment>
        {
          !loading && !showFailMessage &&
          <div className="ReportOrder">
            <FormBuilder
              initialValues={{...this.currentIssue}}
              listOfFields={[
                {
                  title: <span className="">What's Wrong?</span>,
                  type: "textArea",
                  name: "notes",
                  rows: 5,
                  placeholder: "What happened? Tell us here as clear and brief as possible what went wrong? And we will contact you as soon as we can."
                },
                {
                  title: <span className="">Contact Number</span>,
                  type: "phone",
                  name: "phone",
                },
                {
                  title: <span className="">Contact Email <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  required: true,
                  type: "email",
                  name: "email",
                }
              ]}
              onChange={newObj => this.currentIssue = newObj}
              onValidate={(isValid)=>{this.setState({isValid: isValid})}}
            />
          </div>
        }
        {
          loading &&
          <LoadingComponent 
            Header={"Submitting Order"}
            Text={"Please Stand by"}
          />
        }
        {
          showFailMessage &&
          <div id="FailMessage">
            <div className="failMessageWrapper">
              <i className="far fa-surprise"></i>
              <h3>ERROR!</h3>
              <h4>Oh No, something went wrong!</h4>
              <p className="">I could be that the servers are too stressed right now, or we just had a problem receving your order.<br/>
              So please try again in a minute. We will work on fixing the problem soon.</p>
            </div>
          </div>
        }
      </React.Fragment>
    </SettingsDialogWrapper>
    )
  }
}






export default ReportOrder;