import React from 'react';
import ReactTooltip from 'react-tooltip';
import AddNewUsersDialog from '../dialogs/add_new_user';

class Doctor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
      showNewUserModal: false,
      listOfDoctors: [],
      showChooseDoctor: true
    };
    this.getListOfDoctors = this.getListOfDoctors.bind(this);
    this.onChooseDoctor = this.onChooseDoctor.bind(this);
    this.businessChanged = window.GlobalUtil.subscribeEvent("businessChanged", (newBuissness)=>{
      this.setState({
        listOfDoctors: this.getListOfDoctors(newBuissness),
        showChooseDoctor: false //WHEN I GET THE NEW LIST OF DOCTORS I SHOULD ALSO RESET THE DROPDOWN. THAT IS WHAT THIS IS DOING
      }, ()=>{
        this.setState({
          showChooseDoctor: true //RELOAD THIS SO IT HAS THE NEW DOCTORS
        });
      });
    });
  }

  componentDidMount(){
    this.setState({listOfDoctors: this.getListOfDoctors(window.Session.Business)});
  }

  componentWillUnmount(){
    if(this.businessChanged){
      window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChanged);
      this.businessChanged = false;
    }
  }

  getListOfDoctors(newBuissness){
    var listOfUsers = (newBuissness && newBuissness.users ? newBuissness.users: {});
    return(Object.keys(listOfUsers).filter(key=>listOfUsers[key].type === "doctor").map((key, index)=>{
      return({
        name: `${listOfUsers[key].name}`,
        id: key
      });
    }))
  }

  onChooseDoctor(chosenDoctor){
    var {order} = this.state;
    order.doctor = chosenDoctor;
    this.setState({order: order});
    this.props.onChange(order);     
  }

  render(){
    const DropdownSelect = window.GlobalComp.DropdownSelect;
    const {order={}, showNewUserModal, showChooseDoctor, listOfDoctors} = this.state;
    var {doctor} = order;
    return (
      <div id="EditOrderDoctor" className="editOrder">
        <div className="sectionIconLeft">
          <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-user-md" /></div>
          <div className="sectionIconLeftContent">
            <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-user-md" /></div>Choose a Doctor
              {(!doctor || !doctor.id) && <span className="form-group" style={{"margin": "-10px 0 0 0"}}><small className="form-text red-text text-muted">Required</small></span>}
            </div>
            <div className="inputPatient">
              {
                showChooseDoctor
                ? <DropdownSelect 
                    options={listOfDoctors}
                    selected={doctor}
                    onChange={this.onChooseDoctor}
                    placeholder={"Please Choose a Doctor"}
                  />
                : null
              }
              <span>Or</span>
              <button className="button addNewPatientButton" onClick={()=>{this.setState({showNewUserModal: true})}} data-tip="Add New Doctor">New Doctor</button>
            </div>
          </div>
          {
            showNewUserModal
            &&  <AddNewUsersDialog 
                  headerText={"New Doctor"}
                  currentObject={{
                    name: '',
                    type: 'doctor',
                  }}
                  pushOrRemove={'push'}
                  onSave={(newDoctor)=>{
                    //BECAUSE WE ARE RUNNING THAT PROCESS ABOVE WE WILL AUTOMATICLY UPDATING (showChooseDoctor & listOfDoctors)
                    this.onChooseDoctor({name: `${newDoctor.name}`, id: newDoctor.id});
                    this.setState({showNewUserModal: undefined});
                  }}
                  onCancel={()=>{
                    this.setState({showNewUserModal: undefined});
                  }}
                />
          }
        </div>
        <ReactTooltip offset={{top: 10}}/>
      </div>
    );
  }
}

export default Doctor;
