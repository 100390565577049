import React from 'react';
import '../css/order_tracker.css';
import {NavLink, Link} from 'react-router-dom';
class OrderTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersByStatus: (window.Session.Business && window.Session.Business.ordersByStatus ? window.Session.Business.ordersByStatus : {})
    };

    this.businessChangedListener = window.GlobalUtil.subscribeEvent("businessChanged", (newBusiness)=>{
      if(newBusiness && newBusiness.ordersByStatus) this.setState({ordersByStatus: newBusiness.ordersByStatus});
    });

    this.isAdminTabListener = window.GlobalUtil.subscribeEvent("isAdminTabListener", (isAdmin)=>{
      if(window.Session.BusinessSuperAdmin && window.Session.BusinessSuperAdmin.ordersByStatus) this.setState({ordersByStatus: window.Session.BusinessSuperAdmin.ordersByStatus});
      else if(window.Session.Business && window.Session.Business.ordersByStatus) this.setState({ordersByStatus: window.Session.Business.ordersByStatus});
    });
  }

  componentDidMount(){

  }

  componentWillUnmount(){
    if(this.businessChangedListener) window.GlobalUtil.unsubscribeEvent("businessChanged", this.businessChangedListener);
    if(this.isAdminTabListener) window.GlobalUtil.unsubscribeEvent("isAdminTabListener", this.isAdminTabListener);
  }

  render(){
    var {admin} = this.props;
    var {ordersByStatus} = this.state;
    var {active=0, complete=0, pending=0, reviewing=0, shipping=0} = ordersByStatus;
    var onAdminTab = window.GlobalUtil.IsSuperAdminUserAndTab();
    return (
      <div id="OrderTracker">
        <div className="container-fluid">
          <div className="row">
            <div className="col-auto">
              <div className="title">
                Orders Tracker
              </div>
            </div>
            <div className="col">
              <div className="info">
                <div className="row">
                  <div className="col">
                    <NavLink exact to={!onAdminTab ? "/panel/orders" : "/panel/admin/orders/pending"}>
                      <div className="states state-pending">
                        <div className="state">Pending</div>
                        <div className="totals">{pending}</div>
                        <div className="arrow-right"/>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col">
                    <NavLink exact to={!onAdminTab ? "/panel/orders" : "/panel/admin/orders/active"}>
                      <div className="states state-active">
                        <div className="state">Active</div>
                        <div className="totals">{active}</div>
                        <div className="arrow-right"/>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col">
                    <NavLink exact to={!onAdminTab ? "/panel/orders" : "/panel/admin/orders/reviewing"}>
                      <div className="states state-reviewing">
                        <div className="state">Reviewing</div>
                        <div className="totals">{reviewing}</div>
                        <div className="arrow-right"/>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col">
                    <NavLink exact to={!onAdminTab ? "/panel/orders" : "/panel/admin/orders/shipping"}>
                      <div className="states state-shipping">
                        <div className="state">Shipping</div>
                        <div className="totals">{shipping}</div>
                        <div className="arrow-right"/>
                      </div>
                    </NavLink>
                  </div>
                  <div className="col">
                    <NavLink exact to={!onAdminTab ? "/panel/orders/newFilledOrders" : "/panel/admin/orders/complete"}>
                      <div className="states state-complete">
                        <div className="state">Complete</div>
                        <div className="totals">{complete}</div>
                        <div className="arrow-right"/>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderTracker;

