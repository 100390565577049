import React from 'react';
import AllOrders from './tab_orders';

class TabCurrentOrdersComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount(){}
  componentWillUnmount(){}
  render(){
    return (
      <AllOrders {...this.props} admin={true} title={"List of Completed Orders"} status={"complete"}/>
    );
  }
}



export default TabCurrentOrdersComplete;