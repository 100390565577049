/*
<MultipleSelect
  options={ARRAY} //ARRAY OF ALL OPTIONS TO CHOOSE FROM. EACH OPTION IN ARRAY SHOULD BE AT LEAST {name: 'name', id: 'unique_id'}
  selected={ARRAY} //ARRAY OF CURRENTLY SELECTED OPTIONS
  onChange={FUNCTION} //ON ANY CHANGE RUNS THIS FUNCTION PASSING IN CURRENT ARRAY OF SELECTED OBJECTS
  onFilter={FUNCTION} //IF THIS EXIST IT RUNS IN PLACE OF THE FILTER, ALLOWING FOR FILTER TO BE DONE THOUGH API RATHER THAN IN BROWSER
  newFilter={ //THIS RUNS IN THE PLACE OF BUILT IN FILTER PASSES NEW FILTER PHRASE AND EXPECTS A CALLBACK TO BE RAN WITH NEW OPTIONS ARRAY
    FUNCTION(NEW_PHRASE, CALLBACK){ 
      //GET NEW OPTIONS THEN PASS THEM BACK IN WITH THE CALLBACK FUNCTION
      CALLBACK(NEW_OPTIONS) 
    }
  }  
  optionUseMany={BOOL} //DEFAULT FALSE, THIS LETS YOU SELECT A OPTION MULTIPLE TIMES WITHOUT IT REMOVING IT FROM THE OPTIONS LIST
/>
*/
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

export default class MultipleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleUID: window.GlobalUtil.getRandomId(),
      options: this.props.options.slice(),
      filteredOptions: this.props.options.slice(),
      selected: [],
      filterText: '',
      active: undefined
    };

    this.filterServices = this.filterServices.bind(this);
    this.addService = this.addService.bind(this);
    this.removeService = this.removeService.bind(this);
    this.onShowOptions = this.onShowOptions.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.keyPress = this.keyPress.bind(this);

    this.prevGen = {options: this.props.options.slice(), selected: this.props.selected.slice()};
  }

  componentDidMount(){
    if(this.props.selected && this.props.selected.length > 0) {           
      let {selected, options} = this.state;
      var filteredOptions = options.slice(); 
      if(!this.props.optionUseMany) { //ONLY IF WE CAN CHOOSE EACH OPTION ONCE DO WE NEED TO FILTER OUT THE USED ONES
        this.props.selected.forEach((object, index)=>{              
          filteredOptions = filteredOptions.filter(curObjects=>curObjects.id != object.id)
          selected.push(object);
        })
      }
            
      this.setState({
        selected: selected,
        filteredOptions: filteredOptions,
        options: filteredOptions,
        filterText: ''
      });
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutside);
    document.removeEventListener('keydown', this.keyPress);
  }


  //WHEN CERTAIN IMPORTANT THINGS ARE CHANGED I MUST HARD REFRESH VIDEO LOADER IN ORDER TO GET JQUERY WORKING CORRECTLY AGAIN.
  componentDidUpdate(prevProps, prevState, snapshot) {
    let shouldHardUpdate = false;
    let whatToCheck = ["selected", "options"]; //LIST OF ITEMS IN GENERAL TO COMPARE
    whatToCheck.map((item, index)=>{
      if((!this.prevGen[item] || this.prevGen[item].length < 1) && this.props[item].length > 0) {
        this.prevGen = {options: this.props.options.slice(), selected: this.props.selected.slice()}; 
        shouldHardUpdate = true;
      }
    });   
    if(shouldHardUpdate) {
      this.setState({
        options: this.props.options.slice(),
        filteredOptions: this.props.options.slice(),
        selected: this.props.selected.slice()
      });
    }
  }


  filterServices(event, hideDropdown) {          
    if(this.props.newFilter){ //IF THEY HIGHJACK THE FILTER THEN RUN THIS, PASS IN NEW SEARCH TERM, RETURN NEW FILTERED LIST OF OPTIONS
      let value = event.target.value;
      this.setState({
        filterText: value
      });
      this.props.newFilter(value, (newOptions)=>{      
        this.setState({
          options: newOptions,
          filteredOptions: newOptions
        });
        if(!this.state.showDropdownOptions && !hideDropdown) this.onShowOptions();
      });
    } else {
      let {options} = this.state;
      let filteredOptions = options.slice(); 
      let whatImSearchingFor = event.target.value.toLowerCase().replace(/[^a-zA-Z ]/g, ""); 
      filteredOptions = filteredOptions.filter(object => {
        //Set as lowercase and remove any extra stuff
        var currentItem = object.name.toLowerCase().replace(/[^a-zA-Z ]/g, "");
        return currentItem.search(whatImSearchingFor) !== -1;
      });
      this.setState({
        filteredOptions: filteredOptions,
        filterText: event.target.value
      });
      if(!this.state.showDropdownOptions && !hideDropdown) this.onShowOptions();
    }
  }

  
  addService(object) {
    let {selected, options} = this.state;
    let filteredOptions = options.slice(); 
    filteredOptions = filteredOptions.filter(curObjects=>curObjects.id != object.id)
    if(this.props.optionUseMany) object.id = window.GlobalUtil.getRandomId(); //IF WE CAN CHOOSE AN OPTION MULTIPLE TIMES THEN GIVE EACH NEW ONE A UNIQUE ID
    selected.push(object);
    this.setState({
      selected: selected,
      filteredOptions: filteredOptions,
      options: (this.props.optionUseMany ? options : filteredOptions),
      filterText: '',
      active: undefined
    }, this.removeListener);
  }


  removeService(object) {
    let {selected, options} = this.state;
    let filteredOptions = options.slice(); 
    selected = selected.filter(curObjects=>curObjects.id != object.id)
    if(!this.props.optionUseMany) filteredOptions.push(object); //ONLY PUSH BACK IN PREVIOUSLY SELECTED OPTIONS IF WE CAN ONLY CHOOSE ONE OF EACH OPTION
    this.setState({
      selected: selected,
      filteredOptions: filteredOptions,
      options: filteredOptions,
      filterText: '',
      active: undefined
    }, this.removeListener);
    this.filterServices({"target": {"value": this.state.filterText}}, true); 
  }

  onShowOptions() {
    this.setState({
      showDropdownOptions: true,
      active: undefined
    });
    document.addEventListener('click', this.clickOutside);
    document.addEventListener('keydown', this.keyPress);
  }

  clickOutside(event) {         
    if(!event.target.parentNode || !event.target.isConnected) return;       
    if (!$(event.target).closest(`#${this.state.multipleUID}`).length) {
      this.removeListener()
    }
  }

  removeListener(){
    $(`#${this.state.multipleUID}searchResultsList`).focus()
    let {selected} = this.state;          
    document.removeEventListener('click', this.clickOutside);
    document.removeEventListener('keydown', this.keyPress);
    this.setState({
      showDropdownOptions: false
    });
    if(this.props.onChange) this.props.onChange(selected);
  }

  keyPress(e){
    let {active, filteredOptions} = this.state;
    active = (active !== undefined ? Number(active) : -1);
    var keyCode = e.keyCode;

    if(keyCode == 38 && active !== -1){  //PRESSED UP KEY
      active = (active == 0 ? undefined : active-1);
      this.setState({
        active: active,
        filterText: (active !== undefined && filteredOptions[active] ? filteredOptions[active].name : '')
      })
      //THIS HANDLES THE EXTRA SCROLLING
      //NO NEED TO RUN THIS IF THEIR ARE NOT MORE THAN 4 WITH AT LEAST 1 OFF SCREEN
      if(filteredOptions.length > 4) $(".searchResultsList").animate({scrollTop: ((active ? active : 0)*41)}, 200, 'swing');
      return;
    }
    
    if(keyCode == 40){ //PRESSED DOWN KEY
      active++;
      if(active >= filteredOptions.length) return; //CANT GO PAST CURRENT LIST SIZE
      this.setState({
        active: active,
        filterText: (filteredOptions[active] ? filteredOptions[active].name : '')
      })
      //THIS HANDLES THE EXTRA SCROLLING
      //NO NEED TO RUN THIS IF THEIR ARE NOT MORE THAN 4 WITH AT LEAST 1 OFF SCREEN
      if(filteredOptions.length > 4) $(".searchResultsList").animate({scrollTop: (active*41)}, 200, 'swing');
      return;
    }
    
    if(keyCode == 13 && active > -1){ //PRESSED ENTER AND HAVE SELECTED ON THEN ADD IT
      this.addService(filteredOptions[active]);
      return;
    }

    if(keyCode == 27){ //PRESSED ESCAPE
      this.removeListener();
      return;
    }
  }


  render() {
    let {filteredOptions=[], selected=[], multipleUID, showDropdownOptions, filterText='', options} = this.state;
    if(selected && selected.length > 0) selected = selected.sort((a, b)=>{return(a.name>b.name ? 1 : -1)});
    let {placeholder="Select all services to include"} = this.props;
    return (
      <div id={multipleUID} className="elite-multiple-select">
        <div className="searchItems">
          <input 
            className="searchBox"
            type="text" 
            placeholder={placeholder}
            name="filterServices" 
            onChange={this.filterServices} 
            value={filterText} 
            onClick={this.onShowOptions}
            onKeyDown={()=>{if(!showDropdownOptions) this.onShowOptions()}} 
          />
          { //SHOW DROPDOWN OPTIONS
            showDropdownOptions && filteredOptions.length > 0
            ? <ul className="searchResultsList">
                {
                  filteredOptions.map((object, index) => {
                    return (
                      <li 
                        key={index} 
                        className={`searchResultsListItem ${(this.state.active == index ? "active" : "")}`}
                        onClick={this.addService.bind(this, object)}>
                        {object.name}
                      </li>
                    )
                  }) 
                }
              </ul>
            : null
          }
        </div>
        { //ITEMS CHOOSEN
          selected.length > 0
          ? <div className="selectedItems">
              {selected.map((selected, index) => {
                return (
                  <span key={index} className="selectedItem">
                    <i className="fa fa-times" aria-hidden="true" onClick={this.removeService.bind(this, selected)}></i> {/*circle*/}
                    {selected.name}
                  </span>
                )
              })}
            </div>
          : null
        }
      </div>
    ) 
  }  
}