import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/order_summary_dialog.css';
import '../../css/new_order_dialog.css';

import SettingsDialogWrapper from '../settings_dialog_wrapper';

import PreviewDoctor from '../previewOrder/doctor';
import PreviewPatient from '../previewOrder/patient';
import PreviewProducts from '../previewOrder/products';
import PreviewShipping from '../previewOrder/shipping';
import PreviewInvoice from '../previewOrder/invoice';
import PreviewTotal from '../previewOrder/total';
/*

-------IMPORTANT
WHEN THEY CLICK ON THE DARK AREA DON'T ALLOW IT TO CLOSE. 
MAKE THEM CHOOSE FORM ACCEPT OR CANCEL


THEN SET UP THE EDITING ORDERS DIALOGS IN "edit_order_dialogs"
EACH SHOULD BE API CALL THEN FORM WITH VALIDATION 


NEXT DO THE onUpdateLocalOrder() BELOW REGETTING THE NEW ORDER PRICE
AND UPDATING THE ORDER


FINALY POINT THE onUpdateOrder TO A GLOBAL API UTIL THAT CAN BE USED HERE, ON THE ACCTUAL SUBMITTION, AND ON THE TRY AGAIN SCREEN


*/


class ViewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render(){
    var {currentObject, headerText} = this.props;
    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="1200"
        height="900"
        onCancel={this.props.onCancel}
        onSave={this.props.onCancel}
        acceptButtonEnabled={true}
        clickToCloseOverlay={false}
        zIndex={100}
        acceptButtonText={"Done"}
      >
      <React.Fragment>
        <OrderSummary
          order={currentObject}
          onEdit={()=>{}}
        />
      </React.Fragment>
    </SettingsDialogWrapper>
    )
  }
}


export const OrderSummary = (props) => {
  var {order} = props;
  var {patient="", doctor="", products=[], payment={}, shipping={}, id, date} = order; 
  var total = products.reduce((accumulator, obj) => {
    return Number(accumulator)+Number(obj.total);
  }, 0);
  var newDate = new Date(Number(date)).toLocaleDateString();
  return (
    <div id="OrderSummaryEdit" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          {window.CONST.SITE.InvoiceStamp()}
          <br/>
        </div>
        <div className="col-12 d-md-none d-lg-none d-xl-none">
          <hr/>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <div className="invoiceStamp">
            <div className="name">Order Summary</div>
            <div className="id">Id: {id}</div>
            <div className="date">Submitted: {newDate}</div>
            <div className="date">Total: {window.GlobalUtil.convertToMoney(total)}</div>
          </div>
          <br/>
        </div>
      </div>
      <div className="row"><div className="col"><hr/></div></div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <PreviewDoctor
            order={order}
            showEdit={false}
            onEdit={props.onEdit}
          />
          <br/>
        </div>
        <div className="col-12 d-md-none d-lg-none d-xl-none">
          <hr/>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <PreviewPatient
            order={order}
            showEdit={false}
            onEdit={props.onEdit}
          />
          <br/>
        </div>
      </div>
      

      <div className="row"><div className="col"><hr/></div></div>
      

      <div className="row">
        <div className="col-12">
          <PreviewProducts
            order={order}
            showEdit={false}
            onEdit={props.onEdit}
          />
        </div>
      </div>

      <div className="row"><div className="col"><hr/></div></div>

      
      <div className="row">
        <div className="col-12 col-md-4 col-lg-4">
          <PreviewShipping
            order={order}
            showEdit={false}
            onEdit={props.onEdit}
          />
          <br/>
        </div>

        <div className="col-12 d-md-none d-lg-none d-xl-none"><hr/></div>

        <div className="col-12 col-md-4 col-lg-4">
          <PreviewInvoice
            order={order}
            showEdit={false}
            onEdit={props.onEdit}
          />
          <br/>
        </div>

        <div className="col-12 d-md-none d-lg-none d-xl-none"><hr/></div>

        <div className="col-12 col-md-4 col-lg-4">
          <PreviewTotal
            order={order}
            showEdit={false}
            onEdit={props.onEdit}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewOrder;