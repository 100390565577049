import React from 'react';
import '../../css/api_filter_chooser.css';
import RightSideBar from '../right_sidebar';
//import {FilterByClass} from 'global-api-helper';


export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      filters: this.props.filters,
    };
    this.onShow = this.onShow.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onApplyFilter = this.onApplyFilter.bind(this);
    this.checkIfHasFilters = this.checkIfHasFilters.bind(this);
    this.removeExistingFitlers = this.removeExistingFitlers.bind(this);
  }

  onShow(viewing){
    this.setState({showFilters: true})
  }

  onCancel(){
    this.setState({showFilters: undefined})
  }

  onApplyFilter(newFilter, fullFilterObject){
    this.setState({
      filters: newFilter,
      fullFilterObject: fullFilterObject
    }, ()=>{
      this.props.onChangeFilters(newFilter);
    });        
  }

  checkIfHasFilters(){
    return (this.props.filters.filter(obj=>obj.fromFilter).length > 0)
  }

  removeExistingFitlers(){
    var filters = this.props.filters.map((filterObj, index)=>{
      if(!filterObj.fromFilter){ //IF NOT MODIFED THEN JUST RETURN IT
        return filterObj;
      } else { //IF IT HAD A DEFAULT THEN RETURN THE DEFAULT INSTEAD
        var defaultFilterValue = this.props.defaultFilters.filter(obj=>obj.id === filterObj.id)[0];
        return (defaultFilterValue ? defaultFilterValue : {});//IF NOT FOUND IN DEFAULT THEN RETURN AN OBJECT WITHOUT AN ID
      }
    })
    filters = filters.filter(obj=>obj.id); //REMOVE ANY WITHOUT AN ID
    this.onApplyFilter(filters, {})
  }

  render(){
    var {showFilters, fullFilterObject={}, filters} = this.state;
    var {filtersListOfFields} = this.props;
    var haveCustomFilters = this.checkIfHasFilters();
          
    return(
      <div id="FilterTrigger">
        <div className="filterButtonWrapper">
          <span className="filtersButton buttonDiv" onClick={this.onShow}><i className="fas fa-filter" /> Filter</span>
          {
            haveCustomFilters &&
            <i className="fas fa-times-circle" onClick={this.removeExistingFitlers}/>
          }
        </div>
        {
          showFilters &&
          <FilerForm 
            doc="forms" 
            currentFilter={filters}
            fullFilterObject={fullFilterObject}
            listOfFields={filtersListOfFields}
            onApply={this.onApplyFilter}
            onClose={this.onCancel}
          />
        }
      </div>
    )
  }
}





class FilerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullObject: this.props.fullFilterObject
    };


    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.onChooseFilter = this.onChooseFilter.bind(this);
    this.setupFullObject = this.setupFullObject.bind(this);


    if(Object.keys(this.props.fullFilterObject).length === 0){
      this.state.fullObject = this.setupFullObject();
    }
  }

  setupFullObject(){
    var fullObj = {};
    this.props.currentFilter.map((object, index)=>{
      if(object.key === "where") fullObj[object.id] = object.value.slice().pop();
    })
    return fullObj;
  }

  onChooseFilter(){ //CONVERT OBJ INTO FILTER
    var {listOfFields} = this.props;
    var {fullObject} = this.state;
    var currentFilter = this.props.currentFilter.slice();


    Object.keys(fullObject).map((key, index)=>{
      var val = fullObject[key];            
      //GET FIELD FOR THIS KEY
      var tempField = listOfFields.filter((obj)=>obj.name === key)[0];
      if(!tempField) return;

      if(tempField.type === "datePicker") val = window.GlobalUtil.dateSeconds(val); //IF IS A DATE THEN CONVERT TO NUMBERS  
      var addedNew = false;

      //GET CURRENT FILTER VALUE IF EXSIT ELSE PUSH TO CURRENT FILTER
      currentFilter = currentFilter.map((obj)=>{
        if(obj.id === key){
          addedNew = true;
          var newObj = tempField.convertToFilter(val);
          newObj.fromFilter = true;
          return newObj;
        } 
        return obj;
      });

      if(!addedNew){
        var newObj = tempField.convertToFilter(val);
        newObj.fromFilter = true;
        currentFilter.push(newObj)
      }
    });

  
    
    if(this.props.onApply){
      this.props.onApply(currentFilter, fullObject);
      this.wrapperRef.onClose(this.props.onClose)
    } 
  }




  render(){
    const FormBuilder = this.FormBuilder;
    var {listOfFields=[]} = this.props;
    var {fullObject, isValid} = this.state;
    if(listOfFields.length < 1) return null;  
          
    return(
      <RightSideBar ref={e=>this.wrapperRef=e}>
        <div id="APIFilterChooser">
          <h4>Additional Filters:</h4>
          <div className="subHeader">Choose additional filters to apply to your results.</div>
          <div className="inputForm">
            <FormBuilder
              initialValues={{...fullObject}}
              listOfFields={listOfFields}
              onChange={newObj => {
                this.setState({fullObject: newObj})
              }}
              onValidate={(isValid)=>{this.setState({isValid: isValid})}}
            />
          </div>
          <div className="buttonGroup">
            <button 
              className={`cancel`} 
              onClick={()=>{
                this.wrapperRef.onClose(this.props.onClose)
              }}>
              Cancel
            </button>
            <button className={`submit ${!isValid ? 'disabled' : ''}`} disabled={!isValid} onClick={this.onChooseFilter}>
              Apply
            </button>
          </div>
        </div>
      </RightSideBar>
    )
  }
}


// var FilterByClass = new FilterByClass();
// FilterByClass.Set({
//   id: "forms",
//   filterValues: [
//     {
//       name: 'businessName', 
//       type: 'string'
//     },
//     {
//       name: 'date', 
//       type: 'date'
//     },
//     {
//       name: 'lastChanged', 
//       type: 'date'
//     },
//     {
//       name: 'firstName', 
//       type: 'string'
//     },
//     {
//       name: 'lastName', 
//       type: 'string'
//     },
//     {
//       name: 'type',
//       type: 'array',
//       values: ['home_page']
//     }
//   ]
// })


