import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
//import dotenv from 'dotenv';
import './global-util';
import './global-components';
import GlobalAPI from 'global-api';
import './set-up-session';
import './css/index.css';

import App from './App';
import firebase from 'firebase';
import toastr from 'toastr';
import InitializePrototypes from "./prototypes";

import DUMMYPRODUCTS from "./test-dummy-data";
import Logo1 from "img/logo1.png";
import Logo2 from "img/logo.png";
import Logo3 from "img/logo_svg.svg";
// // import individual service
// import S3 from 'aws-sdk/clients/s3';
//env-cmd -f ./.env

// Your web app's Firebase configuration
//dotenv.config()



var firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_KEY,
    authDomain: "readycrown.firebaseapp.com",
    databaseURL: "https://readycrown.firebaseio.com",
    projectId: "readycrown",
    storageBucket: "readycrown.appspot.com",
    messagingSenderId: process.env.REACT_APP_FB_MSID,
    appId: process.env.REACT_APP_FB_APPID,
    clientId: process.env.REACT_APP_FB_CID,
    scopes: [
		  "email",
		  "profile"
    ]
};

firebase.initializeApp(firebaseConfig);
window.firebase = firebase;





InitializePrototypes();
///GlobalAPINew = new GlobalAPI();
const GlobalAPINew = new GlobalAPI();
window.toastr = toastr;
window.CONST = {
  SITE: {
    "address": "3397 N 1200 E Suite 112, Lehi, UT 84043",
    "phone": [
      "855-RCROWNS",
      "855-727-6967",
      "8557276967"
    ],
    "hours": "Mon to Fri 8:00am to 5:00pm",
    "email": [
      "info@readycrowns.com"
    ],
    "logo": {
      "light": Logo1,
      "dark": Logo2,
      "darkSVG": Logo3
    },
    "invoiceStampArray": [
      "Ready Crowns LLC",
      "3397 N 1200 E",
      "Suite 112,",
      "Lehi, UT 84043",
      "(855) RCROWNS, (855) 727-6967",
      "info@Readycrowns.com"
    ],
    "InvoiceStamp": ()=>{
      return(
        <div className="invoiceStamp">
          <div className="name">Ready Crowns LLC</div>
          <div className="steet">3397 N 1200 E</div>
          <div className="suite">Suite 112,</div>
          <div className="cityState">Lehi, UT 84043</div>
          <div className="tel">Te. (855) 727-6967</div>
        </div>
      ) 
    }
  }
};



//THIS GETS RUNS ALL START UP API'S (LIKE GETTINGS USER OBJ, AND PRODUCTS, ETC.) AND SETS ALL INFO IN window.Session
//SEE set-up-session.js FOR FULL LIST
GlobalAPINew.onAuthStateChanged((user)=>{ 
  // GetUserDBInfo(UID); 
  if(!user.uid){
    window.Session.SessionClear();
    return;
  }
  var businessID = user.uid.replace(/\s/g,''); 
  window.Session.SessionSetup(businessID);
});


serviceWorker.unregister();



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


//177038972076-5nsvp8kg3bk5c7gr7nu0e14pjrn7r3ck.apps.googleusercontent.com
//secret
//vXsiMaU1D4Ln2-9Eq223no8d
//google-site-verification=qyZMWhfZjzZgbzmPwQtANaoob_SiSIG343nXHb-JY4o