import React from 'react';
import NumberFormat from 'react-number-format';
const InputNumberMoney = (props)=>{
  var {name, title, value, min=0, max=undefined, step=0.01, placeholder=""} = props;
  var onValueChange = (value)=>{          
    props.onChange(value.value);
  };
  var inputRef = {};
  return(
    <div className="inputNumberMoney">
     {  
        title && 
        <div className="inputNumberMoneyTitle"  onClick={()=>{if(inputRef) inputRef.focus()}}>{title}</div>
      } 
      <div className="inputNumberMoneyInputWrapper">
        <NumberFormat 
          prefix={'$'}
          thousandSeparator={true}
          className="inputNumberMoneyInput" 
          type="text" 
          name={name} 
          value={value} 
          min={min} 
          max={max} 
          onValueChange={onValueChange} 
          step={step} 
          getInputRef={(newRef)=>{inputRef=newRef}}
          placeholder={placeholder} 
        />
      </div>
    </div>
  )
}
export default InputNumberMoney;