import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/new_order_dialog.css';
// //import '../../css/backend.css';
import SettingsDialogWrapper from '../settings_dialog_wrapper';
import {ShippingLocationClass} from 'global-api-helper';

class AddNewShippingLocation extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      loading: false
    };
    this.onAddNewLocation = this.onAddNewLocation.bind(this);
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.ShippingLocationClass = new ShippingLocationClass();    
  }
  
  componentDidMount(){
    this._isMounted = true;
    this.setState({loading: true});
    this.ShippingLocationClass.Create(this.props.currentLocation)
    .then(newShippingLocationClass=>{
      //this.ShippingLocationClass = newShippingLocationClass;
      if(this._isMounted) this.setState({loading: false});
    });
  }

  componentWillUnmount(){
    this._isMounted = false;
    console.log("componentWillUnmount");
  }

  onAddNewLocation(){
    this.ShippingLocationClass.Set()
    .then(success=>{
      this.props.onSave(success);
    })
    .catch(error=>{
      this.props.onCancel();
    });    
    
  }

  render(){
    var {isValid, loading} = this.state;
    var states = window.GlobalUtil.states();
    const FormBuilder = this.FormBuilder;
    if(loading) var LoadingComponent = window.GlobalComp.Loading;
    var statesOptions = Object.keys(states).map((key, index)=>{
      return(
        {
          value: states[key], 
          title: states[key]
        }
      )
    })
    statesOptions.unshift({
      title: "Choose a State", 
      value: "",
      disabled: true,
    });
    return(
      <SettingsDialogWrapper
        headerText="Invoice Location"
        width="600"
        height="600"
        onCancel={this.props.onCancel}
        onSave={this.onAddNewLocation}
        acceptButtonEnabled={isValid}
      >
        {
          loading
          ? <LoadingComponent 
              Text="Loading Locations"
            />
          : <FormBuilder
              initialValues={this.ShippingLocationClass}
              listOfFields={[
                {
                  title: <span className="">Name <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "text",
                  name: "name",
                  placeholder: "Whom to Invoice",
                  required: true
                },
                {
                  title: <span className="">Email <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "email",
                  name: "email",
                  placeholder: "Email",
                  required: false
                },
                {
                  title: <span className="">Street <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "text",
                  name: "street",
                  placeholder: "Street",
                  required: true
                },
                {
                  title: <span className="">Street 2</span>,
                  type: "text",
                  name: "street2",
                  placeholder: "Street2"
                },
                {
                  title: <span className="">City <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "text",
                  name: "city",
                  placeholder: "City",
                  required: true
                },
                {
                  title: <span className="">State <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "select",
                  name: "state",
                  options: statesOptions,
                  placeholder: "",
                  required: true
                },
                {
                  title: <span className="">Zip Code <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                  type: "number",
                  name: "zip",
                  placeholder: "Zip Code",
                  required: true
                },
              ]}
              onChange={newObj => this.ShippingLocationClass = newObj}
              onValidate={(isValid)=>{
                this.setState({isValid: isValid});
              }}
          />
        }
      </SettingsDialogWrapper>
    )
  }
}



export default AddNewShippingLocation;