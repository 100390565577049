import React from 'react';
// import logo from '../logo.svg';
////import '../../css/backend.css';

class TabSuccessMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onClickTooth = this.onClickTooth.bind(this);
  }
  onClickTooth(){
  }

  render(){
    var {showLogin, activeTeeth} = this.state;
    return (
      <React.Fragment>
        <div id="PageContent">
          <div id="SuccessMessage" className={this.props.className}>
            <div className="successMessageWrapper">
              <i className="far fa-smile-beam"></i>
              <h3>SUCCESS!</h3>
              <h4>Your order has been submitted.</h4>
              <p className="">Feel free to start a new order or view your exiting orders by click on the navigation above. <br/>Any order can be edited until they are being processed. You can see your orders state as the last item in the orders table. </p>
              <div className="buttonGroup">
                <button className="button cancleButton" onClick={this.props.onCancel}>Finished</button>
                <button className="button nextButton" onClick={this.props.onRestart}>Create New</button>
              </div>
            </div>
          </div>
        </div>
        <div id="NavButtons">
          <div className="leftButtons">
          </div>
          <div className="rightButtons">
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default TabSuccessMessage;
