import React from 'react';
import Wrapper from './wrapper';
import {NavLink, Link} from 'react-router-dom';
import Form from './form';

import fullScanner from "../../img/fullScanner1.png";
import wideimage from "../../img/working_crowns.jpg";
import angledPen from "../../img/3dPen1.png";
import TriosReadyLogo from "../../img/3shapeTriosReadyLogo.png";
import ThreeShapeLogo from "../../img/3shapeLogo.png";
import CONTENT from "./CONTENT";
      
const index = (props) => {
  var AccordionList = window.GlobalComp.AccordionList;
  return (
    <Wrapper>
        <div id="homePage">
          <div id="body">
            <div id="Row1" className="section">
              <div className="Row1_background mixedBackground2">
                <div className="Row1_background_overlay">
                </div>
              </div>
              <div className="containerWrapper">
                <div className="container">
                    <div className="row">
                      <div className="col-md">
                        <div className="centerText">
                          <div className="textSection">
                          <div className="preHeader">{CONTENT[0]}</div>
                            <h1>{CONTENT[1]}</h1>
                            <p className="">{CONTENT[2]}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md formContainer">
                        <div id="contactForm" className="contactSection">
                          <Form customHeader={<h3 className="headerFormHeader"><strong className="">{CONTENT[3]}</strong></h3>} />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>


            <div  id="Row2" className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-5">
                    <div className="image">
                      <img src={fullScanner} />
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-7">
                    <div className="textSection">
                      <div className="preHeader">{CONTENT[4]}</div>
                      <h2 className="sectionHeader">{CONTENT[5]}</h2>
                      <p className="mainText">{CONTENT[6]}</p>
                      <ul className="checkBoxList">
                        <li><i className="far fa-check-square" />{CONTENT[7]}</li>
                        <li><i className="far fa-check-square" />{CONTENT[8]}</li>
                        <li><i className="far fa-check-square" />{CONTENT[9]}</li>
                        <li><i className="far fa-check-square" />{CONTENT[10]}</li>
                      </ul>
                      <a href="#contactForm" className="button yellowButton">{CONTENT[11]}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div  id="Row3" className="section blueBackground">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="textSection">
                      <div className="preHeader">{CONTENT[12]}</div>
                      <h2 className="sectionHeader">{CONTENT[13]}</h2>
                      <p className="mainText">{CONTENT[14]}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="hoverBox">
                      <div className="iconWrapper">
                        <i className="fas fa-tooth"></i>
                      </div>
                      <div className="boxHeader">{CONTENT[15]}</div>
                      <p className="boxText">{CONTENT[16]}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="hoverBox">
                      <div className="iconWrapper">
                        <i className="fas fa-clock"></i>
                      </div>
                      <div className="boxHeader">{CONTENT[17]}</div>
                      <p className="boxText">{CONTENT[18]}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="hoverBox">
                      <div className="iconWrapper">
                        <i className="fas fa-tv"></i>
                      </div>
                      <div className="boxHeader">{CONTENT[19]}</div>
                      <p className="boxText">{CONTENT[20]}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="hoverBox">
                      <div className="iconWrapper">
                        <i className="fas fa-stethoscope"></i>
                      </div>
                      <div className="boxHeader">{CONTENT[21]}</div>
                      <p className="boxText">{CONTENT[22]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div  id="Row4" className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="image"><img src={wideimage} alt="" className=""/></div>
                  </div>
                  <div className="col-12">
                    <div className="preHeader">{CONTENT[23]}</div>
                    <h2 className="sectionHeader">{CONTENT[24]}</h2>
                    <p className="mainText">{CONTENT[25]}</p>
                  </div>
                </div>
              </div>
            </div>

            <div  id="Row5" className="section orangeBackground">
              <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="preHeader">{CONTENT[26]}</div>
                      <h2 className="sectionHeader">{CONTENT[27]}</h2>
                      <p className="mainText">{CONTENT[28]}</p>
                      <a href="#contactForm" className="button yellowButton" style={{"marginBottom": "30px"}}>{CONTENT[29]}</a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="image">
                        <img src={angledPen} />
                      </div>
                    </div>
                </div>
              </div>
            </div>

            <div id="Row6" className="section">
              <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-lg-3">
                      <div className="iconText">
                        <div className="iconWrapper">
                          <i className="fas fa-comment-dollar"/>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-lg-9">
                      <div className="text">
                        <h2 className="">{CONTENT[30]}</h2>
                        <p className="">{CONTENT[31]}</p>
                        <a href="#contactForm" className="button yellowButton">{CONTENT[32]}</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>


            <div id="Row7" className="section">
              <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="preHeader">{CONTENT[33]}</div>
                      <h2 className="sectionHeader">{CONTENT[34]}</h2>
                      <p className="mainText">{CONTENT[35]}</p>
                    </div>
                    <div className="col-md-12">
                      <div className="accordionSection">
                        <AccordionList
                          fields={CONTENT[36]}
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
        
              
          </div>
        </div>
      </Wrapper>
  );
}


export default index;
