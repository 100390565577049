import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: (this.props.initialValues ? this.props.initialValues : {}),
      id: window.GlobalUtil.getRandomId(),
      touched: {},
      isValid: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnTouched = this.handleOnTouched.bind(this);
    this.handleChangeValid = this.handleChangeValid.bind(this);

    this.props.listOfFields.map((field, index)=>{ //SET ALL REQUIRED AS INVALID
      if(field.required) this.state.isValid[field.name] = false;
      this.state.touched[field.name] = false;
    });
  }

  handleSubmit(e){
    e.preventDefault()
    var {currentObject} = this.state;
    if(this.props.onSubmit) this.props.onSubmit(currentObject);
  };

  handleOnChange(path, newValue){
    var {currentObject} = this.state;
    window.GlobalUtil.deepSetFromString(currentObject, path, newValue);
    this.setState({currentObject});
    if(this.props.onChange) this.props.onChange(currentObject);
  }

  handleOnTouched(name){
    var {touched} = this.state;
    touched[name] = true;
    this.setState({touched});
  }

  handleChangeValid(name, newState){
    var {isValid} = this.state;
    if(isValid[name] && isValid[name] === newState) return;
    isValid[name] = newState;
    this.setState({isValid});

    var isValid = Object.keys(isValid).filter(key=>isValid[key] === false);
    if(this.props.onValidate){
      if(this.ValidateTimeout) clearTimeout(this.ValidateTimeout);
      this.ValidateTimeout = setTimeout(() => {
        this.props.onValidate(isValid.length === 0);
      }, 500);
    }
  }

  render(){
    var {currentObject, id, touched, isValid} = this.state;
    var {listOfFields=[], values={}, useExternalValues} = this.props;
    if(useExternalValues) currentObject = {...values};
          
    return (
      <form action="" onSubmit={this.handleSubmit}>
        {
          listOfFields.map((field, index)=>{
            if(!FIELD_MAPPER[field.type]) return;
            var FieldObject = FIELD_MAPPER[field.type];                    
            return(
              <div className={(field.type !== "sectionDivider" ? "form-group" : "")} key={index}>
                <FieldObject
                  field={field}
                  id={`${id}${index}`}
                  value={currentObject[field.name]}
                  currentObject={currentObject}
                  meta={{
                    touched: touched[field.name],
                    error: (isValid[field.name] != undefined ? !isValid[field.name] : false),
                  }}
                  onTouch={this.handleOnTouched}
                  onChangeValid={this.handleChangeValid} //SET IF ERROR OR NOT, FOR REQUIRED FIELDS
                  onChange={this.handleOnChange}
                />
              </div>
            )
          })  
        }
        <input type="submit" value="Submit" hidden={true} />
      </form>
    );
  }
}







class ExtenderClass extends React.Component {
  constructor(props) {
    super(props);
    this.Validate = this.Validate.bind(this);
  }
  componentDidMount(){
    this.Validate(this.props.value);
    if(this && this.props.field && this.props.field.onMount) this.props.field.onMount((newName, newValue)=>{this.setState({[newName]: newValue})});
  }

  Validate(newValue){
    if(!this) return;
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    if(valid && this.extraCheck) valid = this.extraCheck(newValue)
    this.props.onChangeValid(name, valid);
  }
}





class FileUpload extends ExtenderClass {
  constructor(props) {
    super(props);
    this.extraCheck = (value)=>{
      var {currentObject={}, field={}} = this.props;
      if(!value || !value.name || !value.url) return false;
      return true;
    }
  }

  render(){ 
    var {field, id, value={name: '', url: ''}, onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, options=[], add, accept, def} = field;
    var tempStyles = {
      "height": "auto",
      "display": "block",
      "padding": "15px 10px",
      "margin": "0 0 15px 0",
      "borderColor": "#ddd"
    }
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <div className="">{value.url}</div>
        {
          def
          ? <React.Fragment>
              <div style={{display: "flex", "alignItems": "center"}}>
                <div style={{maxWidth: "100px"}}>
                  <img src={def} alt="" style={{width: "100%"}}/>
                </div>
                <div style={{flex: 1, paddingLeft: "10px"}}>
                  <div className="">Select New Image</div>
                  <input 
                    type="file" 
                    accept={accept} 
                    className={`form-control ${(meta.error && meta.touched ? 'error' : '')}`} 
                    style={tempStyles}
                    value={value.name} 
                    name={name} 
                    onBlur={()=>{
                      onTouch(name);
                      if(required) this.Validate(value);
                    }}
                    onChange={e=>{
                      onChange(name, {name: e.target.value, file: e.target.files[0]});
                      if(required) this.Validate({name: e.target.value, file: e.target.files[0]});
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          : <input 
              type="file" 
              accept={accept} 
              className={`form-control ${(meta.error && meta.touched ? 'error' : '')}`} 
              style={tempStyles}
              value={value.name} 
              name={name} 
              onBlur={()=>{
                onTouch(name);
                if(required) this.Validate(value);
              }}
              onChange={e=>{
                onChange(name, {name: e.target.value, file: e.target.files[0]});
                if(required) this.Validate({name: e.target.value, file: e.target.files[0]});
              }}
            />
        }
        
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}





class SectionDivider extends ExtenderClass {
  constructor(props) {
    super(props);
  }
  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {title="", className="", styles={}} = field;
    return(
      <div className={className} style={styles}>{title}</div>
    )
  }
}




class RadioBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }
  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, options=[], add} = field;
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <div className={`radioListInline radio-form-control ${((meta.error && meta.touched) ? 'error' : '')}`}>
          {
            options.length > 0 && 
            options.map((option, index)=>{
              var isActive = false;
              return(
                <div key={index} className="radioListItem">
                  <input 
                    id={`${id}${index}`} 
                    type="radio" 
                    value={value} 
                    checked={(value === option.value)} 
                    onChange={(e)=>{
                      onChange(name, e.target.value);
                      if(required) this.Validate(e.target.value);
                    }}
                    onBlur={()=>{
                      onTouch(name);
                      if(required) this.Validate(value);
                    }}
                  />
                  <label htmlFor={`${id}${index}`}>
                    <div className={"box " + (isActive ? 'active' : '')}>{option.title}</div>
                  </label>
                </div>
              )
            })
          }
        </div>
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}


class SelectBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, options=[]} = field;
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <select 
          type="select" 
          className={`form-control ${(meta.error && meta.touched && 'error')}`}
          value={value}
          name={name}
          onChange={e=>{
            onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}>
          {
            options.length > 0 && 
            options.map((object, index)=>{
              if(object.disabled) return(<option key={index} value={object.value} disabled>{object.title}</option>)
              return(
                <option key={index} value={object.value}>{object.title}</option>
              )
            })
          }
        </select>
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}


class TextBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, placeholder=""} = field;
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <input 
          type="text" 
          className={`form-control ${(meta.error && meta.touched && 'error')}`}  
          placeholder={placeholder} 
          value={value}
          name={name}
          onChange={e=>{
            onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}


class TextAreaBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, placeholder, rows, columns} = field;
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <textarea 
          value={value}
          name={name}
          onChange={e=>{
            onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}
          className={`form-control ${(meta.error && meta.touched && 'error')}`}  
          placeholder={placeholder} 
          rows={rows} 
          columns={columns}>
        </textarea>
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}

class DatePickerWrapper extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title} = field;
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <DatePicker 
          selected={value}
          onChange={(newValue)=>{
            onChange(name, newValue);
            if(required) this.Validate(newValue);
          }}
          name={name}

          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}
          className={`form-control ${(meta.error && meta.touched && 'error')}`}  
        />
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}




const MustBeEmail = value => {
  //var valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) //WORKS ONLY WITH .3LETTER SO .COM.NET.GOV ETC
  var valid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(value))
  return(valid ? true : false)
}


class EmailBox extends ExtenderClass {
  constructor(props) {
    super(props);
    this.extraCheck = (value)=>{
      var {currentObject={}, field={}} = this.props;
      //if(!field.valueToCheck) return false;
      if(!currentObject || !currentObject[field.name] || currentObject[field.name] !== value) return false;
      return MustBeEmail(value);
    }
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, placeholder, hideMessage} = field;
    return(
      <div>
        <div className="input-header">{title}</div>
        <input 
          type="email" 
          className={`form-control ${(meta.error && meta.touched && 'error')}`}  
          placeholder={placeholder} 
          value={value}
          name={name}
          onChange={e=>{
            onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {meta.error && meta.touched && <small className="form-text text-muted">{meta.error}</small>}
        {!hideMessage && <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>}
      </div>
    )
  }
}



class PhoneBox extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, placeholder} = field;
    return(
      <div>
        <div className="input-header">{title}</div>
        <NumberFormat 
          format="(###) ###-####" 
          mask="_" 
          placeholder={(placeholder ? placeholder : "(XXX) XXX - XXXX")}
          value={value}
          onValueChange={newValue=>{
            onChange(name, newValue.value);
            if(!meta.touched) onTouch(name);
            if(required) this.Validate(newValue.value);
          }}
        />
        {meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}


class NumberInput extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name, required, title, placeholder, min, max, step=1} = field;
    return(
      <div>
        <div className="input-header">{title}</div>
        <input 
          type="number" 
          className={`form-control ${(meta.error && meta.touched && 'error')}`}  
          placeholder={placeholder} 
          value={value}
          name={name}
          min={min}
          max={max}
          step={step}
          onChange={e=>{
            var newValue = e.target.value;
            // var newValueClean = newValue.replace(/[^0-9.]/g,"");
            // var trueValue = (newValueClean ? newValueClean : value);
            // if(trueValue && max && Number(trueValue) > max) trueValue = max;
            onChange(name, newValue);
            if(required) this.Validate(newValue);
          }}
          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}
          pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
        />
        {meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}



class Password extends ExtenderClass {
  constructor(props) {
    super(props);
  }

  render(){ 
    var {field, id, value='', onChange, onTouch, onChangeValid, meta} = this.props;
    var {name="", required, title, placeholder=""} = field;
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <input 
          type="password" 
          className={`form-control ${(meta.error && meta.touched && 'error')}`}  
          placeholder={placeholder} 
          value={value}
          name={name}
          onChange={e=>{
            onChange(name, e.target.value);
            if(required) this.Validate(e.target.value);
          }}
          onBlur={()=>{
            onTouch(name);
            if(required) this.Validate(value);
          }}
        />
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}


class Stars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverValue: (this.props.value ? this.props.value : 0)
    };
    this.validate = this.validate.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }
  componentDidMount(){
    this.validate(this.props.value);
  }

  validate(newValue){
    if(!this.props.field.required){
      this.props.onChangeValid(this.props.field.name, true);
      return;
    }
    var valid = true;
    if(!newValue) valid = false;
    this.props.onChangeValid(this.props.field.name, valid);
  };

  onHover(newValue){
    this.setState({hoverValue: newValue});
  }

  onClick(newValue){
    this.props.onChange(this.props.field.name, newValue);
    this.setState({hoverValue: newValue})
    this.validate(newValue);
  }

  onMouseLeave(event){
    var e = event.toElement || event.relatedTarget;
    if (e.parentNode == this || e == this) {
       return;
    }
    this.setState({hoverValue: this.props.value})
  }

  render(){
    var {field, id, value=0, onChange, onTouch, onChangeValid, meta, styles={}} = this.props;
    var {name="", required, title, placeholder=""} = field;
    var {hoverValue} = this.state;
    value = Number(value)
    return(
      <div>
        {title && <div className="input-header">{title}</div>}
        <div className="reviewStars" onMouseLeave={this.onMouseLeave}>
          <div className="star" 
            onMouseEnter={()=>this.onHover(0.5)} 
            onClick={()=>this.onClick(0.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue > 0 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(1)} 
            onClick={()=>this.onClick(1)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 1 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(1.5)} 
            onClick={()=>this.onClick(1.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 1.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(2)} 
            onClick={()=>this.onClick(2)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 2 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(2.5)} 
            onClick={()=>this.onClick(2.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 2.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(3)} 
            onClick={()=>this.onClick(3)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 3 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(3.5)} 
            onClick={()=>this.onClick(3.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 3.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(4)} 
            onClick={()=>this.onClick(4)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 4 ? 'active' : '')}`} />
          </div>
          <div className="star" 
            onMouseEnter={()=>this.onHover(4.5)} 
            onClick={()=>this.onClick(4.5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 4.5 ? 'active' : '')}`} />
          </div>
          <div className="star star-invert" 
            onMouseEnter={()=>this.onHover(5)} 
            onClick={()=>this.onClick(5)}>
            <i style={styles} className={`fas fa-star-half ${(hoverValue >= 5 ? 'active' : '')}`} />
          </div>
        </div>
        {required && meta.error && meta.touched && <small className="form-text red-text text-muted">{meta.error}</small>}
      </div>
    )
  }
}




const FIELD_MAPPER = {
  text: TextBox,
  textArea: TextAreaBox,
  select: SelectBox,
  radio: RadioBox,
  sectionDivider: SectionDivider,
  fileUpload: FileUpload,
  number: NumberInput,
  email: EmailBox,
  phone: PhoneBox,
  password: Password,
  stars: Stars,
  datePicker: DatePickerWrapper
};

export default FormBuilder;