import React from 'react';
// import logo from '../logo.svg';
//import '../../css/backend.css';
import '../../css/new_order_dialog.css';
import '../../css/view_contact_dialog.css';

import SettingsDialogWrapper from '../settings_dialog_wrapper';
import {InputFormsClass} from 'global-api-helper';

/*

-------IMPORTANT
WHEN THEY CLICK ON THE DARK AREA DON'T ALLOW IT TO CLOSE. 
MAKE THEM CHOOSE FORM ACCEPT OR CANCEL


THEN SET UP THE EDITING ORDERS DIALOGS IN "edit_order_dialogs"
EACH SHOULD BE API CALL THEN FORM WITH VALIDATION 


NEXT DO THE onUpdateLocalOrder() BELOW REGETTING THE NEW ORDER PRICE
AND UPDATING THE ORDER


FINALY POINT THE onUpdateOrder TO A GLOBAL API UTIL THAT CAN BE USED HERE, ON THE ACCTUAL SUBMITTION, AND ON THE TRY AGAIN SCREEN


*/


class ViewContactDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false
    };
    this.onChanged = this.onChanged.bind(this);
  }
  onChanged(){
    this.setState({hasChanged: true});
  }
  render(){
    var {currentObject, headerText} = this.props;
    return(
      <SettingsDialogWrapper
        headerText={this.props.headerText}
        width="1200"
        height="900"
        onCancel={()=>{
          if(this.state.hasChanged) this.props.onSave();
          else this.props.onCancel();
        }}
        onSave={this.props.onSave}
        acceptButtonEnabled={true}
        clickToCloseOverlay={false}
        zIndex={100}
        acceptButtonText={"Done"}
      >
      <React.Fragment>
        <ContactFull
          currentObject={currentObject}
          onChanged={this.onChanged}
        />
      </React.Fragment>
    </SettingsDialogWrapper>
    )
  }
}






const ContactFull = (props) => {
  var {currentObject, onChanged} = props;
  var {date, lastChanged, id, message, businessName, firstName, lastName, typeName, email} = currentObject;
  return (
    <div id="ViewContactDialog" className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="twoColumnText">
            <div className="twoColumnWrapper">
              <div className="title">Name:</div>
              <div className="text">{firstName} {lastName}</div>
            </div>
            <div className="twoColumnWrapper">
              <div className="title">Date:</div>
              <div className="text">{window.GlobalUtil.convertToDate(date, 'M d, Y H:m A')}</div>
            </div>
            <div className="twoColumnWrapper">
              <div className="title">Last Modified:</div>
              <div className="text">{window.GlobalUtil.convertToDate(lastChanged, 'M d, Y H:m A')}</div>
            </div>
            <div className="twoColumnWrapper">
              <div className="title">Business Name:</div>
              <div className="text">{businessName}</div>
            </div>
            <div className="twoColumnWrapper">
              <div className="title">Email:</div>
              <div className="text">{email}</div>
            </div>
            <div className="twoColumnWrapper">
              <div className="title">Type:</div>
              <div className="text">{typeName}</div>
            </div>
            <div className="twoColumnWrapper">
              <div className="title">Message:</div>
              <div className="text"><p>{message}</p></div>
            </div>
            <hr/>
            <ActionTaken currentObject={currentObject} onChanged={onChanged}/>

          </div>
          <br/>
        </div>
      </div>
    </div>
  );
}










class ActionTaken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObject: (this.props.currentObject ? this.props.currentObject : {})
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.FormBuilder = window.GlobalComp.FormBuilder;
    this.InputFormsClass = new InputFormsClass();
  }
  onSubmit(){
    if(!this.state.isValid) return;
    this.fullObject.date = window.GlobalUtil.getTimeNow();
    var {currentObject={}} = this.state;
    if(!currentObject.actions) currentObject.actions = [];
    currentObject.actions.push({...this.fullObject});    
    this.InputFormsClass.Update(currentObject)
    .then((success)=>{
      this.fullObject = {};
      this.setState({
        currentObject: currentObject,
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false});
      });
      this.props.onChanged();
    })
    .catch((error)=>{
      this.fullObject = {};
      currentObject.actions.pop();
      this.setState({
        currentObject: currentObject,
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false});
      });
    });
  }

  render(){
    var {currentObject={}, isValid, hideForm} = this.state;
    var {actions=[]} = currentObject;
    const FormBuilder = this.FormBuilder;
    if(actions) actions = actions.slice().reverse();
    return(
      <div id="ContactActions">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-6 col-xl-6">
            <div id="ListOfActions">
              <h4 className="title">Actions Taken</h4>
              {
                (actions.length > 0)
                ? actions.map((action, index)=>{
                    return(
                      <div className="actionLog" key={index}>
                        <div className="title">
                          {action.title}
                        </div>
                        <div className="date">
                          {window.GlobalUtil.convertToDate(action.date, 'M d, Y')}
                        </div>
                        <div className="action">
                          {action.action}
                        </div>
                      </div>
                    )
                  })
                : <div className="actionLog">
                    <div className="title">
                      No Actions Logged
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6 col-xl-6">
            <div id="NewActionLog">
              <h4 className="title">New Action</h4>
              {
                !hideForm &&
                <FormBuilder
                  initialValues={{...this.fullObject}}
                  listOfFields={[
                    {
                      title: <span className="">Title <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                      type: "text",
                      name: "title",
                      placeholder: "Action Title",
                      required: true
                    },
                    {
                      title: <span className="">Action <span className="red-text"><i className="fas fa-star-of-life" /></span></span>,
                      type: "textArea",
                      rows: 5,
                      name: "action",
                      placeholder: "Action Summary",
                      required: true
                    },
                  ]}
                  onChange={newObj => this.fullObject = newObj}
                  onValidate={(isValid)=>{this.setState({isValid: isValid})}}
                />
              }
              <button className={`submit ${!isValid ? 'disabled' : ''}`} disabled={!isValid} onClick={this.onSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewContactDialog;