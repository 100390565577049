import React from 'react';
import ListTemplate from '../../components/tabsTables/list_template';

import ReviewOrderDialog from '../../components/dialogs/review_order';
import ReportOrderDialog from '../../components/dialogs/report_order';
import EditOrderDialog from '../../components/dialogs/edit_order';
import PreviewOrderDialog from '../../components/dialogs/view_order';
import OrderAgainDialog from '../../components/dialogs/order_again';
import ReactTooltip from 'react-tooltip';
import TabsTable from '../../components/tabsTables/index';
import {TableListOrdersClass} from 'global-api-helper';

const LIST_TO_CANCEL = ["orderToEdit","orderToPreview","newOrder","reviewOrder","reportOrder"];
class TabFilledOrders extends ListTemplate {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      defaultFilters: [
        {
          "key": "where",
          "value": ["business", "==", window.GlobalUtil.getCurrentBusinessID()],
          "id": "business1"
        }
      ],
      filters: [
        {
          "key": "where",
          "value": ["business", "==", window.GlobalUtil.getCurrentBusinessID()],
          "id": "business1"
        }
      ],
      filtersListOfFields: [
        {
          title: "Date Start",
          type: "datePicker",
          name: "dateStart",
          required: false,
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["date", ">=", newValue],
              "id": "dateStart"
            })
          }
        },
        {
          title: "Date End",
          type: "datePicker",
          name: "dateEnd",
          required: false,
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["date", "<=", newValue],
              "id": "dateEnd"
            })
          }
        }
      ],
    };

    if(this.props.trueStatus){
      this.state.defaultFilters.push({
        "key": "where",
        "value": ["trueStatus", "==", this.props.trueStatus],
        "id": "trueStatus1"
      })
      this.state.filters.push({
        "key": "where",
        "value": ["trueStatus", "==", this.props.trueStatus],
        "id": "trueStatus1"
      })
    }
    
    this.apiCall = this.apiCall.bind(this);
    this.createRows = this.createRows.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    
    this.DeleteOrderComp = this.DeleteOrderComp.bind(this);
    this.PreviewOrderComp = this.PreviewOrderComp.bind(this);
    this.EditOrderComp = this.EditOrderComp.bind(this);
    this.OrderAgainComp = this.OrderAgainComp.bind(this);
  }

  componentDidMount(){
    this.apiCall();
  }
  
  apiCall(){          
    this.ListClass = new TableListOrdersClass(); ///Users/danielezaldivar/Sites/ready-crown/src/global-api-classes/table-orders.js
    var {filters, orderBy, limit} = this.state;
    this.setState({loading: true});
    this.onApiLoaded(()=>this.ListClass.GetFiltered({
      filters: filters,
      orderBy: orderBy,
      limit: limit
    }))
  }

  createRows(arrayOfItems){
    var {business={}} = this.state
    var totalTotal = 0;
    var newRows = arrayOfItems.map((object, index)=>{
      totalTotal += Number(object.total);
      var newDate = window.GlobalUtil.convertToDate(object.date, 'n/d/Y');
      if(this.props.trueStatus=="complete"){
        newDate = window.GlobalUtil.convertToDate(object.completedDate, 'n/d/Y');
      }
      if(newDate === window.GlobalUtil.convertToDate(new Date(), 'n/d/Y')) newDate = (<div>Today</div>); 
      var items = [object.id, object.patient, object.doctor, newDate, <StatusView status={object.status}/>, window.GlobalUtil.convertToMoney(Number(object.total)), object.products.length];

      if(this.props.trueStatus!="active"){
        items = items.concat([
          this.OrderAgainComp(object), 
          this.PreviewOrderComp(object), 
          this.ReviewOrderComp(object), 
          this.ReportOrderComp(object)
        ]);
      }
      if(this.props.admin){
        items = items.concat([
          this.EditOrderComp(object), 
          this.DeleteOrderComp(object)
        ]);
      }
            
            
      var Rows = {
        items: items, 
        disableEdit: true, 
        object: object,
        customStyles: [
          {
            fontSize: "14px"
          },
          {},
          {},
          {},
          {},
          {},
          {
            textAlign: 'center'
          },
        ]
      };
      if(this.props.admin){
        var Status = (<StatusDropdown object={object} onChange={(e)=>this.onChangeStatus(object, e)} />);
        Rows.items.push(Status);
      }
      return(Rows);
    })
    this.setState({totalTotal: totalTotal, loading: false, rows: newRows});
  }


  DeleteOrderComp(object){
    return <DeleteOrder 
      object={object} 
      onClick={()=>{
        window.GlobalComp.Confirm({
          title: 'Deleting',
          message: 'Are you SURE you wish to delete this order?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                let DeleteFunction = this.ListClass.Delete(object.id);
                DeleteFunction.then(e=>{
                  this.apiCall();
                });
              }
            },
            {
              label: 'No',
              onClick: () => {
                //Do nothing, just close;
              }
            }
          ]
        });
      }} 
      admin={this.props.admin}
    />
  }

  PreviewOrderComp(object){
    return <PreviewOrder 
      object={object} 
      onClick={()=>this.setState({orderToPreview: object})} 
    />
  }

  EditOrderComp(object){
    return <EditOrder 
      object={object} 
      onClick={()=>this.setState({orderToEdit: object})} 
      admin={this.props.admin}
    />
  }

  OrderAgainComp(object){
    return <OrderAgain 
      object={object} 
      onClick={()=>this.setState({newOrder: object})} 
      admin={this.props.admin}
    />
  }

  ReviewOrderComp(object){
    return <ReviewOrder 
      object={object} 
      onClick={()=>this.setState({reviewOrder: object})} 
      admin={this.props.admin}
    />
  }


  ReportOrderComp(object){
    return <ReportOrder 
      object={object} 
      onClick={()=>this.setState({reportOrder: object})} 
      admin={this.props.admin}
    />
  }


  
  onChangeStatus(obj, e){
    if(!this.props.admin) return;
    var status = e.target.value;
    obj.status = status;
    if(status=="complete"){
      obj.completedDate = Date.now();
      obj.trueStatus = status;
    }
    this.ListClass.Update(obj)
    .then(()=>{
      this.apiCall();
    })
    .catch(fail=>{
      console.log("FAILED TO UPDATE ORDERS @TabAdminOrders@onChangeStatus", fail);
    });
  }




  render(){
    var {orderToEdit, 
      orderToPreview, 
      totalTotal, 
      loading, 
      rows=[], 
      newOrder, 
      reviewOrder, 
      reportOrder, 

      orderBy,

      filters, 
      defaultFilters,
      filtersListOfFields
    } = this.state;
    var {admin, titleFunction} = this.props;
    var {business={}} = window.GlobalUtil;
    return (
      <React.Fragment>
        <TabsTable 
          ref={e=>this.TabsTableRef=e}
          loading={loading}
          title={titleFunction(totalTotal)}
          headers={this.createHeaders(this.props.HEADER, this.props.HEADER_ON_ADMIN)}
          rows={rows}
          CurrentClass={this.ListClass}
          onPaginate={(asyncLoadedFunction)=>{
            this.setState({loading: true});
            this.onApiLoaded(asyncLoadedFunction);
          }}
          actionButton={undefined}


          //ORDER BY OPTIONS
          orderBy={orderBy}
          onChangeOrderBy={(orderByNew)=>{
            this.setState({orderBy: orderByNew}, this.apiCall);
          }}


          //TO SET UP FILTERS FILL THIS OUT
          filters={filters}
          defaultFilters={defaultFilters}
          filtersListOfFields={filtersListOfFields}
          onChangeFilters={(newFilters)=>{
            this.setState({
              filters: newFilters,
              orderBy: ["date", "desc"]
            }, this.apiCall);
          }}
        />
        {
          orderToEdit &&
          <EditOrderDialog
            headerText={"Edit Order"}
            currentObject={orderToEdit}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
        {
          orderToPreview &&
          <PreviewOrderDialog
            headerText={"View Order"}
            currentObject={orderToPreview}
            onSave={()=>{}}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }

        {
          newOrder &&
          <OrderAgainDialog
            headerText={"New Order"}
            currentObject={newOrder}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
        {
          reviewOrder &&
          <ReviewOrderDialog
            headerText={"Review Order"}
            currentObject={reviewOrder}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
        {
          reportOrder &&
          <ReportOrderDialog
            headerText={"Report Order"}
            currentObject={reportOrder}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }

        
        
      </React.Fragment>
    );
  }
}


const StatusDropdown = ({object, onChange}) => {
  var {status} = object;
  return(
    <select type="select" name="status" value={status} onChange={onChange}>
      <option value={'pending'}>Pending</option>
      <option value={'active'}>Active</option>
      <option value={'reviewing'}>Reviewing</option>
      <option value={'shipping'}>Shipping</option>
      <option value={'complete'}>Complete</option>
    </select>
  )
}

const StatusView = ({status}) => {
  return(
    <div className={"status " + status}>
      {status}
    </div>
  )
}


const DeleteOrder = ({object, onClick}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className={"tableTrashIcon"} data-tip="Delete Order" onClick={()=>{onClick(id)}}>
      <i className="fas fa-trash-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}


const PreviewOrder = ({object, onClick}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className={"tableTrashIcon"} data-tip="Preview Order" onClick={()=>{onClick(object)}}>
      <i className="fas fa-eye" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}

const EditOrder = ({object, onClick, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className={"tableTrashIcon"} data-tip="Edit Order" onClick={()=>{onClick(object)}}>
      <i className="fas fa-pencil-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}


const OrderAgain = ({object, onClick, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className="tableTrashIcon" data-tip="Order This Same Order Again"  onClick={()=>{onClick(object)}}>
      <i className="fas fa-cart-plus" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}

const ReviewOrder = ({object, onClick, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className="tableTrashIcon" data-tip="Leave a Review for This Order"  onClick={()=>{onClick(object)}}>
      {
        !object.review
        ? <i className="far fa-star" />
        : <i className="fas fa-star" />
      }
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}

const ReportOrder = ({object, onClick, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className="tableTrashIcon" data-tip="Report Somthing Wrong with this Order"  onClick={()=>{onClick(object)}}>
      <i className="fas fa-exclamation-circle" style={(object.issue && !object.issue.resolved ? {color: "red"} : {})} />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}




export default TabFilledOrders;