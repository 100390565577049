import React from 'react';
const Total = (props) => {
  var {order} = props;
  var {products} = order; 
  var total = products.reduce((accumulator, obj) => {
    return Number(accumulator)+Number(obj.total);
  }, 0);
  return (
    <div  className="editOrder">
      <div className="sectionIconLeft">
        <div className="sectionIconLeftIcon d-none d-md-none d-lg-flex d-xl-flex"><i className="fas fa-dollar-sign" /></div>
        <div className="sectionIconLeftContent">
          <div className="sectionHeader"><div className="sectionIconLeftIcon d-inline-block d-md-inline-block d-lg-none d-xl-none"><i className="fas fa-dollar-sign" /></div>Order Total</div>
          <div className="inputPatientTotal">
            <div className="">{window.GlobalUtil.convertToMoney(total)}</div>
          </div>
          <div className=""><small>Order total does not reflect any discounts added on the invoices. Order total only reflects base price of products selected.</small></div>
        </div>
      </div>
    </div>
  );
}

export default Total;
