import React from 'react';
// import logo from '../logo.svg';
////import '../../css/backend.css';
import {OrdersClass} from 'global-api-helper';
class TabFailMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.OrdersClass = new OrdersClass();
  }

  async onSubmit(){
    var {curOrderObject={}} = this.props;
    const onStart = ()=>{
      this.setState({loading: true});
    };
    const onSuccess = (message)=>{
      this.setState({loading: false});
      this.props.onGotoPage(4);
      this.props.onRestart();
    };
    const onFail = (message)=>{
      this.setState({loading: false});
      this.props.onGotoPage(5);
    };
    await window.GlobalUtil.delay(500);
    await this.OrdersClass.Update(curOrderObject, onStart, onSuccess, onFail);
  };
  
  render(){  
    var {loading} = this.state;
    if(loading) {
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent 
        />
      );
    };
    return (
      <React.Fragment>
        <div id="PageContent">
          <div id="FailMessage" className={this.props.className}>
            <div className="failMessageWrapper">
              <i className="far fa-surprise"></i>
              <h3>ERROR!</h3>
              <h4>Oh No, something went wrong!</h4>
              <p className="">I could be that the servers are too stressed right now, or we just had a problem receving your order.<br/>
              So please try again in a minute. We will work on fixing the problem soon.</p>
              <div className="buttonGroup">
                <button className="button cancleButton" onClick={this.props.onCancel}>Cancel</button>
                <button className={`button nextButton`} onClick={this.onSubmit}>Try Again</button>
              </div>
            </div>
          </div>
        </div>
        <div id="NavButtons">
          <div className="leftButtons">
            
          </div>
          <div className="rightButtons">
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default TabFailMessage;
