import React from 'react';
import ReactTooltip from 'react-tooltip';
import InvoicePDF from '../../components/pdf_invoice';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import PreviewInvoice from '../../components/dialogs/view_invoice';
import {InvoicesClass, InvoiceLocationsClass} from 'global-api-helper';
class TabInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      loading: true,
      locationsArray: [],
      primaryLocation: undefined,
      lastOrderID: undefined,
      firstOrderId: undefined,
      disablePrev: true,
      disableNext: true,
      invoiceToPreview: undefined
    };
    this.apiGetInvoices = this.apiGetInvoices.bind(this);
    this.apiGetInvoiceLocations = this.apiGetInvoiceLocations.bind(this);
    this.handleViewInvoice = this.handleViewInvoice.bind(this);
    this.handleSendInvoice = this.handleSendInvoice.bind(this);
    this.InvoicesClass = new InvoicesClass();
    this.InvoiceLocationsClass = new InvoiceLocationsClass();
  }

  componentDidMount(){
    this.apiGetInvoiceLocations();
    this.apiGetInvoices();
  }

  componentWillUnmount(){}
  
  apiGetInvoiceLocations(){
    this.InvoiceLocationsClass.GetMultiple()
    .then((locationsArray)=>{
      var primaryLocation = locationsArray.filter(obj=>obj.primary);  
      this.setState({
        locationsArray: locationsArray,
        primaryLocation: (primaryLocation[0] ? primaryLocation[0] : locationsArray[0])
      });
    })
    .catch((fail)=>{
      console.log("fail");
      console.log(fail,"\n\n");   
      this.setState({
        locationsArray: [],
        primaryLocation: undefined
      });
    });
  }

  apiGetInvoices(){
    this.setState({loading: true});
    this.InvoicesClass.Get()
    .then(invoices=>{
      console.log("invoices");
      console.log(invoices,"\n\n");
      this.setState({
        invoices: invoices,
        loading: false
      });
    })
    .catch((fail)=>{
      console.log("fail");
      console.log(fail,"\n\n");   
      this.setState({
        invoices: [],
        loading: false
      });
    });
  }

  handleSendInvoice(){}

  handleViewInvoice(invoiceToPreview){
    this.setState({invoiceToPreview: invoiceToPreview});
  }


  render(){
    var {invoices=[], loading, locationsArray, primaryLocation, disablePrev, disableNext, invoiceToPreview} = this.state;
    if(loading) {
      var LoadingComponent = window.GlobalComp.Loading;
      return(
        <LoadingComponent 
        />
      );
    };
    var DownloadIcon = <div className="button" data-tip="Download PDF Invoice">
      <i className="fas fa-file-pdf" />
    </div>
    var LoadingIcon = <div className="button" data-tip="Download PDF Invoice">
      <i className="fas fa-spinner rotating" />
    </div>
    var DownloadLink = (primaryLocation 
      ? <PDFDownloadLink document={ <InvoicePDF invoice={invoices[0]} primaryLocation={primaryLocation} />} fileName="invoice.pdf">
        {({ blob, url, loading, error }) => (loading ? LoadingIcon : DownloadIcon)}
      </PDFDownloadLink>
      : null)


    return (
      <div id="Invoices">
        <h5 className="panelTitle">
          <span className="text">List of Invoices</span>
        </h5>
        {
          invoices.length > 0
          ? invoices.map((invoice, index)=>{
              return(
                <Invoice
                  key={index}
                  invoice={invoice}
                  handleSendInvoice={this.handleSendInvoice}
                  handleViewInvoice={this.handleViewInvoice.bind(this, invoice)}
                  DownloadLink={DownloadLink}
                />
              )
            })
          : <div className="nonFoundMessage">
              <div className="header">No Invoices Found</div>
            </div>
        }
        
        {
          invoiceToPreview &&
          <PreviewInvoice
            headerText={"Preview Invoice"}
            currentObject={invoiceToPreview}
            primaryLocation={primaryLocation}
            onSave={()=>{this.setState({invoiceToPreview: undefined})}}
            onCancel={()=>{this.setState({invoiceToPreview: undefined})}}
          />
        }
      </div>
    );
  }
}

const Invoice = ({invoice, handleSendInvoice, handleViewInvoice, DownloadLink}) => {
  var {id, status, startDate, endDate} = invoice;
  var CleanStart = new Date(startDate).formatDate('n/d/y');
  var CleanEnd = new Date(new Date().setDate(new Date(endDate).getDate()-1)).formatDate('n/d/y');
  return(
    <div className={"invoice " + status}>
      <div className="text">
        <div className="month">{new Date(startDate).formatDate('M')}</div>
        <div className="range">From: {CleanStart} - To: {CleanEnd}</div>
      </div>
      <div className="buttons">
        {/*<div className="button" data-tip="Send Invoice Again" onClick={()=>{
            handleSendInvoice(invoice)
          }}>
          <i className="fas fa-envelope" />
        </div>*/}
        <div className="button" data-tip="View Invoice" onClick={handleViewInvoice}>
          <i className="fas fa-eye" />
        </div>
        {DownloadLink}
      </div>
      <div className="status">
        <div className={"statusText " + status}>
          <span className="">{status}</span>
        </div>
      </div>
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}



const DownloadPDF = ({object, onClick}) => {
  var {id, status} = object;
  return(
    <div className={"tableTrashIcon"} onClick={()=>{onClick(object)}}>
      <i className="fas fa-file-pdf" />
    </div>
  )
}

export default TabInvoices;