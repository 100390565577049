import React from 'react';

class AccordionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: undefined
    };
    this.onSetActive = this.onSetActive.bind(this);
  }
  
  onSetActive(newActive){
    var {activeRow} = this.state;
    if(newActive === activeRow){
      this.setState({activeRow: undefined});
    } else {
      this.setState({activeRow: newActive});
    }
  }

  render(){
    var {activeRow} = this.state;
    var {fields=[]} = this.props;
    return(
      <div className="accordionList">
        {
          fields.map((object, index)=>{
            return(
              <Field
                key={index}
                isActive={(activeRow !== undefined && activeRow === index)}
                onClickHeader={this.onSetActive.bind(this, index)}
                filed={object}
              />
            )
          })
        }
      </div>
    )
  }
}



class Field extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: undefined
    };
  }

  componentDidMount(){
    this.fullHeight = this.TextWrapperRef.offsetHeight;
  }

  render(){
    var {activeRow} = this.state;
    var {isActive, filed} = this.props;
          
    return(
      <div className={`accordionBox ${(isActive ? 'active' : '')}`}>
        <div className="accordionTitle" onClick={this.props.onClickHeader}>
          <div className="text">{filed.title}</div>
          <div className="fa">
            {
              isActive
              ? <i className="fas fa-plus animated fadeIn" />
              : <i className="fas fa-minus animated fadeIn" />
            }
          </div>
        </div>
        <div className="accordionText" style={(!isActive ? {"maxHeight": "0px"} : {"maxHeight":`${this.fullHeight}px`})}>
          <div className="accordionTextWrapper" ref={e=> this.TextWrapperRef = e}>
            {
              filed.text
            }
          </div>
        </div>
      </div>
    )
  }
}



export default AccordionList;