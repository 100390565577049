import React from 'react';
import ListTemplate from '../../components/tabsTables/list_template';

import EditOrderDialog from '../../components/dialogs/edit_order';
import PreviewOrderDialog from '../../components/dialogs/view_order';
import OrderAgainDialog from '../../components/dialogs/order_again';
import ReactTooltip from 'react-tooltip';
import TabsTable from '../../components/tabsTables/index';
import {TableListOrdersClass, BusinessClass, EmailClass} from 'global-api-helper';
const LIST_TO_CANCEL = ["orderToEdit","orderToPreview","newOrder"];
class TabCurrentOrders extends ListTemplate {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      defaultFilters: [
      ],
      filters: [
      ],
      filtersListOfFields: [
        {
          title: "Date Start",
          type: "datePicker",
          name: "dateStart",
          required: false,
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["date", ">=", newValue],
              "id": "dateStart"
            })
          }
        },
        {
          title: "Date End",
          type: "datePicker",
          name: "dateEnd",
          required: false,
          convertToFilter: (newValue)=>{
            return({
              "key": "where",
              "value": ["date", "<=", newValue],
              "id": "dateEnd"
            })
          }
        }
      ],
    };

    this.createRows = this.createRows.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);

    this.onChangeStatus = this.onChangeStatus.bind(this);

    this.DeleteOrderComp = this.DeleteOrderComp.bind(this);
    this.PreviewOrderComp = this.PreviewOrderComp.bind(this);
    this.OrderAgainComp = this.OrderAgainComp.bind(this);
    this.EditOrderComp = this.EditOrderComp.bind(this);
  }
  
  componentDidMount(){
    this.apiCall();
  }


  apiCall(){
    this.ListClass = new TableListOrdersClass(); ///Users/danielezaldivar/Sites/ready-crown/src/global-api-classes/table-orders.js
    var {filters, orderBy, limit} = this.state;
    this.setState({loading: true});
    
    filters.unshift({
      "key": "where",
      "value": ["status", "==", this.props.status],
      "id": "status"
    });
    this.onApiLoaded(()=>this.ListClass.GetFiltered({
      filters: filters,
      orderBy: orderBy,
      limit: limit
    }));
  }


  createRows(arrayOfItems){
    var {business={}} = this.state
    var totalTotal = 0;
    var newRows = arrayOfItems.map((object, index)=>{
      totalTotal += Number(object.total);
      var newDate = new Date(Number(object.date)).toLocaleDateString();
      var items = [object.id, object.business, object.patient, object.doctor, newDate, <StatusView status={object.status}/>, window.GlobalUtil.convertToMoney(Number(object.total)), object.products.length, this.EditOrderComp(object), this.DeleteOrderComp(object), this.OrderAgainComp(object), this.PreviewOrderComp(object)];
      var Rows = {
        items: items, 
        disableEdit: true, 
        object: object,
        customStyles: [
          {
            fontSize: "12px"
          },
          {
            fontSize: "12px"
          },
          {},
          {},
          {},
          {},
          {},
          {
            textAlign: 'center'
          },
        ]
      };
      if(this.props.admin){
        var Status = (<StatusDropdown object={object} onChange={(e)=>this.onChangeStatus(object, e)} />);
        Rows.items.push(Status);
      }
      return(Rows);
    })
    this.setState({totalTotal: totalTotal, loading: false, rows: newRows});
  }


  DeleteOrderComp(object){
    return <DeleteOrder 
      object={object} 
      onDelete={()=>{
        window.GlobalComp.Confirm({
          title: 'Deleting',
          message: 'Are you SURE you wish to delete this order?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                let DeleteFunction = this.ListClass.Delete(object.id);
                DeleteFunction.then(e=>{
                  this.apiCall();
                });
              }
            },
            {
              label: 'No',
              onClick: () => {
                //Do nothing, just close;
              }
            }
          ]
        });
      }} 
      admin={this.props.admin}
    />
  }

  PreviewOrderComp(object){
    return <PreviewOrder 
      object={object} 
      onPreview={()=>this.setState({orderToPreview: object})} 
    />
  }

  EditOrderComp(object){
    return <EditOrder 
      object={object} 
      onEdit={()=>this.setState({orderToEdit: object})} 
      admin={this.props.admin}
    />
  }

  OrderAgainComp(object){
    return <OrderAgain 
      object={object} 
      onOrderAgain={()=>this.setState({newOrder: object})} 
      admin={this.props.admin}
    />
  }



  handleSortBy(whatToSortBy){
    var {orderBy, ascDesc} = this.state;
    if(whatToSortBy !== orderBy) {
      orderBy = whatToSortBy;
      ascDesc = 'asc';
    } else {
      if(ascDesc == 'desc') {
        orderBy = undefined;
        ascDesc = 'asc';
      } else {
        orderBy = whatToSortBy;
        ascDesc = 'desc';
      }
    }

    this.setState({
      orderBy: orderBy, 
      ascDesc: ascDesc
    }, this.apiCall);
  }


  
  onChangeStatus(obj, e){
    if(!this.props.admin) return;
    var status = e.target.value;
    obj.status = status;

    if(status=="shipping"){//WHEN STATUS CHANGED TO SHIPPED THEN SEND SHIPPED EMAIL, IF THEY OPTED INTO THAT.
      obj.shippedDate = Date.now();
      //GET EMAIL AND SENT "PRODUCT SENT" EMAIL
      var BusinessClassNew = new BusinessClass();
      BusinessClassNew(obj.business)
      .then((UserObj)=>{
        if(!UserObj || !UserObj.sendOrderSentEmail) return;  //IF THEY DIDN'T WANT EMAILS THEN DONT SEND THEM.
        var EmailClassNew = new EmailClass();
        EmailClassNew.SendOrderSent(UserObj.email, obj);
      })
      .catch(()=>{
        //FAILD AT GETTING BUSINESS
      });
    }

    if(status=="complete"){
      obj.completedDate = Date.now();
      obj.trueStatus = status;
    }

    this.ListClass.Update(obj)
    .then(()=>{
      this.apiCall();
    })
    .catch(fail=>{
      console.log("FAILED TO UPDATE ORDERS @TabAdminOrders@onChangeStatus", fail);
    });
  }


  render(){
    var {
      orderToEdit, 
      orderToPreview, 
      totalTotal, 
      loading, 
      rows=[], 
      newOrder, 

      orderBy,

      filters, 
      defaultFilters,
      filtersListOfFields
    } = this.state;
    var {admin, title, filter} = this.props;
    var {business={}} = window.GlobalUtil;
    const Title = <div style={{display: "flex"}}><div style={{flex: 1}}>{title}</div><div className="">Total: {window.GlobalUtil.convertToMoney(Number(totalTotal))}</div></div>;
    
    return (
      <React.Fragment>
        <TabsTable 
          ref={e=>this.TabsTableRef=e}
          loading={loading}
          title={(!admin ? "List of current Orders" : Title)}
          headers={this.createHeaders(HEADER, HEADER_ON_ADMIN)}
          rows={rows}
          actionButton={undefined}

          CurrentClass={this.ListClass}
          onPaginate={(asyncLoadedFunction)=>{
            this.setState({loading: true});
            this.onApiLoaded(asyncLoadedFunction);
          }}

          //ORDER BY OPTIONS
          orderBy={orderBy}
          onChangeOrderBy={(orderByNew)=>{
            this.setState({orderBy: orderByNew}, this.apiCall);
          }}


          //TO SET UP FILTERS FILL THIS OUT
          filters={filters}
          defaultFilters={defaultFilters}
          filtersListOfFields={filtersListOfFields}
          onChangeFilters={(newFilters)=>{
            this.setState({
              filters: newFilters,
              orderBy: ["date", "desc"]
            }, this.apiCall);
          }}
        />
        {
          orderToEdit &&
          <EditOrderDialog
            headerText={"Edit Order"}
            currentObject={orderToEdit}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
        {
          orderToPreview &&
          <PreviewOrderDialog
            headerText={"View Order"}
            currentObject={orderToPreview}
            onSave={()=>{}}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
        {
          newOrder &&
          <OrderAgainDialog
            headerText={"New Order"}
            currentObject={newOrder}
            onSave={()=>{
              this.onCancel(LIST_TO_CANCEL);
              this.apiCall();
            }}
            onCancel={()=>this.onCancel(LIST_TO_CANCEL)}
          />
        }
      </React.Fragment>
    );
  }
}


const StatusDropdown = ({object, onChange}) => {
  var {status} = object;
  return(
    <select type="select" name="status" value={status} onChange={onChange}>
      <option value={'pending'}>Pending</option>
      <option value={'active'}>Active</option>
      <option value={'reviewing'}>Reviewing</option>
      <option value={'shipping'}>Shipping</option>
      <option value={'complete'}>Complete</option>
    </select>
  )
}


const StatusView = ({status}) => {
  return(
    <div className={"status " + status}>
      {status}
    </div>
  )
}


const DeleteOrder = ({object, onDelete, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className={"tableTrashIcon " + ((status!="pending" && !admin) ? " disabled" : "")} data-tip="Delete Order" onClick={()=>{onDelete(id)}}>
      <i className="fas fa-trash-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}


const PreviewOrder = ({object, onPreview}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className={"tableTrashIcon"} data-tip="Preview Order" onClick={()=>{onPreview(object)}}>
      <i className="fas fa-eye" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}

const EditOrder = ({object, onEdit, admin}) => {
  var {id, status, priceOverride} = object;
  //DONT ALLOW EDITING IF priceOverride EXIST OR THEY COULD ADD PRODUCT LATER AND KEEP OVERRIDE PRICE.
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className={"tableTrashIcon " + (((status != "pending" || priceOverride) && !admin) ? " disabled" : "")} data-tip="Edit Order"  onClick={()=>{onEdit(object)}}>
      <i className="fas fa-pencil-alt" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}

const OrderAgain = ({object, onOrderAgain, admin}) => {
  var {id, status} = object;
  return(
    <div style={{textAlign: 'center', "maxWidth":"none"}} className="tableTrashIcon" data-tip="Order This Same Order Again"  onClick={()=>{onOrderAgain(object)}}>
      <i className="fas fa-cart-plus" />
      <ReactTooltip offset={{top: 10}}/>
    </div>
  )
}



const HEADER = [
  {
    key: 'id', 
    name: 'ID', 
    width: "200",
    style: {},
    disableOrderBy: true
  },
  {
    key: 'business', 
    name: 'Business', 
    width: "200",
    style: {}
  },
  {
    key: 'patient', 
    name: 'Patient', 
    width: undefined,
    style: {}
  },
  {
    key: 'doctor', 
    name: 'Doctor', 
    width: undefined,
    style: {}
  },
  {
    key: 'date', 
    name: 'Date Added', 
    width: undefined,
    style: {}
  },
  {
    key: 'status', 
    name: 'Status', 
    width: undefined,
    style: {},
    disableOrderBy: true
  },
  {
    key: 'total', 
    name: 'Total', 
    width: undefined,
    style: {}
  },
  {
    key: 'products', 
    name: 'Products', 
    width: "100",
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'edit', 
    name: 'Edit', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'delete', 
    name: 'Delete', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },
  {
    key: 'reOrder', 
    name: 'Order Again', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },  
  {
    key: 'preview', 
    name: 'Preview', 
    width: '100',
    style: {
      textAlign: 'center'
    },
    disableOrderBy: true
  },    
];

const HEADER_ON_ADMIN = [{
  key: 'status_change', 
  name: 'Change Status', 
  width: '200',
  style: {},
  disableOrderBy: true
}];


export default TabCurrentOrders;